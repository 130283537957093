import { Intent } from '@blueprintjs/core';
import React from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { discardError } from '../../redux/actions/errors-actions';
import { AppToaster } from '../../utils/toaster';
import { accessDeniedErrorIdentifier } from './error-identifiers/access-denied-error-identifier';
import { cancelSubsBeforeDeactivationErrorIdentifier } from './error-identifiers/cancel-subs-before-deactivation-error-identifier';
import { emailAlreadyTakenErrorIdentifier } from './error-identifiers/email-already-taken-error-identifier';
import { emailNotConnectedErrorIdentifier } from './error-identifiers/email-not-connected-error-identifier';
import { emailUnsubscribeErrorIdentifier } from './error-identifiers/email-unsubscribe-error-identifier';
import { ErrorIdentifier } from './error-identifiers/error-identifier';
import { genericGraphQLErrorIdentifier } from './error-identifiers/generic-graphql-error-identifier';
import { invalidCredentialsErrorIdentifier } from './error-identifiers/invalid-credentials-error-identifier';
import { invalidLoginVerificationErrorIdentifier } from './error-identifiers/invalid-login-verification-error-identifier';
import { invalidReportRecipientErrorIdentifier } from './error-identifiers/invalid-report-recipient-error-identifier';
import { invalidResetPasswordCodeErrorIdentifier } from './error-identifiers/invalid-reset-password-code-error-identifier';
import { invalidTwitterCredentialsErrorIdentifier } from './error-identifiers/invalid-twitter-credentials-error-identifier';
import { loggingTroubleErrorIdentifier } from './error-identifiers/logging-trouble-error-identifier';
import { networkErrorIdentifier } from './error-identifiers/network-error-identifier';
import { notAuthenticatedErrorIdentifier } from './error-identifiers/not-authenticated-error-identifier';
import { reportNotFoundErrorIdentifier } from './error-identifiers/report-not-found-error-identifier';
import { twitterAccountAlreadyInuseErrorIdentifier } from './error-identifiers/twitter-account-already-inuse-error-identifier';

export default function ErrorReporter() {
  const dispatch = useAppDispatch();

  const errors = useAppSelector(state => state.errors);

  // Currently Disabled:
  // blankErrorIdentifier
  const knownErrorIdentifiers: ErrorIdentifier[] = [
    networkErrorIdentifier,
    invalidCredentialsErrorIdentifier,
    emailNotConnectedErrorIdentifier,
    loggingTroubleErrorIdentifier,
    emailAlreadyTakenErrorIdentifier,
    reportNotFoundErrorIdentifier,
    accessDeniedErrorIdentifier,
    notAuthenticatedErrorIdentifier,
    invalidReportRecipientErrorIdentifier,
    invalidResetPasswordCodeErrorIdentifier,
    cancelSubsBeforeDeactivationErrorIdentifier,
    invalidTwitterCredentialsErrorIdentifier,
    invalidLoginVerificationErrorIdentifier,
    twitterAccountAlreadyInuseErrorIdentifier,
    emailUnsubscribeErrorIdentifier,
    genericGraphQLErrorIdentifier,
  ];

  const defaultError = (error: Error) => {
    AppToaster.show({
      intent: Intent.DANGER,
      icon: 'warning-sign',
      message:
        'Oops! Something went wrong. Please contact us at <a href="mailto:help@newslit.co">help@newslit.co</a> if you continue having issues.',
    });

    dispatch(discardError(error));
  };

  const getError = (error: Error) => {
    if (!error) {
      return;
    }

    // false errorElement is set by error identifiers which are using our AppTosaster
    // null errorElement will set if there is no matching ErrorIdentifier
    let errorElement: JSX.Element | null | false = null;

    for (let i = 0; i < knownErrorIdentifiers.length && errorElement === null; i++) {
      errorElement = knownErrorIdentifiers[i](i, error, (error: Error) =>
        dispatch(discardError(error))
      );
    }

    if (errorElement === null) {
      return defaultError(error);
    }

    return errorElement ? errorElement : null;
  };

  return <>{errors.map(x => getError(x))}</>;
}
