import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { addSlackChannel } from '../../../../redux/actions/brief-settings-actions';
import { reportError } from '../../../../redux/actions/errors-actions';
import { cancelSlackAuth } from '../../../../services/slack';
import './SlackCallbackPage.scss';

const SlackCallbackPage: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();

  // Redux state
  const dispatch = useAppDispatch();
  const session = useAppSelector(state => state.session);
  const slackIntegration = useAppSelector(state => state.slackIntegration);

  const [failed, setFailed] = useState(false);
  const [code, setCode] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);

  const closeWindow = useCallback(() => {
    if (window.opener && window.opener.popupCompleted) {
      window.opener.popupCompleted();
    } else {
      history.push('/');
    }
  }, [history]);

  useEffect(() => {
    if (slackIntegration.briefAdded) {
      closeWindow();
    }
  }, [slackIntegration.briefAdded, closeWindow]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const c = queryParams.get('code');
    const s = queryParams.get('state');
    const error = queryParams.get('error');

    if (error === 'access_denied') {
      cancelSlackAuth();
      setFailed(true);
      return;
    }

    if (error) {
      dispatch(reportError(new Error(error)));
      setFailed(true);
      return;
    }

    if (!c || !s) {
      dispatch(reportError(new Error('Missing "code" or "state".')));
      setFailed(true);
      return;
    }

    setCode(c);
    setState(s);
  }, [location.search, history, dispatch, setCode, setState, setFailed, closeWindow]);

  useEffect(() => {
    if (code && state && session && session.token) {
      dispatch(addSlackChannel(code, state));
    } else if (failed) {
      closeWindow();
    }
  }, [code, state, session, dispatch, closeWindow, failed]);

  if (
    failed ||
    (slackIntegration.isAddingSlackToBrief === false && slackIntegration.briefAdded === undefined)
  ) {
    return (
      <div>
        <Card>
          <Card.Body>
            <h1 className="slack-callback-content-header">Oops, something went wrong</h1>
            <Form noValidate>
              <Button className="mt-3 btn-block" as={Link} to={'/'}>
                Back
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <h1 className="slack-callback-content-header">
            <Spinner animation="grow"></Spinner> Authenticating with Slack...
          </h1>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SlackCallbackPage;
