import { PlusIcon } from '@heroicons/react/solid';
import React, { FunctionComponent, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import DashboardNav from '../../components/dashboard-nav/DashboardNav';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import DashboardPageLayout from '../../layouts/dashboard/dashboardPageLayout';
import { closeSidebar } from '../../redux/actions/sidebar-actions';
import { AppDispatch } from '../../redux/store';

const AddNewBriefRoute = '/dashboard/brief/new';

const HomePage: FunctionComponent = () => {
  const history = useHistory();

  const dispatch: AppDispatch = useAppDispatch();

  const account = useAppSelector(state => state.session.account);
  const briefs = useAppSelector(state => state.briefs);

  useEffect(() => {
    if (briefs.isFetching) {
      dispatch(closeSidebar());
    }
  }, [briefs.isFetching, dispatch]);

  const isLoading = () => {
    return !!briefs.isCreating || !!briefs.isFetching;
  };

  // const activeAccount = account?.subscription?.active;

  // TODO(Jose): Temp redirection until we improve our home UX
  if (account.metadata?.isTwitterUser) return <Redirect to="/dashboard/social" />;

  if (briefs && briefs.items && briefs.items.length > 0) {
    return <Redirect to={`/dashboard/brief/${briefs.items[0].id}`} />;
  }

  if (account.metadata?.totalReportsCount === 0 && !account.metadata?.isTwitterUser) {
    return <Redirect to="/dashboard/onboarding/new-report" />;
  }

  if (isLoading()) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardPageLayout>
      <DashboardNav>
        <div className="tw-flex-1 tw-flex">
          <h1 className="tw-text-lg tw-font-bold tw-m-0 tw-text-gray-900 sm:tw-truncate">Home</h1>
        </div>
        <div className="tw-flex tw-ml-4">
          <button
            type="button"
            className="tw-order-0 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-purple-600 hover:tw-bg-purple-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-purple-500 sm:tw-order-1 sm:tw-ml-3"
            onClick={() => {
              history.push(AddNewBriefRoute);
            }}
          >
            <PlusIcon className="tw--ml-1 tw-mr-2 tw-h-5 tw-w-5" aria-hidden="true" />
            New Brief
          </button>
        </div>
      </DashboardNav>

      <main className="tw-flex-1 tw-relative tw-overflow-y-auto tw-bg-gray-100 focus:tw-outline-none">
        <div className="tw-px-4 tw-mt-6 sm:tw-px-6 lg:tw-px-8">
          <ul className="tw-flex tw-flew-row tw-flex-wrap">
            {(briefs?.items || []).map(brief => (
              <li
                key={brief.id}
                className="tw-col-span-1 tw-bg-white tw-rounded-lg tw-shadow tw-divide-y tw-divide-gray-200 tw-m-3 tw-w-full sm:tw-w-80"
              >
                {/* TODO(Jose): Cleanup homepage */}
                {/*<ReportCard report={brief} disabled={!activeAccount} />*/}
              </li>
            ))}
          </ul>
        </div>
      </main>
    </DashboardPageLayout>
  );
};

export default HomePage;
