import React from 'react';
import laborImg from '../../assets/images/labor.svg';
import { usePaywallContext } from '../../contexts/paywall';
import { Subscription } from '../../services/models/subscription';

interface InactiveSubscriptionProps {
  title?: string;
  description?: string;
  subscription: Subscription;
}

export default function InactiveSubscription(props: InactiveSubscriptionProps) {
  const { setPaywallModalShown } = usePaywallContext();

  const title = props.title ? props.title : 'See the latest news for your network';
  const description = props.description
    ? props.description
    : 'Purchase a Newslit plan to start seeing news today';

  const handleBuyNowClick = () => {
    setPaywallModalShown(true);
  };

  return (
    <div className="tw-flex tw-h-full tw-justify-center tw-items-center tw-bg-blur-dashboard tw-bg-no-repeat tw-bg-center tw-text-center">
      <div>
        <div className="tw-flex tw-justify-center">
          <img className="tw-w-72" src={laborImg} alt="upgrade" />
        </div>

        <h2 className="tw-font-bold tw-text-xl md:tw-text-3xl">{title}</h2>

        <p className="tw-mt-4 tw-px-4 tw-max-w-2xl tw-mx-auto tw-text-center tw-text-lg md:tw-text-xl tw-text-gray-600">
          {description}
        </p>

        <div className="tw-mt-4 tw-text-center">
          <button
            type="button"
            className="tw-inline-flex tw-items-center tw-px-6 tw-py-3 tw-border tw-border-transparent tw-shadow-sm tw-text-base tw-font-bold tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark hover:tw-text-white hover:tw-no-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
            onClick={handleBuyNowClick}
          >
            Upgrade Now
          </button>
        </div>
      </div>
    </div>
  );
}
