export const parseZonedDate = (str: string) => {
  if (!str) {
    return undefined;
  }
  return new Date(str.replace(/\[.*\]/, ''));
};

export const formatDate = (date: Date, format: Intl.DateTimeFormatOptions) => {
  return new Intl.DateTimeFormat('en', format).format(date);
};
