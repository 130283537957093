import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Subscription } from '../../services/models/subscription';
import Pricing from '../pricing/pricing';

interface PricingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PricingModal(props: PricingModalProps) {
  const history = useHistory();

  const subscription: Subscription = useAppSelector(state => state.session?.account.subscription);
  const [currentPlanId] = useState(subscription.planId);

  useEffect(() => {
    if (subscription.planId !== currentPlanId) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }, [subscription, history, currentPlanId, props]);

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto"
        onClose={() => {}}
      >
        <div className="tw-flex tw-min-h-screen tw-text-center md:tw-block md:tw-px-2 lg:tw-px-4">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-hidden tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity md:tw-block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="tw-hidden md:tw-inline-block md:tw-align-middle md:tw-h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-translate-y-4 md:tw-translate-y-0 md:tw-scale-95"
            enterTo="tw-opacity-100 tw-translate-y-0 md:tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-translate-y-0 md:tw-scale-100"
            leaveTo="tw-opacity-0 tw-translate-y-4 md:tw-translate-y-0 md:tw-scale-95"
          >
            <div className="tw-flex tw-text-base tw-text-left tw-transition tw-w-full md:tw-inline-block md:tw-max-w-2xl md:tw-px-4 md:tw-my-8 md:tw-align-middle lg:tw-max-w-6xl">
              <div className="tw-w-full tw-relative tw-flex tw-items-center tw-bg-white tw-px-4 tw-pt-14 tw-pb-8 tw-overflow-hidden tw-shadow-2xl sm:tw-px-6 sm:tw-pt-8 md:tw-p-6 lg:tw-p-8">
                <button
                  type="button"
                  className="tw-absolute tw-top-4 tw-right-4 tw-text-gray-400 hover:tw-text-gray-500 sm:tw-top-8 sm:tw-right-6 md:tw-top-6 md:tw-right-6 lg:tw-top-8 lg:tw-right-8"
                  onClick={() => props.onClose()}
                >
                  <span className="tw-sr-only">Close</span>
                  <XIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                </button>

                <Pricing />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
