import gql from 'graphql-tag';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient } from './graphql-client';
import { Story } from './models/story';
import { StoryConnection } from './models/story-connection';
import { StoryShareData } from './models/story-share-data';
import { StoryTweet } from './models/story-tweet';

const GET_BRIEF_STORIES_PAGED = gql`
  query getBriefStoriesPaged($briefId: Long, $after: String, $first: Int) {
    brief(id: $briefId) {
      storiesConnection(after: $after, first: $first) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            author
            excerpt
            id
            imageHeight
            imageUrl
            imageWidth
            language
            publicationDate
            source
            title
            url
            score
            scoreHexColor
            scoreLabel
            isClicked
          }
        }
      }
    }
  }
`;

export const getBriefStoriesPaged = async (
  briefId: number,
  after: number = 0,
  pageSize: number
): Promise<{ hasMoreStories: boolean; stories: Story[]; lastCursor: number }> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ brief: { storiesConnection: StoryConnection } }>({
    query: GET_BRIEF_STORIES_PAGED,
    variables: {
      briefId: briefId,
      after: after.toString(),
      first: pageSize,
    },
  });

  if (!response.data || !response.data.brief) {
    throw new EmptyResponseError();
  }

  const connections = response.data.brief.storiesConnection;
  const edges = connections?.edges || [];
  const pageInfo = connections?.pageInfo;
  const lastCursor = parseInt(pageInfo?.endCursor) || 0;

  return {
    hasMoreStories: !!pageInfo?.hasNextPage,
    stories: edges.map(x => x.node),
    lastCursor,
  };
};

const GET_BRIEF_STORIES_PREVIEW = gql`
  query getBriefStoriesPreview($briefId: Long) {
    brief(id: $briefId) {
      storiesPreview {
        author
        excerpt
        id
        imageHeight
        imageUrl
        imageWidth
        language
        publicationDate
        source
        title
        url
        score
        scoreHexColor
        scoreLabel
        isClicked
      }
    }
  }
`;

export const getBriefStoriesPreview = async (briefId: number): Promise<Story[]> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ brief: { storiesPreview: Story[] } }>({
    query: GET_BRIEF_STORIES_PREVIEW,
    variables: {
      briefId,
    },
  });

  if (!response.data || !response.data.brief || !response.data.brief.storiesPreview) {
    throw new EmptyResponseError();
  }

  return response.data.brief.storiesPreview;
};

const GET_STORY_TWEETS = gql`
  query getBriefStoryTweets($storyId: Long) {
    storyTweets(storyId: $storyId) {
      body
      bodyHtml
      dateCreated
      externalStatusId
      externalUrl
      imageUrl
      name
      username
      time
    }
  }
`;

export const getStoryTweets = async (storyId: number): Promise<StoryTweet[]> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ storyTweets: StoryTweet[] }>({
    query: GET_STORY_TWEETS,
    variables: {
      storyId,
    },
  });

  return response.data.storyTweets || [];
};

// TODO(Jose): GraphQL resolver that needs to be updated to use BriefId
const GET_STORY_SHARE_DATA = gql`
  mutation getStoryShareData($briefId: ID!, $storyId: ID!) {
    storyShareTextCreate(input: { reportId: $briefId, storyId: $storyId }) {
      shareText
      shortUrl
    }
  }
`;

export const getStoryShareData = async (
  briefId: number,
  storyId: number
): Promise<StoryShareData> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ storyShareTextCreate: StoryShareData }>({
    mutation: GET_STORY_SHARE_DATA,
    variables: {
      briefId: briefId.toString(),
      storyId: storyId.toString(),
    },
  });

  if (!response.data || !response.data.storyShareTextCreate) {
    throw new EmptyResponseError();
  }

  return response.data.storyShareTextCreate;
};

const MARK_STORY_AS_VIEWED = gql`
  mutation markStoryAsViewed($storyId: ID!) {
    storyClick(input: { storyId: $storyId }) {
      clickedStoryId
    }
  }
`;

export const markStoryAsViewed = async (storyId: number): Promise<number> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.mutate<{ storyClick: { clickedStoryId: number } }>({
    mutation: MARK_STORY_AS_VIEWED,
    variables: {
      storyId: storyId.toString(),
    },
  });

  if (!response.data || !response.data.storyClick) {
    throw new EmptyResponseError();
  }

  return response.data.storyClick.clickedStoryId;
};
