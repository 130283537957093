import React, { FunctionComponent } from 'react';

interface NewslitAppProps {
  appRef: any;
  title: string;
  uri: string;
  load?: () => void;
}

const NewslitApp: FunctionComponent<NewslitAppProps> = ({ appRef, title, uri, load }) => {
  return (
    <iframe
      ref={appRef}
      id="newslit-reader-app"
      title={title}
      className="tw-flex tw-h-full tw-border-none"
      src={uri}
      height={'100%'}
      width={'100%'}
      onLoad={() => {
        if (load) load();
      }}
    />
  );
};

export default NewslitApp;
