import { SharedLink } from '../../services/models/shared-link';
import * as sharedLinksManager from '../../services/sharedlinks';
import { asyncAction, Dispatch } from '../types';

export enum SharedLinkAction {
  StartFetching = 'SharedLinkActionStartFetching',
  CompleteFetching = 'SharedLinkActionCompleteFetching',
  CancelFetching = 'SharedLinkActionCancelFetching',
}

export const requestSharedLink = (sharedLinkId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startFetchingSharedLink(sharedLinkId));
      const sharedLink = await sharedLinksManager.getSharedLink(sharedLinkId);
      dispatch(completeFetchingSharedLink(sharedLinkId, sharedLink));
    },
    () => cancelFetchingSharedLink(sharedLinkId)
  );

export const startFetchingSharedLink = (sharedLinkId: number) => ({
  type: SharedLinkAction.StartFetching,
  sharedLinkId,
});

export const completeFetchingSharedLink = (sharedLinkId: number, sharedLink: SharedLink) => ({
  type: SharedLinkAction.CompleteFetching,
  sharedLinkId,
  sharedLink,
});

export const cancelFetchingSharedLink = (sharedLinkId: number) => ({
  type: SharedLinkAction.CancelFetching,
  sharedLinkId,
});
