import { Product } from '../../services/models/product';
import * as productsManager from '../../services/products';
import { asyncAction, Dispatch } from '../types';

export enum ProductsAction {
  StartFetching = 'ProductsActionStartFetching',
  CompleteFetching = 'ProductsActionCompleteFetching',
  CancelFetching = 'ProductsActionCancelFetching',
}

export const getProducts = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingProducts());
    const products = await productsManager.getProducts();
    dispatch(completeFetchingProducts(products));
  }, cancelFetchingProducts);

export const startFetchingProducts = () => ({
  type: ProductsAction.StartFetching,
});

export const completeFetchingProducts = (products: Product[]) => ({
  type: ProductsAction.CompleteFetching,
  products,
});

export const cancelFetchingProducts = () => ({
  type: ProductsAction.CancelFetching,
});
