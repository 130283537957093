import { Intent } from '@blueprintjs/core';
import { AppToaster } from '../../../utils/toaster';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const emailAlreadyTakenErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: any,
  discard: DiscardErrorCallback
) => {
  const message = 'Looks like that email address is already taken';

  if (error.message.indexOf(message) === -1) {
    return null;
  }

  discard(error);

  AppToaster.show({
    intent: Intent.WARNING,
    icon: 'warning-sign',
    message: (
      <>
        Looks like that email address is already taken. Get started by{' '}
        <a href="/users/signin">going to your dashboard</a>
      </>
    ),
  });

  return false;
};
