import { Menu, Transition } from '@headlessui/react';
import {
  AdjustmentsIcon,
  DocumentTextIcon,
  DotsVerticalIcon,
  DownloadIcon,
  LogoutIcon,
  SupportIcon,
} from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import newslitLogo from '../../assets/images/newslit-logo.svg';
import { usePwaContext } from '../../contexts/pwa';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { Account } from '../../services/models/account';
import { Brief } from '../../services/models/brief';
import { ExternalUserType } from '../../services/models/external-user';
import { FeatureFlag } from '../../services/models/feature-flags';
import { classNames } from '../../utils/class-names';
import { AddNewBriefBtn } from '../add-new-brief-btn/addNewBriefBtn';
import { EmptyBriefCTA } from '../empty-brief-cta/emptyBriefCTA';
import { UpsellSidebarNotification } from '../upsell-sidebar-notification/upsellSidebarNotification';

interface DesktopSidebarProps {
  account: Account;
  briefs: Brief[];
  currentBriefCount: number;
  maxBriefCount: number;
  onAddNewBrief: () => void;
  onTwitterSignInSuccess: () => void;
}

const DesktopSidebar: React.FC<DesktopSidebarProps> = ({
  account,
  briefs,
  currentBriefCount,
  maxBriefCount,
  onAddNewBrief,
  onTwitterSignInSuccess,
}) => {
  const { deferredPrompt, isPwaCompatibleBrowser } = usePwaContext();

  const socialBriefFlag = useFeatureFlag(FeatureFlag.ShowSocialBrief);

  const twitterUser = account.externalUsers.find(eu => eu.type === ExternalUserType.TWITTER);

  return (
    <div className="tw-flex tw-flex-col tw-w-64">
      <div className="tw-flex-1 tw-flex tw-flex-col tw-min-h-0 tw-border-r tw-border-gray-200 tw-bg-white">
        <div className="tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto">
          <div className="tw-flex tw-items-center tw-h-12 tw-flex-shrink-0 tw-px-4">
            <img className="tw-h-7 tw-w-auto" src={newslitLogo} alt="Newslit" />
          </div>

          <div className="tw-h-0 tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto">
            {/* Navigation */}
            <nav className="tw-px-2 tw-pt-4">
              <div className="tw-mt-6">
                {/* Secondary navigation */}
                <div className="tw-flex tw-items-center tw-justify-between">
                  <h3
                    className="tw-px-3 tw-text-sm tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider"
                    id="desktop-briefs-headline"
                  >
                    Briefs
                  </h3>
                  <span className="tw-font-light tw-text-gray-700 tw-tracking-wider">
                    using <span className="tw-font-bold tw-text-gray-900">{currentBriefCount}</span>
                    /{maxBriefCount}
                  </span>
                </div>

                {currentBriefCount === 0 && (!account.metadata?.isTwitterUser || !socialBriefFlag) && (
                  <div className="tw-pt-4">
                    <EmptyBriefCTA onClick={onAddNewBrief} />
                  </div>
                )}

                {((socialBriefFlag && account.metadata?.isTwitterUser) ||
                  currentBriefCount > 0) && (
                  <div className="tw-py-4">
                    <AddNewBriefBtn onClick={onAddNewBrief} />
                  </div>
                )}

                <div
                  className="tw-mt-1 tw-space-y-1"
                  role="group"
                  aria-labelledby="desktop-briefs-headline"
                >
                  {socialBriefFlag && account.metadata?.isTwitterUser && twitterUser!! && (
                    <NavLink
                      to="/dashboard/social"
                      className="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 tw-rounded-md hover:tw-text-gray-900 hover:tw-bg-gray-50 hover:tw-no-underline"
                      activeClassName="tw-bg-gray-100 tw-text-gray-900"
                    >
                      <DocumentTextIcon
                        className="tw-mr-3 tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                        aria-hidden="true"
                      />
                      <span className="tw-truncate">@{twitterUser?.username}</span>
                    </NavLink>
                  )}
                  {briefs.map(brief => (
                    <NavLink
                      key={brief.id}
                      to={`/dashboard/brief/${brief.id}`}
                      className="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 tw-rounded-md hover:tw-text-gray-900 hover:tw-bg-gray-50 hover:tw-no-underline"
                      activeClassName="tw-bg-gray-100 tw-text-gray-900"
                    >
                      <DocumentTextIcon
                        className="tw-mr-3 tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                        aria-hidden="true"
                      />
                      <span className="tw-truncate">{brief.name}</span>
                    </NavLink>
                  ))}
                </div>
              </div>
            </nav>
          </div>

          {/*Bottom CTA location */}
          {/*<div className="tw-flex-shrink-0 tw-block tw-w-full ">*/}
          {/*  <div className="tw-px-3 tw-my-5"></div>*/}
          {/*</div>*/}
          <UpsellSidebarNotification account={account} />

          <div className="tw-flex-shrink-0 tw-block tw-w-full tw-border-t tw-border-gray-200 tw-p-4">
            {/* User account dropdown */}
            <Menu as="div" className="tw-relative tw-inline-block tw-text-left tw-w-full">
              <Menu.Button className="tw-group tw-w-full tw-rounded-md  tw-text-sm tw-text-left tw-font-medium tw-text-gray-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100 focus:tw-ring-secondary">
                <span className="tw-flex tw-w-full tw-justify-between tw-items-center">
                  <span className="tw-flex tw-min-w-0 tw-items-center tw-justify-between tw-space-x-3">
                    <img
                      className="tw-w-10 tw-h-10 tw-bg-gray-300 tw-rounded-full tw-flex-shrink-0"
                      src={account?.imageUrl}
                      alt=""
                    />
                    <span className="tw-flex-1 tw-flex tw-flex-col tw-min-w-0">
                      <span className="tw-text-gray-900 tw-text-sm tw-font-medium tw-truncate">
                        {account?.firstName} {account?.lastName}
                      </span>
                      <span className="tw-text-gray-500 tw-text-sm tw-truncate">
                        {account?.email?.emailAddress}
                      </span>
                    </span>
                  </span>
                  <DotsVerticalIcon
                    className="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-100"
                enterFrom="tw-opacity-0 tw-scale-95"
                enterTo="tw-opacity-100 tw-scale-100"
                leave="tw-transition tw-ease-in tw-duration-75"
                leaveFrom="tw-opacity-100 tw-scale-100"
                leaveTo="tw-opacity-0 tw-scale-95"
              >
                <Menu.Items className="tw-z-10 tw-mx-3 tw-origin-top tw-absolute tw-right-0 tw-left-0 tw-bottom-14 tw-mt-1 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200 focus:tw-outline-none">
                  {isPwaCompatibleBrowser && (
                    <div className="tw-py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                              'tw-group tw-flex tw-items-center tw-w-full tw-px-4 tw-py-2 tw-text-sm'
                            )}
                            onClick={() => deferredPrompt?.prompt()}
                          >
                            <DownloadIcon
                              className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                              aria-hidden="true"
                            />
                            Install App
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  )}
                  <div className="tw-py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                            'tw-group tw-flex tw-items-center tw-w-full tw-px-4 tw-py-2 tw-text-sm'
                          )}
                          onClick={() => {
                            const my = window as any;
                            if (my.hasOwnProperty('Intercom')) {
                              my['Intercom']('show');
                            }
                          }}
                        >
                          <SupportIcon
                            className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                            aria-hidden="true"
                          />
                          Get Help
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="tw-py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={'/dashboard/settings/overview'}
                          className={classNames(
                            active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                            'tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm'
                          )}
                        >
                          <AdjustmentsIcon
                            className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                            aria-hidden="true"
                          />
                          Settings
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="tw-py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={'/users/signout'}
                          className={classNames(
                            active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                            'tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm'
                          )}
                        >
                          <LogoutIcon
                            className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                            aria-hidden="true"
                          />
                          Logout
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopSidebar;
