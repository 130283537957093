import React, { FunctionComponent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BriefKeyword } from '../../services/models/brief-keyword';
import HelpDialog from '../help-dialog/HelpDialog';
import './EditBriefKeyword.scss';

export interface EditBriefKeywordProps {
  title: string;
  maxItems?: number;
  helpSummary: JSX.Element;
  helpContent: JSX.Element;
  keywords: BriefKeyword[];
  tagClassName?: string;
  optional?: boolean;
  placeholder?: string;
  isUpdating: boolean;
  create: (keyword: string) => void;
  destroy: (keywordId: number) => void;
  validate?: (keyword: string) => null | string;
}

const EditBriefKeyword: FunctionComponent<EditBriefKeywordProps> = ({
  title,
  placeholder,
  maxItems,
  tagClassName,
  helpSummary,
  helpContent,
  keywords,
  isUpdating,
  optional,
  create,
  destroy,
  validate,
}) => {
  const [error, setError] = useState('');
  const [isInvalid, setInvalid] = useState(false);
  const [currentKeyword, setCurrentKeyword] = useState('');
  const textInput = useRef<HTMLInputElement | null>(null);
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const handleCloseHelpDialog = () => setShowHelpDialog(false);
  const handleShowHelpDialog = () => setShowHelpDialog(true);

  useEffect(() => {
    const empty = !currentKeyword?.trim() || currentKeyword?.trim().length === 0;

    if (empty) {
      setError('');
      setInvalid(true);
      return;
    }

    if (!!validate) {
      const result = validate(currentKeyword.trim());

      if (typeof result === 'string' && result.length > 0) {
        setError(result);
        setInvalid(true);
        return;
      }
    }
    setError('');
    setInvalid(false);
  }, [currentKeyword, setError, validate]);

  const addKeyword = (event: SyntheticEvent) => {
    event.preventDefault();

    if (!isInvalid) {
      const value = currentKeyword.split(',')[0].trim();

      if (
        !keywords.some(
          x => x.keyword?.localeCompare(value, undefined, { sensitivity: 'base' }) === 0
        )
      ) {
        create(value);
      }

      setCurrentKeyword('');
    }
  };

  const removeKeyword = (keyword: BriefKeyword) => {
    if (!isUpdating && !!keyword && keyword.id !== undefined) {
      destroy(keyword.id);
    }
  };

  const handleCurrentKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentKeyword(event.target.value);
  };

  const handleSeparator = (e: React.KeyboardEvent) => {
    const separators = [',', 'Enter', 'Tab'];

    if (separators.some(x => x === e.key)) {
      addKeyword(e);
    }

    if (e.key === 'Backspace') {
      if (canDeleteKeywords() && currentKeyword.length === 0) {
        removeKeyword(keywords[keywords.length - 1]);
      }
    }
  };

  const canDeleteKeywords = () => {
    return optional || (keywords || []).length > 1;
  };

  const getTags = () => {
    return (keywords || []).map((x, i) => {
      let keywordClassName = 'keyword ' + tagClassName;

      if (x.exactMatch) {
        keywordClassName += ' exact-match';
      }

      return (
        <span key={i} className={keywordClassName}>
          <span>{x.keyword}</span>
          {(() => {
            if (canDeleteKeywords()) {
              return (
                <span
                  onClick={() => removeKeyword(x)}
                  className={isUpdating ? 'keyword-remove keyword-disabled' : 'keyword-remove'}
                >
                  x
                </span>
              );
            }
          })()}
        </span>
      );
    });
  };

  const getPlaceholder = () => {
    if (placeholder) {
      return placeholder;
    }
    return 'Add keyword';
  };

  return (
    <div className="EditBriefKeyword">
      <Form noValidate>
        <Form.Group>
          <Form.Label>
            <span className="bold-h2 light-blue-text">{title}</span>
          </Form.Label>
          <p className="help-block">
            <span className="gray-text">{helpSummary}</span>
            <i
              className="fa fa-question-circle fa-fw text-muted"
              aria-hidden="true"
              style={{ cursor: 'pointer' }}
              onClick={handleShowHelpDialog}
            ></i>
          </p>
          <div className="inline-tag-editor" onClick={() => textInput?.current?.focus()}>
            {getTags()}
            <input
              ref={textInput}
              type="text"
              disabled={keywords.length < (maxItems || 20) ? false : true}
              placeholder={getPlaceholder()}
              maxLength={52}
              value={currentKeyword}
              onChange={(e: any) => handleCurrentKeywordChange(e)}
              onKeyDownCapture={(e: any) => handleSeparator(e)}
              required
            />
          </div>
          <div className="gray-text pt-1">
            [{keywords.length}/{maxItems}] keywords / phrase
          </div>
          {(() => {
            if (isInvalid && error.length > 0) {
              return <Form.Text className="text-muted">{error}</Form.Text>;
            }
          })()}
        </Form.Group>
      </Form>

      <HelpDialog show={showHelpDialog} onHide={handleCloseHelpDialog}>
        {helpContent}
      </HelpDialog>
    </div>
  );
};

export default EditBriefKeyword;
