import { Subscription } from '../../../../../services/models/subscription';
import { StripeAction } from '../../../../actions/stripe-actions';

export default function subscriptionReducer(
  state: Subscription = {} as any,
  action: any
): Subscription {
  switch (action.type) {
    case StripeAction.StartChangingSubscription:
      return { ...state, isChanging: true };
    case StripeAction.CancelChangingSubscription:
      return { ...state, isChanging: false };
    case StripeAction.CompleteChangingSubscription:
      return { ...state, ...action.subscription, isChanging: false };
    default:
      return state;
  }
}
