import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import {
  getUnsubscribeLink,
  unsubscribeFromBrief,
} from '../../../../redux/actions/brief-unsubscribe-actions';
import './UnsubscribeReportPage.scss';

// TODO(Jose): Update path to be /brief/unsubscribe
const UnsubscribeReportPage: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const briefUnsubscribeLink = useAppSelector(state => state.briefUnsubscribeLink);

  const [code, setCode] = useState<string | null>(null);
  const [memberId, setMemberId] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const verificationCode = searchParams.get('c');
    const member = searchParams.get('m');

    if (!verificationCode || !member) {
      history.push('/');
    } else {
      setCode(verificationCode);
      setMemberId(member);
    }
  }, [location.search, history, setCode, setMemberId]);

  useEffect(() => {
    if (code && memberId) {
      dispatch(getUnsubscribeLink(memberId, code));
    }
  }, [code, memberId, dispatch]);

  const loadingStage = () => {
    if (!briefUnsubscribeLink || briefUnsubscribeLink.isFetching) {
      return (
        <>
          <h2 className="report-unsubscribe-loading-header">
            <Spinner animation="grow"></Spinner>
            <span>Loading...</span>
          </h2>
        </>
      );
    }
  };

  const loadedStage = () => {
    if (
      briefUnsubscribeLink &&
      !briefUnsubscribeLink.isFetching &&
      briefUnsubscribeLink.briefName &&
      !briefUnsubscribeLink.isUnsubscribe &&
      !briefUnsubscribeLink.isCompleted
    ) {
      return (
        <>
          <h2 className="report-unsubscribe-panel-header">Please Confirm</h2>
          <p className="report-unsubscribe-panel-body">
            Unsubscribing from "{briefUnsubscribeLink?.briefName}" Brief. The News Brief will stay
            active but you won't receive any more emails about it.
          </p>
          <Button
            disabled={!briefUnsubscribeLink.briefName || briefUnsubscribeLink.isUnsubscribing}
            size="lg"
            variant="purple"
            onClick={() => dispatch(unsubscribeFromBrief(memberId as string, code as string))}
          >
            {briefUnsubscribeLink.isUnsubscribing ? 'Unsubscribing...' : 'Unsubscribe'}
          </Button>
        </>
      );
    }
  };

  const errorStage = () => {
    if (
      briefUnsubscribeLink &&
      briefUnsubscribeLink.isFetching === false &&
      !briefUnsubscribeLink.briefName
    ) {
      return (
        <>
          <h2 className="report-unsubscribe-panel-header">Oops! Something went wrong.</h2>

          <Link to="/">Return to Newslit</Link>
        </>
      );
    }
  };

  const alreadyUnsubscribedStage = () => {
    if (
      briefUnsubscribeLink &&
      briefUnsubscribeLink.isFetching === false &&
      briefUnsubscribeLink.isUnsubscribe
    ) {
      return (
        <>
          <h2 className="report-unsubscribe-panel-header">Already Unsubscribe</h2>
          <p className="report-unsubscribe-panel-body">
            You're already unsubscribed from this Brief.
          </p>
          <Link to="/">Return to Newslit</Link>
        </>
      );
    }
  };

  const completedStage = () => {
    if (
      briefUnsubscribeLink &&
      briefUnsubscribeLink.isFetching === false &&
      briefUnsubscribeLink.isCompleted
    ) {
      return (
        <>
          <h2 className="report-unsubscribe-panel-header">Unsubscribe Successful</h2>
          <p className="report-unsubscribe-panel-body">
            You will no longer receive email from this Brief.
          </p>
          <Link to="/">Return to Newslit</Link>
        </>
      );
    }
  };

  return (
    <div>
      <Helmet>
        <title>Unsubscribe from Brief</title>
      </Helmet>

      <Card>
        <Card.Body>
          {loadingStage()}
          {loadedStage()}
          {errorStage()}
          {completedStage()}
          {alreadyUnsubscribedStage()}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UnsubscribeReportPage;
