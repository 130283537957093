import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useAppSelector } from '../../hooks/useAppSelector';
import { saveSession } from '../../services/authentication';
import { isInHootSuite } from '../../services/hootsuite';
import { Session } from '../../services/models/session';
import { launchPopupWindow } from '../../services/popups';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Dialog } from '@headlessui/react';
import { Modal } from '../ui';
import { createBodyFromMessage, createMailtoLink } from '../../utils/createMailtoLinks';

interface TwitterLoginBtnProps {
  label?: string;
  checkIfEnable: () => boolean;
  callback?: () => void;
  onSuccess?: () => void;
  disabled?: boolean;
}

const TwitterLoginBtn: FunctionComponent<TwitterLoginBtnProps> = ({
  label,
  checkIfEnable,
  callback,
  onSuccess,
  disabled,
}) => {
  const externalAuth = useAppSelector(state => state.externalAuth);

  const [disabledModalShown, setDisabledModalShown] = useState(false);

  const mailtoLink = createMailtoLink(
    'help@newslit.co',
    `Need Help with Newslit Twitter Login`,
    createBodyFromMessage('', true)
  );

  const twitterLoginListener = (event: any) => {
    if (event.origin !== window.location.origin) {
      console.log('message event with diff origin');
      return;
    }

    try {
      let session: Session = JSON.parse(event.data);

      if (session && session.account && session.token) {
        saveSession(session);

        onSuccess ? onSuccess() : console.warn('[Newslit] no Twitter Login onSuccess defined');
      }

      window.removeEventListener('message', twitterLoginListener);
    } catch (e) {
      console.log(e);
    }
  };

  const onTwitterLoginClick = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (checkIfEnable && callback && !checkIfEnable()) {
      callback();
      return;
    }

    window.addEventListener('message', twitterLoginListener, false);

    launchPopupWindow(
      window.location.origin + `/popup/twitter${isInHootSuite() ? '?hsp=true' : ''}`,
      'location=yes,scrollbars=yes,status=yes',
      {
        height: 570,
        width: 620,
      },
      () => {}
    );
  };

  return (
    <>
      {disabled && (
        <div className="tw-rounded-md tw-bg-yellow-50 tw-p-4 tw-mb-4">
          <div className="tw-flex">
            <div className="tw-flex-shrink-0">
              <ExclamationIcon className="tw-h-5 tw-w-5 tw-text-yellow-400" aria-hidden="true" />
            </div>
            <div className="tw-ml-3">
              <div className="tw-mt-2 tw-text-sm tw-text-yellow-700">
                <p className="tw-mb-1">
                  Our Twitter integration is currently disabled. We are actively trying to resolve
                  this and will share updates as additional information becomes available.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <Button
        className="btn-block twitter-btn"
        variant="twitter"
        disabled={externalAuth.isFetchingTwitterLoginLink}
        onClick={e => (disabled ? setDisabledModalShown(true) : onTwitterLoginClick(e))}
      >
        <i className="fa fa-twitter"></i> {!!label ? label : 'Sign in with Twitter'}
        {(() => {
          if (externalAuth.isFetchingTwitterLoginLink) {
            return <Spinner className="waiting-for-twitter" size="sm" animation="border" />;
          }
        })()}
      </Button>

      {disabledModalShown && (
        <Modal isOpen={disabledModalShown} onClose={() => setDisabledModalShown(false)}>
          <div className="sm:tw-flex sm:tw-items-start">
            <div className="tw-mt-3 sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
              <Dialog.Title
                as="h3"
                className="tw-text-center tw-text-lg tw-leading-6 tw-font-medium tw-text-primary"
              >
                Looking to Sign in with Twitter?
              </Dialog.Title>
              <div className="tw-mt-2">
                <p className="">Twitter authentication is not currently working on Newslit.</p>
                <p className="tw-mt-3">
                  If you need to login to your account set up a password to your account. You can
                  also{' '}
                  <a
                    href={mailtoLink}
                    target="_blank"
                    rel="noreferrer"
                    className="tw-font-medium tw-text-secondary hover:tw-text-secondary-dark"
                  >
                    Contact us at help@newslit.co
                  </a>{' '}
                  if you have any questions or need further help.
                </p>
              </div>
              <div className="tw-mt-3">
                <a
                  href="/users/password_reset"
                  className="tw-w-full tw-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary disabled:tw-opacity-50"
                >
                  Request a Password Reset
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TwitterLoginBtn;
