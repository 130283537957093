import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import React, { FunctionComponent, useRef } from 'react';
import { Account } from '../../services/models/account';
import { Modal } from '../ui';

interface CancelSubscriptionDialogProps {
  open: boolean;
  account: Account;
  onAccept: () => void;
  onCancel: () => void;
}

const CancelSubscriptionDialog: FunctionComponent<CancelSubscriptionDialogProps> = ({
  open,
  account,
  onAccept,
  onCancel,
}) => {
  const cancelButtonRef = useRef(null);

  const getActions = () => {
    return (
      <>
        <button
          type="button"
          className="tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-red-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm"
          onClick={() => onAccept()}
          disabled={account.isCancelingSubscription}
        >
          {account.isCancelingSubscription && (
            <svg
              className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="tw-opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="tw-opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Cancel Subscription
        </button>
        <button
          type="button"
          className="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm"
          onClick={() => onCancel()}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </>
    );
  };

  return (
    <Modal isOpen={open} onClose={() => onCancel()}>
      <div className="sm:tw-flex sm:tw-items-start">
        <div className="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
          <ExclamationIcon className="tw-h-6 tw-w-6 tw-text-red-600" aria-hidden="true" />
        </div>
        <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
          <Dialog.Title as="h3" className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
            Cancel your Newslit Subscription?
          </Dialog.Title>
          <div className="tw-mt-2">
            <p className="tw-text-sm tw-text-gray-500 tw-m-o">
              Are you sure you want to cancel your{' '}
              <span className="tw-font-bold">{account?.product?.name}</span> Subscription?
            </p>
          </div>
        </div>
      </div>
      <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">{getActions()}</div>
    </Modal>
  );
};

export default CancelSubscriptionDialog;
