import platform from 'platform';

export function createMailtoLink(to: string, subject?: string, body?: string): string {
  let res = `mailto:${to}`;

  if (subject) {
    res += `?subject=${subject}`;
  }

  if (body) {
    res += (subject ? '&' : '?') + `body=${encodeURIComponent(body)}`;
  }

  return res;
}

export function createBodyFromMessage(message: string, includeInstructions: boolean) {
  return `${message}

---
${includeInstructions ? 'Please write your message above this line.' : ''}
${typeof window !== 'undefined' ? 'Current page: ' + window?.location?.href : ''}
${
  platform &&
  `Browser: ${platform.name} ${platform.version}
OS: ${platform.os?.family} ${platform.os?.version} (${platform.os?.architecture})`
}
`;
}
