export enum ErrorsAction {
  Notify = 'ErrorsActionNotify',
  NotifyAll = 'ErrorsActionNotifyAll',
  Discard = 'ErrorsActionDiscard',
  DiscardAll = 'ErrorsActionDiscardAll',
}

export const reportError = (error: Error) => ({
  type: ErrorsAction.Notify,
  error,
});

export const reportErrors = (errors: Error[]) => ({
  type: ErrorsAction.NotifyAll,
  errors,
});

export const discardError = (error: Error) => ({
  type: ErrorsAction.Discard,
  error,
});

export const discardAllErrors = () => ({
  type: ErrorsAction.DiscardAll,
});
