import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BriefUnsubscribePage from '../../pages/brief/unsubscribe/BriefUnsubscribePage';
import StripeCheckoutCallbackPage from '../../pages/treasury/StripeCheckoutCallbackPage';
import UsersPage from '../../pages/users/UsersPage';
import DashboardApp from '../App/DashboardApp';
import LargeSingleColumnLayout from '../large-single-column-layout/LargeSingleColumnLayout';
import ProtectedRoute from '../protected-route/ProtectedRoute';
import SingleColumnLayout from '../single-column-layout/SingleColumnLayout';

const AppRoutes: FunctionComponent = () => {
  return (
    <Switch>
      <Route path={'/auth'} component={SingleColumnLayout} />
      <Route path={'/auth-users'} component={LargeSingleColumnLayout} />
      <Route path={'/email'} component={SingleColumnLayout} />
      <Route path={'/login'} component={SingleColumnLayout} />
      <Route path={'/popup'} component={SingleColumnLayout} />
      <Route exact path={'/brief/unsubscribe'} component={BriefUnsubscribePage} />
      <Route path={'/report'} component={LargeSingleColumnLayout} />
      <Route exact path={'/treasury/callback/'} component={StripeCheckoutCallbackPage} />
      <Route exact path="/users/" component={UsersPage} />
      <Route path="/users/signin" component={UsersPage} />
      <Route path="/users/signup" component={UsersPage} />
      <Route path={'/users'} component={SingleColumnLayout} />
      <Route path={'/verify-email'} component={SingleColumnLayout} />
      <ProtectedRoute exact path={`/cancel`} component={LargeSingleColumnLayout} />
      <ProtectedRoute path={'/dashboard'} component={DashboardApp} />
      <Route render={() => <Redirect to={'/users/signin'} />} />
    </Switch>
  );
};

export default AppRoutes;
