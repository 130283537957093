import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  activateAccount,
  getAccountActivation,
} from '../../../../redux/actions/account-activation-actions';
import { StoreState } from '../../../../redux/store-state';
import { AccountActivation } from '../../../../services/models/account-activation';
import { Session } from '../../../../services/models/session';
import './ActivateAccountPage.scss';

interface Props {
  session: Session;
  accountActivation: AccountActivation;
  activateAccount: (code: string, password: string) => void;
  getAccountActivation: (code: string) => void;
}

const ActivateAccountPage: FunctionComponent<Props> = ({
  session,
  accountActivation,
  activateAccount,
  getAccountActivation,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [code, setCode] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [wrongPasswordLength, setWrongPasswordLength] = useState(false);

  useEffect(() => {
    setWrongPasswordLength(password.length < 6 || password.length > 32);
  }, [password, setWrongPasswordLength]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const verificationCode = searchParams.get('c');

    if (!verificationCode) {
      history.push('/');
    } else {
      setCode(verificationCode);
    }
  }, [location.search, history, setCode]);

  useEffect(() => {
    if ((session?.account && session?.token) || accountActivation.isActivated) {
      history.push('/');
    }
  }, [session, accountActivation, history]);

  useEffect(() => {
    if (code) {
      getAccountActivation(code);
    }
  }, [code, getAccountActivation]);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (code && password) {
      activateAccount(code, password);
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordTouched(true);
    setPassword(event.target.value);
  };

  if (accountActivation.isFetching === undefined || accountActivation.isFetching) {
    return (
      <div>
        <Card>
          <Card.Body>
            <h1 className="activate-account-content-loading">
              <Spinner animation="grow"></Spinner>
              <span>Loading...</span>
            </h1>
          </Card.Body>
        </Card>
      </div>
    );
  }

  if (accountActivation.isFetching === false && !accountActivation.email) {
    return (
      <div>
        <Card>
          <Card.Body>
            <h1 className="activate-account-content-header text-center">
              Oops! Something went wrong.
            </h1>
            <Link to="/">Return to Newslit</Link>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Activate Account</title>
      </Helmet>

      <Card>
        <Card.Body>
          <h1 className="activate-account-content-header text-center">Choose Password</h1>
          <Form noValidate onSubmit={e => handleSubmit(e)}>
            <Form.Group controlId="password">
              <Form.Label>Email</Form.Label>
              <div>{accountActivation.email}</div>
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                isInvalid={passwordTouched && wrongPasswordLength}
                value={password}
                onChange={(e: any) => handlePasswordChange(e)}
                placeholder="At least 6 characters"
                required
              />
              {(() => {
                if (passwordTouched && wrongPasswordLength) {
                  return (
                    <Form.Text className="text-muted">
                      Please create a password between 6 and 32 characters long
                    </Form.Text>
                  );
                }
              })()}
            </Form.Group>

            <Button
              disabled={
                !accountActivation ||
                accountActivation.isActivating ||
                !accountActivation.email ||
                !passwordTouched ||
                wrongPasswordLength
              }
              className="mt-3 btn-block"
              variant="purple"
              type="submit"
            >
              {accountActivation.isActivating ? 'Activating...' : 'Save Password'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    session: state.session,
    accountActivation: state.accountActivation,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    activateAccount: (code: string, password: string) => dispatch(activateAccount(code, password)),
    getAccountActivation: (code: string) => dispatch(getAccountActivation(code)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccountPage);
