import React, { FunctionComponent, useEffect, useState } from 'react';
import CancelSubscriptionDialog from '../../../components/cancel-subscription-dialog/CancelSubscriptionDialog';
import PricingModal from '../../../components/pricing-modal/pricingModal';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import SettingsPageLayout from '../../../layouts/settings/settingsPageLayout';
import { cancelSubscription } from '../../../redux/actions/account-actions';
import { initUpdatePaymentSettings } from '../../../redux/actions/stripe-actions';
import { formatDate, parseZonedDate } from '../../../services/date';
import {
  Subscription,
  SubscriptionInterval,
  SubscriptionStatus,
} from '../../../services/models/subscription';
import { classNames } from '../../../utils/class-names';
import { usePaywallContext } from '../../../contexts/paywall';

const PlanPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const { paywallModalShown, setPaywallModalShown } = usePaywallContext();

  const account = useAppSelector(state => state.session.account);
  const product = account.product;
  const { trial, trialDaysLeft } = account.subscription;
  const isCurrentPlanMonthly = account.subscription.interval !== SubscriptionInterval.Yearly;

  const [showCancelSubsDialog, setShowCancelSubsDialog] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);

  useEffect(() => {
    if (!account.isCancelingSubscription) {
      setShowCancelSubsDialog(false);
    }
  }, [account]);

  useEffect(() => {
    if (paywallModalShown) {
      setPaywallModalShown(false);
      setShowPricingModal(true);
    }
  }, [paywallModalShown, setPaywallModalShown]);

  const getPlanActionButton = (subscription: Subscription) => {
    const { trial, canceled, active } = subscription;

    let text = '';

    if (trial) {
      text = 'Upgrade';
    } else if (active && !canceled) {
      text = 'Change';
    } else if (canceled && !active) {
      text = 'Buy it again';
    } else {
      return null;
    }

    return (
      <button
        type="button"
        className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
        onClick={() => {
          setShowPricingModal(true);
        }}
      >
        {text}
      </button>
    );
  };

  const isActiveSubscription = () => {
    return (
      account && account.subscription && account.subscription.active && !account.subscription.trial
    );
  };

  const shouldShowCancelationSection = () => {
    return (
      isActiveSubscription() &&
      !account.subscription.canceled &&
      account.subscription?.planId !== 'TIER_API_CUSTOM'
    );
  };

  const getSubscriptionNextInvoiceDate = () => {
    const date = parseZonedDate(account.subscription.currentPeriodEnd);

    const dateText = !!date
      ? formatDate(date, {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        })
      : '';

    return dateText;
  };

  const getSubscriptionStatus = (subscription: Subscription) => {
    let statusLabel;
    let statusCss;

    switch (subscription?.status) {
      case SubscriptionStatus.Active: {
        statusLabel = 'Active';
        statusCss = 'tw-text-green-600';
        break;
      }
      case SubscriptionStatus.PastDue: {
        statusLabel = 'Past Due';
        statusCss = 'tw-text-yellow-700';
        break;
      }
      case SubscriptionStatus.Canceled: {
        statusLabel = 'Canceled';
        statusCss = 'tw-text-red-700';
        break;
      }
      default: {
        statusLabel = subscription?.status;
        statusCss = 'tw-text-gray-900';
        break;
      }
    }

    return <span className={classNames(statusCss)}>{statusLabel}</span>;
  };

  return (
    <SettingsPageLayout title="Plan & Billing | Newslit">
      {/* Past due alert */}
      {!!account?.subscription?.pastDue && (
        <div className="tw-rounded-md tw-bg-red-50 tw-p-4 tw-mt-5">
          <div className="tw-flex">
            <div className="tw-flex-shrink-0">
              <svg
                className="tw-h-5 tw-w-5 tw-text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div className="tw-ml-3">
              <h3 className="tw-text-sm tw-font-bold tw-text-red-800">
                Oh no! Your payment method recently failed to process.
              </h3>
              <div className="tw-mt-2 tw-text-sm tw-text-red-700">
                <p>Update your payment information below</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Plan Info */}
      <div className="tw-mt-10 tw-divide-y tw-divide-gray-200">
        <div className="tw-space-y-1">
          <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">Plan</h3>
          {trial && trialDaysLeft > 0 && (
            <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500 tw-m-0">
              You have <span className="tw-font-bold">{trialDaysLeft}</span> day
              {trialDaysLeft > 1 ? 's' : ''} left in your trial
            </p>
          )}
          {trial && trialDaysLeft === 0 && (
            <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500 tw-m-0">
              Your trial expires <span className="tw-font-bold">Today</span>
            </p>
          )}
          {trial && trialDaysLeft < 0 && (
            <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500 tw-m-0">
              Your trial has expired
            </p>
          )}
        </div>

        <div className="tw-mt-6">
          <dl className="tw-divide-y tw-divide-gray-200">
            <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
              <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Name</dt>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <span className="tw-flex-grow">
                  <span className="tw-font-bold">{product?.name}</span>
                  {!trial && (
                    <>
                      {' '}
                      via{' '}
                      <span className="tw-capitalize">
                        {!!account?.subscription?.cardOnFile && (
                          <>
                            {isCurrentPlanMonthly
                              ? SubscriptionInterval.Monthly
                              : SubscriptionInterval.Yearly}
                            {'ly '}
                            Subscription
                          </>
                        )}
                        {!account?.subscription?.cardOnFile && <>Custom Susbscription</>}
                      </span>
                    </>
                  )}
                </span>
                <span className="tw-ml-4 tw-flex-shrink-0">
                  {getPlanActionButton(account.subscription)}
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {isActiveSubscription() && (
        // Billing Info
        <div className="tw-mt-10 tw-divide-y tw-divide-gray-200">
          <div className="tw-space-y-1">
            <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">Billing</h3>
          </div>

          <div className="tw-mt-6">
            <dl className="tw-divide-y tw-divide-gray-200">
              <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                  {account?.subscription?.canceled ? 'Expires at' : 'Next invoice'}
                </dt>
                <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                  <span className="tw-flex-grow">{getSubscriptionNextInvoiceDate()}</span>
                  {shouldShowCancelationSection() && (
                    <span className="tw-ml-4 tw-flex-shrink-0">
                      <button
                        type="button"
                        className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                        onClick={() => setShowCancelSubsDialog(true)}
                      >
                        Cancel subscription
                      </button>
                    </span>
                  )}
                </dd>
              </div>

              {!!account?.subscription?.cardOnFile && (
                <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-pt-5">
                  <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Card information</dt>
                  <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                    <span className="tw-flex-grow">
                      <span className="tw-font-bold tw-capitalize">
                        {account?.subscription?.cardOnFile.brand}
                      </span>{' '}
                      ending in{' '}
                      <span className="card-number bold-h3 black-text">
                        {account?.subscription?.cardOnFile?.lastFourDigits}
                      </span>
                    </span>
                    <span className="tw-ml-4 tw-flex-shrink-0">
                      <button
                        type="button"
                        className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                        onClick={() =>
                          dispatch(initUpdatePaymentSettings(account, window.location.href))
                        }
                      >
                        Edit payment method
                      </button>
                    </span>
                  </dd>
                </div>
              )}

              <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-pt-5">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Status</dt>
                <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                  {getSubscriptionStatus(account.subscription)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}

      {showCancelSubsDialog && (
        <CancelSubscriptionDialog
          account={account}
          open={showCancelSubsDialog}
          onAccept={() => dispatch(cancelSubscription())}
          onCancel={() => setShowCancelSubsDialog(false)}
        />
      )}

      <PricingModal isOpen={showPricingModal} onClose={() => setShowPricingModal(false)} />
    </SettingsPageLayout>
  );
};

export default PlanPage;
