import gql from 'graphql-tag';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient, getGraphQLClient } from './graphql-client';
import { getHootsuiteUID } from './hootsuite';
import { AccountActivation } from './models/account-activation';
import { Session } from './models/session';
import { SignUpRequest } from './models/signup-request';
import { onUserSignOut } from './user-events';

const sessionKey = 'session';

export const isLocalStorageAvailable = () => {
  try {
    localStorage.getItem(sessionKey);
    return true;
  } catch {
    return false;
  }
};

export const getSession = (): Session | null => {
  if (isLocalStorageAvailable()) {
    const json = localStorage.getItem(sessionKey);
    return json ? JSON.parse(json) : null;
  } else {
    return null;
  }
};

export const isAuthenticated = () => {
  const json = getSession();
  return !!json;
};

export const signIn = async (email: string, password: string): Promise<Session> => {
  const client = getGraphQLClient();
  const huid = await getHootsuiteUID();
  const response = await client.mutate<{ accountSignin: Session }>({
    mutation: _getSignInMutation(),
    variables: {
      email,
      password,
      huid,
    },
  });

  if (!response.data || !response.data.accountSignin) {
    throw new EmptyResponseError();
  }

  saveSession(response.data.accountSignin);

  return response.data.accountSignin;
};

const _getSignInMutation = () => {
  return gql`
    mutation signIn($email: String!, $password: String!, $huid: String) {
      accountSignin(input: { email: $email, password: $password, huid: $huid }) {
        account {
          userid
        }
        token
      }
    }
  `;
};

export const discardLocalSession = () => {
  localStorage.removeItem(sessionKey);
};

export const signOut = async () => {
  const client = getAuthenticatedGraphQLClient();
  discardLocalSession();
  await client.mutate({
    mutation: _getSignOutMutation(),
  });

  onUserSignOut();
};

const _getSignOutMutation = () => {
  return gql`
    mutation signout {
      accountSignout {
        ok
      }
    }
  `;
};

export const signUp = async (signUpRequest: SignUpRequest) => {
  const client = getGraphQLClient();
  const huid = await getHootsuiteUID();
  const response = await client.mutate<{ accountSignup: Session }>({
    mutation: _getSignUpMutation(),
    variables: {
      emailAddress: signUpRequest.emailAddress,
      firstName: signUpRequest.firstName,
      lastName: signUpRequest.lastName,
      password: signUpRequest.password,
      huid,
    },
  });

  if (!response.data || !response.data.accountSignup) {
    throw new EmptyResponseError();
  }

  saveSession(response.data.accountSignup);

  return response.data.accountSignup;
};

const _getSignUpMutation = () => {
  return gql`
    mutation signUp(
      $emailAddress: String!
      $password: String!
      $firstName: String!
      $lastName: String!
      $huid: String
    ) {
      accountSignup(
        input: {
          emailAddress: $emailAddress
          firstName: $firstName
          lastName: $lastName
          password: $password
          huid: $huid
        }
      ) {
        account {
          userid
        }
        token
      }
    }
  `;
};

export const requestPasswordReset = async (email: string): Promise<boolean> => {
  const client = getGraphQLClient();
  const response = await client.mutate<{ accountPasswordResetRequest: { ok: boolean } }>({
    mutation: _getRequestPasswordResetMutation(),
    variables: {
      email,
    },
  });

  if (!response.data || !response.data.accountPasswordResetRequest) {
    throw new EmptyResponseError();
  }

  return response.data.accountPasswordResetRequest.ok;
};

const _getRequestPasswordResetMutation = () => {
  return gql`
    mutation requestPasswordReset($email: String!) {
      accountPasswordResetRequest(input: { email: $email }) {
        ok
      }
    }
  `;
};

export const resetPassword = async (code: string, password: string): Promise<boolean> => {
  const client = getGraphQLClient();
  const response = await client.mutate<{ accountPasswordReset: { ok: boolean } }>({
    mutation: _getResetPasswordMutation(),
    variables: {
      code,
      password,
    },
  });

  if (!response.data || !response.data.accountPasswordReset) {
    throw new EmptyResponseError();
  }

  return response.data.accountPasswordReset.ok;
};

const _getResetPasswordMutation = () => {
  return gql`
    mutation completePasswordReset($code: String!, $password: String!) {
      accountPasswordReset(input: { code: $code, password: $password }) {
        ok
      }
    }
  `;
};

export const signInWithTwitter = async (
  oauthToken: string,
  oauthVerifier: string,
  hasSession: boolean
): Promise<Session> => {
  const client = hasSession ? getAuthenticatedGraphQLClient() : getGraphQLClient();

  const response = await client.mutate<{ accountTwitterLogin: Session }>({
    mutation: _getTwitterSignInMutation(),
    variables: {
      oauthToken,
      oauthVerifier,
    },
  });

  if (!response.data || !response.data.accountTwitterLogin) {
    throw new EmptyResponseError();
  }

  saveSession(response.data.accountTwitterLogin);

  return response.data.accountTwitterLogin;
};

const _getTwitterSignInMutation = () => {
  return gql`
    mutation twitterSignIn($oauthToken: String!, $oauthVerifier: String!) {
      accountTwitterLogin(input: { oauthToken: $oauthToken, oauthVerifier: $oauthVerifier }) {
        account {
          userid
          memberSince
        }
        token
      }
    }
  `;
};

export const getAccountActivation = async (code: string): Promise<AccountActivation> => {
  const client = getGraphQLClient();
  const response = await client.query<{ accountActivationLink: AccountActivation }>({
    query: _getAccountActivationQuery(),
    variables: {
      code,
    },
  });

  if (!response.data || !response.data.accountActivationLink) {
    throw new EmptyResponseError();
  }

  return response.data.accountActivationLink;
};

const _getAccountActivationQuery = () => {
  return gql`
    query accountActivation($code: String!) {
      accountActivationLink(c: $code) {
        c
        email
        isActivated
      }
    }
  `;
};

export const activateAccount = async (code: string, password: string): Promise<Session> => {
  const client = getGraphQLClient();
  const response = await client.mutate<{ accountActivation: Session }>({
    mutation: _getActivateAccountMutation(),
    variables: {
      code,
      password,
    },
  });

  if (!response.data || !response.data.accountActivation) {
    throw new EmptyResponseError();
  }

  saveSession(response.data.accountActivation);

  return response.data.accountActivation;
};

const _getActivateAccountMutation = () => {
  return gql`
    mutation activateAccount($code: String!, $password: String!) {
      accountActivation(input: { c: $code, password: $password }) {
        token
        account {
          userid
        }
      }
    }
  `;
};

export const loginVerification = async (token: string): Promise<Session> => {
  const client = getGraphQLClient();
  const response = await client.mutate<{ accountLoginVerification: Session }>({
    mutation: _getLoginVerificationMutation(),
    variables: {
      token,
    },
  });

  if (!response.data || !response.data.accountLoginVerification) {
    throw new EmptyResponseError();
  }

  saveSession(response.data.accountLoginVerification);

  return response.data.accountLoginVerification;
};

const _getLoginVerificationMutation = () => {
  return gql`
    mutation accountLoginVerification($token: String!) {
      accountLoginVerification(input: { token: $token }) {
        account {
          userid
        }
        token
      }
    }
  `;
};

export const saveSession = (session: Session) => {
  localStorage.setItem(sessionKey, JSON.stringify(session));
};
