import { BriefSettingsAction } from '../../actions/brief-settings-actions';
import { BriefStoriesAction } from '../../actions/brief-stories-actions';
import { BriefStoryTweetsAction } from '../../actions/brief-story-tweets-actions';
import { BriefsAction } from '../../actions/briefs-actions';
import { SessionAction } from '../../actions/session-actions';
import { BriefsStoreNode } from '../../store-state';
import briefItemsReducer from './items';

export default function briefsReducer(state: BriefsStoreNode = {}, action: any) {
  switch (action.type) {
    case BriefsAction.StartFetchingAll:
      return startFetchingBriefs(state, action);
    case BriefsAction.CompleteFetchingAll:
      return completeFetchingBriefs(state, action);
    case BriefsAction.CancelFetchingAll:
      return cancelFetching(state, action);
    case BriefsAction.StartFetchingBriefDetails:
      return startFetchingBriefDetails(state, action);
    case BriefsAction.CompleteFetchingBriefDetails:
      return completeFetchingBriefDetails(state, action);
    case BriefsAction.CancelFetchingBriefDetails:
      return cancelFetchingBriefDetails(state, action);

    /**
     * Create new Brief
     */
    case BriefsAction.StartCreating:
      return { ...state, isCreating: true };
    case BriefsAction.CancelCreating:
      return { ...state, isCreating: false };
    case BriefsAction.CompleteCreating:
      return {
        ...state,
        isCreating: false,
        items: briefItemsReducer(state.items, action),
      };

    /**
     * Update Brief Items
     */
    case BriefsAction.ShowBriefDestroyPrompt:
    case BriefsAction.HideBriefDestroyPrompt:
    case BriefsAction.StartDestroying:
    case BriefsAction.CompleteDestroying:
    case BriefsAction.CancelDestroying:
    case BriefsAction.StartUpdatingName:
    case BriefsAction.CompleteUpdatingName:
    case BriefsAction.CancelUpdatingName:
    case BriefSettingsAction.StartUpdatingLanguage:
    case BriefSettingsAction.CompleteUpdatingLanguage:
    case BriefSettingsAction.CancelUpdatingLanguage:
    case BriefSettingsAction.StartUpdatingKeywords:
    case BriefSettingsAction.CompleteCreatingKeyword:
    case BriefSettingsAction.CancelCreatingKeyword:
    case BriefSettingsAction.CompleteDestroyingKeyword:
    case BriefSettingsAction.CancelDestroyingKeyword:
    case BriefSettingsAction.StartUpdatingSchedule:
    case BriefSettingsAction.CompleteUpdatingSchedule:
    case BriefSettingsAction.CancelUpdatingSchedule:
    case BriefSettingsAction.StartUpdatingSuggestedStories:
    case BriefSettingsAction.CompleteUpdatingSuggestedStories:
    case BriefSettingsAction.CancelUpdatingSuggestedStories:
    case BriefSettingsAction.StartCreatingRecipient:
    case BriefSettingsAction.CompleteCreatingRecipient:
    case BriefSettingsAction.CancelCreatingRecipient:
    case BriefSettingsAction.StartDestroyingRecipient:
    case BriefSettingsAction.CompleteDestroyingRecipient:
    case BriefSettingsAction.CancelDestroyingRecipient:
    case BriefSettingsAction.StartRemovingSlackChannel:
    case BriefSettingsAction.CompleteRemovingSlackChannel:
    case BriefSettingsAction.CancelRemovingSlackChannel:
    case BriefStoriesAction.StartFetching:
    case BriefStoriesAction.CompleteFetchingPage:
    case BriefStoriesAction.CancelFetching:
    case BriefStoriesAction.StartFetchingPreview:
    case BriefStoriesAction.CompleteFetchingPreview:
    case BriefStoriesAction.CancelFetchingPreview:
    case BriefStoriesAction.StartFetchingShareData:
    case BriefStoriesAction.CompleteFetchingShareData:
    case BriefStoriesAction.CancelFetchingShareData:
    case BriefStoriesAction.StartMarkStoryAsViewed:
    case BriefStoriesAction.CompleteMarkStoryAsViewed:
    case BriefStoriesAction.CancelMarkStoryAsViewed:
    case BriefStoryTweetsAction.StartFetching:
    case BriefStoryTweetsAction.CompleteFetching:
    case BriefStoryTweetsAction.CancelFetching:
      return updateBriefItems(state, action);

    /**
     * Clear Briefs Store node after signout
     */
    case SessionAction.SignOut:
      return {};

    default:
      return state;
  }
}

const startFetchingBriefs = (state: BriefsStoreNode, action: any) => {
  return {
    ...state,
    isFetching: true,
  };
};

const completeFetchingBriefs = (state: BriefsStoreNode, action: any) => {
  return {
    ...state,
    items: briefItemsReducer(state.items, action),
    isFetching: false,
  };
};

const cancelFetching = (state: BriefsStoreNode, action: any) => {
  return {
    ...state,
    isFetching: false,
  };
};

const startFetchingBriefDetails = (state: BriefsStoreNode, action: any) => {
  return {
    ...state,
    items: briefItemsReducer(state.items, action),
    isFetching: true,
  };
};

const completeFetchingBriefDetails = (state: BriefsStoreNode, action: any) => {
  return {
    ...state,
    items: briefItemsReducer(state.items, action),
    isFetching: false,
  };
};

const cancelFetchingBriefDetails = (state: BriefsStoreNode, action: any) => {
  return {
    ...state,
    items: briefItemsReducer(state.items, action),
    isFetching: false,
  };
};

const updateBriefItems = (state: BriefsStoreNode, action: any) => {
  return { ...state, items: briefItemsReducer(state.items, action) };
};
