import * as briefsManager from '../../services/briefs';
import { BriefUnsubscribeLink } from '../../services/models/brief-unsubscribe-link';
import { asyncAction, Dispatch } from '../types';

export enum BriefUnsubscribeAction {
  // Unsubscribe Link
  StartFetchingLink = 'BriefUnsubscribeAction/StartFetchingLink',
  CompleteFetchingLink = 'BriefUnsubscribeAction/CompleteFetchingLink',
  CancelFetchingLink = 'BriefUnsubscribeAction/CancelFetchingLink',

  // Unsubscribe Brief
  StartUnsubscribing = 'BriefUnsubscribeAction/StartUnsubscribing',
  CompleteUnsubscribing = 'BriefUnsubscribeAction/CompleteUnsubscribing',
  CancelUnsubscribing = 'BriefUnsubscribeAction/CancelUnsubscribing',
}

export const getUnsubscribeLink = (memberId: string, code: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingLink());
    const briefUnsubscribeLink = await briefsManager.getBriefUnsubscribeLink(memberId, code);
    dispatch(completeFetchingLink(briefUnsubscribeLink));
  }, cancelFetchingLink);

export const startFetchingLink = () => ({
  type: BriefUnsubscribeAction.StartFetchingLink,
});

export const cancelFetchingLink = () => ({
  type: BriefUnsubscribeAction.CancelFetchingLink,
});

export const completeFetchingLink = (briefUnsubscribeLink: BriefUnsubscribeLink) => ({
  type: BriefUnsubscribeAction.CompleteFetchingLink,
  briefUnsubscribeLink,
});

export const unsubscribeFromBrief = (memberId: string, code: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startUnsubscribing());
    const deletedRecipientId = await briefsManager.unsubscribeBriefRecipient(memberId, code);
    deletedRecipientId ? dispatch(completeUnsubscribing()) : dispatch(cancelUnsubscribing());
  }, cancelUnsubscribing);

export const startUnsubscribing = () => ({
  type: BriefUnsubscribeAction.StartUnsubscribing,
});

export const cancelUnsubscribing = () => ({
  type: BriefUnsubscribeAction.CancelUnsubscribing,
});

export const completeUnsubscribing = () => ({
  type: BriefUnsubscribeAction.CompleteUnsubscribing,
});
