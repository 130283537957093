import { Dialog, Listbox, Switch, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import React, { Fragment, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { updateSetting } from '../../redux/actions/settings-actions';
import {
  AppSetting,
  BooleanSettingValue,
  getBooleanSetting,
  getNumberSetting,
  SocialDigestHourOption,
  SOCIAL_DIGEST_HOURS_OPTIONS,
} from '../../services/models/app-settings';
import { FeatureFlag } from '../../services/models/feature-flags';
import { classNames } from '../../utils/class-names';

interface SocialPreferencesPanelProps {
  panelOpen: boolean;
  onClose: () => void;
}

export default function SocialPreferencesPanel({
  panelOpen,
  onClose,
}: SocialPreferencesPanelProps) {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.session.account);
  const socialAlertsFlag = useFeatureFlag(FeatureFlag.ShowSocialAlerts);

  // Preferences
  const timezone =
    account.settings?.length > 0
      ? account.settings.find(x => x.name === AppSetting.Timezone)?.value
      : '';
  const [receiveSocialAlerts, setReceiveSocialAlerts] = useState(false);
  const [receiveSocialDigest, setReceiveSocialDigest] = useState(false);
  const [alertsMaxEmailsPerDay, setAlertsMaxEmailsPerDay] = useState(5);
  const [alertsSharesThreshold, setAlertsSharesThreshold] = useState(3);
  const [socialDigestHourOption, setSocialDigestHourOption] = useState(
    SOCIAL_DIGEST_HOURS_OPTIONS[9]
  );

  useEffect(() => {
    const getSocialDigestHourOption = () => {
      const accountSetting = account.settings?.find(x => x.name === AppSetting.SocialDigestHour);
      const socialDigestHour = !!accountSetting
        ? accountSetting.value
        : SOCIAL_DIGEST_HOURS_OPTIONS[9].value;
      return SOCIAL_DIGEST_HOURS_OPTIONS[Number(socialDigestHour)];
    };

    setReceiveSocialAlerts(getBooleanSetting(account.settings, AppSetting.ReceiveSocialAlerts));
    setReceiveSocialDigest(getBooleanSetting(account.settings, AppSetting.ReceiveSocialDigest));
    setAlertsMaxEmailsPerDay(
      getNumberSetting(account.settings, AppSetting.SocialAlertsMaxEmailsPerDay, 5)
    );
    setAlertsSharesThreshold(
      getNumberSetting(account.settings, AppSetting.SocialAlertsSharesThreshold, 3)
    );
    setSocialDigestHourOption(getSocialDigestHourOption());
  }, [account.settings]);

  const handleDigestHourChange = (option: SocialDigestHourOption) => {
    setSocialDigestHourOption(option);
    dispatch(updateSetting(AppSetting.SocialDigestHour, option.value));
  };

  const handleReceiveDigestChange = (value: boolean) => {
    setReceiveSocialDigest(value);
    dispatch(
      updateSetting(
        AppSetting.ReceiveSocialDigest,
        value ? BooleanSettingValue.Yes : BooleanSettingValue.No
      )
    );
  };

  const handleReceiveAlertsChange = (value: boolean) => {
    setReceiveSocialAlerts(value);
    dispatch(
      updateSetting(
        AppSetting.ReceiveSocialAlerts,
        value ? BooleanSettingValue.Yes : BooleanSettingValue.No
      )
    );

    if (value) {
      dispatch(
        updateSetting(AppSetting.SocialAlertsSharesThreshold, alertsSharesThreshold.toString())
      );
      dispatch(
        updateSetting(AppSetting.SocialAlertsMaxEmailsPerDay, alertsMaxEmailsPerDay.toString())
      );
    }
  };

  const handleAlertsThresholdChange = (value: number) => {
    setAlertsSharesThreshold(value);
    dispatch(updateSetting(AppSetting.SocialAlertsSharesThreshold, value.toString()));
  };

  const handleAlertsMaxEmailsChange = (value: number) => {
    setAlertsMaxEmailsPerDay(value);
    dispatch(updateSetting(AppSetting.SocialAlertsMaxEmailsPerDay, value.toString()));
  };

  return (
    <Transition.Root show={panelOpen} as={Fragment}>
      <Dialog as="div" className="tw-fixed tw-inset-0 tw-overflow-hidden" onClose={onClose}>
        <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-in-out tw-duration-500"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in-out tw-duration-500"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-absolute tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
          </Transition.Child>
          <div className="tw-fixed tw-inset-y-0 tw-right-0 tw-pl-10 tw-max-w-full tw-flex">
            <Transition.Child
              as={Fragment}
              enter="tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
              enterFrom="tw-translate-x-full"
              enterTo="tw-translate-x-0"
              leave="tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
              leaveFrom="tw-translate-x-0"
              leaveTo="tw-translate-x-full"
            >
              <div className="tw-relative tw-w-screen tw-max-w-md">
                <Transition.Child
                  as={Fragment}
                  enter="tw-ease-in-out tw-duration-500"
                  enterFrom="tw-opacity-0"
                  enterTo="tw-opacity-100"
                  leave="tw-ease-in-out tw-duration-500"
                  leaveFrom="tw-opacity-100"
                  leaveTo="tw-opacity-0"
                >
                  <div className="tw-absolute tw-top-0 tw-left-0 tw--ml-8 tw-pt-4 tw-pr-2 tw-flex sm:tw--ml-10 sm:tw-pr-4">
                    <button
                      type="button"
                      className="tw-rounded-md tw-text-gray-300 hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white"
                      onClick={onClose}
                    >
                      <span className="tw-sr-only">Close panel</span>
                      <XIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="tw-h-full tw-flex tw-flex-col tw-py-6 tw-bg-white tw-shadow-xl tw-overflow-y-scroll">
                  <div className="tw-mt-6 tw-relative tw-flex-1 tw-px-4 sm:tw-px-6">
                    <div className="tw-space-y-1">
                      <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        Email Notifications
                      </h3>
                      <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500 tw-m-0">
                        Manage how you get notify
                      </p>
                    </div>

                    <div className="tw-mt-6">
                      <div className="tw-divide-y tw-divide-gray-200">
                        <Switch.Group
                          as="div"
                          className="tw-flex tw-items-center tw-justify-between tw-py-4 sm:tw-py-5"
                        >
                          <span className="tw-flex-grow tw-flex tw-flex-col">
                            <Switch.Label
                              as="span"
                              className="tw-text-sm tw-font-medium tw-text-gray-900"
                              passive
                            >
                              Receive daily digests
                            </Switch.Label>
                          </span>
                          <Switch
                            checked={receiveSocialDigest}
                            onChange={handleReceiveDigestChange}
                            className={classNames(
                              receiveSocialDigest ? 'tw-bg-indigo-600' : 'tw-bg-gray-200',
                              'tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500'
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                receiveSocialDigest ? 'tw-translate-x-5' : 'tw-translate-x-0',
                                'tw-pointer-events-none tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-ease-in-out tw-duration-200'
                              )}
                            />
                          </Switch>
                        </Switch.Group>

                        <div className="tw-flex tw-items-center tw-justify-between tw-py-4 sm:tw-py-5">
                          <span className="tw-flex-grow tw-flex tw-flex-col">
                            <span className="tw-text-sm tw-font-medium tw-text-gray-900">
                              Digest delivery time
                            </span>
                            <span className="tw-text-sm tw-text-gray-500">{timezone}</span>
                          </span>

                          <Listbox
                            value={socialDigestHourOption}
                            onChange={handleDigestHourChange}
                            disabled={!receiveSocialDigest}
                          >
                            {({ open }) => (
                              <>
                                <div className="tw-mt-1 tw-relative">
                                  <Listbox.Button className="tw-bg-white tw-relative tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-pl-3 tw-pr-10 tw-py-2 tw-text-left tw-cursor-default focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-indigo-500 focus:tw-border-indigo-500 sm:tw-text-sm">
                                    <span className="tw-block tw-truncate">
                                      {socialDigestHourOption.label}
                                    </span>
                                    <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none">
                                      <SelectorIcon
                                        className="tw-h-5 tw-w-5 tw-text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="tw-transition tw-ease-in tw-duration-100"
                                    leaveFrom="tw-opacity-100"
                                    leaveTo="tw-opacity-0"
                                  >
                                    <Listbox.Options className="tw-absolute tw-z-10 tw-mt-1 tw-w-full tw-bg-white tw-shadow-lg tw-max-h-60 tw-rounded-md tw-py-1 tw-text-base tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm">
                                      {SOCIAL_DIGEST_HOURS_OPTIONS.map(hourOption => (
                                        <Listbox.Option
                                          key={hourOption.value}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? 'tw-text-white tw-bg-indigo-600'
                                                : 'tw-text-gray-900',
                                              'tw-cursor-default tw-select-none tw-relative tw-py-2 tw-pl-3 tw-pr-9'
                                            )
                                          }
                                          value={hourOption}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected ? 'tw-font-semibold' : 'tw-font-normal',
                                                  'tw-block tw-truncate'
                                                )}
                                              >
                                                {hourOption.label}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active ? 'tw-text-white' : 'tw-text-indigo-600',
                                                    'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4'
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="tw-h-5 tw-w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>

                        {socialAlertsFlag && (
                          <>
                            <Switch.Group
                              as="div"
                              className="tw-flex tw-items-center tw-justify-between tw-py-4 sm:tw-py-5"
                            >
                              <span className="tw-flex-grow tw-flex tw-flex-col">
                                <Switch.Label
                                  as="span"
                                  className="tw-text-sm tw-font-medium tw-text-gray-900"
                                  passive
                                >
                                  Receive news alerts
                                </Switch.Label>
                              </span>
                              <Switch
                                checked={receiveSocialAlerts}
                                onChange={handleReceiveAlertsChange}
                                className={classNames(
                                  receiveSocialAlerts ? 'tw-bg-indigo-600' : 'tw-bg-gray-200',
                                  'tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500'
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    receiveSocialAlerts ? 'tw-translate-x-5' : 'tw-translate-x-0',
                                    'tw-pointer-events-none tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-ease-in-out tw-duration-200'
                                  )}
                                />
                              </Switch>
                            </Switch.Group>

                            <div className="tw-flex tw-items-center tw-justify-between tw-py-4 sm:tw-py-5">
                              <span className="tw-flex-grow tw-flex tw-flex-col">
                                <span className="tw-text-sm tw-font-medium tw-text-gray-900">
                                  Alert you when shared by
                                </span>
                              </span>

                              <Listbox
                                value={alertsSharesThreshold}
                                onChange={handleAlertsThresholdChange}
                                disabled={!receiveSocialAlerts}
                              >
                                {({ open }) => (
                                  <>
                                    <div className="tw-mt-1 tw-relative">
                                      <Listbox.Button className="tw-bg-white tw-relative tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-pl-3 tw-pr-10 tw-py-2 tw-text-left tw-cursor-default focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-indigo-500 focus:tw-border-indigo-500 sm:tw-text-sm">
                                        <span className="tw-block tw-truncate">
                                          {alertsSharesThreshold} people
                                        </span>
                                        <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none">
                                          <SelectorIcon
                                            className="tw-h-5 tw-w-5 tw-text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="tw-transition tw-ease-in tw-duration-100"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                      >
                                        <Listbox.Options className="tw-absolute tw-z-10 tw-mt-1 tw-w-full tw-bg-white tw-shadow-lg tw-max-h-60 tw-rounded-md tw-py-1 tw-text-base tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm">
                                          {[3, 4, 5, 6, 7, 8, 9, 10].map(shareThreshold => (
                                            <Listbox.Option
                                              key={shareThreshold}
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? 'tw-text-white tw-bg-indigo-600'
                                                    : 'tw-text-gray-900',
                                                  'tw-cursor-default tw-select-none tw-relative tw-py-2 tw-pl-3 tw-pr-9'
                                                )
                                              }
                                              value={shareThreshold}
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <span
                                                    className={classNames(
                                                      selected
                                                        ? 'tw-font-semibold'
                                                        : 'tw-font-normal',
                                                      'tw-block tw-truncate'
                                                    )}
                                                  >
                                                    {shareThreshold} people
                                                  </span>

                                                  {selected ? (
                                                    <span
                                                      className={classNames(
                                                        active
                                                          ? 'tw-text-white'
                                                          : 'tw-text-indigo-600',
                                                        'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4'
                                                      )}
                                                    >
                                                      <CheckIcon
                                                        className="tw-h-5 tw-w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>

                            <div className="tw-flex tw-items-center tw-justify-between tw-py-4 sm:tw-py-5">
                              <span className="tw-flex-grow tw-flex tw-flex-col">
                                <span className="tw-text-sm tw-font-medium tw-text-gray-900">
                                  Max number of alerts per day
                                </span>
                              </span>

                              <Listbox
                                value={alertsMaxEmailsPerDay}
                                onChange={handleAlertsMaxEmailsChange}
                                disabled={!receiveSocialAlerts}
                              >
                                {({ open }) => (
                                  <>
                                    <div className="tw-mt-1 tw-relative">
                                      <Listbox.Button className="tw-bg-white tw-relative tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-pl-3 tw-pr-10 tw-py-2 tw-text-left tw-cursor-default focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-indigo-500 focus:tw-border-indigo-500 sm:tw-text-sm">
                                        <span className="tw-block tw-truncate">
                                          {alertsMaxEmailsPerDay} alerts
                                        </span>
                                        <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none">
                                          <SelectorIcon
                                            className="tw-h-5 tw-w-5 tw-text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="tw-transition tw-ease-in tw-duration-100"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                      >
                                        <Listbox.Options className="tw-absolute tw-z-10 tw-mt-1 tw-w-full tw-bg-white tw-shadow-lg tw-max-h-60 tw-rounded-md tw-py-1 tw-text-base tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm">
                                          {Array.from(Array(20)).map((_, numEmails) => (
                                            <Listbox.Option
                                              key={numEmails}
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? 'tw-text-white tw-bg-indigo-600'
                                                    : 'tw-text-gray-900',
                                                  'tw-cursor-default tw-select-none tw-relative tw-py-2 tw-pl-3 tw-pr-9'
                                                )
                                              }
                                              value={numEmails + 1}
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <span
                                                    className={classNames(
                                                      selected
                                                        ? 'tw-font-semibold'
                                                        : 'tw-font-normal',
                                                      'tw-block tw-truncate'
                                                    )}
                                                  >
                                                    {numEmails + 1} alerts
                                                  </span>

                                                  {selected ? (
                                                    <span
                                                      className={classNames(
                                                        active
                                                          ? 'tw-text-white'
                                                          : 'tw-text-indigo-600',
                                                        'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4'
                                                      )}
                                                    >
                                                      <CheckIcon
                                                        className="tw-h-5 tw-w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
