import { AccountActivation } from '../../../services/models/account-activation';
import { AccountActivationAction } from '../../actions/account-activation-actions';

export default function accountActivationReducer(
  state: AccountActivation = {},
  action: any
): AccountActivation {
  switch (action.type) {
    case AccountActivationAction.StartFetching:
      return { ...state, isFetching: true };
    case AccountActivationAction.CompleteFetching:
      return { ...state, ...action.accountActivation, isFetching: false };
    case AccountActivationAction.CancelFetching:
      return { ...state, isFetching: false };
    case AccountActivationAction.StartActivatingAccount:
      return { ...state, isActivating: true };
    case AccountActivationAction.CompleteActivatingAccount:
    case AccountActivationAction.CancelActivatingAccount:
      return { ...state, isActivating: false };
    default:
      return state;
  }
}
