import { Dialog, Listbox, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {
  CalendarIcon,
  CheckIcon,
  PencilIcon,
  PuzzleIcon,
  SelectorIcon,
  UsersIcon,
} from '@heroicons/react/solid';
import React, { Fragment, FunctionComponent } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  createBriefKeyword,
  createRecipient,
  destroyBriefKeyword,
  destroyRecipient,
  removeSlackChannel,
  updateLanguage,
  updateSchedule,
} from '../../redux/actions/brief-settings-actions';
import { SidebarTool } from '../../redux/actions/SidebarTool';
import { Account } from '../../services/models/account';
import { Brief, getKeywordsByType } from '../../services/models/brief';
import { BriefKeywordType } from '../../services/models/brief-keyword-type';
import { BriefLanguage, supportedBriefLanguages } from '../../services/models/brief-language';
import { DayOfWeek } from '../../services/models/day-of-week';
import { classNames } from '../../utils/class-names';
import EditBriefKeyword from '../edit-brief-keyword/EditBriefKeyword';
import EditBriefRecipients from '../edit-brief-recipients/EditBriefRecipients';
import EditBriefSchedule from '../edit-brief-schedule/EditBriefSchedule';
import EditSlackIntegration from '../edit-slack-integration/EditSlackIntegration';

export interface EditOption {
  name: string;
  tool: SidebarTool;
  icon: any;
}

const EDIT_OPTIONS: EditOption[] = [
  { name: 'Edit', tool: SidebarTool.EDIT_BRIEF, icon: PencilIcon },
  { name: 'Schedule', tool: SidebarTool.EDIT_SCHEDULE, icon: CalendarIcon },
  { name: 'Recipients', tool: SidebarTool.EDIT_RECIPIENTS, icon: UsersIcon },
  { name: 'Integrations', tool: SidebarTool.EDIT_INTEGRATIONS, icon: PuzzleIcon },
];

interface EditBriefPanelProps {
  account: Account;
  brief: Brief;
  tool: SidebarTool;
  panelOpen: boolean;
  onClosePanel: () => void;
}
const EditBriefPanel: FunctionComponent<EditBriefPanelProps> = ({
  account,
  brief,
  tool,
  panelOpen,
  onClosePanel,
}) => {
  const editOption = EDIT_OPTIONS.find(eo => eo.tool === tool);

  if (editOption === null) {
    return null;
  }

  return (
    <Transition.Root show={panelOpen} as={Fragment}>
      <Dialog as="div" className="tw-fixed tw-inset-0 tw-overflow-hidden" onClose={onClosePanel}>
        <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-in-out tw-duration-500"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in-out tw-duration-500"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-absolute tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
          </Transition.Child>

          <div className="tw-fixed tw-inset-y-0 tw-right-0 tw-pl-10 tw-max-w-full tw-flex">
            <Transition.Child
              as={Fragment}
              enter="tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
              enterFrom="tw-translate-x-full"
              enterTo="tw-translate-x-0"
              leave="tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
              leaveFrom="tw-translate-x-0"
              leaveTo="tw-translate-x-full"
            >
              <div className="tw-w-screen tw-max-w-md">
                <div className="tw-h-full tw-flex tw-flex-col tw-py-6 tw-bg-white tw-shadow-xl tw-overflow-y-scroll">
                  <div className="tw-px-4 sm:tw-px-6">
                    <div className="tw-flex tw-items-center tw-justify-between tw-pb-4 tw-border-b tw-border-primary">
                      <Dialog.Title className="tw-text-2xl tw-font-bold tw-text-primary tw-m-0">
                        <span className="tw-group tw-flex tw-items-center">
                          {editOption !== undefined && (
                            <>
                              <editOption.icon className="tw-mr-3 tw-flex-shrink-0 tw-h-6 tw-w-6" />{' '}
                              {editOption.name}
                            </>
                          )}
                        </span>
                      </Dialog.Title>
                      <div className="tw-ml-3 tw-h-7 tw-flex tw-items-center">
                        <button
                          type="button"
                          className="tw-bg-white tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
                          onClick={() => onClosePanel()}
                        >
                          <span className="tw-sr-only">Close panel</span>
                          <XIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="tw-relative tw-flex-1 tw-px-4 tw-overflow-y-auto sm:tw-px-6">
                    <EditBrief account={account} brief={brief} tool={tool} />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface EditBriefProps {
  account: Account;
  brief: Brief;
  tool: SidebarTool;
}

const EditBrief: React.FC<EditBriefProps> = ({ account, brief, tool }) => {
  const dispatch = useAppDispatch();

  const productFeatures = account.product.features;
  const selectLanguage =
    supportedBriefLanguages.find(sl => sl.lang === brief.language) || supportedBriefLanguages[0];

  // Brief Keywords
  const briefTopics = getKeywordsByType(brief, BriefKeywordType.Topic);
  const briefFilters = getKeywordsByType(brief, BriefKeywordType.Filter);
  const briefExclusions = getKeywordsByType(brief, BriefKeywordType.Exclusion);

  const handleLanguageChange = (lang: BriefLanguage) => {
    if ([lang in BriefLanguage]) {
      dispatch(updateLanguage(brief.id, lang));
    }
  };

  const handleTopicAddition = (brief: Brief, keyword: string) => {
    const matchingExclusion = (briefExclusions || []).find(
      x => x.keyword?.toLowerCase() === keyword.toLocaleLowerCase()
    );

    if (matchingExclusion && matchingExclusion.id) {
      dispatch(destroyBriefKeyword(brief.id, matchingExclusion.id));
    }

    dispatch(createBriefKeyword(brief.id, keyword, BriefKeywordType.Topic));
  };

  const briefTool = () => (
    <div className="tw-w-full">
      <div className="tw-mt-6">
        <div className="tw-mb-4">
          <div className="tw-block tw-text-xl tw-font-bold tw-text-primary tw-mb-2">Language</div>
          <div className="tw-block tw-text-sm gray-text tw-mb-4">
            Show articles written in the given language
          </div>
          <Listbox value={brief.language} onChange={handleLanguageChange}>
            <div className="tw-mt-1 tw-relative">
              <Listbox.Button className="tw-bg-white tw-relative tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-pl-3 tw-pr-10 tw-py-2 tw-text-left tw-cursor-default focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-indigo-500 focus:tw-border-indigo-500 sm:tw-text-sm">
                <span className="tw-block tw-truncate">{selectLanguage.label}</span>
                <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none">
                  <SelectorIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="tw-transition tw-ease-in tw-duration-100"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
              >
                <Listbox.Options className="tw-absolute tw-z-10 tw-mt-1 tw-w-full tw-bg-white tw-shadow-lg tw-max-h-60 tw-rounded-md tw-py-1 tw-text-base tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm">
                  {supportedBriefLanguages.map(item => (
                    <Listbox.Option
                      key={item.lang}
                      className={({ active }) =>
                        classNames(
                          active ? 'tw-text-white tw-bg-primary' : 'tw-text-gray-900',
                          'tw-cursor-default tw-select-none tw-relative tw-py-2 tw-pl-3 tw-pr-9'
                        )
                      }
                      value={item.lang}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'tw-font-semibold' : 'tw-font-normal',
                              'tw-block tw-truncate'
                            )}
                          >
                            {item.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'tw-text-white' : 'tw-text-primary',
                                'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4'
                              )}
                            >
                              <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>

        {/* Topic Keywords */}
        <EditBriefKeyword
          title="Topics"
          maxItems={productFeatures.keywordsPerBrief}
          placeholder="press ENTER after each keyword"
          tagClassName="blue-tag"
          keywords={briefTopics}
          isUpdating={!!brief.isUpdatingKeywords}
          helpSummary={<span>Enter related keywords on the topics you are interested</span>}
          helpContent={
            <>
              <p>
                Enter any keyword to start your News Brief. Keep your News Brief focused by using
                related terms, ie: machine learning, AI.{' '}
              </p>
              <p>
                Press <code>ENTER</code> after each one.
              </p>
            </>
          }
          create={keyword => handleTopicAddition(brief, keyword)}
          destroy={keywordId => dispatch(destroyBriefKeyword(brief.id, keywordId))}
          validate={keyword =>
            (briefExclusions || []).some(x => x.keyword === keyword)
              ? `There's an exclusion with this keyword`
              : null
          }
        />

        {/* Filter Keywords */}
        <EditBriefKeyword
          title="Filters"
          maxItems={productFeatures.keywordsPerBrief}
          tagClassName="purple-tag"
          placeholder="press ENTER after each keyword"
          keywords={briefFilters}
          isUpdating={!!brief.isUpdatingKeywords}
          helpSummary={
            <span>
              Articles <strong>must</strong> include at least one of these words
            </span>
          }
          helpContent={
            <>
              <p>
                Only articles that have one of these keywords <strong>AND</strong> one of your
                topics will appear. ie: trends, updates, tools.
              </p>
              <p>
                Press <code>ENTER</code> after each word.
              </p>
            </>
          }
          optional={true}
          create={keyword =>
            dispatch(createBriefKeyword(brief.id, keyword, BriefKeywordType.Filter))
          }
          destroy={keywordId => dispatch(destroyBriefKeyword(brief.id, keywordId))}
        />

        <EditBriefKeyword
          title="Exclusions"
          maxItems={productFeatures.keywordsPerBrief}
          tagClassName="carbon-tag"
          placeholder="press ENTER after each keyword"
          keywords={briefExclusions}
          isUpdating={!!brief.isUpdatingKeywords}
          helpSummary={
            <span>
              Articles must <strong>not</strong> include any of these words
            </span>
          }
          helpContent={
            <>
              <p>Any article containing these words won't appear. ie: jobs, courses, webinars.</p>
              <p>
                Press <code>ENTER</code> after each word.
              </p>
            </>
          }
          optional={true}
          create={keyword =>
            dispatch(createBriefKeyword(brief.id, keyword, BriefKeywordType.Exclusion))
          }
          destroy={keywordId => dispatch(destroyBriefKeyword(brief.id, keywordId))}
          validate={keyword =>
            (briefTopics || []).some(x => x.keyword === keyword)
              ? `There's a topic with this keyword`
              : null
          }
        />
      </div>
    </div>
  );

  const recipientsTool = () => (
    <div className="tw-w-full">
      <div className="tw-mt-6">
        <EditBriefRecipients
          brief={brief}
          create={email => dispatch(createRecipient(brief.id, email))}
          destroy={(recipientId: number) => dispatch(destroyRecipient(brief.id, recipientId))}
          maxRecipients={account.product.features.recipientsPerBrief}
        />
      </div>
    </div>
  );

  const scheduleTool = () => (
    <div className="tw-w-full">
      <div className="tw-mt-6">
        <EditBriefSchedule
          brief={brief}
          update={(hour: number, days: DayOfWeek[]) =>
            dispatch(updateSchedule(brief.id, hour, days))
          }
        />
      </div>
    </div>
  );

  const slackTool = () => (
    <div className="tw-w-full">
      <div className="tw-mt-6">
        <EditSlackIntegration
          brief={brief}
          destroy={(slackChannelId: number) =>
            dispatch(removeSlackChannel(brief.id, slackChannelId))
          }
        />
      </div>
    </div>
  );

  return (
    <div className="tw-absolute tw-inset-0 tw-px-4 sm:tw-px-6">
      {tool === SidebarTool.EDIT_BRIEF && briefTool()}
      {tool === SidebarTool.EDIT_RECIPIENTS && recipientsTool()}
      {tool === SidebarTool.EDIT_SCHEDULE && scheduleTool()}
      {tool === SidebarTool.EDIT_INTEGRATIONS && slackTool()}
    </div>
  );
};

export default EditBriefPanel;
