import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import apiSvg from '../../../assets/images/integrations/api.svg';
import hootsuiteLogo from '../../../assets/images/integrations/hootsuite.svg';
import rapidapiLogo from '../../../assets/images/integrations/rapidapi.svg';
import slackLogo from '../../../assets/images/integrations/slack-mark-web.png';
import zapierLogo from '../../../assets/images/integrations/zapier.svg';
import ConnectReportToSlackChannelDialog from '../../../components/connect-report-to-slack-channel-dialog/ConnectReportToSlackChannelDialog';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAvailableIntegration } from '../../../hooks/useAvailableIntegration';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useSubscription } from '../../../hooks/useSubscription';
import SettingsPageLayout from '../../../layouts/settings/settingsPageLayout';
import { ExternalAuth } from '../../../redux/store-state';
import { ApiApp } from '../../../services/models/api-app';
import { AppType } from '../../../services/models/app-type';
import { Brief } from '../../../services/models/brief';
import { FeatureFlag } from '../../../services/models/feature-flags';
import { BillingRoute } from '../../../utils/Routes';

const IntegrationsPage: FunctionComponent = () => {
  const history = useHistory();

  const [showSlackDialog, setShowSlackDialog] = useState(false);

  const subscription = useSubscription();
  const externalAuth: ExternalAuth = useAppSelector(state => state.externalAuth);
  const briefs: Brief[] = useAppSelector(state => state.briefs.items || []);
  const apiApps: ApiApp[] = useAppSelector(state => state.session?.account?.apiApps || []);
  const slackFeatureFlag = useFeatureFlag(FeatureFlag.ShowAddToSlack);
  const zapierIntegration = useAvailableIntegration(AppType.Zapier);
  const slackIntegration = useAvailableIntegration(AppType.Slack);

  const appExists = (appType: AppType) => {
    return apiApps.some(x => x.type === appType);
  };

  return (
    <SettingsPageLayout title="Integrations | Newslit">
      <div className="tw-mt-10 tw-divide-y tw-divide-gray-200">
        <div className="tw-space-y-1">
          <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
            Get the News wherever you need them!
          </h3>
          <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500 tw-m-0">
            Share your News Briefs with your team, users, clients by using our integration options.
          </p>
        </div>

        <div className="tw-mt-6">
          <div className="tw-flex tw-flex-col tw-divide-y tw-divide-gray-200">
            <IntegrationItem
              logo={zapierLogo}
              title="Zapier"
              description="Automatically sync your News Briefs with other services using Zapier."
              buttonLabel={zapierIntegration ? 'Connect' : 'Upgrade Plan'}
              onButtonClick={() => {
                history.push(
                  zapierIntegration ? '/dashboard/settings/integrations/zapier' : BillingRoute
                );
              }}
            />

            {slackFeatureFlag && (
              <>
                <IntegrationItem
                  logo={slackLogo}
                  title="Slack"
                  description="Receive your News Briefs via any Slack channel."
                  buttonLabel={
                    slackIntegration && !!subscription.active ? 'Connect' : 'Upgrade Plan'
                  }
                  onButtonClick={() => {
                    if (slackIntegration && !!subscription.active) {
                      setShowSlackDialog(true);
                    } else {
                      history.push(BillingRoute);
                    }
                  }}
                />

                {briefs && showSlackDialog && (
                  <ConnectReportToSlackChannelDialog
                    briefs={briefs}
                    open={showSlackDialog}
                    onClose={() => setShowSlackDialog(false)}
                    addToSlackLink={externalAuth?.addToSlackLink || null}
                  />
                )}
              </>
            )}

            <IntegrationItem
              logo={hootsuiteLogo}
              title="Hootsuite"
              description="Connect Newslit with Hootsuite."
              buttonLabel="Connect"
              onButtonClick={() => window.open('https://apps.hootsuite.com/apps/newslit', '_blank')}
            />

            {appExists(AppType.Api) && (
              <IntegrationItem
                logo={apiSvg}
                title="API"
                description="Integrate your briefs into your application or website through a single API."
                buttonLabel="Learn More"
                onButtonClick={() => {
                  history.push('/dashboard/settings/integrations/api');
                }}
              />
            )}

            {appExists(AppType.RapidApi) && (
              <IntegrationItem
                logo={rapidapiLogo}
                title="RapidAPI"
                description="Integrate your reports into your application or website through a single API."
                buttonLabel="Learn More"
                onButtonClick={() => {
                  history.push('/dashboard/settings/integrations/rapidapi');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </SettingsPageLayout>
  );
};

export default IntegrationsPage;

interface IntegrationItemProps {
  logo: string;
  title: string;
  description: string;
  buttonLabel: string;
  onButtonClick: () => void;
}

function IntegrationItem(props: IntegrationItemProps) {
  return (
    <div className="tw-flex tw-tw-justify-between tw-items-center tw-p-3">
      <div className="tw-w-16 tw-flex-shrink-0">
        <img
          className="tw-h-10 tw-p-1 tw-object-contain"
          src={props.logo}
          alt={`${props.title} Logo`}
        />
      </div>

      <div className="tw-flex-grow">
        <div className="tw-text-gray-900 tw-font-light tw-text-lg">{props.title}</div>
        <div className="tw-text-gray-400 tw-font-light tw-text-base">{props.description}</div>
      </div>

      <div className="tw-ml-4 tw-flex-shrink-0 tw-justify-center tw-items-end">
        <button
          type="button"
          className="tw-mt-3 tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm disabled:tw-opacity-50"
          onClick={props.onButtonClick}
        >
          {props.buttonLabel}
        </button>
      </div>
    </div>
  );
}
