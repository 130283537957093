export enum BriefLanguage {
  English = 'ENGLISH',
  Spanish = 'SPANISH',
  Italian = 'ITALIAN',
  German = 'GERMAN',
  French = 'FRENCH',
  Any = 'ANY',
}

export const supportedBriefLanguages = [
  { lang: BriefLanguage.English, label: 'English' },
  { lang: BriefLanguage.Spanish, label: 'Spanish' },
  { lang: BriefLanguage.Italian, label: 'Italian' },
  { lang: BriefLanguage.German, label: 'German' },
  { lang: BriefLanguage.French, label: 'French' },
  { lang: BriefLanguage.Any, label: 'Any' },
];
