import { ClockIcon, LightBulbIcon, SearchIcon, SupportIcon } from '@heroicons/react/outline';
import React from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import DashboardNav from '../components/dashboard-nav/DashboardNav';
import { useAppSelector } from '../hooks/useAppSelector';
import DashboardPageLayout from '../layouts/dashboard/dashboardPageLayout';

const newslitFeatures = [
  {
    id: 1,
    name: 'Save Time',
    description:
      'We do the research for you! You get a real-time dashboard and a daily news briefs via e-mail or Slack to ensure you never miss a thing.',
    icon: ClockIcon,
  },
  {
    id: 2,
    name: 'Cut the noise ',
    description:
      'We spot signals hidden to 90% of the world by tracking well over 100,000 news sources and integrating social signals from thousands of top business influencers.',
    icon: SearchIcon,
  },
  {
    id: 3,
    name: 'Stay ahead',
    description:
      'It’s easy to miss important stories. With Newslit, you can connect with over 2,000 applications via Zapier to create workflows and keep track of everything.',
    icon: LightBulbIcon,
  },
  {
    id: 4,
    name: 'Sit back and relax',
    description:
      'Newslit is working 24/7 so you can rest at ease. Did we mention? once is set up, is 100% automated.',
    icon: SupportIcon,
  },
];

export default function DashboardPage() {
  const history = useHistory();
  const match = useRouteMatch();

  const briefs = useAppSelector(state => state.briefs);

  const handleAddNewBriefClick = () => {
    history.push(`${match.url}?brief=new`);
  };

  if (briefs && briefs.items && briefs.items.length > 0) {
    return <Redirect to={`/dashboard/brief/${briefs.items[0].id}`} />;
  }

  return (
    <DashboardPageLayout title="Newslit Dashboard">
      {/* main area */}
      <main className="tw-flex tw-flex-1 tw-z-0 tw-overflow-y-auto tw-overflow-hidden tw-bg-white focus:tw-outline-none">
        {/* Primary section */}
        <section className="tw-flex-1 tw-h-full tw-relative tw-flex tw-flex-col">
          {/* Brief Navbar */}
          <div className="tw-flex-shrink-0 tw-z-20">
            <div className="tw-h-12 sm:tw-hidden"></div>
            <div className="tw-fixed tw-z-10 tw-top-0 tw-right-0 tw-w-full sm:tw-relative sm:tw-top-auto sm:tw-right-auto">
              <DashboardNav className="md:tw-hidden">
                <div className="tw-flex-1 tw-flex">
                  <h1 className="tw-text-lg tw-font-bold tw-m-0 tw-text-gray-900">Newslit News</h1>
                </div>
              </DashboardNav>
            </div>
          </div>

          <div className="tw-block sm:tw-flex sm:tw-h-full sm:tw-overflow-y-auto">
            <div className="tw-py-8 sm:tw-py-16 lg:tw-py-24">
              <div className="tw-relative tw-max-w-xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 lg:tw-max-w-7xl">
                <div className="tw-relative">
                  <h2 className="tw-text-center tw-text-2xl sm:tw-text-3xl tw-leading-8 tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
                    <span className="tw-block">Be the first to know your</span>
                    <span className="tw-block tw-text-secondary">industry's trending news</span>
                  </h2>
                  <p className="tw-mt-4 tw-max-w-2xl tw-mx-auto tw-text-center tw-text-xl tw-text-gray-600">
                    We know you’re busy! After a quick setup, you’ll have a personalized news brief
                    packed with content to hone in on your <strong>industry</strong>,{' '}
                    <strong>competitors</strong> and <strong>brands</strong> that delivers you an
                    unparalleled knowledge advantage.
                  </p>
                  <div className="tw-mt-4 tw-text-center">
                    <button
                      type="button"
                      className="tw-inline-flex tw-items-center tw-px-6 tw-py-3 tw-border tw-border-transparent tw-shadow-sm tw-text-base tw-font-bold tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark hover:tw-text-white hover:tw-no-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                      onClick={handleAddNewBriefClick}
                    >
                      Add your first New Brief
                    </button>
                  </div>
                </div>

                <div className="tw-mt-10">
                  <dl className="tw-space-y-10 md:tw-space-y-0 md:tw-grid md:tw-grid-cols-2 md:tw-gap-x-8 md:tw-gap-y-10">
                    {newslitFeatures.map(feature => (
                      <div key={feature.name} className="relative">
                        <dt>
                          <div className="tw-absolute tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-secondary-dark tw-text-white">
                            <feature.icon className="tw-h-6 tw-w-6" aria-hidden="true" />
                          </div>
                          <p className="tw-ml-16 tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                            {feature.name}
                          </p>
                        </dt>
                        <dd className="tw-mt-2 tw-ml-16 tw-text-base tw-text-gray-500">
                          {feature.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </DashboardPageLayout>
  );
}
