export interface ExternalUser {
  externalUserid: number;
  type: ExternalUserType;
  username: string;
  name: string;
  imageUrl: string;
  bannerImageUrl: string;
  description: string;
  url: string;
}

export enum ExternalUserType {
  TWITTER = 'TWITTER',
}
