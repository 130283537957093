import { SharedLink } from '../../../../services/models/shared-link';
import { BriefStoriesAction } from '../../../actions/brief-stories-actions';
import { SharedLinkAction } from '../../../actions/shared-link-action';
import { SharedLinksAction } from '../../../actions/shared-links-action';
import sharedLinkReducer from './shared-link';

export default function sharedLinkItemsReducer(state: SharedLink[] = [], action: any) {
  switch (action.type) {
    case SharedLinksAction.CompleteFetchingPage:
      return completeFetchingSharedLinksPage(state, action);

    case SharedLinkAction.StartFetching:
    case SharedLinkAction.CompleteFetching:
    case SharedLinkAction.CancelFetching:
      return reduceSharedLink(state, action);

    case BriefStoriesAction.CompleteMarkStoryAsViewed:
      return reduceSharedLinkStory(state, action);

    default:
      return state;
  }
}

const completeFetchingSharedLinksPage = function (state: SharedLink[], action: any) {
  const newSharedLinks: SharedLink[] = action.sharedLinks;
  const sharedLinks: SharedLink[] = [];
  const isFirstPage: boolean = action.isFirstPage;

  if (isFirstPage) {
    return [...newSharedLinks];
  }

  state.forEach(existentSharedLink => {
    const overwrittenSharedLink = newSharedLinks.find(x => x.id === existentSharedLink.id);

    if (overwrittenSharedLink) {
      sharedLinks.push(overwrittenSharedLink);
    } else {
      sharedLinks.push(existentSharedLink);
    }
  });

  newSharedLinks.forEach(newSharedlink => {
    const existentSharedlink = state.find(oldSharedlink => oldSharedlink.id === newSharedlink.id);

    if (!existentSharedlink) {
      sharedLinks.push(newSharedlink);
    }
  });

  return [...sharedLinks];
};

const reduceSharedLink = (state: SharedLink[], action: any) => {
  return state.map(x => (x.id === action.sharedLinkId ? sharedLinkReducer(x, action) : x));
};

const reduceSharedLinkStory = (state: SharedLink[], action: any) => {
  return state.map(x => (x.id === action.storyId ? sharedLinkReducer(x, action) : x));
};
