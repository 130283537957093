import React from 'react';
import { usePaywallContext } from '../../contexts/paywall';
import { Account } from '../../services/models/account';
import { SubscriptionStatus } from '../../services/models/subscription';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { closeMenu } from '../../redux/actions/sidebar-actions';

interface UpsellSidebarNotificationProps {
  account: Account;

}

export function UpsellSidebarNotification(props: UpsellSidebarNotificationProps) {
  const dispatch = useAppDispatch();
  const { setPaywallModalShown } = usePaywallContext();

  const account = props.account;
  const trialExpired = account.subscription.status === SubscriptionStatus.TrialExpired;
  const trialDaysLeft = account.subscription.trialDaysLeft;

  if (!account.subscription?.trial) {
    return null;
  }

  const handleUpsellClick = () => {
    dispatch(closeMenu());
    setPaywallModalShown(true);
  }

  return (
    <div className="tw-flex-shrink-0 tw-block tw-w-full tw-bg-gray-100 tw-border-gray-200">
      <div className="tw-px-3 tw-my-5">
        <span className="tw-block">
          {trialExpired && 'Your free trial has ended.'}
          {!trialExpired &&
            (trialDaysLeft > 0
              ? `Your free trial expires in ${trialDaysLeft} days.`
              : 'Enjoying your trial?')}
        </span>
        <span className="tw-block">
          <button
            type={'button'}
            onClick={handleUpsellClick}
            className="tw-text-secondary tw-font-bold tw-underline hover:tw-text-secondary"
          >
            Upgrade now <span aria-hidden="true">&rarr;</span>
          </button>
        </span>
      </div>
    </div>
  );
}
