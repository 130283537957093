import { EmailUnsubscribeLink } from '../../../services/models/email-unsubscribe-link';
import { EmailUnsubscribeAction } from '../../actions/email-unsubscribe-actions';

export default function emailUnsubscribeReducer(
  state: EmailUnsubscribeLink = {},
  action: any
): EmailUnsubscribeLink {
  switch (action.type) {
    case EmailUnsubscribeAction.StartFetchingLink:
      return { ...state, isFetching: true };
    case EmailUnsubscribeAction.CompleteFetchingLink:
      return { ...state, ...action.link, isFetching: false };
    case EmailUnsubscribeAction.CancelFetchingLink:
      return { ...state, isFetching: false };
    case EmailUnsubscribeAction.StartSocialDigestUnsubscribe:
      return { ...state, isUnsubscribing: true };
    case EmailUnsubscribeAction.CompleteSocialDigestUnsubscribe:
      return { ...state, isUnsubscribing: false, isCompleted: true };
    case EmailUnsubscribeAction.CancelSocialDigestUnsubscribe:
      return { ...state, isUnsubscribing: false };
    default:
      return state;
  }
}
