import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DashboardPageLayout from '../../layouts/dashboard/dashboardPageLayout';
import * as plausible from '../../services/plausible';

const NotFoundPage: FunctionComponent = () => {
  useEffect(() => {
    plausible.trackEvent('400', {
      path: window?.location?.pathname,
    });
  }, []);

  return (
    <DashboardPageLayout>
      <main className="tw-flex-1 tw-relative tw-overflow-y-auto tw-bg-white focus:tw-outline-none">
        <div className="tw-py-16">
          <div className="tw-text-center">
            <h2 className="tw-mt-2 tw-text-4xl tw-font-extrabold tw-text-gray-900 tw-tracking-tight sm:tw-text-5xl">
              Page not found.
            </h2>
            <p className="tw-mt-2 tw-text-base tw-text-gray-500">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="tw-mt-6">
              <Link
                to="/dashboard"
                className="tw-text-base tw-font-medium tw-text-secondary hover:tw-text-secondary-dark"
              >
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </DashboardPageLayout>
  );
};

export default NotFoundPage;
