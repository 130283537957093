import { SidebarActions } from '../../actions/sidebar-actions';
import { DashboardConfigurationNode } from '../../store-state';

export default function dashboardConfiguration(
  state: DashboardConfigurationNode = {},
  action: any
): DashboardConfigurationNode {
  switch (action.type) {
    case SidebarActions.OpenSidebar:
      return { ...state, isRightSidebarOpen: true, tool: action.tool };
    case SidebarActions.CancelOpenSidebar:
      return { ...state, isRightSidebarOpen: false };
    case SidebarActions.CloseSidebar:
      return { ...state, isRightSidebarOpen: false };
    case SidebarActions.OpenMenu:
      return { ...state, isMenuOpen: true };
    case SidebarActions.CloseMenu:
      return { ...state, isMenuOpen: false };
    case SidebarActions.DismissNotifHub:
      return { ...state, isNotifHubOpen: false };
    default:
      return state;
  }
}
