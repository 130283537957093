import React, { FunctionComponent } from 'react';
import * as honeybadger from '../../../../services/honeybadger';
import { Story } from '../../../../services/models/story';
import StoryCardLink from '../../../story-card-link/StoryCardLink';
import './StoryCard.scss';

interface StoryCardProps {
  story: Story;
  hideSeparator?: boolean;
  onStoryClick?: (storyId: number, storyUri: string) => void;
}

export const StoryCard: FunctionComponent<StoryCardProps> = ({
  story,
  hideSeparator,
  onStoryClick,
}) => {
  let storyCardClassName = 'story-card';

  if (hideSeparator) {
    storyCardClassName += ' hide-separator';
  }

  const getStoryDate = () => {
    try {
      return new Intl.DateTimeFormat('en', {
        month: 'short',
        day: 'numeric',
      }).format(new Date(story.publicationDate));
    } catch (e: any) {
      honeybadger.notify(e, 'StoryCard');
      return '';
    }
  };

  return (
    <article className={storyCardClassName}>
      {story.imageUrl && (
        <figure className="story-image">
          <img
            className="image-container rounded"
            src={story.imageUrl}
            alt={story.title}
            loading="lazy"
          />
        </figure>
      )}
      <div className="story-publisher gray-text text-uppercase">{story.source}</div>
      <div className="story-title bold-h2 blue-text">
        <StoryCardLink
          isClicked={story.isClicked}
          briefId={1}
          storyId={story.id}
          href={story.url}
          titleClick={onStoryClick}
        >
          {story.title}
        </StoryCardLink>
      </div>
      <div className="story-details tw-truncate">
        {story.author && (
          <>
            <span className="date-pub tw-truncate">{story.author}</span>
            <span className="date-pub">&nbsp;–&nbsp;</span>
          </>
        )}
        <time dateTime={story.publicationDate.toLocaleString()} className="date-pub">
          {getStoryDate()}
        </time>
      </div>
      <div className="story-excerpt">{story.excerpt}</div>
    </article>
  );
};
