import { ProductsAction } from '../../actions/products-actions';
import { ProductStoreNode } from '../../store-state';

export default function productsReducer(
  state: ProductStoreNode = {},
  action: any
): ProductStoreNode {
  switch (action.type) {
    case ProductsAction.StartFetching:
      return { ...state, isFetching: true };
    case ProductsAction.CompleteFetching:
      return { ...state, products: action.products, isFetching: false };
    case ProductsAction.CancelFetching:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
