import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  createBriefKeyword,
  destroyBriefKeyword,
} from '../../../../redux/actions/brief-settings-actions';
import { requestBriefStoriesPreview } from '../../../../redux/actions/brief-stories-actions';
import { StoreState } from '../../../../redux/store-state';
import { Account } from '../../../../services/models/account';
import { Brief, getKeywordsByType } from '../../../../services/models/brief';
import { BriefKeywordType } from '../../../../services/models/brief-keyword-type';
import EditBriefExclusions from '../../../edit-brief-exclusions/EditBriefExclusions';
import EditBriefFilters from '../../../edit-brief-filters/EditBriefFilters';
import EditBriefTopics from '../../../edit-brief-topics/EditBriefTopics';
import './EditSidebar.scss';

interface Props extends InternalProps {
  account: Account;
  active: boolean;
  draftBrief: Brief | null;
  onComplete: () => void;
}

interface InternalProps {
  getStoriesPreview: (briefId: number) => void;
  createTopic: (briefId: number, keyword: string) => void;
  createFilter: (briefId: number, keyword: string) => void;
  createExclusion: (briefId: number, keyword: string) => void;
  destroyTopic: (briefId: number, id: number) => void;
  destroyFilter: (briefId: number, id: number) => void;
  destroyExclusion: (briefId: number, id: number) => void;
}

const EditSidebar: FunctionComponent<Props> = ({
  account,
  active,
  draftBrief,
  onComplete,
  getStoriesPreview,
  createTopic,
  createFilter,
  createExclusion,
  destroyTopic,
  destroyFilter,
  destroyExclusion,
}) => {
  const changesHashRef = useRef<string>('');

  const onCreateTopic = (keyword: string) => {
    if (!draftBrief) {
      return;
    }

    const matchingExclusion = (
      getKeywordsByType(draftBrief, BriefKeywordType.Exclusion) || []
    ).find(x => x.keyword?.toLowerCase() === keyword.toLocaleLowerCase());

    if (matchingExclusion && matchingExclusion.id) {
      destroyExclusion(draftBrief.id, matchingExclusion.id);
    }

    createTopic(draftBrief.id, keyword);
  };

  const onCreateExclusion = (keyword: string) => {
    if (!draftBrief) {
      return;
    }

    const mathingTopic = (getKeywordsByType(draftBrief, BriefKeywordType.Topic) || []).find(
      x => x.keyword?.toLowerCase() === keyword.toLocaleLowerCase()
    );

    if (mathingTopic && mathingTopic.id) {
      destroyTopic(draftBrief.id, mathingTopic.id);
    }

    createExclusion(draftBrief.id, keyword);
  };

  useEffect(() => {
    if (draftBrief && !draftBrief.isFetching) {
      const changesHash = [...draftBrief.keywords.map(x => x.keyword)].join(', ');

      if (changesHashRef.current !== changesHash) {
        getStoriesPreview(draftBrief.id);
        changesHashRef.current = changesHash;
      }
    }
  }, [draftBrief, getStoriesPreview]);

  if (!active || !draftBrief) {
    return <></>;
  }

  return (
    <div className="edit-sidebar">
      <div className="form-controls text-right">
        <Button variant="purple" onClick={() => onComplete()}>
          Next
        </Button>
      </div>
      <EditBriefTopics
        maxItems={account.product.features.keywordsPerBrief}
        brief={draftBrief}
        create={k => onCreateTopic(k)}
        destroy={id => destroyTopic(draftBrief.id, id)}
      />
      <EditBriefFilters
        maxItems={account.product.features.keywordsPerBrief}
        brief={draftBrief}
        create={k => createFilter(draftBrief.id, k)}
        destroy={id => destroyFilter(draftBrief.id, id)}
      />
      <EditBriefExclusions
        maxItems={account.product.features.keywordsPerBrief}
        brief={draftBrief}
        create={k => onCreateExclusion(k)}
        destroy={id => destroyExclusion(draftBrief.id, id)}
      />
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    account: state.session.account,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getStoriesPreview: (briefId: number) => dispatch(requestBriefStoriesPreview(briefId)),
    createTopic: (briefId: number, keyword: string) =>
      dispatch(createBriefKeyword(briefId, keyword, BriefKeywordType.Topic)),
    createFilter: (briefId: number, keyword: string) =>
      dispatch(createBriefKeyword(briefId, keyword, BriefKeywordType.Filter)),
    createExclusion: (briefId: number, keyword: string) =>
      dispatch(createBriefKeyword(briefId, keyword, BriefKeywordType.Exclusion)),
    destroyTopic: (briefId: number, id: number) => dispatch(destroyBriefKeyword(briefId, id)),
    destroyFilter: (briefId: number, id: number) => dispatch(destroyBriefKeyword(briefId, id)),
    destroyExclusion: (briefId: number, id: number) => dispatch(destroyBriefKeyword(briefId, id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSidebar);
