import * as account from '../../services/account';
import { refreshUserAccount } from '../../services/account';
import { Email } from '../../services/models/email';
import { asyncAction, Dispatch } from '../types';

export enum EmailAction {
  StartUpdating = 'EmailActionStartUpdating',
  CompleteUpdating = 'EmailActionCompleteUpdating',
  CancelUpdating = 'EmailActionCancelUpdating',
  StartSendingVerification = 'EmailActionStartSendingVerification',
  CompleteSendingVerification = 'EmailActionCompleteSendingVerification',
  CancelSendingVerification = 'EmailActionCompleteSendingVerification',
  StartVerifying = 'EmailActionStartVerifying',
  CompleteVerifying = 'EmailActionCompleteVerifying',
  CancelVerifying = 'EmailActionCancelVerifying',
}

export const updateEmail = (email: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startUpdatingEmail());
    const updatedEmail = await account.updateEmail(email);
    await refreshUserAccount();
    dispatch(completeUpdatingEmail(updatedEmail));
  }, cancelUpdatingEmail);

export const startUpdatingEmail = () => ({
  type: EmailAction.StartUpdating,
});

export const completeUpdatingEmail = (email: Email) => ({
  type: EmailAction.CompleteUpdating,
  email,
});

export const cancelUpdatingEmail = () => ({
  type: EmailAction.CancelUpdating,
});

export const sendVerificationEmail = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startSendingVerificationEmail());
    const success = await account.sendVerificationEmail();
    if (success) {
      dispatch(completeSendingVerificationEmail());
    } else {
      dispatch(cancelSendingVerificationEmail());
    }
  }, cancelSendingVerificationEmail);

export const startSendingVerificationEmail = () => ({
  type: EmailAction.StartSendingVerification,
});

export const completeSendingVerificationEmail = () => ({
  type: EmailAction.CompleteSendingVerification,
});

export const cancelSendingVerificationEmail = () => ({
  type: EmailAction.CancelSendingVerification,
});

export const verifyEmail = (code: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startVerifying());
    const success = await account.verifyEmail(code);
    if (success) {
      dispatch(completeVerifying());
    } else {
      dispatch(cancelVerifying());
    }
  }, cancelVerifying);

export const startVerifying = () => ({
  type: EmailAction.StartVerifying,
});

export const completeVerifying = () => ({
  type: EmailAction.CompleteVerifying,
});

export const cancelVerifying = () => ({
  type: EmailAction.CancelVerifying,
});
