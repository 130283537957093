import React from 'react';
import { Alert } from 'react-bootstrap';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const emailUnsubscribeErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: Error,
  discard: DiscardErrorCallback
) => {
  if (error.message.indexOf('Exception while fetching data (/emailUnsubscribe)') === -1) {
    return null;
  }

  return (
    <Alert key={index} variant="warning" dismissible onClose={() => discard(error)}>
      Looks like we had trouble unsubscribing your from our emails. Please contact us at{' '}
      <a href="mailto:help@newslit.co">help@newslit.co</a> if you continue having issues.
    </Alert>
  );
};
