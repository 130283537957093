import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface DefaultPageLayoutProps {
  title?: string;
  addClearbitRisk?: boolean;
  children: ReactNode;
}

export default function DefaultPageLayout(props: DefaultPageLayoutProps) {
  return (
    <>
      <Helmet>
        <title>{props.title ?? 'Newslit'}</title>
        {props.addClearbitRisk && (
          <script type="text/javascript">{`
            !function(){
              var script = document.createElement('script');
              script.async = true;
              script.src = 'https://risk.clearbit.com/v1/risk.js';
              var parent = document.getElementsByTagName('script')[0];
              parent.parentNode.insertBefore(script, parent);
            }();
          `}</script>
        )}
      </Helmet>
      {props.children}
    </>
  );
}
