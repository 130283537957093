import { ErrorsAction } from '../../actions/errors-actions';
import { SessionAction } from '../../actions/session-actions';

export default function errorsReducer(state: Error[] = [], action: any) {
  switch (action.type) {
    case ErrorsAction.Notify:
      return [action.error];
    case ErrorsAction.NotifyAll:
      return [...action.errors];
    case ErrorsAction.Discard:
      return state.filter(x => x !== action.error);
    case ErrorsAction.DiscardAll:
    case SessionAction.SignOut:
      return [];
    default:
      return state;
  }
}
