import { MenuAlt2Icon } from '@heroicons/react/outline';
import React, { ReactNode } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { openMenu } from '../../redux/actions/sidebar-actions';
import { AppDispatch } from '../../redux/store';
import { classNames } from '../../utils/class-names';

interface DashboardNavProps {
  className?: string;
  children: ReactNode;
}

const DashboardNav: React.FC<DashboardNavProps> = ({ className, children }) => {
  const dispatch: AppDispatch = useAppDispatch();

  return (
    <div
      className={classNames(
        className ? className : '',
        'tw-relative tw-flex-shrink-0 tw-flex tw-h-12 tw-bg-white tw-border-b tw-border-gray-200'
      )}
    >
      <button
        type="button"
        className="tw-h-12 tw-w-12 tw-flex tw-items-center tw-justify-center tw-text-gray-500 focus:tw-outline-none md:tw-hidden"
        onClick={() => dispatch(openMenu())}
      >
        <span className="tw-sr-only">Open sidebar</span>
        <MenuAlt2Icon className="tw-h-6 tw-w-6" aria-hidden="true" />
      </button>

      <div className="tw-flex-1 tw-flex tw-pl-2 md:tw-pl-4 tw-pr-4 tw-justify-between tw-items-center">
        {children}
      </div>
    </div>
  );
};

export default DashboardNav;
