export enum SidebarTool {
  NONE,
  EDIT_BRIEF,
  EDIT_SCHEDULE,
  EDIT_RECIPIENTS,
  EDIT_SLACK,
  EDIT_INTEGRATIONS,
}

export const locationToSidebarTool = (pathname: string) => {
  if (pathname.endsWith('edit')) {
    return SidebarTool.EDIT_BRIEF;
  } else if (pathname.endsWith('slack')) {
    return SidebarTool.EDIT_SLACK;
  } else if (pathname.endsWith('recipients')) {
    return SidebarTool.EDIT_RECIPIENTS;
  } else if (pathname.endsWith('schedule')) {
    return SidebarTool.EDIT_SCHEDULE;
  } else if (pathname.endsWith('integrations')) {
    return SidebarTool.EDIT_INTEGRATIONS;
  }
  return SidebarTool.NONE;
};

export const sidebarToolToPathName = (tool: SidebarTool) => {
  switch (tool) {
    case SidebarTool.EDIT_INTEGRATIONS:
      return 'integrations';
    case SidebarTool.EDIT_SLACK:
      return 'slack';
    case SidebarTool.EDIT_SCHEDULE:
      return 'schedule';
    case SidebarTool.EDIT_BRIEF:
      return 'edit';
    case SidebarTool.EDIT_RECIPIENTS:
      return 'recipients';
    default:
      return '';
  }
};

export const isRightSidebarOpen = (pathname: string) => {
  if (pathname.startsWith('/dashboard/report')) {
    if (pathname.endsWith('edit')) {
      return true;
    } else if (pathname.endsWith('slack')) {
      return true;
    } else if (pathname.endsWith('recipients')) {
      return true;
    } else if (pathname.endsWith('schedule')) {
      return true;
    } else if (pathname.endsWith('integrations')) {
      return true;
    }
  }
  return false;
};
