import React, { FunctionComponent, ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface HelpDialogProps {
  children: ReactNode;
  show: boolean;
  onHide: () => void;
}

const HelpDialog: FunctionComponent<HelpDialogProps> = ({ children, show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
      <Modal.Body>
        {children}
        <Button variant="secondary" size="sm" onClick={onHide}>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default HelpDialog;
