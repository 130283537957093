import React, { Component, SyntheticEvent } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { resetPassword } from '../../../../redux/actions/session-actions';
import { StoreState } from '../../../../redux/store-state';
import { Session } from '../../../../services/models/session';
import './ConfirmPasswordResetPage.scss';

const CodeQueryParam = 'c';

interface ConfirmPasswordResetPageProps extends RouteComponentProps {
  session: Session;
  resetPassword: (code: string, password: string) => void;
}

interface ConfirmPasswordResetPageState {
  code: string | null;
  password: string;
  passwordTouched?: boolean;
  wrongPasswordLength?: boolean;
  passwordConfirmation: string;
  passwordConfirmationTouched?: boolean;
  passwordConfirmationMismatch?: boolean;
}

// TODO(Jose): Convert this into a Functional component
class ConfirmPasswordResetPage extends Component<
  ConfirmPasswordResetPageProps,
  ConfirmPasswordResetPageState
> {
  constructor(props: ConfirmPasswordResetPageProps) {
    super(props);

    this.state = {
      password: '',
      passwordTouched: false,
      wrongPasswordLength: false,
      passwordConfirmation: '',
      passwordConfirmationTouched: false,
      passwordConfirmationMismatch: false,
      code: new URLSearchParams(this.props.location.search).get(CodeQueryParam),
    };

    if (!this.state.code) {
      props.history.push('/');
    }
  }

  async handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    this.props.resetPassword(this.state.code as string, this.state.password);
  }

  handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    const wrongPasswordLength = value.length < 6 || value.length > 32;
    this.setState({
      password: value,
      passwordTouched: true,
      wrongPasswordLength,
    });
  }

  handlePasswordConfirmationChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    const passwordConfirmationMismatch = value !== this.state.password;
    this.setState({
      passwordConfirmation: value,
      passwordConfirmationTouched: true,
      passwordConfirmationMismatch,
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Confirm Password</title>
        </Helmet>
        <Card>
          <Card.Body>
            {this.redirect()}
            <h1 className="password-reset-content-header text-center">Reset password</h1>
            <div>{this.showForm()}</div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  showForm() {
    return (
      <Form noValidate onSubmit={e => this.handleSubmit(e)}>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            isInvalid={this.state.passwordTouched && this.state.wrongPasswordLength}
            value={this.state.password}
            onChange={(e: any) => this.handlePasswordChange(e)}
            placeholder="At least 6 characters"
            required
          />
          {(() => {
            if (this.state.passwordTouched && this.state.wrongPasswordLength) {
              return (
                <Form.Text className="text-muted">
                  Please create a password between 6 and 32 characters long
                </Form.Text>
              );
            }
          })()}
        </Form.Group>

        <Form.Group controlId="password-confirmation">
          <Form.Label>Password confirmation</Form.Label>
          <Form.Control
            type="password"
            isInvalid={
              this.state.passwordConfirmationTouched && this.state.passwordConfirmationMismatch
            }
            value={this.state.passwordConfirmation}
            onChange={(e: any) => this.handlePasswordConfirmationChange(e)}
            required
          />
          {(() => {
            if (this.state.passwordConfirmationTouched && this.state.passwordConfirmationMismatch) {
              return (
                <Form.Text className="text-muted">
                  Password confirmation doesn't match Password
                </Form.Text>
              );
            }
          })()}
        </Form.Group>

        <Button
          disabled={
            this.props.session.isResettingPassword ||
            this.props.session.isRequestingPasswordReset ||
            !this.state.passwordTouched ||
            this.state.wrongPasswordLength ||
            !this.state.passwordConfirmationTouched ||
            this.state.passwordConfirmationMismatch
          }
          className="mt-3 btn-block"
          variant="purple"
          type="submit"
        >
          {this.props.session.isResettingPassword ? 'Resetting...' : 'Reset password'}
        </Button>
      </Form>
    );
  }

  redirect() {
    if (this.props.session.isPasswordResetConfirmed) {
      return <Redirect to="/users/signin"></Redirect>;
    }
  }
}

function mapStateToProps(state: StoreState) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    resetPassword: (code: string, password: string) => dispatch(resetPassword(code, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmPasswordResetPage));
