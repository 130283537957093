import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { getSession } from './authentication';
import { configuration } from './configuration';
import { NotAuthenticatedError } from './errors/not-authenticated-error';

export const getGraphQLClient = (): ApolloClient<any> => {
  return new ApolloClient({
    uri: configuration.backend.graphqlUrl,
    cache: new InMemoryCache(),
  });
};

export const getAuthenticatedGraphQLClient = (): ApolloClient<any> => {
  const session = getSession();
  if (!session) {
    throw new NotAuthenticatedError();
  }
  const headers: { [header: string]: string } = {
    authorization: `Bearer ${session.token}`,
  };

  return new ApolloClient({
    headers,
    uri: configuration.backend.graphqlUrl,
    cache: new InMemoryCache(),
  });
};
