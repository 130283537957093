import { FeatureFlags } from '../../../services/models/feature-flags';
import { FeatureFlagsAction } from '../../actions/feature-flags-actions';

export default function featureFlagsReducer(state: FeatureFlags = {}, action: any): FeatureFlags {
  switch (action.type) {
    case FeatureFlagsAction.StartFetching:
      return { ...state, isFetching: true };
    case FeatureFlagsAction.CompleteFetching:
      return { ...state, flags: action.featureFlags, isFetching: false };
    case FeatureFlagsAction.CancelFetching:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
