function getEnvVariable(key: string): string {
  const value = process.env[key];
  if (value === undefined) {
    throw new Error(`${key} is not set`);
  }
  return value;
}

export const configuration = {
  environment: getEnvVariable('REACT_APP_ENVIRONMENT'),
  version: process.env.REACT_APP_RELEASE_VERSION || process.env.REACT_APP_VERSION,
  backend: {
    graphqlUrl: getEnvVariable('REACT_APP_GRAPHQL_API_URL'),
    baseUrl: getEnvVariable('REACT_APP_BASE_URL'),
  },
  honeybadger: {
    apiKey: getEnvVariable('REACT_APP_HONEYBADGER_API_KEY'),
    environmentName: getEnvVariable('REACT_APP_HONEYBADGER_ENVIRONMENT'),
  },
  reader: {
    url: getEnvVariable('REACT_APP_READER_URL'),
  },
  segment: {
    apiKey: getEnvVariable('REACT_APP_SEGMENT_WRITE_KEY'),
  },
  stripe: {
    publishableKey: getEnvVariable('REACT_APP_STRIPE_PUBLIC_KEY'),
  },
  zapier: {
    embeddedWidgetUrl: getEnvVariable('REACT_APP_ZAPIER_WIDGET_URL'),
  },
};
