import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import { classNames } from '../../utils/class-names';

export interface TimeFilter {
  /** Time filter title. */
  title: string;
  /** Time filter label. */
  label: string;
  /** Time filter value. */
  value: string;
}

export const timeFilters: TimeFilter[] = [
  { title: '24 Hrs', label: 'Last 24 hours', value: '24' },
  { title: '8 Hrs', label: 'Last 8 hours', value: '8' },
  { title: '4 Hrs', label: 'Last 4 hours', value: '4' },
  { title: '2 Hrs', label: 'Last 2 hours', value: '2' },
  { title: '1 Hr', label: 'Last hour', value: '1' },
];

export interface SortOption {
  /** Sort Option Name. */
  name: string;
  /** Sort Option value. */
  value: string;
}

export const sortOptions: SortOption[] = [
  { name: 'Most Popular', value: 'shares' },
  { name: 'Newest', value: 'time' },
];

interface FilterNavProps {
  sortOptions: SortOption[];
  activeSortOption: SortOption;
  onActiveSortChange: (sort: SortOption) => void;
  timeFilterOptions: TimeFilter[];
  activeTimeFilter: TimeFilter;
  onActiveTimeFilterChange: (time: TimeFilter) => void;
  activeSharesFilter: number;
  onActiveSharesFilterChange: (shares: number) => void;
  onFilterOptionsChange: (sort: SortOption, time: TimeFilter, shares: number) => void;
}

export default function FilterNav({
  sortOptions,
  activeSortOption,
  onActiveSortChange,
  timeFilterOptions,
  activeTimeFilter,
  onActiveTimeFilterChange,
  activeSharesFilter,
  onActiveSharesFilterChange,
  onFilterOptionsChange,
}: FilterNavProps) {
  const handleSortOptionChange = (newSortOption: SortOption) => {
    if (newSortOption !== activeSortOption) {
      onFilterOptionsChange(newSortOption, activeTimeFilter, activeSharesFilter);
    }

    onActiveSortChange(newSortOption);
  };

  const handleTimeFilterSelect = (newTimeFilter: TimeFilter) => {
    if (newTimeFilter !== activeTimeFilter) {
      onFilterOptionsChange(activeSortOption, newTimeFilter, activeSharesFilter);
    }

    onActiveTimeFilterChange(newTimeFilter);
  };

  const handleSharesFilterSelect = (newSharesFilter: number) => {
    if (newSharesFilter !== activeSharesFilter) {
      onFilterOptionsChange(activeSortOption, activeTimeFilter, newSharesFilter);
    }

    onActiveSharesFilterChange(newSharesFilter);
  };

  return (
    <div className="tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-py-1.5 tw-text-sm tw-font-medium tw-text-gray-500">
      <div className="tw-max-w-7xl tw-mx-auto tw-px-4 tw-flex tw-items-center tw-justify-between">
        {/* Sort By Options */}
        <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
          <div>
            <Menu.Button className="tw-group tw-inline-flex tw-justify-center tw-text-sm tw-font-bold tw-text-gray-700 hover:tw-text-gray-900">
              {activeSortOption.name}
              <ChevronDownIcon
                className="tw-flex-shrink-0 -tw-mr-1 tw-ml-1 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <Menu.Items className="tw-origin-top-left tw-absolute tw-left-0 tw-mt-2 tw-w-40 tw-rounded-md tw-shadow-2xl tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
              <div className="tw-py-1">
                {sortOptions.map(option => (
                  <Menu.Item key={option.value}>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          option.value === activeSortOption.value
                            ? 'tw-font-bold tw-text-gray-900'
                            : 'tw-text-gray-500',
                          active ? 'tw-bg-gray-100' : '',
                          'tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-sm'
                        )}
                        onClick={() => handleSortOptionChange(option)}
                      >
                        {option.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        {/* Filter By Options (Based on Sort Selection) */}
        <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
          <div>
            <Menu.Button className="tw-group tw-inline-flex tw-justify-center tw-text-sm tw-font-semibold tw-text-gray-700 hover:tw-text-gray-900">
              {activeSortOption.value === 'shares'
                ? activeTimeFilter.title
                : activeSharesFilter > 0
                ? `${activeSharesFilter}+`
                : 'All'}
              <ChevronDownIcon
                className="tw-flex-shrink-0 -tw-mr-1 tw-ml-1 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <Menu.Items className="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-40 tw-rounded-md tw-shadow-2xl tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
              <div className="tw-py-1">
                {activeSortOption.value === 'shares' &&
                  timeFilterOptions.map(option => (
                    <Menu.Item key={option.label}>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            option.value === activeTimeFilter.value
                              ? 'tw-font-bold tw-text-gray-900'
                              : 'tw-text-gray-500',
                            active ? 'tw-bg-gray-100' : '',
                            'tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-sm'
                          )}
                          onClick={() => handleTimeFilterSelect(option)}
                        >
                          {option.label}
                        </button>
                      )}
                    </Menu.Item>
                  ))}

                {activeSortOption.value === 'time' &&
                  [0, 2, 3, 4, 5].map(numShare => (
                    <Menu.Item key={numShare}>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            numShare === activeSharesFilter
                              ? 'tw-font-bold tw-text-gray-900'
                              : 'tw-text-gray-500',
                            active ? 'tw-bg-gray-100' : '',
                            'tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-sm'
                          )}
                          onClick={() => handleSharesFilterSelect(numShare)}
                        >
                          {numShare > 0 ? `${numShare}+ shares` : 'All'}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
