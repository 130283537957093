import { Email } from '../../../../../services/models/email';
import { EmailAction } from '../../../../actions/email-actions';

export default function emailReducer(state: Email = {} as any, action: any): Email {
  switch (action.type) {
    case EmailAction.StartUpdating:
      return { ...state, isUpdating: true };
    case EmailAction.CompleteUpdating:
      return { ...state, ...action.email, isUpdating: false };
    case EmailAction.CancelUpdating:
      return { ...state, isUpdating: false };
    case EmailAction.StartSendingVerification:
      return { ...state, isSendingVerificationEmail: true };
    case EmailAction.CompleteSendingVerification:
    case EmailAction.CancelSendingVerification:
      return { ...state, isSendingVerificationEmail: false };
    case EmailAction.StartVerifying:
      return { ...state, isVerifying: true };
    case EmailAction.CompleteVerifying:
      return { ...state, isVerifying: false, verified: true };
    case EmailAction.CancelVerifying:
      return { ...state, isVerifying: false };
    default:
      return state;
  }
}
