import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { usePaywallContext } from '../../contexts/paywall';

const UpgradeRedirectionPage = () => {
  const { setPaywallModalShown } = usePaywallContext();

  useEffect(() => {
    setPaywallModalShown(true);
  }, [setPaywallModalShown]);

  return <Redirect to={'/dashboard'} />;
};

export default UpgradeRedirectionPage;
