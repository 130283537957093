import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import newslitLogo from '../../../assets/images/newslit-logo.svg';
import rocketIllustration from '../../../assets/images/rocket-illustration.png';
import ErrorReporter from '../../../components/error-reporter/ErrorReporter';
import TwitterLoginBtn from '../../../components/twitter-login-btn/TwitterLoginBtn';
import Input from '../../../components/ui/Input';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import DefaultPageLayout from '../../../layouts/defaultPageLayout';
import { finalizeSignInWithTwitter, requestSignIn } from '../../../redux/actions/session-actions';
import { isLocalStorageAvailable } from '../../../services/authentication';

const SignInPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const session = useAppSelector(state => state.session);
  const { search: searchLocation } = useLocation();

  // TODO: Control via SiteFlag
  const showTwitterLogin = true;
  const isTwitterIntegrationActive = true;

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: yup.object().shape({
      email: yup.string().email().required('Please type a valid email'),
      password: yup.string().required('Please type your password'),
    }),
    onSubmit: values => {
      dispatch(requestSignIn(values.email, values.password));
    },
  });

  useEffect(() => {
    // if account is already logged in, redirect them to the dashboard page
    if (session?.account && session?.token) {
      const searchParams = new URLSearchParams(searchLocation);
      const redirectAfterSignInPath = searchParams.get('next');
      const redirectPath = !!redirectAfterSignInPath ? redirectAfterSignInPath : '/dashboard';
      history.push(redirectPath);
    }
  }, [session, history, searchLocation]);

  return (
    <DefaultPageLayout title="Sign in to Newslit">
      <div className="tw-min-h-screen tw-bg-white tw-flex">
        <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-py-12 tw-px-4 sm:tw-px-6 lg:tw-flex-none lg:tw-px-20 xl:tw-px-24">
          <div className="tw-mx-auto tw-w-full tw-max-w-sm lg:tw-w-96">
            <div>
              <img className="tw-h-12 tw-w-auto" src={newslitLogo} alt="Newslit" />
              <h2 className="tw-mt-6 tw-text-3xl tw-font-extrabold tw-text-gray-900">
                Sign in to your account
              </h2>
              <p className="tw-mt-2 tw-text-sm tw-text-gray-600">
                Or{' '}
                <a
                  href={`/users/signup`}
                  className="tw-font-medium tw-text-secondary-dark hover:tw-text-secondary"
                >
                  start your 14-day free trial
                </a>
              </p>
            </div>

            <div className="tw-mt-8">
              {showTwitterLogin && (
                <>
                  <div className="tw-mt-1">
                    <TwitterLoginBtn
                      disabled={!isTwitterIntegrationActive}
                      checkIfEnable={() => isLocalStorageAvailable()}
                      onSuccess={() => dispatch(finalizeSignInWithTwitter())}
                    />
                  </div>

                  <div className="tw-mt-6 tw-relative">
                    <div
                      className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                      aria-hidden="true"
                    >
                      <div className="tw-w-full tw-border-t tw-border-gray-300" />
                    </div>
                    <div className="tw-relative tw-flex tw-justify-center tw-text-sm">
                      <span className="tw-px-2 tw-bg-white tw-text-gray-500">Or continue with</span>
                    </div>
                  </div>
                </>
              )}

              <div className="tw-mt-6">
                <form onSubmit={formik.handleSubmit} className="tw-space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="tw-mt-1">
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        error={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : undefined
                        }
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="tw-space-y-1">
                    <label
                      htmlFor="password"
                      className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >
                      Password
                    </label>
                    <div className="tw-mt-1 tw-relative tw-rounded-md tw-shadow-sm">
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        error={
                          formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : undefined
                        }
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="tw-flex tw-items-center tw-justify-between">
                    <div className="tw-text-sm tw-ml-auto">
                      <a
                        href="/users/password_reset"
                        className="tw-font-medium tw-text-secondary-dark hover:tw-text-secondary"
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={session.isFetching || !formik.isValid}
                      className="tw-w-full tw-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary disabled:tw-opacity-50"
                    >
                      {session.isFetching && (
                        <svg
                          className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="tw-opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            className="tw-opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-hidden lg:tw-block tw-relative tw-w-0 tw-flex-1">
          <img
            className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
            src={rocketIllustration}
            alt="Newslit Illustration"
          />
        </div>
      </div>

      <ErrorReporter />
    </DefaultPageLayout>
  );
};

export default SignInPage;
