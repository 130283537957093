import 'font-awesome/css/font-awesome.min.css';
import React, { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PaywallContextProvider } from '../../contexts/paywall';
import { PwaContextProvider } from '../../contexts/pwa';
import { TrackingContextProvider } from '../../contexts/trackers';
import { configuration } from '../../services/configuration';
import { trackPageArrival } from '../../services/segment';
import '../../styles/theme.scss';
import Hootsuite from '../hootsuite/Hootsuite';
import AppRoutes from '../Routes/AppRoutes';

const AppContainer = () => {
  return (
    <TrackingContextProvider writeKey={configuration.segment.apiKey}>
      <PwaContextProvider>
        <PaywallContextProvider>
          <App />
        </PaywallContextProvider>
      </PwaContextProvider>
    </TrackingContextProvider>
  );
};

const App: FunctionComponent = () => {
  let location = useLocation();

  // Todo(Jose): move this to our TrackingContextProvider
  useEffect(() => {
    trackPageArrival();
  }, [location]);

  return (
    <>
      <AppRoutes />
      <Hootsuite />
    </>
  );
};

export default AppContainer;
