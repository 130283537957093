import * as featureFlagsManager from '../../services/feature-flags';
import { FeatureFlags } from '../../services/models/feature-flags';
import { asyncAction, Dispatch } from '../types';

export enum FeatureFlagsAction {
  StartFetching = 'FeatureFlagsActionStartFetching',
  CompleteFetching = 'FeatureFlagsActionCompleteFetching',
  CancelFetching = 'FeatureFlagsActionCancelFetching',
}

export const getFeatureFlags = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingFeatureFlags());
    const featureFlags = await featureFlagsManager.getFeatureFlags();
    dispatch(completeFetchingFeatureFlags(featureFlags));
  }, cancelFetchingFeatureFlags);

export const startFetchingFeatureFlags = () => ({
  type: FeatureFlagsAction.StartFetching,
});

export const completeFetchingFeatureFlags = (featureFlags: FeatureFlags) => ({
  type: FeatureFlagsAction.CompleteFetching,
  featureFlags,
});

export const cancelFetchingFeatureFlags = () => ({
  type: FeatureFlagsAction.CancelFetching,
});
