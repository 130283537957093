export class AppError extends Error {
  public errorCode: string;

  public constructor(code: string, message?: string) {
    super(message ? `[${code}]" ${message}".` : code);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = AppError.name;
    this.errorCode = code;
  }
}
