import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { reportError } from '../../../../redux/actions/errors-actions';
import { signInWithTwitter } from '../../../../redux/actions/session-actions';
import { isAuthenticated } from '../../../../services/authentication';
import './TwitterCallbackPage.scss';

const TwitterCallbackPage: FunctionComponent = () => {
  const history = useHistory();
  const { search } = useLocation();

  // Query Params
  const searchParams = new URLSearchParams(search);
  const oauthToken = searchParams.get('oauth_token');
  const oauthVerifier = searchParams.get('oauth_verifier');
  const denied = searchParams.get('denied');

  const dispatch = useAppDispatch();
  const session = useAppSelector(state => state.session);
  const errors = useAppSelector(state => state.errors);

  const [loading, setLoading] = useState(true);
  const [isAuth] = useState(isAuthenticated());

  const isInHootSuiteCallback = () => {
    return localStorage.getItem('is-hootsuite');
  };

  const closeWindow = useCallback(() => {
    if (isInHootSuiteCallback()) {
      localStorage.removeItem('is-hootsuite');
    }

    if (window.opener && session && session.token) {
      window.opener.postMessage(JSON.stringify(session), window.location.origin);
    }

    if (window.opener && window.opener.popupCompleted) {
      window.opener.popupCompleted();
    } else {
      history.push('/');
    }
  }, [session, history]);

  useEffect(() => {
    if (denied) {
      closeWindow();
      return;
    }

    if (!oauthToken || !oauthVerifier) {
      dispatch(reportError(new Error('Missing "oauth_token" or "oauth_verifier".')));
      setLoading(false);
      return;
    }

    if (!session.isFetching) {
      dispatch(signInWithTwitter(oauthToken, oauthVerifier, isAuth));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (session && session.isFetching === false && !errors.length) {
      closeWindow();
      return;
    }

    if (session.isFetching === false) {
      setLoading(false);
    }
  }, [isAuth, session, errors, closeWindow]);

  return (
    <div>
      <Card>
        <Card.Body>
          <h1 className="twitter-callback-content-header text-center">
            {(() => {
              if (loading) {
                return (
                  <>
                    <Spinner animation="grow"></Spinner> Authenticating...
                  </>
                );
              }
              return 'Oops, something went wrong';
            })()}
          </h1>
          <Form noValidate>
            {(() => {
              if (!loading) {
                return (
                  <Button
                    className="mt-3 btn-block"
                    onClick={() => {
                      if (window.opener) {
                        window.close();
                      } else {
                        history.push('/');
                      }
                    }}
                  >
                    Back
                  </Button>
                );
              }
            })()}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TwitterCallbackPage;
