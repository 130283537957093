import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SharedLink } from '../../services/models/shared-link';
import { SocialRoute } from '../../utils/Routes';

export interface StoryTweetsPanelProps {
  sharedLink: SharedLink | undefined;
}

export default function StoryTweetsPanel(props: StoryTweetsPanelProps) {
  const history = useHistory();

  if (!props.sharedLink) {
    return null;
  }

  const story = props.sharedLink.story;

  return (
    <div className="tw-h-full tw-relative tw-flex tw-flex-col">
      <div className="tw-flex-shrink-0">
        <nav className="tw-h-12 tw-border-b tw-border-gray-200 tw-bg-white tw-px-4 tw-flex tw-flex-col tw-justify-center">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-m-0 tw-text-base tw-font-extrabold tw-text-gray-900">
              What people are saying
            </h2>
            <div className="tw-ml-3 tw-flex tw-h-7 tw-items-center">
              <button
                type="button"
                className="tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2"
                onClick={() => history.replace(SocialRoute)}
              >
                <span className="tw-sr-only">Close panel</span>
                <XIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </nav>
        <div className="lg:tw-hidden tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-800">
          <div className="tw-flex tw-relative">
            <div>
              {/* TODO(Jose): Make use of our StoryCardLink component */}
              <a
                href={story.url}
                className="tw-block focus:tw-outline-none hover:tw-no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="tw-text-base tw-font-extrabold tw-line-clamp-2">{story.title}</h4>
                <p className="tw-mt-1 tw-text-xs tw-font-bold tw-text-gray-500 tw-uppercase">
                  {story.source}
                </p>
              </a>
            </div>

            {story.imageUrl && (
              <div className="tw-ml-4 tw-flex-shrink-0">
                <img
                  src={story.imageUrl}
                  alt={story.title}
                  width="80"
                  height="80"
                  className="tw-flex-none tw-rounded-lg tw-bg-slate-100 tw-aspect-square"
                  loading="lazy"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div aria-label="Tweet list" className="tw-min-h-0 tw-flex-1 tw-overflow-y-auto">
        <ul className="tw-border-b tw-border-gray-200 tw-divide-y tw-divide-gray-200">
          {props.sharedLink.tweets.map(tweet => (
            <li
              key={tweet.externalStatusId}
              className="tw-relative tw-bg-white tw-p-4 hover:tw-bg-gray-50 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-blue-600"
            >
              <div className="tw-flex tw-justify-between tw-space-x-3">
                <div className="tw-flex-shrink-0">
                  <img className="tw-h-10 tw-w-10 tw-rounded-full" src={tweet.imageUrl} alt="" />
                </div>
                <div className="tw-min-w-0 tw-flex-1">
                  <a
                    href={tweet.externalUrl}
                    className="tw-block focus:tw-outline-none hover:tw-no-underline"
                  >
                    <span className="tw-absolute tw-inset-0" aria-hidden="true" />
                    <p className="tw-text-sm tw-font-bold tw-text-gray-900 tw-no-underline tw-truncate ">
                      {tweet.name}
                    </p>
                    <p className="tw-text-sm tw-text-gray-500 tw-no-underline tw-truncate">
                      @{tweet.username}
                    </p>
                  </a>
                </div>
                <time
                  dateTime={tweet.dateCreated?.toString()}
                  className="tw-flex-shrink-0 tw-whitespace-nowrap tw-text-xs tw-text-gray-500"
                ></time>
              </div>
              <div className="tw-mt-2">
                <p className="tw-text-sm tw-text-gray-700">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tweet.bodyHtml.replace(
                        /href/g,
                        "target='_blank' class='tw-underline' href"
                      ),
                    }}
                  />
                </p>
              </div>
              <div className="tw-mt-2">
                <time
                  dateTime={tweet.dateCreated?.toString()}
                  className="tw-flex-shrink-0 tw-whitespace-nowrap tw-text-xs tw-text-gray-500"
                >
                  {tweet.time}
                </time>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
