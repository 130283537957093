import React, { FunctionComponent, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { requestCurrentAccount } from '../../redux/actions/account-actions';
import { requestBriefsOverview } from '../../redux/actions/briefs-actions';
import { getFeatureFlags } from '../../redux/actions/feature-flags-actions';
import { getProducts } from '../../redux/actions/products-actions';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import DashboardAppRoutes from '../Routes/DashboardAppRoutes';

const DashboardApp: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const isLoadingSession = useAppSelector(state => state.session?.isFetching);
  const account = useAppSelector(state => state.session?.account);
  const featureFlags = useAppSelector(state => state.featureFlags);
  const productStore = useAppSelector(state => state.productStore);
  const briefs = useAppSelector(state => state.briefs);

  useEffect(() => {
    if (!isLoadingSession && !account.metadata) {
      dispatch(requestCurrentAccount());
    }
  }, [isLoadingSession, account, dispatch]);

  useEffect(() => {
    if (featureFlags.isFetching === undefined) {
      dispatch(getFeatureFlags());
    }
  }, [featureFlags, dispatch]);

  useEffect(() => {
    if (productStore.isFetching === undefined) {
      dispatch(getProducts());
    }
  }, [productStore, dispatch]);

  useEffect(() => {
    if (briefs.isFetching === undefined) {
      dispatch(requestBriefsOverview());
    }
  }, [briefs, dispatch]);

  if (!account) {
    return <Redirect to={'/users/signin'} />;
  }

  if (!account.metadata) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <DashboardAppRoutes />
    </>
  );
};

export default DashboardApp;
