import { gql } from 'apollo-boost';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient } from './graphql-client';
import { SharedLink } from './models/shared-link';
import { SharedLinksConnection } from './models/shared-links-connection';

/**
 * Wait for the given milliseconds
 * @param {number} milliseconds The given time to wait
 * @returns {Promise} A fulfiled promise after the given time has passed
 */
function waitFor<T>(milliseconds: number): Promise<T> {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const getSharedLinksPage = async (
  after: number = 0,
  pageSize: number,
  sortOption: string,
  timeFilter?: string,
  shareFilters?: number
): Promise<{ hasMoreSharedLinks: boolean; sharedLinks: SharedLink[]; lastCursor: number }> => {
  return getSharedLinksWithRetryBackoff(after, pageSize, sortOption, timeFilter, shareFilters);
};

const getSharedLinksWithRetryBackoff = async (
  after: number = 0,
  pageSize: number,
  sortOption: string,
  timeFilter?: string,
  sharesFilter: number = 0,
  enqueued?: number,
  retries: number = 0
): Promise<{ hasMoreSharedLinks: boolean; sharedLinks: SharedLink[]; lastCursor: number }> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ sharedLinks: SharedLinksConnection }>({
    query: _getSharedLinksPageQuery(),
    variables: {
      first: pageSize,
      after: after.toString(),
      sort: sortOption,
      time: timeFilter,
      shares: sharesFilter,
    },
  });

  if (!response.data || !response.data.sharedLinks) {
    throw new EmptyResponseError();
  }

  const connections = response.data.sharedLinks;
  const pageMeta = connections?.pageMeta;

  if (pageMeta.enqueued > 0 && retries < 5) {
    // on every retry, we exponentially increase the time to wait.
    // Here is how it looks for a `maxRetries` = 5
    // (2 ** 1) * 500 = 1000 ms
    // (2 ** 2) * 500 = 2000 ms
    // (2 ** 3) * 500 = 3000 ms
    // (2 ** 4) * 500 = 4000 ms
    const timeToWait = 2 ** retries * 500;
    console.log(`[Newslit] waiting for ${timeToWait}ms...`);
    await waitFor(pageMeta.retryMilliseconds);

    return getSharedLinksWithRetryBackoff(
      after,
      pageSize,
      sortOption,
      timeFilter,
      sharesFilter,
      pageMeta.enqueued,
      retries + 1
    );
  }

  const edges = connections?.edges || [];
  const pageInfo = connections?.pageInfo;
  const lastCursor = parseInt(pageInfo?.endCursor) || 0;

  return {
    hasMoreSharedLinks: !!pageInfo?.hasNextPage,
    sharedLinks: edges.map(x => x.node),
    lastCursor,
  };
};

const _getSharedLinksPageQuery = () => {
  return gql`
    query getSharedLinks($first: Int, $after: String, $sort: String, $time: String, $shares: Int) {
      sharedLinks(first: $first, after: $after, sort: $sort, time: $time, shares: $shares) {
        pageMeta {
          enqueued
          retryMilliseconds
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            url
            shareCount
            story {
              id
              author
              excerpt
              imageHeight
              imageUrl
              imageWidth
              language
              publicationDate
              source
              title
              url
              isClicked
            }
            externalUsers {
              bannerImageUrl
              description
              externalUserid
              imageUrl
              name
              type
              url
              username
            }
            tweets {
              body
              bodyHtml
              dateCreated
              externalStatusId
              externalUrl
              imageUrl
              name
              username
              time
            }
          }
        }
      }
    }
  `;
};

export const getSharedLink = async (sharedLinkId: number): Promise<SharedLink> => {
  return getSharedLinkWithRetryBackoff(sharedLinkId);
};

const getSharedLinkWithRetryBackoff = async (
  sharedLinkId: number,
  retries: number = 0
): Promise<SharedLink> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ sharedLink: SharedLink }>({
    query: _getSharedLink(),
    variables: {
      sharedLinkId,
    },
  });

  if (!response.data) {
    throw new EmptyResponseError();
  }

  const sharedLink = response.data.sharedLink;

  if (!sharedLink.story && retries < 4) {
    // missing story, let's retry with exponential backoff
    // Here is how it looks for a `maxRetries` = 4
    // (2 ** 1) * 500 = 1000 ms
    // (2 ** 2) * 500 = 2000 ms
    // (2 ** 3) * 500 = 3000 ms
    const timeToWait = 2 ** retries * 500;

    console.log(
      `waiting to fetch story[${sharedLink.url}] retry ${retries} for ${timeToWait}ms...`
    );
    await waitFor(timeToWait);

    return getSharedLinkWithRetryBackoff(sharedLinkId, retries + 1);
  }

  // unable to load story after 4 retries, let's load a story unavailable
  if (!sharedLink.story) {
    sharedLink.story = _getUnavailableStory(sharedLink);
  }

  return sharedLink;
};

const _getSharedLink = () => {
  return gql`
    query getSharedLink($sharedLinkId: Long) {
      sharedLink(sharedLinkId: $sharedLinkId) {
        id
        url
        shareCount
        story {
          id
          author
          excerpt
          imageHeight
          imageUrl
          imageWidth
          language
          publicationDate
          source
          title
          url
          isClicked
        }
        externalUsers {
          bannerImageUrl
          description
          externalUserid
          imageUrl
          name
          type
          url
          username
        }
        tweets {
          body
          bodyHtml
          dateCreated
          externalStatusId
          imageUrl
          name
          username
        }
      }
    }
  `;
};

const _getUnavailableStory = (sharedLink: SharedLink) => {
  return {
    id: sharedLink.id,
    title: 'Story Not Available At This Time',
    url: sharedLink.url,
    author: '',
    excerpt: '',
    imageHeight: 0,
    imageWidth: 0,
    imageUrl: '',
    language: '',
    publicationDate: new Date(),
    source: '',
    score: 0,
    scoreHexColor: '',
    scoreLabel: '',
    tweets: [],
    isClicked: false,
  };
};
