import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Account } from '../../services/models/account';
import { SocialRoute } from '../../utils/Routes';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FeatureFlag } from '../../services/models/feature-flags';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

const DashboardRedirection: FunctionComponent = () => {
  const account: Account = useAppSelector(state => state.session.account);
  const briefs = useAppSelector(state => state.briefs);
  const isFetching = useAppSelector(
    state => !!state.featureFlags.isFetching || !!state.briefs.isFetching
  );

  const socialBriefFlag = useFeatureFlag(FeatureFlag.ShowSocialBrief);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (briefs && briefs.items && briefs.items.length > 0) {
    return <Redirect to={`/dashboard/brief/${briefs.items[0].id}`} />;
  }

  // TODO: Redirect to Social check
  if (socialBriefFlag && account.metadata?.isTwitterUser) {
    return <Redirect to={SocialRoute} />;
  }

  return <Redirect to="/dashboard/brief" />;
};

export default DashboardRedirection;
