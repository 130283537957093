import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import benefit1 from '../../assets/images/benefit-1.png';
import benefit2 from '../../assets/images/benefit-2.png';
import benefit3 from '../../assets/images/benefit-3.png';
import benefit4 from '../../assets/images/benefit-4.png';
import './BriefInProgress.scss';

interface BriefInProgressProps {
  canAddMoreBriefs: boolean;
}

const BriefInProgress: FunctionComponent<BriefInProgressProps> = ({ canAddMoreBriefs }) => {
  return (
    <div className="PendingReportScreen Screen bi-pending">
      <div className="bi-pending-header">
        <h1 className="bi-pending-header-title hidden-xs">
          Your News Brief will be ready soon{' '}
          <span role="img" aria-label="launch">
            🚀
          </span>
        </h1>
        <p className="bi-pending-header-description">
          An email notification will be sent once the report is live in your dashboard.
        </p>
        {(() => {
          if (canAddMoreBriefs) {
            return (
              <Link className="btn bi-pending-header-btn" to="/dashboard/regular-new-report">
                Create another report?
              </Link>
            );
          }
        })()}
      </div>
      <hr className="bi-pending-divider" />
      <div className="bi-pending-benefits">
        <div className="bi-pending-benefits-container">
          <h2 className="bi-pending-benefits-container-title">We want to help you:</h2>
          <div className="row mod-fullwidth BenefitCardContainer">
            <div className="bi-pending-benefits-container-benefit BenefitCard">
              <img
                alt="Save time"
                className="bi-pending-benefits-container-benefit-img"
                src={benefit1}
              />
              <h3 className="bi-pending-benefits-container-benefit-title">Save Time</h3>
              <p className="bi-pending-benefits-container-benefit-description">
                One daily email informs you of what stories are critical today
              </p>
            </div>
            <div className="bi-pending-benefits-container-benefit BenefitCard">
              <img
                alt="Be smarter"
                className="bi-pending-benefits-container-benefit-img"
                src={benefit2}
              />
              <h3 className="bi-pending-benefits-container-benefit-title">Be Smarter</h3>
              <p className="bi-pending-benefits-container-benefit-description">
                Interest Scores show you how important each story is today
              </p>
            </div>
            <div className="bi-pending-benefits-container-benefit BenefitCard">
              <img
                alt="Amplify your influence"
                className="bi-pending-benefits-container-benefit-img"
                src={benefit3}
              />
              <h3 className="bi-pending-benefits-container-benefit-title">
                Amplify Your Influence
              </h3>
              <p className="bi-pending-benefits-container-benefit-description">
                Share directly from your daily News Brief or Dashboard
              </p>
            </div>
            <div className="bi-pending-benefits-container-benefit BenefitCard">
              <img
                alt="Give your team an edge"
                className="bi-pending-benefits-container-benefit-img"
                src={benefit4}
              />
              <h3 className="bi-pending-benefits-container-benefit-title">
                Give your team an edge
              </h3>
              <p className="bi-pending-benefits-container-benefit-description">
                Add team members in sales or marketing, so they have a competitive info advantage
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefInProgress;
