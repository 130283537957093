import React, { useEffect } from 'react';
import * as plausible from '../../services/plausible';
import { createBodyFromMessage, createMailtoLink } from '../../utils/createMailtoLinks';

export default function ErrorPage() {
  const message = '';
  const isUrgent = false;

  const subject = `${isUrgent ? '[!] ' : ''}Newslit Support Request`;

  const mailtoLink = createMailtoLink(
    'help@newslit.co',
    subject,
    createBodyFromMessage(message, true)
  );

  useEffect(() => {
    plausible.trackEvent('500', {
      path: window?.location?.pathname,
    });
  }, []);

  return (
    <>
      <div className="tw-min-h-full tw-pt-16 tw-pb-12 tw-flex tw-flex-col tw-bg-white">
        <main className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-max-w-7xl tw-w-full tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
          {/* TODO: Add Newslit logo */}
          {/*<div className="tw-flex-shrink-0 tw-flex tw-justify-center">*/}
          {/*  <a href="/" className="tw-inline-flex">*/}
          {/*    <span className="tw-sr-only">Newslit News</span>*/}
          {/*    <img*/}
          {/*      className="tw-h-12 tw-w-auto"*/}
          {/*      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"*/}
          {/*      alt=""*/}
          {/*    />*/}
          {/*  </a>*/}
          {/*</div>*/}

          <div className="tw-py-16">
            <div className="tw-text-center">
              <h1 className="tw-mt-2 tw-text-4xl tw-font-extrabold tw-text-gray-900 tw-tracking-tight sm:tw-text-5xl">
                Uh oh! An error has occurred
              </h1>
              <p className="tw-mt-2 tw-text-base tw-text-gray-500">
                Sorry, we are having troubles loading this page.
              </p>
              <div className="tw-mt-6">
                <a
                  href={mailtoLink}
                  className="tw-text-base tw-font-medium tw-text-secondary hover:tw-text-secondary-dark"
                >
                  Contact us if the issue persists
                </a>
              </div>
            </div>
          </div>
        </main>
        <footer className="tw-flex-shrink-0 tw-max-w-7xl tw-w-full tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
          <nav className="tw-flex tw-justify-center tw-space-x-4">
            <a
              href={mailtoLink}
              className="tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-text-gray-600"
            >
              Contact Support
            </a>
            <span className="tw-inline-block tw-border-l tw-border-gray-300" aria-hidden="true" />
            <a
              href="https://twitter.com/newslit"
              className="tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-text-gray-600"
            >
              Twitter
            </a>
          </nav>
        </footer>
      </div>
    </>
  );
}
