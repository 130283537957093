import { combineReducers } from 'redux';
import accountActivation from './account-activation';
import briefUnsubscribeReducer from './brief-unsubscribe';
import briefsReducer from './briefs';
import dashboardConfiguration from './dashboard-configuration';
import emailUnsubscribeLink from './email-unsubscribe';
import errors from './errors';
import externalAuth from './external-auth';
import featureFlags from './feature-flags';
import productsReducer from './products';
import session from './session';
import sharedLinks from './shared-links';
import slackIntegration from './slack-integration';
import toastNotification from './toast-notifications';

const rootReducer = combineReducers({
  dashboardConfiguration,
  errors,
  session,
  briefs: briefsReducer,
  sharedLinks,
  externalAuth,
  featureFlags,
  slackIntegration,
  toastNotification,
  accountActivation,
  briefUnsubscribeLink: briefUnsubscribeReducer,
  emailUnsubscribeLink,
  productStore: productsReducer,
});

export default rootReducer;
