import { StoryShareData } from '../../../../../../../../services/models/story-share-data';
import { BriefStoriesAction } from '../../../../../../../actions/brief-stories-actions';

export default function briefStoryShareDataReducer(
  state: StoryShareData = null as any,
  action: any
): StoryShareData {
  switch (action.type) {
    case BriefStoriesAction.StartFetchingShareData:
      return { ...state, isFetching: true };
    case BriefStoriesAction.CompleteFetchingShareData:
      return { ...state, isFetching: false, ...action.shareData };
    case BriefStoriesAction.CancelFetchingShareData:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
