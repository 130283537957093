import { FeatureFlag } from '../services/models/feature-flags';
import { useAppSelector } from './useAppSelector';

export const useFeatureFlag = (featureFlag: FeatureFlag): boolean => {
  return useAppSelector(state => {
    let result =
      !!state.featureFlags && !!state.featureFlags.flags && state.featureFlags.flags[featureFlag];

    if (result === undefined) {
      result = false;
    }

    return result;
  });
};
