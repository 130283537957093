import { ExclamationCircleIcon } from '@heroicons/react/solid';
import React, { InputHTMLAttributes } from 'react';
import { classNames } from '../../utils/class-names';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const Input: React.FC<InputProps> = ({
  id,
  name,
  type,
  autoComplete,
  required,
  value,
  error,
  onChange,
  onBlur,
  placeholder,
}) => {
  return (
    <>
      <div className="tw-relative tw-rounded-md tw-shadow-sm">
        <input
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          required={required}
          className={classNames(
            !!error
              ? 'text-red-900 tw-border-red-300 tw-placeholder-red-300 tw-pr-10 focus:tw-border-red-500 focus:tw-ring-red-500 '
              : 'tw-border-gray-300 tw-placeholder-gray-400 focus:tw-border-indigo-500 focus:tw-ring-indigo-500',
            'tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded-md tw-shadow-sm focus:tw-outline-none sm:tw-text-sm'
          )}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error && (
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none">
            <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="tw-mt-2 tw-text-sm tw-text-red-600 tw-m-0" id="email-error">
          {error}
        </p>
      )}
    </>
  );
};

export default Input;
