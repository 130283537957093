// @ts-ignore
import { EventTypes } from 'redux-segment';
import { Account } from '../../services/models/account';
import { Subscription } from '../../services/models/subscription';
import * as plausible from '../../services/plausible';
import * as stripeService from '../../services/stripe';
import { asyncAction, Dispatch } from '../types';

export enum StripeAction {
  StartCheckoutFlow = 'StripeStartCheckoutFlow',
  CompleteCheckoutFlow = 'StripeCompleteCheckoutFlow',
  CancelCheckoutFlow = 'StripeCancelCheckoutFlow',
  StartUpdatePaymentSettings = 'StripeStartUpdatePaymentSettings',
  CompleteUpdatePaymentSettings = 'StripeCompleteUpdatePaymentSettings',
  CancelUpdatePaymentSettings = 'StripeCancelUpdatePaymentSettings',
  StartChangingSubscription = 'StripeActionStartChangingSubscription',
  CompleteChangingSubscription = 'StripeActionCompleteChangingSubscription',
  CancelChangingSubscription = 'StripeActionCancelChangingSubscription',
}

export const initCheckoutFlow = (
  userId: number,
  userEmail: string,
  planId: string,
  callbackUrl?: string
) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startCheckoutFlow(planId));
    plausible.trackEvent('Checkout Started', { plan: planId });
    await stripeService.redirectToPurchaseFlow(planId, callbackUrl);
    plausible.trackEvent('Checkout Completed', { plan: planId });
    dispatch(completeCheckoutFlow(planId));
  }, cancelCheckoutFlow);

export const initUpdatePaymentSettings = (account: Account, callbackUrl?: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startUpdatePaymentSettings());
    await stripeService.redirectToUpdatePaymentMethodFlow(callbackUrl);
    dispatch(completeUpdatePayment());
  }, cancelUpdatePayment);

export const startCheckoutFlow = (planId: string) => ({
  type: StripeAction.StartCheckoutFlow,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Checkout Started',
        properties: {
          plan: planId,
        },
      },
    },
  },
});

export const cancelCheckoutFlow = () => ({
  type: StripeAction.CancelCheckoutFlow,
});

export const completeCheckoutFlow = (planId: string) => ({
  type: StripeAction.CompleteCheckoutFlow,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Checkout Completed',
      },
      properties: {
        plan: planId,
      },
    },
  },
});

export const startUpdatePaymentSettings = () => ({
  type: StripeAction.StartUpdatePaymentSettings,
});

export const completeUpdatePayment = () => ({
  type: StripeAction.CompleteCheckoutFlow,
});

export const cancelUpdatePayment = () => ({
  type: StripeAction.CancelUpdatePaymentSettings,
});

export const changeSubscription = (planId: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startChangingSubscription());
    const subscription = await stripeService.changeSubscription(planId);
    dispatch(completeChangingSubscription(subscription));
  }, cancelChangingSubscription);

export const startChangingSubscription = () => ({
  type: StripeAction.StartChangingSubscription,
});

export const completeChangingSubscription = (subscription: Subscription) => ({
  type: StripeAction.CompleteChangingSubscription,
  subscription,
});

export const cancelChangingSubscription = () => ({
  type: StripeAction.CancelChangingSubscription,
});
