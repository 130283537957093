import React, { FunctionComponent, ReactNode } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { markStoryAsViewed } from '../../redux/actions/brief-stories-actions';
import { AppDispatch } from '../../redux/store';
import { StoreState } from '../../redux/store-state';
import { AppSetting, BooleanSettingValue } from '../../services/models/app-settings';
import { FeatureFlag } from '../../services/models/feature-flags';
import './StoryCardLink.scss';

const selectOpenInNewTab = (state: StoreState) => {
  const settings = state.session?.account?.settings || [];
  const newTabSetting = settings.find(x => x.name === AppSetting.NewTab);
  return !!newTabSetting ? newTabSetting.value === BooleanSettingValue.Yes : true;
};

interface StoryCardLinkProps {
  href: string;
  briefId: number;
  storyId: number;
  isClicked: boolean;
  children: ReactNode;
  titleClick?: (storyId: number, storyUri: string) => void;
}

const StoryCardLink: FunctionComponent<StoryCardLinkProps> = ({
  href,
  isClicked,
  briefId,
  storyId,
  children,
  titleClick,
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const openInNewTab = useAppSelector(selectOpenInNewTab);

  const reportStoryClickShowStoryReaderFlag = useFeatureFlag(
    FeatureFlag.ReportStoryClickShowStoryReader
  );

  const getClassNames = () => (isClicked ? 'story-link story-link-clicked' : 'story-link');

  function getLinkComponent(openInNewTab: boolean) {
    if (!reportStoryClickShowStoryReaderFlag || !titleClick) {
      if (!openInNewTab) {
        return (
          <a
            onClick={() => markStoryAsViewed(storyId, briefId)}
            className={getClassNames()}
            href={href}
          >
            {children}
          </a>
        );
      } else {
        return (
          <a
            href={href}
            onClick={() => dispatch(markStoryAsViewed(storyId, briefId))}
            className={getClassNames()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
      }
    }

    const ref = '#';

    return (
      <a
        href={ref}
        onClick={() => {
          if (titleClick) {
            titleClick(storyId, href);
          }

          dispatch(markStoryAsViewed(storyId, briefId));
        }}
        className={getClassNames()}
      >
        {children}
      </a>
    );
  }

  return getLinkComponent(openInNewTab);
};

export default StoryCardLink;
