import React, { FunctionComponent, SyntheticEvent, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { sanitizeKeyword } from '../../../../services/briefs';
import { BriefKeywordInput } from '../../../../services/models/brief-creation-request';
import { BriefKeywordType } from '../../../../services/models/brief-keyword-type';
import HelpDialog from '../../../help-dialog/HelpDialog';
import './CreateBriefKeyword.scss';

export interface CreateBriefKeywordProps {
  title?: string;
  keywords: BriefKeywordInput[];
  error?: string;
  maxItems?: number;
  placeholder?: string;
  update: (keywords: BriefKeywordInput[]) => void;
}

const CreateBriefKeyword: FunctionComponent<CreateBriefKeywordProps> = ({
  title,
  maxItems,
  placeholder,
  keywords,
  error,
  update,
}) => {
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [isExactMatch, setIsExactMatch] = useState(false);
  const textInput = useRef<HTMLInputElement | null>(null);
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const handleCloseHelpDialog = () => setShowHelpDialog(false);
  const handleShowHelpDialog = () => setShowHelpDialog(true);

  const addKeyword = (event: SyntheticEvent) => {
    event.preventDefault();

    if (isValid()) {
      const value = currentKeyword.split(',')[0].trim();

      if (!keywords.some(x => x.keyword === value)) {
        const newKeyword: BriefKeywordInput = {
          type: BriefKeywordType.Topic,
          exactMatch: value.startsWith('"'),
          keyword: sanitizeKeyword(value),
        };
        update([...keywords, newKeyword]);
      }

      setCurrentKeyword('');
      setIsExactMatch(false);
    }
  };

  const removeKeyword = (keyword: string) => {
    if (!!keyword) {
      update(keywords.filter(x => x.keyword !== keyword));
    }
  };

  const isValid = () => {
    return currentKeyword && currentKeyword.trim() && currentKeyword.trim().length > 0;
  };

  const handleCurrentKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsExactMatch(value.startsWith('"'));
    setCurrentKeyword(value);
  };

  const getTags = () => {
    return (keywords || []).map((x, i) => {
      let keywordClassName = 'keyword ';
      if (x.exactMatch) {
        keywordClassName += ' exact-match';
      }
      return (
        <span key={i} className={keywordClassName}>
          <span>{sanitizeKeyword(x.keyword)}</span>
          <span onClick={() => removeKeyword(x.keyword)} className="keyword-remove">
            x
          </span>
        </span>
      );
    });
  };

  const handleSeparator = (e: React.KeyboardEvent) => {
    const separators = [',', 'Enter', 'Tab'];

    if (separators.some(x => x === e.key)) {
      addKeyword(e);
    }

    if (e.key === 'Backspace') {
      if (keywords.length > 0 && currentKeyword.length === 0) {
        removeKeyword(keywords[keywords.length - 1].keyword);
      }
    }
  };

  const getPlaceholder = () => {
    if (placeholder) {
      return placeholder;
    }
    return 'Add keyword';
  };

  const className = isExactMatch ? 'exact-match' : '';

  return (
    <div className={`${className} CreateReportKeyword`}>
      <div className="notice-exact-match">Entering an Exact Match</div>
      <Form noValidate>
        <Form.Group>
          <Form.Label>{title}</Form.Label>
          <p className="help-block">
            Press <span className="keyboard-key">ENTER</span> after EACH word.
            <i
              className="fa fa-question-circle fa-fw text-muted"
              aria-hidden="true"
              style={{ cursor: 'pointer' }}
              onClick={handleShowHelpDialog}
            />
          </p>
          <div className="inline-tag-editor" onClick={() => textInput?.current?.focus()}>
            {getTags()}
            <input
              ref={textInput}
              type="text"
              maxLength={52}
              placeholder={getPlaceholder()}
              value={currentKeyword}
              onChange={(e: any) => handleCurrentKeywordChange(e)}
              onKeyDownCapture={(e: any) => handleSeparator(e)}
              required
            />
          </div>
          <div className="form-diagnostics">
            [{keywords.length}/{maxItems}] keywords / phrase
          </div>
          {(() => {
            if (!!error) {
              return <Form.Text className="text-muted">{error}</Form.Text>;
            }
          })()}
        </Form.Group>
      </Form>

      <HelpDialog show={showHelpDialog} onHide={handleCloseHelpDialog}>
        <p>
          Enter some keywords to start your News Brief. Keep your Brief focused by using related
          terms, ie: machine learning, AI.
        </p>
        <p>
          Press <code>ENTER</code> after each one.
        </p>
      </HelpDialog>
    </div>
  );
};

export default CreateBriefKeyword;
