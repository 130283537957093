import { EmailAction } from '../../actions/email-actions';
import { ToastNotificationsAction } from '../../actions/toast-notifications-actions';
import { AccountAction } from '../../actions/account-actions';

export default function toastNotificationReducer(state: string = '', action: any): string {
  switch (action.type) {
    case EmailAction.CompleteUpdating:
    case EmailAction.CompleteSendingVerification:
      return 'Please check your inbox for your verification email';
    case AccountAction.CompleteUpdatingName:
      return 'Your name has been updated';
    case ToastNotificationsAction.Discard:
      return '';
    default:
      return state;
  }
}
