import * as account from '../../services/account';
import { Setting } from '../../services/models/setting';
import { asyncAction, Dispatch } from '../types';

export enum SettingsAction {
  StartUpdating = 'SettingsActionStartUpdating',
  CompleteUpdating = 'SettingsActionCompleteUpdating',
  CancelUpdating = 'SettingsActionCancelUpdating',
  StartUpdatingTimeZone = 'SettingsActionStartUpdatingTimeZone',
  CompleteUpdatingTimeZone = 'SettingsActionCompleteUpdatingTimeZone',
  CancelUpdatingTimeZone = 'SettingsActionCancelUpdatingTimeZone',
}

export const updateSetting = (name: string, value: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startUpdatingSetting());
    const settings = await account.updateSetting(name, value);
    dispatch(completeUpdatingSetting(settings));
  }, cancelUpdatingSetting);

export const startUpdatingSetting = () => ({
  type: SettingsAction.StartUpdating,
});

export const completeUpdatingSetting = (settings: Setting[]) => ({
  type: SettingsAction.CompleteUpdating,
  settings,
});

export const cancelUpdatingSetting = () => ({
  type: SettingsAction.CancelUpdating,
});

export const updateTimezoneSetting = (timeZone: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startUpdatingTimeZoneSetting());
    const settings = await account.updateSetting('time_zone', timeZone);
    dispatch(completeUpdatingTimeZoneSetting(settings));
  }, cancelUpdatingTimeZoneSetting);

export const startUpdatingTimeZoneSetting = () => ({
  type: SettingsAction.StartUpdatingTimeZone,
});

export const completeUpdatingTimeZoneSetting = (settings: Setting[]) => ({
  type: SettingsAction.CompleteUpdatingTimeZone,
  settings,
});

export const cancelUpdatingTimeZoneSetting = () => ({
  type: SettingsAction.CancelUpdatingTimeZone,
});
