import React, { FunctionComponent } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import BriefPage from '../../pages/brief/BriefPage';
import DashboardPage from '../../pages/dashboardPage';
import HomePage from '../../pages/home/HomePage';
import NotFoundPage from '../../pages/not-found/NotFoundPage';
import QuestionnairePage from '../../pages/onboarding/QuestionnairePage';
import SocialPage from '../../pages/social/SocialPage';
import UpgradeRedirectionPage from '../../pages/upgrade/upgradeRedirectionPage';
import AddBriefPage from '../add-brief-page/AddBriefPage';
import SettingsApp from '../App/settingsApp';
import DashboardRedirection from '../dashboard-redirection/dashboardRedirection';
import ProtectedRoute from '../protected-route/ProtectedRoute';

const DashboardAppRoutes: FunctionComponent = () => {
  return (
    <Switch>
      <ProtectedRoute exact path={`/dashboard`} component={DashboardRedirection} />

      <ProtectedRoute path={`/dashboard/home`} component={HomePage} />

      <ProtectedRoute path={`/dashboard/social`} component={SocialPage} />

      {/* Brief Routes */}
      {/* TODO: Remove this once we check no place reference it. */}
      <Redirect from="/dashboard/report" to="/dashboard/brief" />
      <Redirect from="/dashboard/new-report" to="/dashboard/brief/new" />

      <ProtectedRoute exact path={`/dashboard/brief`} component={DashboardPage} />

      {/* Deprecated Route */}
      <ProtectedRoute path={`/dashboard/brief/new`}>
        <AddBriefPage />
      </ProtectedRoute>

      <Redirect from="/dashboard/report/:briefId/:tool?" to="/dashboard/brief/:briefId/:tool?" />
      <ProtectedRoute path={`/dashboard/brief/:briefId/:tool?`} component={BriefPage} />

      {/* Deprecated Routes */}
      <ProtectedRoute exact path={`/dashboard/onboarding`} component={QuestionnairePage} />
      <ProtectedRoute path={`/dashboard/onboarding/new-report`} component={AddBriefPage} />

      {/* Dashboard Settings Routes */}
      <ProtectedRoute path={'/dashboard/settings'} component={SettingsApp} />

      <Redirect from={'/dashboard/upsell/:reason'} to={'/dashboard/upgrade'} />
      <ProtectedRoute path={`/dashboard/upgrade`} component={UpgradeRedirectionPage} />

      {/*NotFoundPage always goes in the bottom*/}
      <ProtectedRoute path={`/dashboard/not-found`} component={NotFoundPage} />

      <ProtectedRoute component={NotFoundPage} />
    </Switch>
  );
};

export default DashboardAppRoutes;
