import gql from 'graphql-tag';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient, getGraphQLClient } from './graphql-client';

export const getTwitterLoginLink = async (hasSession: boolean): Promise<string> => {
  const client = hasSession ? getAuthenticatedGraphQLClient() : getGraphQLClient();

  const response = await client.query<{ accountTwitterLoginLink: { loginUrl: string } }>({
    query: _getTwitterLoginLinkQuery(),
  });

  if (!response.data || !response.data.accountTwitterLoginLink) {
    throw new EmptyResponseError();
  }

  return response.data.accountTwitterLoginLink.loginUrl;
};

const _getTwitterLoginLinkQuery = () => {
  return gql`
    {
      accountTwitterLoginLink {
        loginUrl
      }
    }
  `;
};
