import React, { FunctionComponent } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { SlackChannel } from '../../../../services/models/slack-channel';

interface Props {
  slackChannel: SlackChannel | null;
  onAccept: () => void;
  onCancel: () => void;
}

const DisconnectSlackChannelDialog: FunctionComponent<Props> = ({
  slackChannel = null,
  onAccept,
  onCancel,
}) => {
  const cancel = () => {
    onCancel();
  };

  const accept = () => {
    onAccept();
  };

  return (
    <Modal centered show={!!slackChannel} onHide={() => cancel()}>
      <Form noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Remove Slack Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to disconnect your <strong>{slackChannel?.name}</strong> Slack channel from
          this report?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" onClick={() => cancel()}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => accept()}>
            Remove
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DisconnectSlackChannelDialog;
