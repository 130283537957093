export enum ToastNotificationsAction {
  Notify = 'ToastNotificationsActionNotify',
  Discard = 'ToastNotificationsActionDiscard',
}

export const notifyToast = (message: string) => ({
  type: ToastNotificationsAction.Notify,
  message,
});

export const discardToast = () => ({
  type: ToastNotificationsAction.Discard,
});
