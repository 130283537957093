import gql from 'graphql-tag';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient } from './graphql-client';
import { Product } from './models/product';

export const getProducts = async (): Promise<Product[]> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ products: Product[] }>({
    query: _getProductsQuery(),
  });

  if (!response.data || !response.data.products) {
    throw new EmptyResponseError();
  }

  return response.data.products;
};

const _getProductsQuery = () => {
  return gql`
    query getProducts {
      products {
        productId
        name
        features {
          briefs
          recipientsPerBrief
          keywordsPerBrief
          slackEnabled
          zapierEnabled
        }
        plans {
          name
          planId
          productId
          monthlyPrice
          price
          periodicity
        }
        mostPopular
        published
      }
    }
  `;
};
