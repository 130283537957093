import { AccountMetadata } from '../../../../../services/models/account';
import { BriefsAction } from '../../../../actions/briefs-actions';

export default function metadataReducer(
  state: AccountMetadata = {} as any,
  action: any
): AccountMetadata {
  switch (action.type) {
    case BriefsAction.CompleteCreating:
      return {
        ...state,
        activeReportsCount: state.activeReportsCount + 1,
        totalReportsCount: state.totalReportsCount + 1,
      };

    case BriefsAction.CompleteDestroying:
      return {
        ...state,
        activeReportsCount: state.activeReportsCount - 1,
      };

    default:
      return state;
  }
}
