import React, { FunctionComponent, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signOut } from '../../../../redux/actions/session-actions';
import { StoreState } from '../../../../redux/store-state';
import { Session } from '../../../../services/models/session';

interface Props {
  session: Session;
  signOut: () => void;
}

const SignOutPage: FunctionComponent<Props> = ({ session, signOut }) => {
  const history = useHistory();

  useEffect(() => {
    if ((session && session.token) || session.account) {
      signOut();
    } else {
      history.push('/');
    }
  }, [session, history, signOut]);

  return (
    <Card>
      <Card.Body className="text-center">Signing out...</Card.Body>
    </Card>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    signOut: () => dispatch(signOut()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOutPage);
