import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const networkErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: any,
  discard: DiscardErrorCallback
) => {
  if (!error.networkError) {
    return null;
  }

  return (
    <div key={index}>
      <Modal centered show={true} onHide={() => discard(error)}>
        <Modal.Header closeButton>
          <Modal.Title>Connection error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          There was a connection error, please make sure your internet is working normally and try
          again or refresh the page.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => discard(error)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
