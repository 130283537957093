import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../hooks/useAppSelector';
import { AppType } from '../services/models/app-type';

/**
 * Verify that the specified AppType is an available integration for the current account.
 * It redirects to the default route if the app is not an availabe integration.
 */
const withAvailableIntegration = <P extends object>(
  appType: AppType,
  Component: React.ComponentType<P>
) => {
  const WithAvailableIntegration: FunctionComponent = props => {
    const { ...remainingProps } = props;

    const availableIntegrations = useAppSelector(
      state => state.session?.account?.availableIntegrations || []
    );

    if (availableIntegrations.some(x => x === appType)) {
      return <Component {...(remainingProps as P)} />;
    }

    return <Redirect to="/"></Redirect>;
  };

  return WithAvailableIntegration;
};

export default withAvailableIntegration;
