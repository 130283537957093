import { Setting } from './setting';

export enum AppSetting {
  NewTab = 'new_tab',
  ReceiveFeatureAnnouncement = 'receive_feature_announcement',
  ReceiveSocialAlerts = 'receive_social_alerts',
  ReceiveSocialDigest = 'receive_social_digest',
  SocialAlertsMaxEmailsPerDay = 'social_alerts_max_emails_per_day',
  SocialAlertsSharesThreshold = 'social_alerts_shares_threshold',
  SocialDigestHour = 'social_digest_hour',
  Timezone = 'time_zone',
}

export enum BooleanSettingValue {
  Yes = 'yes',
  No = 'no',
}

export const getBooleanSetting = (accountSettings: Setting[], setting: AppSetting) => {
  const item = accountSettings?.find(x => x.name === setting);
  return !!item ? item.value === BooleanSettingValue.Yes : false;
};

export const getNumberSetting = (
  accountSettings: Setting[],
  setting: AppSetting,
  defaultValue: number
): number => {
  const item = accountSettings?.find(x => x.name === setting);
  return !!item ? Number(item.value) : defaultValue;
};

export interface SocialDigestHourOption {
  label: string;
  value: string;
}

export const SOCIAL_DIGEST_HOURS_OPTIONS: SocialDigestHourOption[] = [
  { label: '12:00 am', value: '24' },
  { label: '01:00 am', value: '1' },
  { label: '02:00 am', value: '2' },
  { label: '03:00 am', value: '3' },
  { label: '04:00 am', value: '4' },
  { label: '05:00 am', value: '5' },
  { label: '06:00 am', value: '6' },
  { label: '07:00 am', value: '7' },
  { label: '08:00 am', value: '8' },
  { label: '09:00 am', value: '9' },
  { label: '10:00 am', value: '10' },
  { label: '11:00 am', value: '11' },
  { label: '12:00 pm', value: '12' },
  { label: '01:00 pm', value: '13' },
  { label: '02:00 pm', value: '14' },
  { label: '02:00 pm', value: '14' },
  { label: '03:00 pm', value: '15' },
  { label: '04:00 pm', value: '16' },
  { label: '05:00 pm', value: '17' },
  { label: '06:00 pm', value: '18' },
  { label: '07:00 pm', value: '19' },
  { label: '08:00 pm', value: '20' },
  { label: '09:00 pm', value: '21' },
  { label: '10:00 pm', value: '22' },
  { label: '11:00 pm', value: '23' },
];
