import React, { FunctionComponent } from 'react';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { FeatureFlag } from '../../../../services/models/feature-flags';
import { VectorIcon } from '../../../VectorIcon';
import './NewBriefWizardStepper.scss';

export enum AddBriefWizardStep {
  DefineScreen,
  RefineScreen,
  NameScreen,
}

interface Props {
  step: AddBriefWizardStep;
  isWorking: boolean;
}

const NewBriefWizardStepper: FunctionComponent<Props> = ({ step, isWorking }) => {
  const newReportShowPreviewScreenFlag = useFeatureFlag(FeatureFlag.NewReportShowPreviewScreen);

  const renderTitleBar = () => {
    return (
      <div className="wizard-title-container">
        <h1 className="bold-h1 tw-my-4">
          {step === AddBriefWizardStep.DefineScreen && (
            <span>Tell us what you want to monitor</span>
          )}
          {step === AddBriefWizardStep.RefineScreen && (
            <span>
              Monitor like a Pro! <VectorIcon file="rocket" />
            </span>
          )}
          {step === AddBriefWizardStep.NameScreen && (
            <span>
              That was easy, wasn’t it? <VectorIcon file="happy" />
            </span>
          )}
        </h1>
      </div>
    );
  };

  const renderProgressBar = () => {
    let headers = ['Topic', 'Refine', 'Done'];

    if (newReportShowPreviewScreenFlag) {
      headers = ['Topic', 'Preview', 'Done'];
    }

    return (
      <ul className="progress-bar-container">
        {headers.map((item: string, i: number) => {
          const cssName = i <= step ? 'completed' : 'pending';
          const cssWorking = i === step && isWorking ? ' working' : '';
          const cssCurrent = i === step ? 'current' : '';

          return (
            <li key={i}>
              <div className={'bar ' + cssName + cssWorking} />
              <span className={cssCurrent}>
                {i + 1}. {item}
              </span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="wizard-layout tw-py-4">
      {renderTitleBar()}
      {renderProgressBar()}
    </div>
  );
};

export default NewBriefWizardStepper;
