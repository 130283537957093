import * as FullStory from '@fullstory/browser';
import * as honeybadger from './honeybadger';
import { shutdownIntercom } from './intercom';
import { Viewer } from './models/viewer';
import * as segment from './segment';

/**
 * This event will be called by the services layer
 * whenever a user identifies himself.
 */
export const onUserIdentified = (viewer: Viewer) => {
  honeybadger.identifyUser(viewer.account.userid);

  segment.identifyUser(
    viewer.account,
    viewer.activeReportsCount,
    viewer.totalReportsCount,
    viewer.isTwitterUser
  );

  // Let's shutdown FullStory for paying customers
  if (viewer.account.subscription?.active && !viewer.account.subscription?.trial) {
    try {
      console.log(`[Newslit] Shutting down FS service`);
      FullStory.shutdown();
    } catch (error: any) {
      honeybadger.notify(error, 'onUserIdentified');
    }
  }
};

/**
 * This event will be called by the services layer
 * whenever a user signs out.
 */
export const onUserSignOut = () => {
  try {
    FullStory.shutdown();
  } catch (error: any) {
    honeybadger.notify(error, 'onUserSignOut');
  }

  shutdownIntercom();
  segment.reset();
  honeybadger.resetContext();
};
