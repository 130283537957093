import { SharedLink } from '../../services/models/shared-link';
import * as sharedLinksManager from '../../services/sharedlinks';
import { asyncAction, Dispatch } from '../types';

export enum SharedLinksAction {
  StartFetching = 'SharedLinksActionStartFetching',
  CompleteFetchingPage = 'SharedLinksActionCompleteFetchingPage',
  CancelFetching = 'SharedLinksActionCancelFetching',
}

export const requestSharedLinksPage = (
  after: number,
  pageSize: number = 20,
  sortOption: string,
  timeFilter?: string,
  sharesFilter?: number
) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingSharedLinks(after === 0));

    const page = await sharedLinksManager.getSharedLinksPage(
      after,
      pageSize,
      sortOption,
      timeFilter,
      sharesFilter
    );

    dispatch(
      completeFetchingSharedLinksPage(
        page.hasMoreSharedLinks,
        after === 0,
        page.lastCursor,
        page.sharedLinks
      )
    );
  }, cancelFetchingSharedLinks);

export const startFetchingSharedLinks = (isFirstPage: boolean) => ({
  type: SharedLinksAction.StartFetching,
  isFirstPage,
});

export const cancelFetchingSharedLinks = () => ({
  type: SharedLinksAction.CancelFetching,
});

export const completeFetchingSharedLinksPage = (
  hasMoreSharedLinks: boolean,
  isFirstPage: boolean,
  lastCursor: number,
  sharedLinks: SharedLink[]
) => ({
  type: SharedLinksAction.CompleteFetchingPage,
  hasMoreSharedLinks,
  isFirstPage,
  lastCursor,
  sharedLinks: sharedLinks,
});
