import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import newslitLogo from '../../../assets/images/newslit-logo.svg';
import rocketIllustration from '../../../assets/images/rocket-illustration.png';
import ErrorReporter from '../../../components/error-reporter/ErrorReporter';
import LoadingSpinner from '../../../components/loading-spinner/LoadingSpinner';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import DefaultPageLayout from '../../../layouts/defaultPageLayout';
import {
  getUnsubscribeLink,
  unsubscribeFromBrief,
} from '../../../redux/actions/brief-unsubscribe-actions';

const BriefUnsubscribePage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const briefUnsubscribeLink = useAppSelector(state => state.briefUnsubscribeLink);

  const [code, setCode] = useState<string | null>(null);
  const [memberId, setMemberId] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const verificationCode = searchParams.get('c');
    const member = searchParams.get('m');

    if (!verificationCode || !member) {
      history.push('/');
    } else {
      setCode(verificationCode);
      setMemberId(member);
    }
  }, [location.search, history, setCode, setMemberId]);

  useEffect(() => {
    if (code && memberId) {
      dispatch(getUnsubscribeLink(memberId, code));
    }
  }, [code, memberId, dispatch]);

  const loadingStage = () => !briefUnsubscribeLink || briefUnsubscribeLink.isFetching;

  const loadedStage = () =>
    !loadingStage() &&
    briefUnsubscribeLink.briefName &&
    !briefUnsubscribeLink.isUnsubscribe &&
    !briefUnsubscribeLink.isCompleted;

  const errorStage = () =>
    !loadingStage() && briefUnsubscribeLink.isFetching === false && !briefUnsubscribeLink.briefName;

  const completedStage = () =>
    !loadingStage() && briefUnsubscribeLink && briefUnsubscribeLink.isCompleted;

  const alreadyUnsubscribedStage = () => !loadingStage() && briefUnsubscribeLink.isUnsubscribe;

  const currentTemplateStage = () => {
    if (loadingStage()) {
      return <LoadingSpinner />;
    }

    if (loadedStage()) {
      return (
        <>
          <h2 className="tw-mt-6 tw-text-3xl tw-font-extrabold tw-text-gray-900">Please Confirm</h2>
          <p className="tw-mt-2 tw-text-sm tw-text-gray-600">
            Unsubscribing from "{briefUnsubscribeLink?.briefName}" Brief. The News Brief will stay
            active but you won't receive any more emails about it.
          </p>
          <button
            type="button"
            className="tw-order-0 tw-w-full tw-flex tw-justify-center tw-mt-5 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark hover:tw-text-white hover:tw-no-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
            disabled={!briefUnsubscribeLink.briefName || briefUnsubscribeLink.isUnsubscribing}
            onClick={() => dispatch(unsubscribeFromBrief(memberId as string, code as string))}
          >
            {briefUnsubscribeLink.isUnsubscribing ? 'Unsubscribing...' : 'Unsubscribe'}
          </button>
        </>
      );
    }

    if (errorStage()) {
      return (
        <>
          <h2 className="tw-mt-6 tw-text-3xl tw-font-extrabold tw-text-gray-900">
            Oops! Something went wrong.
          </h2>
          <button
            type="button"
            className="tw-order-0 tw-w-full tw-flex tw-justify-center tw-mt-5 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark hover:tw-text-white hover:tw-no-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
            onClick={() => history.push('/')}
          >
            Return to Newslit
          </button>
        </>
      );
    }

    if (completedStage()) {
      return (
        <>
          <h2 className="tw-mt-6 tw-text-3xl tw-font-extrabold tw-text-gray-900">
            Unsubscribe Successful
          </h2>
          <p className="tw-mt-2 tw-text-sm tw-text-gray-600">
            You will no longer receive email from this Brief.
          </p>
          <button
            type="button"
            className="tw-order0 tw-w-full tw-flex tw-justify-center tw-mt-5 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark hover:tw-text-white hover:tw-no-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
            onClick={() => history.push('/')}
          >
            Return to Newslit
          </button>
        </>
      );
    }

    if (alreadyUnsubscribedStage()) {
      return (
        <>
          <h2 className="tw-mt-6 tw-text-3xl tw-font-extrabold tw-text-gray-900">
            Already Unsubscribe
          </h2>
          <p className="tw-mt-2 tw-text-sm tw-text-gray-600">
            You're already unsubscribed from this Brief.
          </p>
          <button
            type="button"
            className="tw-order-0 tw-w-full tw-flex tw-justify-center tw-mt-5 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark hover:tw-text-white hover:tw-no-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
            onClick={() => history.push('/')}
          >
            Return to Newslit
          </button>
        </>
      );
    }
  };

  return (
    <DefaultPageLayout title="Unsubscribe from Brief">
      <div className="tw-min-h-screen tw-bg-white tw-flex">
        <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-py-12 tw-px-4 sm:tw-px-6 lg:tw-flex-none lg:tw-px-20 xl:tw-px-24">
          <div className="tw-mx-auto tw-w-full tw-max-w-sm lg:tw-w-96">
            <img className="tw-h-12 tw-w-auto" src={newslitLogo} alt="Newslit" />
            {currentTemplateStage()}
          </div>
        </div>
        <div className="tw-hidden lg:tw-block tw-relative tw-w-0 tw-flex-1">
          <img
            className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
            src={rocketIllustration}
            alt="Newslit Illustration"
          />
        </div>
      </div>
      <ErrorReporter />
    </DefaultPageLayout>
  );
};

export default BriefUnsubscribePage;
