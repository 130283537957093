import { Menu, Transition } from '@headlessui/react';
import {
  DotsVerticalIcon,
  FlagIcon,
  MailIcon,
  MenuAlt2Icon,
  ShareIcon,
} from '@heroicons/react/solid';
import React, { Fragment, FunctionComponent } from 'react';
import { configuration } from '../../../../services/configuration';
import { SharedLink } from '../../../../services/models/shared-link';
import * as plausible from '../../../../services/plausible';
import * as segment from '../../../../services/segment';
import { classNames } from '../../../../utils/class-names';
import { StoryCard } from '../story-card/StoryCard';
import './SharedLinkCard.scss';

interface SharedLinkCardProps {
  sharedLink: SharedLink;
  isFirst?: boolean;
  onShareBarClick?: (storyId: number, storyUri: string) => void;
  onReadStory?: (storyId: number, storyUri: string) => void;
}

enum ShareType {
  Email,
  Twitter,
  LinkedIn,
}

export const SharedLinkCard: FunctionComponent<SharedLinkCardProps> = ({
  sharedLink,
  isFirst,
  onShareBarClick,
  onReadStory,
}) => {
  const getShareUrl = (shareType: ShareType) => {
    const story = sharedLink.story;

    const mailBody =
      'Check out this story:\r\n\r\n' +
      story.url +
      '\r\n\r\n' +
      'Sent via Newslit - Next-generation news monitoring and research tool. https://newslit.co';

    return shareType === ShareType.Email
      ? `mailto:?subject=${encodeURI(story.title)}&body=${encodeURI(mailBody)}`
      : `${configuration.backend.baseUrl}/share?u=${encodeURI(story.url)}&t=${shareType}`;
  };

  return (
    <div className="sharedlink-card">
      <StoryCard key={sharedLink.id} story={sharedLink.story} hideSeparator={isFirst} />

      <div className="sharedlink-footer">
        <div
          className="sharedlink-sharedby tw-cursor-pointer tw-whitespace-nowrap tw-overflow-hidden"
          onClick={() => {
            if (onShareBarClick) {
              plausible.trackEvent('Story ShareBar Clicked', {
                shares: `${sharedLink.shareCount}`,
              });
              segment.trackEvent('Story ShareBar Clicked', {
                shares: `${sharedLink.shareCount}`,
              });

              onShareBarClick(sharedLink.story.id, sharedLink.story.url);
            }
          }}
        >
          <span className="share-count">{sharedLink.shareCount}</span>

          {sharedLink.externalUsers.map((externalUser, index) => {
            const imgKey = `${sharedLink.id}-${externalUser.username}-${index}`;
            const isLast = index === sharedLink.externalUsers.length - 1;

            return (
              <img
                key={imgKey}
                src={externalUser.imageUrl}
                alt={externalUser.username}
                className={classNames(
                  !isLast ? 'mr-n1' : '',
                  'tw-inline tw-w-7 tw-h-7 tw-rounded-full tw-ring-2 tw-ring-white'
                )}
                loading="lazy"
              />
            );
          })}
        </div>

        <div className="sharedlink-actions">
          <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
            <div>
              <Menu.Button className="tw--m-2 tw-p-1.5 tw-border tw-border-gray-200 tw-rounded-full tw-flex tw-items-center tw-text-gray-400 hover:tw-text-gray-600 hover:tw-bg-gray-50">
                <span className="tw-sr-only">Open options</span>
                <DotsVerticalIcon className="tw-h-4 tw-w-4" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="tw-transition tw-ease-out tw-duration-100"
              enterFrom="tw-opacity-0 tw-scale-95"
              enterTo="tw-opacity-100 tw-scale-100"
              leave="tw-transition tw-ease-in tw-duration-75"
              leaveFrom="tw-opacity-100 tw-scale-100"
              leaveTo="tw-opacity-0 tw-scale-95"
            >
              <Menu.Items className="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                <div className="tw-py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          plausible.trackEvent('SharedLink Menu Clicked', {
                            option: 'Share via Twitter',
                          });
                          window.open(getShareUrl(ShareType.Twitter), '_blank');
                        }}
                        className={classNames(
                          active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                          'tw-group tw-flex tw-items-center tw-w-full tw-px-4 tw-py-2 tw-text-sm'
                        )}
                      >
                        <ShareIcon
                          className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400"
                          aria-hidden="true"
                        />
                        <span>Share via Twitter</span>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          plausible.trackEvent('SharedLink Menu Clicked', {
                            option: 'Share via Email',
                          });
                          window.open(getShareUrl(ShareType.Email), '_blank');
                        }}
                        className={classNames(
                          active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                          'tw-group tw-flex tw-items-center tw-w-full tw-px-4 tw-py-2 tw-text-sm'
                        )}
                      >
                        <MailIcon
                          className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400"
                          aria-hidden="true"
                        />
                        <span>Share via Email</span>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          plausible.trackEvent('SharedLink Menu Clicked', {
                            option: 'Read Article',
                          });

                          if (onReadStory) {
                            plausible.trackEvent('Story Reader Clicked', {
                              story: sharedLink.story.url,
                            });
                            segment.trackEvent('Story Reader Clicked', {
                              story: sharedLink.story.url,
                            });

                            onReadStory(sharedLink.story.id, sharedLink.story.url);
                          }
                        }}
                        className={classNames(
                          active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                          'tw-group tw-flex tw-items-center tw-w-full tw-px-4 tw-py-2 tw-text-sm'
                        )}
                      >
                        <MenuAlt2Icon
                          className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400"
                          aria-hidden="true"
                        />
                        <span>Read Article</span>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          plausible.trackEvent('SharedLink Menu Clicked', {
                            option: 'Report content',
                          });

                          const my = window as any;
                          if (my.hasOwnProperty('Intercom')) {
                            my['Intercom']('show');
                          }
                        }}
                        className={classNames(
                          active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                          'tw-group tw-flex tw-items-center tw-w-full tw-px-4 tw-py-2 tw-text-sm'
                        )}
                      >
                        <FlagIcon
                          className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400"
                          aria-hidden="true"
                        />
                        <span>Report content</span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};
