import { Intent } from '@blueprintjs/core';
import { AppToaster } from '../../../utils/toaster';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const genericGraphQLErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: any,
  discard: DiscardErrorCallback
) => {
  const message: string = error?.message || '';
  const matches = message.match(/GraphQL error:(?<internalError>.*):\s(?<error>.*)/);

  if (matches && matches.groups && matches.groups['error']) {
    // Ignore this generic errors
    if (!message.includes('Account has an inactive subscription')) {
      AppToaster.show({
        intent: Intent.DANGER,
        icon: 'warning-sign',
        message: matches.groups['error'],
      });
    }

    discard(error);

    return false;
  }

  return null;
};
