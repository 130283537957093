import { Switch } from '@headlessui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UpdateEmailDialog from '../../../components/update-email-dialog/updateEmailDialog';
import UpdateNameDialog from '../../../components/update-name-dialog/updateNameDialog';
import UpdatePasswordDialog from '../../../components/update-password-dialog/updatePasswordDialog';
import UpdateTimezoneDialog from '../../../components/update-timezone-dialog/updateTimezoneDialog';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import SettingsPageLayout from '../../../layouts/settings/settingsPageLayout';
import { updateName } from '../../../redux/actions/account-actions';
import { updateEmail } from '../../../redux/actions/email-actions';
import { updateSetting, updateTimezoneSetting } from '../../../redux/actions/settings-actions';
import {
  AppSetting,
  BooleanSettingValue,
  getBooleanSetting,
} from '../../../services/models/app-settings';
import { classNames } from '../../../utils/class-names';

const OverviewPage: FunctionComponent = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.session.account);

  // Preferences
  const timezone =
    account.settings?.length > 0 ? account.settings.find(x => x.name === 'time_zone')?.value : '';
  const [newTab, setNewTab] = useState(false);
  const [featureAnnouncements, setFeatureAnnouncements] = useState(false);

  // Dialogs
  const [showNameDialog, setShowNameDialog] = useState(false);
  const [showUpdateEmailDialog, setShowUpdateEmailDialog] = useState(false);
  const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = useState(false);
  const [showTimezoneDialog, setShowTimezoneDialog] = useState(false);

  useEffect(() => {
    setNewTab(getBooleanSetting(account.settings, AppSetting.NewTab));
    setFeatureAnnouncements(
      getBooleanSetting(account.settings, AppSetting.ReceiveFeatureAnnouncement)
    );
  }, [account.settings]);

  const getTimezoneSetting = () => {
    const setting = account.settings?.find(x => x.name === AppSetting.Timezone);
    return !!setting ? setting.value : '';
  };

  const handleNewTabChange = () => {
    setNewTab(v => !v);
    dispatch(
      updateSetting(AppSetting.NewTab, !newTab ? BooleanSettingValue.Yes : BooleanSettingValue.No)
    );
  };

  const handleFeatureAnnouncementsChange = () => {
    setFeatureAnnouncements(v => !v);
    dispatch(
      updateSetting(
        AppSetting.ReceiveFeatureAnnouncement,
        !featureAnnouncements ? BooleanSettingValue.Yes : BooleanSettingValue.No
      )
    );
  };

  return (
    <SettingsPageLayout title="Settings Overview | Newslit">
      {/* Profile Settings with inline editing */}
      <div className="tw-mt-10 tw-divide-y tw-divide-gray-200">
        <div className="tw-space-y-1">
          <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">Profile</h3>
          <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500 tw-m-0">
            Put a face to your name! Make changes to login details, email, password & preferences.
          </p>
        </div>

        <div className="tw-mt-6">
          <dl className="tw-divide-y tw-divide-gray-200">
            <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
              <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Name</dt>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <span className="tw-flex-grow">
                  {account?.firstName} {account?.lastName}
                </span>
                <span className="tw-ml-4 tw-flex-shrink-0">
                  <button
                    type="button"
                    className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                    onClick={() => {
                      setShowNameDialog(true);
                    }}
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
            <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-pt-5">
              <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Avatar</dt>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <span className="tw-flex-grow">
                  <img className="tw-h-8 tw-w-8 tw-rounded-full" src={account?.imageUrl} alt="" />
                </span>
                <span className="tw-ml-4 tw-flex-shrink-0 tw-flex tw-items-start tw-space-x-4">
                  <button
                    type="button"
                    className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                    onClick={() => {
                      window.open('https://en.gravatar.com/', '_blank');
                    }}
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
            <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-pt-5">
              <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Email</dt>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <span className="tw-flex-grow">
                  {account?.email?.emailAddress}{' '}
                  {!account?.email?.verified && (
                    <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-yellow-100 tw-text-yellow-800 tw-capitalize">
                      Needs Verification
                    </span>
                  )}
                </span>
                <span className="tw-ml-4 tw-flex-shrink-0">
                  <button
                    type="button"
                    className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                    onClick={() => {
                      setShowUpdateEmailDialog(true);
                    }}
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
            <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-pt-5">
              <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Password</dt>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <span className="tw-flex-grow">• • • • • • • •</span>
                <span className="tw-ml-4 tw-flex-shrink-0">
                  <button
                    type="button"
                    className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                    onClick={() => {
                      setShowUpdatePasswordDialog(true);
                    }}
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Preferences Settings*/}
      <div className="tw-mt-10 tw-divide-y tw-divide-gray-200">
        <div className="tw-space-y-1">
          <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">Preferences</h3>
          <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500">
            Manage your account preferences.
          </p>
        </div>
        <div className="tw-mt-6">
          <dl className="tw-divide-y tw-divide-gray-200">
            <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
              <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Timezone</dt>

              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <span className="tw-flex-grow">{timezone}</span>
                <span className="tw-ml-4 tw-flex-shrink-0">
                  <button
                    type="button"
                    className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                    onClick={() => {
                      setShowTimezoneDialog(true);
                    }}
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
            <Switch.Group
              as="div"
              className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-pt-5"
            >
              <Switch.Label as="dt" className="tw-text-sm tw-font-medium tw-text-gray-500" passive>
                Open external links in new browser tab?
              </Switch.Label>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <Switch
                  checked={newTab}
                  onChange={handleNewTabChange}
                  className={classNames(
                    newTab ? 'tw-bg-secondary-dark' : 'tw-bg-gray-200',
                    'tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-ml-auto'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      newTab ? 'tw-translate-x-5' : 'tw-translate-x-0',
                      'tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-ease-in-out tw-duration-200'
                    )}
                  />
                </Switch>
              </dd>
            </Switch.Group>
            <Switch.Group
              as="div"
              className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-pt-5"
            >
              <Switch.Label as="dt" className="tw-text-sm tw-font-medium tw-text-gray-500" passive>
                Receive feature announcements
              </Switch.Label>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <Switch
                  checked={featureAnnouncements}
                  onChange={handleFeatureAnnouncementsChange}
                  className={classNames(
                    featureAnnouncements ? 'tw-bg-secondary-dark' : 'tw-bg-gray-200',
                    'tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-ml-auto'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      featureAnnouncements ? 'tw-translate-x-5' : 'tw-translate-x-0',
                      'tw-inline-block tw-h-5 tw-w-5 tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-ease-in-out tw-duration-200'
                    )}
                  />
                </Switch>
              </dd>
            </Switch.Group>
          </dl>
        </div>
      </div>

      {/* Deactivate Account*/}
      <div className="tw-mt-10">
        <div className="tw-space-y-1">
          <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
            Deactivate your account
          </h3>
          <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500">
            If you do this, all your Briefs and account data will get removed from our system.
          </p>
        </div>
        <div className="tw-mt-6">
          <button
            type="button"
            className="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-gray-100 hover:tw-bg-grey-200 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-grey-500 sm:tw-text-sm"
            onClick={() => {
              history.push('/cancel');
            }}
          >
            Deactivate account
          </button>
        </div>
      </div>

      {showNameDialog && (
        <UpdateNameDialog
          isOpen={showNameDialog}
          firstName={account.firstName}
          lastName={account.lastName}
          onClose={() => {
            setShowNameDialog(false);
          }}
          onSave={(firstName, lastName) => dispatch(updateName(firstName, lastName))}
        />
      )}

      {showUpdateEmailDialog && (
        <UpdateEmailDialog
          isOpen={showUpdateEmailDialog}
          email={account?.email?.emailAddress || ''}
          onClose={() => {
            setShowUpdateEmailDialog(false);
          }}
          onSave={email => dispatch(updateEmail(email))}
        />
      )}

      {showUpdatePasswordDialog && (
        <UpdatePasswordDialog
          isOpen={showUpdatePasswordDialog}
          onClose={() => {
            setShowUpdatePasswordDialog(false);
          }}
          account={account}
        />
      )}

      {showTimezoneDialog && (
        <UpdateTimezoneDialog
          isOpen={showTimezoneDialog}
          onClose={() => {
            setShowTimezoneDialog(false);
          }}
          timezone={getTimezoneSetting()}
          onSave={timezone => dispatch(updateTimezoneSetting(timezone))}
        />
      )}
    </SettingsPageLayout>
  );
};

export default OverviewPage;
