import { isInHootSuite } from './hootsuite';

interface IPopupFeatures {
  width: number;
  height: number;
}

export function launchPopupWindow(
  link: string,
  features: string,
  args: IPopupFeatures,
  callback?: () => void
) {
  features += `, width=${args.width}, height=${args.height}`;

  let windowTop = window.top;

  if (!isInHootSuite() && windowTop !== null) {
    const y = windowTop.outerHeight / 2 + windowTop.screenY - args.height / 2;
    const x = windowTop.outerWidth / 2 + windowTop.screenX - args.width / 2;
    features += `, top=${y}, left=${x}`;
  }

  const wnd = window.open(link, '_blank', features);

  (window as any).popupCompleted = () => {
    wnd?.close();

    if (callback) {
      callback();
    } else {
      window.location.reload();
    }
  };

  return wnd;
}
