import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { completeUpdatingEmail } from '../../redux/actions/email-actions';
import { updateEmail } from '../../services/account';
import * as honeybadger from '../../services/honeybadger';
import { createBodyFromMessage, createMailtoLink } from '../../utils/createMailtoLinks';
import { genericGraphQLErrorIdentifier } from '../error-reporter/error-identifiers/generic-graphql-error-identifier';
import { Input } from '../ui';

const EmailEnforcer: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.session?.account);
  const email = useAppSelector(state => state.session?.account?.email);
  const loadingSession = useAppSelector(state => state.session?.isFetching !== false);
  const isAccountLoaded = !!account && !loadingSession;

  const [showDialog, setShowDialog] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressTouched, setEmailAddressTouched] = useState(false);
  const [validEmailAddress, setValidEmailAddress] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const subject = `Newslit Support Request`;
  const mailtoLink = createMailtoLink('help@newslit.co', subject, createBodyFromMessage('', true));

  useEffect(() => {
    setValidEmailAddress(isValid(emailAddress));
  }, [emailAddress]);

  useEffect(() => {
    const value = email?.emailAddress || '';
    setEmailAddress(value);
  }, [email]);

  useEffect(() => {
    if (isAccountLoaded && !email?.emailAddress) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, [isAccountLoaded, email, setShowDialog]);

  const save = (event: SyntheticEvent) => {
    event.preventDefault();

    if (isValid(emailAddress)) {
      addEmail(emailAddress);
    }
  };

  const addEmail = async (emailAddress: string) => {
    try {
      const addedEmail = await updateEmail(emailAddress);
      dispatch(completeUpdatingEmail(addedEmail));
    } catch (error: any) {
      console.error(error);
      honeybadger.notify(error, 'addEmail');
      setError(error);
    }
  };

  const getMessageBox = () => {
    if (!error) {
      return;
    }

    return genericGraphQLErrorIdentifier(1, error, error => setError(null));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmailAddress(value);
    setEmailAddressTouched(true);
  };

  const isValid = (value: string) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value);
  };

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto"
        open={showDialog}
        onClose={() => {}}
      >
        <div className="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity tw-backdrop-blur-sm" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          >
            <div className="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full sm:tw-p-6">
              <div className="sm:flex sm:items-start">
                <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                  Provide your email
                </h3>
                <div className="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
                  <p>Change the email address you want associated with your account.</p>
                </div>

                {getMessageBox()}

                <form onSubmit={(e: any) => save(e)} className="tw-space-y-6 tw-mt-3">
                  <div>
                    <label
                      htmlFor="email"
                      className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >
                      Email
                    </label>
                    <div className="tw-mt-1">
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        required
                        error={
                          emailAddressTouched && !validEmailAddress
                            ? 'Please enter a valid email address'
                            : undefined
                        }
                        value={emailAddress}
                        onChange={(e: any) => handleChange(e)}
                      />
                    </div>
                  </div>

                  <p className="tw-text-sm tw-text-gray-500">
                    Having troubles adding your email?{' '}
                    <a href={mailtoLink} className="tw-text-secondary hover:tw-text-secondary-dark">
                      Contact us
                    </a>
                  </p>

                  <div className="tw-space-y-1">
                    <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
                      <button
                        type="submit"
                        className="tw-mt-3 tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm disabled:tw-opacity-50"
                        disabled={!isValid(emailAddress)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EmailEnforcer;
