import { BriefKeyword } from '../../../../../../services/models/brief-keyword';
import { BriefSettingsAction } from '../../../../../actions/brief-settings-actions';

export default function briefKeywordsReducer(state: BriefKeyword[] = [], action: any) {
  switch (action.type) {
    case BriefSettingsAction.CompleteCreatingKeyword:
      return [...state, action.keyword];

    case BriefSettingsAction.CompleteDestroyingKeyword:
      return state.filter(k => k.id !== action.keywordId);

    default:
      return state;
  }
}
