import * as slack from '../../services/slack';
import * as twitter from '../../services/twitter';
import { asyncAction, Dispatch } from '../types';

export enum ExternalAuthActions {
  // Twitter Login
  StartFetchingTwitterLoginLink = 'ExternalAuthActions/StartFetchingTwitterLoginLink',
  CompleteFetchingTwitterLoginLink = 'ExternalAuthActions/CompleteFetchingTwitterLoginLink',
  CancelFetchingTwitterLoginLink = 'ExternalAuthActions/CancelFetchingTwitterLoginLink',

  // Add to Slack
  StartFetchingAddToSlackLink = 'ExternalAuthActions/StartFetchingAddToSlackLink',
  CompleteFetchingAddToSlackLink = 'ExternalAuthActions/CompleteFetchingAddToSlackLink',
  CancelFetchingAddToSlackLink = 'ExternalAuthActions/CancelFetchingAddToSlackLink',

  // Reset Slack Auth Info
  ResetAddToSlackLink = 'ExternalAuthActions/ResetAddToSlackLink',
  CancelResetAddToSlackLink = 'ExternalAuthActions/CancelResetSlackAuthLink',
}

export const getTwitterLoginLink = (hasSession: boolean) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingTwitterLoginLink());
    const twitterLoginLink = await twitter.getTwitterLoginLink(hasSession);
    dispatch(completeFetchingTwitterLoginLink(twitterLoginLink));
  }, cancelFetchingTwitterLoginLink);

export const startFetchingTwitterLoginLink = () => ({
  type: ExternalAuthActions.StartFetchingTwitterLoginLink,
});

export const completeFetchingTwitterLoginLink = (twitterLoginLink: string) => ({
  type: ExternalAuthActions.CompleteFetchingTwitterLoginLink,
  twitterLoginLink,
});

export const cancelFetchingTwitterLoginLink = () => ({
  type: ExternalAuthActions.CancelFetchingTwitterLoginLink,
});

export const getAddToSlackLink = (briefId: number, returnRoute: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingAddToSlackLink());
    const addToSlackLink = await slack.getAddToSlackLink(briefId, returnRoute);
    dispatch(completeFetchingAddToSlackLink(addToSlackLink));
  }, cancelFetchingAddToSlackLink);

export const startFetchingAddToSlackLink = () => ({
  type: ExternalAuthActions.StartFetchingAddToSlackLink,
});

export const cancelFetchingAddToSlackLink = () => ({
  type: ExternalAuthActions.CancelFetchingAddToSlackLink,
});

export const completeFetchingAddToSlackLink = (addToSlackLink: string) => ({
  type: ExternalAuthActions.CompleteFetchingAddToSlackLink,
  addToSlackLink,
});

export const resetSlackAuthInformation = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(resetAddToSlackLink());
  }, cancelResetAddToSlackLink);

export const resetAddToSlackLink = () => ({
  type: ExternalAuthActions.ResetAddToSlackLink,
});

export const cancelResetAddToSlackLink = () => ({
  type: ExternalAuthActions.CancelResetAddToSlackLink,
});
