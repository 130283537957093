import { createContext, Dispatch, ReactNode, useContext, useState } from 'react';

type PaywallContextType = {
  paywallModalShown: boolean;
  setPaywallModalShown: Dispatch<boolean>;
};

const defaultValue: PaywallContextType = {
  paywallModalShown: false,
  setPaywallModalShown: value => console.warn('no paywall provider'),
};

const PaywallContext = createContext(defaultValue);

interface PaywallContextProviderProps {
  children: ReactNode;
}

export const PaywallContextProvider = ({ children }: PaywallContextProviderProps) => {
  const [paywallModalShown, setPaywallModalShown] = useState(false);

  const paywallProviderValue = {
    paywallModalShown,
    setPaywallModalShown,
  };

  return <PaywallContext.Provider value={paywallProviderValue}>{children}</PaywallContext.Provider>;
};

export const usePaywallContext = () => useContext(PaywallContext);
