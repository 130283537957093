import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import withSubscriptionAwareness from '../../hoc/withSubscriptionAwareness';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import DashboardPageLayout from '../../layouts/dashboard/dashboardPageLayout';
import { createBrief } from '../../redux/actions/briefs-actions';
import { Brief } from '../../services/models/brief';
import { BriefKeywordInput } from '../../services/models/brief-creation-request';
import { BriefKeywordType } from '../../services/models/brief-keyword-type';
import { BriefLanguage } from '../../services/models/brief-language';
import { BriefStatus } from '../../services/models/brief-status';
import { FeatureFlag } from '../../services/models/feature-flags';
import './AddBriefPage.scss';
import DefineReportStep from './components/define-brief-step/DefineBriefStep';
import EditSidebar from './components/edit-sidebar/EditSidebar';
import NewBriefWizardStepper, {
  AddBriefWizardStep,
} from './components/new-brief-wizard-stepper/NewBriefWizardStepper';
import RefineReportStep from './components/refine-brief-step/RefineBriefStep';
import SetBriefNameStep from './components/set-brief-name-step/SetBriefNameStep';

const NeutralRoute = '/dashboard/brief/new';
const DefineRoute = '/dashboard/brief/new/define';
const RefineRoute = '/dashboard/brief/new/refine';
const NameRoute = '/dashboard/brief/new/complete';

const AddBriefPage: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.session.account);
  const briefs = useAppSelector(state => state.briefs);

  const [step, setStep] = useState(AddBriefWizardStep.DefineScreen);
  const newReportShowPreviewScreenFlag = useFeatureFlag(FeatureFlag.NewReportShowPreviewScreen);

  useEffect(() => {
    switch (location.pathname) {
      case RefineRoute:
        setStep(AddBriefWizardStep.RefineScreen);
        break;
      case NameRoute:
        setStep(AddBriefWizardStep.NameScreen);
        break;
      default:
        setStep(AddBriefWizardStep.DefineScreen);
        break;
    }
  }, [location, setStep]);

  const [draftBrief, setDraftBrief] = useState<Brief | null>(null);
  const draftBriefIdRef = useRef<number | null>(null);

  useEffect(() => {
    const draftBrief = (briefs.items || []).find(x => x.status === BriefStatus.Draft) || null;
    setDraftBrief(draftBrief);
  }, [briefs.items, setDraftBrief]);

  useEffect(() => {
    if (draftBrief && step === AddBriefWizardStep.DefineScreen) {
      history.push(RefineRoute);
    }

    if (draftBriefIdRef.current !== null) {
      const brief = (briefs.items || []).find(brief => brief.id === draftBriefIdRef.current);

      if (brief && brief.status !== BriefStatus.Draft) {
        history.push(`/dashboard/brief/${draftBriefIdRef.current}`);
      }
    } else if (draftBrief) {
      draftBriefIdRef.current = draftBrief.id;
    }
  }, [draftBrief, briefs.items, history, step]);

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleDefineStepCompletion = (keywords: BriefKeywordInput[]) => {
    // TODO(Jose): revise suffix strategy for report naming
    // const source = ['Briefing', 'News', 'Clippings'];
    // const item = source[Math.floor(Math.random() * source.length)];
    const reportName = capitalize(
      keywords.filter(x => x.type === BriefKeywordType.Topic)[0].keyword
    );

    dispatch(
      createBrief({
        name: reportName,
        keywords: keywords,
        // TODO(Jose): revise brief draft strategy moving forward
        draft: false,
        language: BriefLanguage.English,
      })
    );
  };

  const handleRefineStepCompletion = () => {
    history.push(NameRoute);
  };

  const isLoading = () => {
    return (
      !!briefs.isCreating ||
      !!briefs.isFetching ||
      !!draftBrief?.isPublishing ||
      !!draftBrief?.isUpdatingKeywords
    );
  };

  // const getCssExtraClasses = () => {
  //   let result = [];
  //
  //   if (step === AddReportWizardStep.RefineScreen) {
  //     if (newReportShowPreviewScreenFlag) {
  //       result.push('new-preview');
  //     } else {
  //       result.push('optimize-for-sidebar');
  //     }
  //   }
  //   return result.join(' ');
  // };

  if (briefs.items && account.product) {
    const briefsExceeded =
      briefs.items.filter(x => x.status === 'ACTIVE').length === account.product.features.briefs;
    const notActive = !account.subscription.active;

    if (briefsExceeded) {
      return <Redirect to="/dashboard/upsell/report_count" />;
    } else if (notActive) {
      return <Redirect to="/dashboard/upsell/not_active" />;
    }

    if (location.pathname === NeutralRoute) {
      return <Redirect to={DefineRoute} />;
    }
  }

  return (
    <DashboardPageLayout title="Add New Brief">
      <NewBriefWizardStepper isWorking={isLoading()} step={step} />

      <DefineReportStep
        active={step === AddBriefWizardStep.DefineScreen}
        showCancelAction={!(account.metadata?.totalReportsCount === 0)}
        onComplete={keywords => handleDefineStepCompletion(keywords)}
      />

      <RefineReportStep
        active={step === AddBriefWizardStep.RefineScreen}
        draftBrief={draftBrief}
        onComplete={() => handleRefineStepCompletion()}
      />

      <SetBriefNameStep active={step === AddBriefWizardStep.NameScreen} draftBrief={draftBrief} />

      {!newReportShowPreviewScreenFlag && step === AddBriefWizardStep.RefineScreen && (
        <EditSidebar
          active={true}
          draftBrief={draftBrief}
          onComplete={() => handleRefineStepCompletion()}
        />
      )}
    </DashboardPageLayout>
  );
};

export default withSubscriptionAwareness(AddBriefPage);
