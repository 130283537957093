import * as briefStoriesManager from '../../services/brief-stories';
import { StoryTweet } from '../../services/models/story-tweet';
import { asyncAction, Dispatch } from '../types';

export enum BriefStoryTweetsAction {
  StartFetching = 'BriefStoryTweetsAction/StartFetching',
  CompleteFetching = 'BriefStoryTweetsAction/CompleteFetching',
  CancelFetching = 'BriefStoryTweetsAction/CancelFetching',
}

export const requestBriefStoryTweets = (briefId: number, storyId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startFetchingBriefStoryTweets(briefId, storyId));
      const storyTweets = await briefStoriesManager.getStoryTweets(storyId);
      dispatch(completeFetchingBriefStoryTweets(briefId, storyId, storyTweets));
    },
    () => cancelFetchingBriefStoryTweets(briefId, storyId)
  );

export const startFetchingBriefStoryTweets = (briefId: number, storyId: number) => ({
  type: BriefStoryTweetsAction.StartFetching,
  briefId,
  storyId,
});

export const completeFetchingBriefStoryTweets = (
  briefId: number,
  storyId: number,
  tweets: StoryTweet[]
) => ({
  type: BriefStoryTweetsAction.CompleteFetching,
  briefId,
  storyId,
  tweets,
});

export const cancelFetchingBriefStoryTweets = (reportId: number, storyId: number) => ({
  type: BriefStoryTweetsAction.CancelFetching,
  reportId,
  storyId,
});
