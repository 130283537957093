import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../hooks/useAppSelector';
import { BillingRoute } from '../utils/Routes';

const withSubscriptionAwareness = <P extends object>(Component: React.ComponentType<P>) => {
  const WithSubscriptionAwareness: FunctionComponent = props => {
    const { ...remainingProps } = props;

    const subscription = useAppSelector(state => state.session?.account?.subscription);

    if (!subscription.active) {
      return <Redirect to={BillingRoute} />;
    }

    return <Component {...(remainingProps as P)} />;
  };

  return WithSubscriptionAwareness;
};

export default withSubscriptionAwareness;
