import { RefObject, useEffect, useRef } from 'react';

export default function useEventListener<T extends HTMLElement = HTMLDivElement>(
  eventName: keyof WindowEventMap | string, // string to allow custom event
  handler: (event: Event) => void,
  element?: RefObject<T>
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: Event) => void>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Define the listening target
      const targetElement: T | Window = element?.current || window;

      // Make sure element supports addEventListener On
      const isSupported = targetElement && targetElement.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: Event) => {
        if (!!savedHandler?.current) {
          savedHandler.current(event);
        }
      };

      // Add event listener
      targetElement.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        targetElement.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}
