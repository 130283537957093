import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import recipientInfo from '../../assets/images/recipients-info.svg';
import { Brief } from '../../services/models/brief';
import { BriefRecipient } from '../../services/models/brief-recipient';
import { VectorIcon } from '../VectorIcon';
import './EditBriefRecipients.scss';

export interface EditBriefRecipientsProps {
  brief: Brief;
  create: (email: string) => void;
  destroy: (id: number) => void;
  maxRecipients: number;
}

const EditBriefRecipients: FunctionComponent<EditBriefRecipientsProps> = ({
  brief,
  create,
  destroy,
  maxRecipients,
}) => {
  const [currentRecipient, setCurrentRecipient] = useState('');

  const addRecipient = (event: SyntheticEvent) => {
    event.preventDefault();
    if (isFull()) {
      return;
    }
    const value = currentRecipient.split(',')[0].trim().toLocaleLowerCase();
    if (isValid(value)) {
      if (
        !brief.recipients
          .filter(x => x && x.emailAddress)
          .some(x => x.emailAddress?.toLocaleLowerCase() === value)
      ) {
        create(value);
      }
      setCurrentRecipient('');
    }
  };

  const removeRecipient = (recipient: BriefRecipient) => {
    if (recipient && recipient.id) {
      destroy(recipient.id);
    }
  };

  const isValid = (value: string) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value);
  };

  const isFull = () => {
    return brief.recipients.length === maxRecipients;
  };

  const handleCurrentRecipientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentRecipient(event.target.value);
  };

  const getTags = () => {
    const recipients = brief?.recipients || [];

    if (recipients.length === 0) {
      return null;
    }

    return (
      <div className="tool-item-list">
        <div className="tool-item-list-header bold-h3">Email list</div>
        {recipients.map((x, i) => (
          <div className="tool-item" key={i}>
            <span>{x.emailAddress}</span>
            <Button variant="tool-button" onClick={() => removeRecipient(x)}>
              Delete
            </Button>
          </div>
        ))}
      </div>
    );
  };

  const handleSeparator = (e: React.KeyboardEvent) => {
    const separators = [',', 'Enter', 'Tab'];

    if (separators.some(x => x === e.key)) {
      addRecipient(e);
    }
  };

  return (
    <div className="recipients-tool-layout">
      <div className="bold-h2 black-text">Recipients</div>
      <div className="light-h2 carbon-text">Add recipients to share your News Brief.</div>
      <div className="recipients-tool-buttons">
        <img src={recipientInfo} alt="Recipients Info" />
      </div>
      <Form noValidate className="w-100">
        <InputGroup>
          <FormControl
            value={currentRecipient}
            disabled={isFull()}
            isInvalid={currentRecipient.length > 0 && !isValid(currentRecipient)}
            onChange={(e: any) => handleCurrentRecipientChange(e)}
            onKeyDownCapture={(e: any) => handleSeparator(e)}
            type="email"
            required
          />
          <InputGroup.Append>
            <Button
              disabled={!isValid(currentRecipient) && isFull()}
              variant="purple"
              onClick={(e: any) => addRecipient(e)}
            >
              <VectorIcon file="users_white" size={20} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {getTags()}
      </Form>
    </div>
  );
};

export default EditBriefRecipients;
