import { BriefUnsubscribeLink } from '../../../services/models/brief-unsubscribe-link';
import { BriefUnsubscribeAction } from '../../actions/brief-unsubscribe-actions';

export default function briefUnsubscribeReducer(
  state: BriefUnsubscribeLink = {},
  action: any
): BriefUnsubscribeLink {
  switch (action.type) {
    case BriefUnsubscribeAction.StartFetchingLink:
      return { ...state, isFetching: true };

    case BriefUnsubscribeAction.CancelFetchingLink:
      return { ...state, isFetching: false };

    case BriefUnsubscribeAction.CompleteFetchingLink:
      return {
        ...state,
        isFetching: false,
        ...action.briefUnsubscribeLink,
      };

    case BriefUnsubscribeAction.StartUnsubscribing:
      return { ...state, isUnsubscribing: true };

    case BriefUnsubscribeAction.CancelUnsubscribing:
      return { ...state, isUnsubscribing: false };

    case BriefUnsubscribeAction.CompleteUnsubscribing:
      return {
        ...state,
        isUnsubscribing: false,
        isCompleted: true,
      };

    default:
      return state;
  }
}
