import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useAppSelector';
import { hsp, isInHootSuite } from '../../services/hootsuite';
import * as honeybadger from '../../services/honeybadger';

const Hootsuite: FunctionComponent = () => {
  const history = useHistory();

  const userId = useAppSelector(state => state.session?.account?.userid || 0);
  const fullName = useAppSelector(state =>
    [state.session?.account?.firstName, state.session?.account?.lastName].join(' ').trim()
  );

  useEffect(() => {
    if (isInHootSuite()) {
      try {
        hsp.init({});
        hsp.bind('refresh', () => window.location.reload());
      } catch (error) {
        honeybadger.notify(error, 'hootsuite');
      }
    }
  }, []);

  useEffect(() => {
    if (isInHootSuite()) {
      history.listen(location => {
        const match = /\/dashboard\/report\/(\d+)$/.test(location.pathname);

        if (match) {
          hsp.saveData({ userId, pathname: location.pathname });
        }
      });

      hsp.getData((data: { pathname: string; userId: string }) => {
        if (data && data.pathname && parseInt(data.userId) === userId) {
          history.push(data.pathname);
        }
      });
    }
  }, [history, userId]);

  useEffect(() => {
    if (fullName && isInHootSuite()) {
      hsp.updatePlacementSubtitle(fullName);
    }
  }, [fullName]);

  return <></>;
};

export default Hootsuite;
