import React from 'react';
import { Alert } from 'react-bootstrap';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const twitterAccountAlreadyInuseErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: any,
  discard: DiscardErrorCallback
) => {
  if (error.message.indexOf('(/accountTwitterLogin)') === -1) {
    return null;
  }

  const message: string = error?.message || '';
  const matches = message.match(/GraphQL error:(?<internalError>.*):\s(?<error>.*)/);

  if (matches && matches.groups && matches.groups['error']) {
    return (
      <Alert key={index} variant="danger">
        {matches.groups['error']}
      </Alert>
    );
  }

  return null;
};
