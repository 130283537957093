import React, { FunctionComponent } from 'react';
import { Brief, getKeywordsByType } from '../../services/models/brief';
import { BriefKeywordType } from '../../services/models/brief-keyword-type';
import EditBriefKeyword from '../edit-brief-keyword/EditBriefKeyword';

export interface EditBriefTopicsProps {
  brief: Brief;
  create: (keyword: string) => void;
  destroy: (keywordId: number) => void;
  maxItems: number;
}

const EditBriefTopics: FunctionComponent<EditBriefTopicsProps> = ({
  brief,
  create,
  destroy,
  maxItems,
}) => {
  const briefTopics = getKeywordsByType(brief, BriefKeywordType.Topic);
  const briefExclusions = getKeywordsByType(brief, BriefKeywordType.Exclusion);

  return (
    <EditBriefKeyword
      title="Topics"
      maxItems={maxItems}
      placeholder="press ENTER after each keyword"
      tagClassName="blue-tag"
      keywords={briefTopics}
      isUpdating={!!brief.isUpdatingKeywords}
      helpSummary={<span>Enter related keywords on the topics you are interested</span>}
      helpContent={
        <>
          <p>
            Enter any keyword to start your News Brief. Keep your News Brief focused by using
            related terms, ie: machine learning, AI.{' '}
          </p>
          <p>
            Press <code>ENTER</code> after each one.
          </p>
        </>
      }
      create={keyword => create(keyword)}
      destroy={keywordId => destroy(keywordId)}
      validate={keyword =>
        (briefExclusions || []).some(x => x.keyword === keyword)
          ? `There's an exclusion with this keyword`
          : null
      }
    />
  );
};

export default EditBriefTopics;
