import { Intent } from '@blueprintjs/core';
import { AppToaster } from '../../../utils/toaster';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const invalidReportRecipientErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: any,
  discard: DiscardErrorCallback
) => {
  const message = 'Invalid report recipient input';

  if (error.message.indexOf(message) === -1) {
    return null;
  }

  discard(error);

  AppToaster.show({
    intent: Intent.DANGER,
    icon: 'warning-sign',
    message: 'Invalid email, please use a valid email.',
  });

  return false;
};
