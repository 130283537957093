import { Story } from '../../../../../../services/models/story';
import { BriefStoriesAction } from '../../../../../actions/brief-stories-actions';

export default function storyReducer(state: Story = {} as Story, action: any): Story {
  switch (action.type) {
    case BriefStoriesAction.CompleteMarkStoryAsViewed:
      return { ...state, isClicked: true };

    default:
      return state;
  }
}
