import Plausible from 'plausible-tracker';

export const bootstrap = () => {
  const { enableAutoPageviews } = Plausible({});

  enableAutoPageviews();
};

export const trackEvent = (customEvent: string, data?: { [propName: string]: string }) => {
  const { trackEvent } = Plausible({});
  trackEvent(customEvent, { props: data });
};
