import React from 'react';
import './CircularProgressBar.scss';

export class CircularProgressBar extends React.Component<{
  total: number;
  current: number;
  removeLabel?: boolean;
}> {
  render() {
    const delta = this.props.total - this.props.current;
    const done = [];
    for (let i = 0; i < this.props.current; ++i) done.push(i);
    const pending = [];
    for (let i = 0; i < delta; ++i) pending.push(i);
    let indexCount = 0;
    return (
      <div className="circular-progress-bar">
        {this.props.removeLabel === false && <label className="light-h5">using</label>}
        {done.map(_ => (
          <div key={indexCount++} className="done" />
        ))}
        {pending.map(_ => (
          <div key={indexCount++} className="pending" />
        ))}
      </div>
    );
  }
}
