import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

declare global {
  interface Window {
    analytics: Analytics;
  }
}

type DefaultValue = {
  isReady: boolean;
  analytics: Analytics | undefined;
};

const defaultValue: DefaultValue = {
  isReady: false,
  analytics: undefined,
};

const TrackingContext = createContext(defaultValue);

interface TrackingContextProviderProps {
  writeKey: string;
  children: ReactNode;
}

export const TrackingContextProvider = ({ writeKey, children }: TrackingContextProviderProps) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(setupAnalytics);

  useEffect(() => {
    const loadAnalytics = async () => {
      console.log(`[Newslit] bootstrapping analytics`);
      let [response] = await AnalyticsBrowser.load({ writeKey });
      setAnalytics(response);
      // @ts-ignore
      window.analytics = response;
    };

    loadAnalytics();
  }, [writeKey]);

  const trackingProviderValue = {
    isReady: !!analytics,
    analytics: analytics,
  };

  return (
    <TrackingContext.Provider value={trackingProviderValue}>
      <>{children}</>
    </TrackingContext.Provider>
  );
};

export const useTrackingContext = () => useContext(TrackingContext);

const setupAnalytics = (): Analytics => {
  const analytics: any = window?.analytics || [];
  window.analytics = analytics;

  if (analytics.initialize) {
    return analytics;
  }

  if (analytics.invoked) {
    return analytics;
  }

  analytics.invoked = !0;
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];

  analytics.factory = function (e: any) {
    return function () {
      let t = Array.prototype.slice.call(arguments);
      t.unshift(e);
      analytics.push(t);
      return analytics;
    };
  };

  for (let e = 0; e < analytics.methods.length; e++) {
    let key = analytics.methods[e];
    analytics[key] = analytics.factory(key);
  }

  return analytics;
};
