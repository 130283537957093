import React, { FunctionComponent, useEffect } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import {
  getEmailUnsubscribeLink,
  unsubscribeFromSocialDigest,
} from '../../../../redux/actions/email-unsubscribe-actions';
import './EmailUnsubscribePage.scss';

const EmailUnsubscribePage: FunctionComponent = () => {
  const history = useHistory();
  const { search } = useLocation();

  // Query Params
  const searchParams = new URLSearchParams(search);
  const emailId = searchParams.get('e');
  const verificationCode = searchParams.get('c');

  const dispatch = useAppDispatch();
  const emailUnsubscribeLink = useAppSelector(state => state.emailUnsubscribeLink);

  useEffect(() => {
    if (!verificationCode || !emailId) {
      history.push('/');
    }
  }, [verificationCode, emailId, history]);

  useEffect(() => {
    if (verificationCode && emailId) {
      dispatch(getEmailUnsubscribeLink(verificationCode, emailId));
    }
  }, [emailId, verificationCode, dispatch]);

  // Fetching the Email Unsubscribe link
  if (emailUnsubscribeLink.isFetching === undefined || emailUnsubscribeLink.isFetching) {
    return (
      <div>
        <Card>
          <Card.Body>
            <h1 className="email-unsubscribe-loading-header">
              <Spinner animation="grow"></Spinner>
              <span>Loading...</span>
            </h1>
          </Card.Body>
        </Card>
      </div>
    );
  }

  // Problems fetching the Email Unsubscribe link
  if (emailUnsubscribeLink.isFetching === false && !emailUnsubscribeLink.emailAddress) {
    return (
      <div>
        <Card>
          <Card.Body>
            <h1 className="email-unsubscribe-panel-header text-center">
              Oops! Something went wrong.
            </h1>
            <Link to="/">Return to Newslit</Link>
          </Card.Body>
        </Card>
      </div>
    );
  }

  if (emailUnsubscribeLink.isFetching === false && !emailUnsubscribeLink.receiveDigest) {
    return (
      <div>
        <Card>
          <Card.Body>
            <h1 className="email-unsubscribe-panel-header text-center">Already Unsubscribe</h1>
            <p className="report-unsubscribe-panel-body">
              You're already unsubscribed from your Newslit Social Brief.
            </p>
            <Link to="/">Return to Newslit</Link>
          </Card.Body>
        </Card>
      </div>
    );
  }

  // Email Unsubscribe Completed successfully
  if (
    emailUnsubscribeLink &&
    emailUnsubscribeLink.isFetching === false &&
    emailUnsubscribeLink.isCompleted
  ) {
    return (
      <div>
        <Card>
          <Card.Body>
            <h1 className="email-unsubscribe-panel-header text-center">Unsubscribe Successful</h1>
            <p className="report-unsubscribe-panel-body">
              You will no longer receive a daily email related to your Social Brief.
            </p>
            <Link to="/">Return to Newslit</Link>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Unsubscribe from Newslit Email</title>
      </Helmet>

      <Card>
        <Card.Body>
          <h2 className="email-unsubscribe-panel-header">
            Would you like to unsubscribe from these emails?
          </h2>

          <p className="email-unsubscribe-panel-body">
            Your email address <strong>{emailUnsubscribeLink.emailAddress}</strong> will stop
            receiving:
          </p>

          <ul>
            <li>Daily Newslit Social Digest at {emailUnsubscribeLink.digestHour}:00</li>
          </ul>

          <div className="mt-3">
            <Button
              disabled={!emailUnsubscribeLink.emailAddress || emailUnsubscribeLink.isUnsubscribing}
              variant="purple"
              className="mr-1"
              onClick={() =>
                dispatch(unsubscribeFromSocialDigest(verificationCode as string, emailId as string))
              }
            >
              {emailUnsubscribeLink.isUnsubscribing ? 'Unsubscribing...' : 'Unsubscribe'}
            </Button>
            <Button
              variant="white"
              disabled={!emailUnsubscribeLink.emailAddress || emailUnsubscribeLink.isUnsubscribing}
              as={Link}
              to={'/'}
            >
              Cancel
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EmailUnsubscribePage;
