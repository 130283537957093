import React, { FunctionComponent, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { getBriefStoryShareData } from '../../redux/actions/brief-stories-actions';
import { AppDispatch } from '../../redux/store';
import { hsp, isInHootSuite } from '../../services/hootsuite';
import { Story } from '../../services/models/story';
import { VectorIcon } from '../VectorIcon';
import './StoryCardShareOptions.scss';

interface Props {
  isPreview?: boolean;
  story: Story;
  briefId: number;
}

enum ShareType {
  Email,
  Twitter,
  Facebook,
  LinkedIn,
}

const StoryCardShareOptions: FunctionComponent<Props> = ({ isPreview, story, briefId }) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [canShareHootsuite, setShareHootsuite] = useState(false);

  useEffect(() => {
    if (story.shareData?.shareText && canShareHootsuite) {
      hsp.composeMessageV2(story.shareData?.shareText);
      setShareHootsuite(false);
    }
  }, [story.shareData, canShareHootsuite]);

  const getShareUrl = (shareType: ShareType) => {
    const mailBody =
      'Check out this story:\r\n\r\n' +
      story.url +
      '\r\n\r\n' +
      'Sent via Newslit - Next-generation news monitoring and research tool. https://newslit.co';

    return shareType === ShareType.Email
      ? `mailto:?subject=${encodeURI(story.title)}&body=${encodeURI(mailBody)}`
      : `https://newslit.co/share?u=${encodeURI(story.url)}&t=${shareType}`;
  };

  const shareWithHootsuite = () => {
    if (!story.shareData || !story.shareData.shortUrl) {
      dispatch(getBriefStoryShareData(briefId, story.id));
    }
    setShareHootsuite(true);
  };

  const getCommonShareOptions = () => (
    <div className="share-options">
      <a
        href={getShareUrl(ShareType.Twitter)}
        className="story-btn-share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <VectorIcon size={20} file="twitter" alt="Tweet" />
      </a>
      <a
        href={getShareUrl(ShareType.LinkedIn)}
        className="story-btn-share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <VectorIcon size={20} file="linked" alt="LinkedIn Share" />
      </a>
      <a
        className="story-btn-share"
        href={getShareUrl(ShareType.Email)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <VectorIcon size={20} file="mail" alt="Email story" />
      </a>
    </div>
  );

  const getHootsuiteShareOptions = () => (
    <div className="share-options">
      {(() =>
        story.shareData?.isFetching ? (
          <Spinner size="sm" animation="border" />
        ) : (
          <span className="story-btn-share" onClick={() => shareWithHootsuite()}>
            <i className="fa fa-share"></i>
          </span>
        ))()}
    </div>
  );

  if (isPreview) {
    return <></>;
  }

  return isInHootSuite() ? getHootsuiteShareOptions() : getCommonShareOptions();
};

export default StoryCardShareOptions;
