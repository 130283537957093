import { Intent, NonIdealState, Spinner, SpinnerSize } from '@blueprintjs/core';
import { AdjustmentsIcon, ExclamationIcon } from '@heroicons/react/solid';
import { StoryCardSkeleton } from '@newslit/uikit';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { SharedLinkCard } from '../../components/common/components/shared-link-card/SharedLinkCard';
import DashboardNav from '../../components/dashboard-nav/DashboardNav';
import FilterNav, {
  SortOption,
  sortOptions,
  TimeFilter,
  timeFilters,
} from '../../components/filter-nav/filterNav';
import InactiveSubscription from '../../components/inactive-subscription/inactiveSubscription';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import PullToRefresh from '../../components/pull-to-refresh/pullToRefresh';
import { ReaderPanel } from '../../components/reader-panel/readerPanel';
import SocialPreferencesPanel from '../../components/social-preferences-panel/socialPreferencesPanel';
import StoryTweetsPanel from '../../components/story-tweets-panel/storyTweetsPanel';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import useLocalStorage from '../../hooks/useLocalStorage';
import DashboardPageLayout from '../../layouts/dashboard/dashboardPageLayout';
import { requestSharedLink } from '../../redux/actions/shared-link-action';
import { requestSharedLinksPage } from '../../redux/actions/shared-links-action';
import { ExternalUserType } from '../../services/models/external-user';
import { SharedLink } from '../../services/models/shared-link';
import { classNames } from '../../utils/class-names';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FeatureFlag } from '../../services/models/feature-flags';

type StoryMatchParams = {
  storyId: string;
};

const SocialPage: FunctionComponent = () => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const storyMatch = useRouteMatch<StoryMatchParams>(`${path}/story/:storyId`);

  const storyId = storyMatch?.params?.storyId;
  const sharedLinkId = Number(storyId);

  const isLoadingSession = useAppSelector(state => state.session?.isFetching !== false);
  const account = useAppSelector(state => state.session.account);
  const subscription = useAppSelector(state => state.session.account.subscription);
  const sharedLinks = useAppSelector(state => state.sharedLinks);
  const twitterUser = account.externalUsers.find(eu => eu.type === ExternalUserType.TWITTER);
  const selectedSharedLink = useAppSelector(state =>
    state.sharedLinks.items?.find(item => item.id === sharedLinkId)
  );
  const socialBriefFlag = useFeatureFlag(FeatureFlag.ShowSocialBrief);

  // TODO: Control via SiteFlag
  const isTwitterIntegrationActive = false;

  const dispatch = useAppDispatch();

  const [sortOption, setSortOption] = useLocalStorage('persist:sort_option', sortOptions[0]);
  const [timeFilter, setTimeFilter] = useLocalStorage('persist:time_filter', timeFilters[0]);
  const [sharesFilter, setSharesFilter] = useLocalStorage('persist:shares_filter', 0);
  const [showPreferences, setShowPreferences] = useState(false);

  const [readerAppShown, setReaderAppShown] = useState(false);
  const [readerUrl, setReaderUrl] = useState<string | null>(null);
  const [readerStoryId, setReaderStoryId] = useState<number | null>(null);

  const handleFilterOptionsChange = useCallback(
    (sort: SortOption, time: TimeFilter, shares: number) => {
      dispatch(requestSharedLinksPage(0, undefined, sort.value, time.value, shares));
    },
    [dispatch]
  );

  const handlePullToRefresh = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      dispatch(
        requestSharedLinksPage(0, undefined, sortOption.value, timeFilter.value, sharesFilter)
      );
    });
  };

  const handleInfiniteScrollAction = (visible: boolean) => {
    if (visible) {
      dispatch(
        requestSharedLinksPage(
          sharedLinks.lastLoadedCursor || 0,
          undefined,
          sortOption.value,
          timeFilter.value,
          sharesFilter
        )
      );
    }
  };

  useEffect(() => {
    if (account.metadata?.isTwitterUser && !sharedLinks.items) {
      dispatch(
        requestSharedLinksPage(0, undefined, sortOption.value, timeFilter.value, sharesFilter)
      );
    }
  }, [account, sharedLinks, sortOption, timeFilter, sharesFilter, dispatch]);

  if (isLoadingSession) {
    return <LoadingSpinner />;
  }

  if ((!socialBriefFlag || !account.metadata?.isTwitterUser) && !isLoadingSession) {
    return <Redirect to="/dashboard" />;
  }

  const isLoading = () => {
    return !sharedLinks.items || (!!sharedLinks.isFetching && !!sharedLinks.isFetchingFirstPage);
  };

  const sharedLinkToStoryCard = (sharedLink: SharedLink, index: number) => {
    if (sharedLink.story) {
      return (
        <SharedLinkCard
          key={sharedLink.id}
          sharedLink={sharedLink}
          isFirst={index === 0}
          onShareBarClick={(storyId, storyUri) => {
            history.push(`${url}/story/${storyId}`);
          }}
          onReadStory={(storyId, storyUri) => {
            setReaderStoryId(storyId);
            setReaderUrl(storyUri);
            setReaderAppShown(true);
          }}
        />
      );
    } else {
      if (!sharedLink.isFetching && !sharedLink.isLoaded) {
        dispatch(requestSharedLink(sharedLink.id));
      }

      return <StoryCardSkeleton key={sharedLink.id} />;
    }
  };

  const getContent = () => {
    if (isLoading()) {
      return (
        <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
          <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
        </div>
      );
    }

    if (!subscription.active) {
      return (
        <InactiveSubscription
          subscription={subscription}
          title="Thank you for trying Newslit!"
          description="You've reached the end of your free trial. To access your social feed, email digests and alerts please select one of our paid plans."
        />
      );
    }

    if (!sharedLinks.items || sharedLinks.items?.length === 0) {
      return (
        <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
          <div className="tw-p-8">
            <NonIdealState
              icon="social-media"
              title="No Recent Stories"
              description="Sorry, no stories have been shared by your network"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="tw-block sm:tw-flex sm:tw-h-full sm:tw-overflow-y-auto">
        <PullToRefresh onRefresh={handlePullToRefresh}>
          <div className="social-feed-container tw-border-t tw-border-gray-100">
            {sharedLinks.items?.map((sharedLink, index) => {
              return sharedLinkToStoryCard(sharedLink, index);
            })}

            {sharedLinks.hasMore && getInfiniteScrollIndicator()}
          </div>
        </PullToRefresh>
      </div>
    );
  };

  const getInfiniteScrollIndicator = () => {
    return (
      <VisibilitySensor partialVisibility onChange={handleInfiniteScrollAction}>
        <>
          <StoryCardSkeleton />
          <StoryCardSkeleton />
          <StoryCardSkeleton />
        </>
      </VisibilitySensor>
    );
  };

  const renderSocialBriefOptions = () => {
    return (
      <div className="tw-flex tw-flex-nowrap">
        <button
          type="button"
          className="tw-bg-white tw-rounded-full tw-p-1 tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-gray-600 focus:tw-outline-none"
          onClick={() => setShowPreferences(true)}
        >
          <AdjustmentsIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
          <span className="tw-sr-only">Social Brief adjustments</span>
        </button>
      </div>
    );
  };

  return (
    <React.StrictMode>
      <DashboardPageLayout title={`@${twitterUser?.username}`}>
        {/* main area */}
        <main className="tw-flex-1 tw-z-0 tw-flex tw-overflow-y-auto tw-overflow-hidden focus:tw-outline-none">
          {/* Primary column: Sharedlinks */}
          <section
            className={classNames(
              isLoading()
                ? 'tw-flex-1'
                : storyMatch
                ? 'tw-hidden lg:tw-flex lg:tw-max-w-screen-sm'
                : 'tw-flex-1',
              'tw-relative tw-z-0 md:tw-max-w-screen-sm tw-overflow-y-auto focus:tw-outline-none'
            )}
          >
            <div className="tw-h-full tw-relative tw-flex tw-flex-col tw-border-r tw-border-gray-200">
              {/* TODO: Improve our pull-to-refresh & navbbar className="tw-sticky tw-top-0" */}
              <div className="tw-flex-shrink-0 tw-z-20">
                <div
                  className={classNames(
                    isTwitterIntegrationActive ? 'tw-h-20 sm:tw-hidden' : 'tw-h-52 sm:tw-hidden'
                  )}
                ></div>
                <div className="tw-fixed tw-z-10 tw-top-0 tw-right-0 tw-w-full sm:tw-relative sm:tw-top-auto sm:tw-right-auto">
                  <DashboardNav>
                    <div className="tw-flex tw-flex-col tw-flex-shrink tw-flex-grow tw-h-full tw-items-stretch tw-justify-center">
                      <h2 className="tw-text-lg tw-font-bold tw-m-0 tw-p-0">
                        @{twitterUser?.username}
                      </h2>
                    </div>
                    <div className="tw-flex tw-ml-4">{renderSocialBriefOptions()}</div>
                  </DashboardNav>

                  <FilterNav
                    sortOptions={sortOptions}
                    activeSortOption={sortOption}
                    onActiveSortChange={setSortOption}
                    timeFilterOptions={timeFilters}
                    activeTimeFilter={timeFilter}
                    onActiveTimeFilterChange={setTimeFilter}
                    activeSharesFilter={sharesFilter}
                    onActiveSharesFilterChange={setSharesFilter}
                    onFilterOptionsChange={handleFilterOptionsChange}
                  />

                  {!isTwitterIntegrationActive && (
                    <div className="tw-overflow-hidden tw-bg-yellow-50 sm:tw-rounded-lg">
                      <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                        <div className="tw-flex">
                          <div className="tw-flex-shrink-0">
                            <ExclamationIcon
                              className="tw-h-5 tw-w-5 tw-text-yellow-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="tw-ml-3">
                            <p className="tw-text-sm tw-text-yellow-700">
                              Our Twitter integration has been disabled. We are actively trying to
                              resolve this and will share updates as additional information becomes
                              available.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {getContent()}
            </div>
          </section>

          {/* secondary column: Sharedlink Tweets */}
          {!isLoading() && storyMatch && selectedSharedLink && (
            <aside className="tw-min-w-0 tw-h-full tw-flex tw-flex-col lg:flex-shrink-0 lg:tw-max-w-screen-sm xl:tw-max-w-md tw-overflow-hidden tw-border-r tw-border-gray-200">
              <StoryTweetsPanel sharedLink={selectedSharedLink} />
            </aside>
          )}
        </main>

        <SocialPreferencesPanel
          panelOpen={showPreferences}
          onClose={() => setShowPreferences(false)}
        />

        <ReaderPanel
          url={readerUrl}
          storyId={readerStoryId}
          show={readerAppShown}
          onClose={setReaderAppShown}
        />
      </DashboardPageLayout>
    </React.StrictMode>
  );
};

export default SocialPage;
