import gql from 'graphql-tag';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient, getGraphQLClient } from './graphql-client';
import { Brief } from './models/brief';
import { BriefCreationRequest } from './models/brief-creation-request';
import { BriefKeyword } from './models/brief-keyword';
import { BriefKeywordType } from './models/brief-keyword-type';
import { BriefLanguage } from './models/brief-language';
import { BriefRecipient } from './models/brief-recipient';
import { BriefUnsubscribeLink } from './models/brief-unsubscribe-link';
import { DayOfWeek } from './models/day-of-week';

const BRIEF_FIELDS = `
  id
  name
  status
  dateAdded
  nextBriefDate
  daysOfWeek
  briefHour
  language
  keywords {
    exactMatch
    id
    keyword
    type
  }
  recipients {
    id
    emailAddress
  }
  slackChannels {
    dateAdded
    id
    name
  }
`;

const GET_BRIEFS_OVERVIEW = gql`
    query getBriefsOverview {
        briefs {
            ${BRIEF_FIELDS}
        }
    }
`;
export const getBriefsOverview = async (): Promise<Brief[]> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.query<{ briefs: Brief[] }>({
    query: GET_BRIEFS_OVERVIEW,
  });
  return response.data.briefs;
};

const GET_BRIEF_DETAILS = gql`
  query getBriefDetails($briefId: Long) {
    brief(id: $briefId) {
      id
      name
      briefHour
      daysOfWeek
      status
      showSuggestedStories
      keywords {
        exactMatch
        id
        keyword
        type
      }
      recipients {
        emailAddress
        id
      }
      slackChannels {
        dateAdded
        id
        name
      }
    }
  }
`;

export const getBriefDetails = async (briefId: number): Promise<Brief> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.query<{ brief: Brief }>({
    query: GET_BRIEF_DETAILS,
    variables: {
      briefId,
    },
  });

  return response.data.brief;
};

const CREATE_BRIEF = gql`
  mutation createReport($briefInput: BriefCreateInput!) {
    briefCreate(input: $briefInput) {
        ${BRIEF_FIELDS}
    }
  }
`;

export const createBrief = async (creationRequest: BriefCreationRequest): Promise<Brief> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.mutate<{ briefCreate: Brief }>({
    mutation: CREATE_BRIEF,
    variables: {
      briefInput: creationRequest,
    },
  });

  if (!response.data || !response.data.briefCreate) {
    throw new EmptyResponseError();
  }

  return response.data.briefCreate;
};

const DESTROY_BRIEF_MUTATION = gql`
  mutation destroyBrief($briefId: ID!) {
    briefDestroy(input: { briefId: $briefId }) {
      deletedBriefId
    }
  }
`;

export const destroyBrief = async (briefId: number): Promise<number> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ briefDestroy: { deletedBriefId: number } }>({
    mutation: DESTROY_BRIEF_MUTATION,
    variables: {
      briefId: briefId.toString(),
    },
  });

  if (!response.data || !response.data?.briefDestroy) {
    throw new EmptyResponseError();
  }

  return response.data.briefDestroy.deletedBriefId;
};

const UPDATE_BRIEF_NAME = gql`
  mutation updateBriefName($briefId: ID!, $name: String) {
    briefUpdate(input: { briefId: $briefId, name: $name }) {
      brief {
        id
        name
      }
    }
  }
`;

export const updateBriefName = async (briefId: number, name: string): Promise<Brief> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ briefUpdate: { brief: Brief } }>({
    mutation: UPDATE_BRIEF_NAME,
    variables: {
      briefId: briefId.toString(),
      name: name,
    },
  });

  if (!response.data || !response.data.briefUpdate || !response.data.briefUpdate.brief) {
    throw new EmptyResponseError();
  }

  return response.data.briefUpdate.brief;
};

const UPDATE_BRIEF_LANGUAGE_MUTATION = gql`
  mutation updateBriefLanguage($briefId: ID!, $language: BriefLanguage) {
    briefLanguageUpdate(input: { briefId: $briefId, language: $language }) {
      brief {
        id
        language
      }
    }
  }
`;

export const updateLanguage = async (briefId: number, language: BriefLanguage): Promise<Brief> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ briefLanguageUpdate: { brief: Brief } }>({
    mutation: UPDATE_BRIEF_LANGUAGE_MUTATION,
    variables: {
      briefId: briefId.toString(),
      language,
    },
  });

  if (
    !response.data ||
    !response.data.briefLanguageUpdate ||
    !response.data.briefLanguageUpdate.brief
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefLanguageUpdate.brief;
};

export const sanitizeKeyword = (keyword: string) => {
  return keyword.replaceAll('"', '');
};

const CREATE_BRIEF_KEYWORD_MUTATION = gql`
  mutation createBriefKeyword(
    $briefId: ID!
    $keyword: String!
    $type: BriefKeywordType!
    $exactMatch: Boolean
  ) {
    briefKeywordCreate(
      input: { briefId: $briefId, keyword: $keyword, type: $type, exactMatch: $exactMatch }
    ) {
      briefKeyword {
        id
        keyword
        type
        exactMatch
      }
    }
  }
`;

export const createBriefKeyword = async (
  briefId: number,
  keyword: string,
  type: BriefKeywordType
): Promise<BriefKeyword> => {
  const exactMatch = keyword.startsWith('"') && keyword.endsWith('"');

  keyword = sanitizeKeyword(keyword);

  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ briefKeywordCreate: { briefKeyword: BriefKeyword } }>({
    mutation: CREATE_BRIEF_KEYWORD_MUTATION,
    variables: {
      type,
      keyword,
      exactMatch,
      briefId: briefId.toString(),
    },
  });

  if (
    !response.data ||
    !response.data.briefKeywordCreate ||
    !response.data.briefKeywordCreate.briefKeyword
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefKeywordCreate.briefKeyword;
};

const DESTROY_BRIEF_KEYWORD_MUTATION = gql`
  mutation destroyBriefKeyword($briefId: ID!, $keywordId: ID!) {
    briefKeywordDestroy(input: { briefId: $briefId, briefKeywordId: $keywordId }) {
      deletedKeywordId
    }
  }
`;

export const destroyBriefKeyword = async (briefId: number, keywordId: number): Promise<number> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.mutate<{ briefKeywordDestroy: { deletedKeywordId: number } }>({
    mutation: DESTROY_BRIEF_KEYWORD_MUTATION,
    variables: {
      briefId: briefId.toString(),
      keywordId: keywordId.toString(),
    },
  });

  if (
    !response.data ||
    !response.data.briefKeywordDestroy ||
    !response.data.briefKeywordDestroy.deletedKeywordId
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefKeywordDestroy.deletedKeywordId;
};

const UPDATE_BRIEF_SCHEDULE_MUTATION = gql`
  mutation updateBriefSchedule($briefId: ID!, $days: [DayOfWeek]!, $hour: Int!) {
    briefScheduleUpdate(input: { briefId: $briefId, daysOfWeekSelected: $days, hour: $hour }) {
      brief {
        daysOfWeek
        briefHour
      }
    }
  }
`;

export const updateBriefSchedule = async (
  briefId: number,
  days: DayOfWeek[],
  hour: number
): Promise<Brief> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ briefScheduleUpdate: { brief: Brief } }>({
    mutation: UPDATE_BRIEF_SCHEDULE_MUTATION,
    variables: {
      briefId: briefId.toString(),
      days: days,
      hour,
    },
  });

  if (
    !response.data ||
    !response.data.briefScheduleUpdate ||
    !response.data.briefScheduleUpdate.brief
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefScheduleUpdate.brief;
};

const TOGGLE_BRIEF_SUGGESTED_STORIES_MUTATION = gql`
  mutation toggleBriefSuggestedStories($briefId: ID!) {
    briefSuggestedStoriesToggle(input: { briefId: $briefId }) {
      brief {
        showSuggestedStories
      }
    }
  }
`;

export const toggleBriefSuggestedStories = async (briefId: number): Promise<Brief> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ briefSuggestedStoriesToggle: { brief: Brief } }>({
    mutation: TOGGLE_BRIEF_SUGGESTED_STORIES_MUTATION,
    variables: {
      briefId: briefId.toString(),
    },
  });

  if (
    !response.data ||
    !response.data.briefSuggestedStoriesToggle ||
    !response.data.briefSuggestedStoriesToggle.brief
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefSuggestedStoriesToggle.brief;
};

const CREATE_BRIEF_RECIPIENT_MUTATION = gql`
  mutation createBriefRecipient($briefId: ID!, $email: String!) {
    briefRecipientCreate(input: { briefId: $briefId, email: $email }) {
      briefRecipient {
        id
        emailAddress
        dateAdded
      }
    }
  }
`;

export const createBriefRecipient = async (
  briefId: number,
  email: string
): Promise<BriefRecipient> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{
    briefRecipientCreate: { briefRecipient: BriefRecipient };
  }>({
    mutation: CREATE_BRIEF_RECIPIENT_MUTATION,
    variables: {
      briefId: briefId.toString(),
      email,
    },
  });

  if (
    !response.data ||
    !response.data.briefRecipientCreate ||
    !response.data.briefRecipientCreate.briefRecipient
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefRecipientCreate.briefRecipient;
};

const DESTROY_BRIEF_RECIPIENT_MUTATION = gql`
  mutation destroyBriefRecipient($briefId: ID!, $recipientId: ID!) {
    briefRecipientDestroy(input: { briefId: $briefId, recipientId: $recipientId }) {
      deletedRecipientId
    }
  }
`;

export const destroyBriefRecipient = async (
  briefId: number,
  recipientId: number
): Promise<number> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{
    briefRecipientDestroy: { deletedRecipientId: number };
  }>({
    mutation: DESTROY_BRIEF_RECIPIENT_MUTATION,
    variables: {
      briefId: briefId.toString(),
      recipientId: recipientId.toString(),
    },
  });

  if (
    !response.data ||
    !response.data.briefRecipientDestroy ||
    !response.data.briefRecipientDestroy.deletedRecipientId
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefRecipientDestroy.deletedRecipientId;
};

const BRIEF_UNSUBSCRIBE_LINK_QUERY = gql`
  query briefUnsubscribeLink($memberId: String!, $code: String!) {
    briefUnsubscribeLink(m: $memberId, c: $code) {
      briefName
      isUnsubscribe
      m
      c
    }
  }
`;

export const getBriefUnsubscribeLink = async (
  memberId: string,
  code: string
): Promise<BriefUnsubscribeLink> => {
  const client = getGraphQLClient();

  const response = await client.query<{
    briefUnsubscribeLink: BriefUnsubscribeLink;
  }>({
    query: BRIEF_UNSUBSCRIBE_LINK_QUERY,
    variables: {
      memberId,
      code,
    },
  });

  if (!response.data || !response.data.briefUnsubscribeLink) {
    throw new EmptyResponseError();
  }

  return response.data.briefUnsubscribeLink;
};

const BRIEF_RECIPIENT_UNSUBSCRIBE_MUTATION = gql`
  mutation briefRecipientUnsubscribe($memberId: String!, $code: String!) {
    briefRecipientUnsubscribe(input: { m: $memberId, c: $code }) {
      deletedRecipientId
    }
  }
`;

export const unsubscribeBriefRecipient = async (
  memberId: string,
  code: string
): Promise<number> => {
  const client = getGraphQLClient();

  const response = await client.mutate<{
    briefRecipientUnsubscribe: { deletedRecipientId: number };
  }>({
    mutation: BRIEF_RECIPIENT_UNSUBSCRIBE_MUTATION,
    variables: {
      memberId,
      code,
    },
  });

  if (
    !response.data ||
    !response.data.briefRecipientUnsubscribe ||
    !response.data.briefRecipientUnsubscribe.deletedRecipientId
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefRecipientUnsubscribe.deletedRecipientId;
};
