import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { changeSubscription, initCheckoutFlow } from '../../redux/actions/stripe-actions';
import { AppDispatch } from '../../redux/store';
import { isInHootSuite } from '../../services/hootsuite';
import { Account } from '../../services/models/account';
import { Product } from '../../services/models/product';
import { SubscriptionInterval } from '../../services/models/subscription';
import { launchPopupWindow } from '../../services/popups';
import { launchIntercom } from '../../utils/Intercom';
import { CheckoutRoute } from '../../utils/Routes';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import PeriodicitySwitch from '../periodicity-switch/PeriodicitySwitch';
import ProductCard from '../product-card/ProductCard';

export default function Pricing() {
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();

  const account: Account = useAppSelector(state => state.session?.account);
  const currentIsBillingMonthly = account?.subscription?.interval !== SubscriptionInterval.Yearly;
  const currentProductId = account?.product?.productId;
  const email = account?.email?.emailAddress;
  const productStore = useAppSelector(state => state.productStore);

  const [isBilledMonthly, setIsBilledMonthly] = useState(currentIsBillingMonthly);
  const [planRequiringConfirmation, setPlanRequiringConfirmation] = useState('');
  const [planRequiringLoading, setPlanRequiringLoading] = useState('');

  const handleTogglePeriodicity = () => {
    if (!currentIsBillingMonthly) {
      return;
    }

    setIsBilledMonthly(!isBilledMonthly);
  };

  const openCheckout = (checkoutUrl: string) => {
    launchPopupWindow(
      checkoutUrl,
      'location=yes,scrollbars=yes,status=yes',
      { height: 700, width: 800 },
      () => {
        history.goBack();

        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    );
  };

  const handleProductPriceSelection = (planId: string, product: Product) => {
    setPlanRequiringLoading(product.productId);

    if (account?.subscription?.planId && !account?.subscription?.canceled) {
      dispatch(changeSubscription(planId));
    } else {
      const checkoutUrl = window.location.origin + CheckoutRoute + planId;

      if (isInHootSuite()) {
        openCheckout(checkoutUrl);
      } else {
        dispatch(initCheckoutFlow(account.userid, email, planId));
      }
    }
  };

  if (!!productStore.isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <div className="tw-w-full tw-mt-6 sm:tw-flex sm:tw-flex-col sm:tw-align-center">
      <h2 className="tw-text-3xl tw-font-extrabold tw-text-gray-900 sm:tw-text-center">
        Choose the plan that works for you
      </h2>

      <PeriodicitySwitch isToggled={isBilledMonthly} onChange={handleTogglePeriodicity} />

      <div className="tw-mt-4 tw-space-y-8 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-6 md:tw-max-w-4xl md:tw-mx-auto md:tw-max-w-none lg:tw-grid-cols-3">
        {productStore.products
          ?.filter(p => p.published || p.productId === currentProductId)
          .map(product => (
            <ProductCard
              key={product.productId}
              account={account}
              active={currentProductId === product.productId}
              mostPopular={currentProductId === product.productId}
              product={product}
              isBilledMonthly={isBilledMonthly}
              onSelected={handleProductPriceSelection}
              showsConfirmation={planRequiringConfirmation === product.productId}
              showsLoading={planRequiringLoading === product.productId}
              handleConfirmationChange={(confirmation: boolean) => {
                if (confirmation) {
                  setPlanRequiringConfirmation(product.productId);
                } else {
                  setPlanRequiringConfirmation('');
                }
              }}
            />
          ))}
      </div>

      <div className="tw-hidden tw-mt-8 tw-mx-auto md:tw-block">
        <div className="tw-rounded-lg tw-bg-gray-100 tw-px-6 tw-py-8 sm:tw-p-10 lg:tw-flex lg:tw-items-center">
          <div className="tw-flex-1">
            <div className="tw-mt-4 tw-text-gray-600 tw-text-lg">
              Looking for API access, bigger plans, enterprise or custom solutions?
            </div>
          </div>
          <div className="tw-mt-6 tw-rounded-md tw-shadow lg:tw-mt-0 lg:tw-ml-10 lg:tw-flex-shrink-0">
            <button
              onClick={launchIntercom}
              className="tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-gray-900 tw-bg-white hover:tw-bg-gray-50"
            >
              Contact us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
