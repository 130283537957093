import { Intent, TagInput, TagInputAddMethod } from '@blueprintjs/core';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useFormik } from 'formik';
import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { createBrief } from '../../redux/actions/briefs-actions';
import { sanitizeKeyword } from '../../services/briefs';
import { Account } from '../../services/models/account';
import { BriefKeywordType } from '../../services/models/brief-keyword-type';
import { BriefLanguage, supportedBriefLanguages } from '../../services/models/brief-language';
import Input from '../ui/Input';

interface AddBriefPanelProps {
  account: Account;
  panelOpen: boolean;
  onClosePanel: () => void;
}

export default function AddBriefPanel({ account, panelOpen, onClosePanel }: AddBriefPanelProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const productFeatures = account.product.features;
  const briefsStore = useAppSelector(state => state.briefs);

  const briefNameRef = React.useRef('');

  useEffect(() => {
    const briefs = briefsStore?.items || [];

    const recentBriefs = [...briefs].sort((r1, r2) => {
      return new Date(r2.dateAdded).getTime() - new Date(r1.dateAdded).getTime();
    });

    const newBrief = recentBriefs.find(
      x => briefNameRef.current.length > 0 && x.name === briefNameRef.current
    );

    if (newBrief) {
      onClosePanel();
      history.push(`/dashboard/brief/${newBrief.id}`);
    }
  }, [briefsStore, history, onClosePanel]);

  const handleClosePanel = () => {
    if (!briefsStore.isCreating) {
      formik.resetForm();
      onClosePanel();
    }
  };

  // TODO(Jose): Improve topic selection by preventing setting an exclusion
  const handleTopicsChange = (values: React.ReactNode[]) => {
    let keywords: string[] = values.map(k => (k as string).valueOf());
    formik.setFieldValue('topics', keywords);
  };

  const handleFiltersChange = (values: React.ReactNode[]) => {
    let keywords: string[] = values.map(k => (k as string).valueOf());
    formik.setFieldValue('filters', keywords);
  };

  const handleExclusionsChange = (values: React.ReactNode[]) => {
    let keywords: string[] = values.map(k => (k as string).valueOf());
    formik.setFieldValue('exclusions', keywords);
  };

  const handleAddNewKeyword = (values: string[], method: TagInputAddMethod) => {
    return values.length <= productFeatures.keywordsPerBrief;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      language: BriefLanguage.English,
      topics: [] as string[],
      filters: [] as string[],
      exclusions: [] as string[],
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Brief Name is required'),
      language: yup.string().required('Brief language is required'),
      topics: yup
        .array()
        .of(yup.string())
        .min(1)
        .max(productFeatures.keywordsPerBrief)
        .required('A brief topic is required'),
    }),
    onSubmit: values => {
      briefNameRef.current = values.name;

      const topicKeywords = values.topics.map(t => ({
        type: BriefKeywordType.Topic,
        exactMatch: t.startsWith('"'),
        keyword: sanitizeKeyword(t),
      }));

      const filterKeywords = values.filters.map(t => ({
        type: BriefKeywordType.Filter,
        exactMatch: t.startsWith('"'),
        keyword: sanitizeKeyword(t),
      }));

      const exclusionsKeywords = values.exclusions.map(t => ({
        type: BriefKeywordType.Exclusion,
        exactMatch: t.startsWith('"'),
        keyword: sanitizeKeyword(t),
      }));

      dispatch(
        createBrief({
          name: values.name,
          keywords: [...topicKeywords, ...filterKeywords, ...exclusionsKeywords],
          draft: false,
          language: values.language,
        })
      );
    },
  });

  return (
    <Transition.Root show={panelOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-inset-0 tw-overflow-hidden"
        onClose={handleClosePanel}
      >
        <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-in-out tw-duration-500"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in-out tw-duration-500"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-absolute tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
          </Transition.Child>

          <div className="tw-fixed tw-inset-y-0 tw-right-0 tw-pl-10 tw-max-w-full tw-flex sm:tw-pl-16">
            <Transition.Child
              as={Fragment}
              enter="tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
              enterFrom="tw-translate-x-full"
              enterTo="tw-translate-x-0"
              leave="tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
              leaveFrom="tw-translate-x-0"
              leaveTo="tw-translate-x-full"
            >
              <div className="tw-w-screen tw-max-w-2xl">
                <form className="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-overflow-y-scroll">
                  {/* Main Content */}
                  <div className="tw-flex-1">
                    {/* Header */}
                    <div className="tw-px-4 tw-py-6 tw-bg-gray-50 sm:tw-px-6">
                      <div className="tw-flex tw-items-start tw-justify-between tw-space-x-3">
                        <div className="tw-space-y-1">
                          <Dialog.Title className="tw-text-lg tw-font-medium tw-text-gray-900">
                            New brief
                          </Dialog.Title>
                          <p className="tw-text-sm tw-text-gray-500">
                            Get started by filling in the information below to create your new
                            brief.
                          </p>
                        </div>
                        <div className="tw-h-7 tw-flex tw-items-center">
                          <button
                            type="button"
                            className="tw-text-gray-400 hover:tw-text-gray-500"
                            onClick={onClosePanel}
                          >
                            <span className="tw-sr-only">Close panel</span>
                            <XIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="tw-py-6 tw-space-y-6 sm:tw-py-0 sm:tw-space-y-0 sm:tw-divide-y sm:tw-divide-gray-200">
                      {/* Brief name */}
                      <div className="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
                        <div>
                          <label
                            htmlFor="brief-name"
                            className="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
                          >
                            Brief name
                          </label>
                        </div>
                        <div className="sm:tw-col-span-2">
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            required
                            autoComplete="off"
                            error={
                              formik.touched.name && formik.errors.name
                                ? formik.errors.name
                                : undefined
                            }
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      {/* Brief Language */}
                      <div className="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
                        <div>
                          <label
                            htmlFor="brief-lang"
                            className="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
                          >
                            Language
                          </label>
                        </div>
                        <div className="sm:tw-col-span-2">
                          <select
                            id="lang"
                            name="language"
                            className="tw-mt-1 tw-block tw-w-full tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-secondary focus:tw-border-secondary sm:tw-text-sm tw-rounded-md"
                            value={formik.values.language}
                            onChange={formik.handleChange}
                          >
                            {supportedBriefLanguages.map(item => (
                              <option key={item.lang} value={item.lang}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {/* Brief Topics */}
                      <div className="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
                        <div>
                          <label
                            htmlFor="brief-topics"
                            className="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
                          >
                            Topics
                          </label>
                          <p className="tw-text-sm tw-text-gray-500">
                            Enter related keywords on the topics you are interested
                          </p>
                        </div>
                        <div className="sm:tw-col-span-2">
                          <TagInput
                            key="brief-topics"
                            fill={true}
                            placeholder="Separate topic keywords with commas..."
                            values={formik.values.topics}
                            onChange={handleTopicsChange}
                            onAdd={handleAddNewKeyword}
                            addOnBlur={true}
                            tagProps={{
                              intent: Intent.PRIMARY,
                            }}
                          />
                          <p className="tw-mt-2 tw-text-xs tw-text-gray-500">
                            [{formik.values.topics.length}/{productFeatures.keywordsPerBrief}]
                            keywords / phrase
                          </p>
                        </div>
                      </div>

                      {/* Brief Filters */}
                      <div className="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
                        <div>
                          <label
                            htmlFor="brief-filters"
                            className="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
                          >
                            Filters
                          </label>
                          <p className="tw-text-sm tw-text-gray-500">
                            Articles <strong>must</strong> include at least one of these words
                          </p>
                        </div>
                        <div className="sm:tw-col-span-2">
                          <TagInput
                            key="brief-filters"
                            fill={true}
                            placeholder="Separate filter keywords with commas..."
                            values={formik.values.filters}
                            onAdd={handleAddNewKeyword}
                            onChange={handleFiltersChange}
                            addOnBlur={true}
                          />
                          <p className="tw-mt-2 tw-text-xs tw-text-gray-500">
                            [{formik.values.filters.length}/{productFeatures.keywordsPerBrief}]
                            keywords / phrase
                          </p>
                        </div>
                      </div>

                      {/* Brief Exclusions */}
                      <div className="tw-space-y-1 tw-px-4 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
                        <div>
                          <label
                            htmlFor="brief-exclusions"
                            className="tw-block tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-mt-px sm:tw-pt-2"
                          >
                            Exclusions
                          </label>
                          <p className="tw-text-sm tw-text-gray-500">
                            Articles must <strong>not</strong> include any of these words
                          </p>
                        </div>
                        <div className="sm:tw-col-span-2">
                          <TagInput
                            key="brief-exclusions"
                            fill={true}
                            placeholder="Separate exclusion keywords with commas..."
                            values={formik.values.exclusions}
                            onAdd={handleAddNewKeyword}
                            onChange={handleExclusionsChange}
                            addOnBlur={true}
                          />
                          <p className="tw-mt-2 tw-text-xs tw-text-gray-500">
                            [{formik.values.exclusions.length}/{productFeatures.keywordsPerBrief}]
                            keywords / phrase
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="tw-flex-shrink-0 tw-px-4 tw-border-t tw-border-gray-200 tw-py-5 sm:tw-px-6">
                    <div className="tw-space-x-3 tw-flex tw-justify-end">
                      <button
                        type="button"
                        className="tw-bg-white tw-py-2 tw-px-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                        onClick={onClosePanel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary disabled:tw-opacity-50"
                        disabled={briefsStore.isCreating || !formik.isValid}
                        onClick={e => formik.handleSubmit()}
                      >
                        {briefsStore.isCreating && (
                          <svg
                            className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="tw-opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="tw-opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
