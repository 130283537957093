import * as emailManager from '../../services/email';
import { EmailUnsubscribeLink } from '../../services/models/email-unsubscribe-link';
import { asyncAction, Dispatch } from '../types';

export enum EmailUnsubscribeAction {
  StartFetchingLink = 'EmailUnsubscribeActionStartFetchingLink',
  CompleteFetchingLink = 'EmailUnsubscribeActionCompleteFetchingLink',
  CancelFetchingLink = 'EmailUnsubscribeActionCancelFetchingLink',
  StartSocialDigestUnsubscribe = 'EmailUnsubscribeActionStartSocialDigestUnsubscribe',
  CompleteSocialDigestUnsubscribe = 'EmailUnsubscribeActionCompleteSocialDigestUnsubscribe',
  CancelSocialDigestUnsubscribe = 'EmailUnsubscribeActionCancelSocialDigestUnsubscribe',
}

export const getEmailUnsubscribeLink = (verificationCode: string, emailId: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingEmailUnsubscribeLink());
    const link = await emailManager.getEmailUnsubscribeLink(verificationCode, emailId);
    dispatch(completeFetchingEmailUnsubscribeLink(link));
  }, cancelFetchingEmailUnsubscribeLink);

export const startFetchingEmailUnsubscribeLink = () => ({
  type: EmailUnsubscribeAction.StartFetchingLink,
});

export const completeFetchingEmailUnsubscribeLink = (link: EmailUnsubscribeLink) => ({
  type: EmailUnsubscribeAction.CompleteFetchingLink,
  link,
});

export const cancelFetchingEmailUnsubscribeLink = () => ({
  type: EmailUnsubscribeAction.CancelFetchingLink,
});

export const unsubscribeFromSocialDigest = (verificationCode: string, emailId: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startSocialDigestUnsubscribe());

    const success = await emailManager.unsubscribeFromSocialDigest(verificationCode, emailId);

    if (success) {
      dispatch(completeSocialDigestUnsubscribe());
    } else {
      dispatch(cancelSocialDigestUnsubscribe());
    }
  }, cancelSocialDigestUnsubscribe);

export const startSocialDigestUnsubscribe = () => ({
  type: EmailUnsubscribeAction.StartSocialDigestUnsubscribe,
});

export const completeSocialDigestUnsubscribe = () => ({
  type: EmailUnsubscribeAction.CompleteSocialDigestUnsubscribe,
});

export const cancelSocialDigestUnsubscribe = () => ({
  type: EmailUnsubscribeAction.CancelSocialDigestUnsubscribe,
});
