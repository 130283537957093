import { discardLocalSession } from '../services/authentication';
import * as honeybadger from '../services/honeybadger';
import { onUserSignOut } from '../services/user-events';
import { reportError } from './actions/errors-actions';

export interface Action {
  type: string;
}

export type Dispatch = (action: Action) => void | Promise<void>;

export type ServiceResult = { ok: boolean; message?: string };

export type ServiceDispatchAction = () => Promise<ServiceResult>;

export const serviceDispatch = async (
  action: ServiceDispatchAction
): Promise<{ ok: boolean; message?: string }> => {
  try {
    const response = await action();
    return response;
  } catch (error) {
    console.error(error);

    honeybadger.notify(error, 'serviceDispatch');

    let errorMessage = error instanceof Error ? error.message : 'Unknown Error';

    if (errorMessage.endsWith('Access denied')) {
      discardLocalSession();
      onUserSignOut();
      window.location.reload();
    }

    return {
      ok: false,
      message: errorMessage,
    };
  }
};

export const asyncAction = (
  action: (dispatch: Dispatch) => Promise<void>,
  errorActionCreator?: () => Action
) => {
  return async (dispatch: Dispatch) => {
    try {
      await action(dispatch);
    } catch (error) {
      console.error(error);

      honeybadger.notify(error, 'asyncAction');

      let errorMessage = error instanceof Error ? error.message : 'Unknown Error';

      if (errorMessage.endsWith('Access denied')) {
        discardLocalSession();
        onUserSignOut();
        window.location.reload();
      } else {
        if (error instanceof Error) dispatch(reportError(error));

        if (errorActionCreator) {
          dispatch(errorActionCreator());
        }
      }
    }
  };
};
