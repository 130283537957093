import React, { useRef, useState } from 'react';
import { timezones } from '../../services/models/timezones';
import { Modal } from '../ui';

interface UpdateTimezoneDialogProps {
  isOpen: boolean;
  timezone: string;
  onClose: () => void;
  onSave: (timezone: string) => void;
}

export default function UpdateTimezoneDialog(props: UpdateTimezoneDialogProps) {
  const [tz, setTz] = useState(props.timezone);

  const cancelButtonRef = useRef(null);

  const handleOnCancel = () => {
    setTz(props.timezone);
    props.onClose();
  };

  const handleSave = () => {
    props.onSave(tz);
    props.onClose();
  };

  const handleTimezoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTz(value);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={handleOnCancel} initialFocus={cancelButtonRef}>
      <div className="sm:flex sm:items-start">
        <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
          Update your Timezone
        </h3>
        <div className="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
          <p>Change the timezone you want associated with your account.</p>
        </div>

        <div className="tw-space-y-6 tw-mt-3">
          <div>
            <label
              htmlFor="Timezone"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
            >
              Timezone
            </label>
            <div className="tw-mt-1">
              <select
                id="Timezone"
                name="Timezone"
                className="tw-mt-1 tw-block tw-w-full tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-secondary focus:tw-border-secondary sm:tw-text-sm tw-rounded-md"
                value={tz}
                onChange={(e: any) => handleTimezoneChange(e)}
              >
                {Object.keys(timezones).map(x => (
                  <option key={x} value={x}>
                    {timezones[x]}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="tw-space-y-1">
          <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
            <button
              type="button"
              className="tw-mt-3 tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm disabled:tw-opacity-50"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              type="button"
              className="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm"
              onClick={handleOnCancel}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
