import { gql } from 'apollo-boost';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient } from './graphql-client';
import { SlackChannel } from './models/slack-channel';

const SlackAuthFlashKey = 'pre-slack-auth-state';

const ADD_TO_SLACK_LINK_QUERY = gql`
  query getAddToSlackLink {
    briefAddToSlackLink {
      addToSlackUrl
    }
  }
`;

export const getAddToSlackLink = async (briefId: number, returnRoute: string): Promise<string> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ briefAddToSlackLink: { addToSlackUrl: string } }>({
    query: ADD_TO_SLACK_LINK_QUERY,
  });

  if (!response.data || !response.data.briefAddToSlackLink) {
    throw new EmptyResponseError();
  }

  // Save the return route and Brief ID in local storage
  _flashState({ briefId, returnRoute });

  return response.data.briefAddToSlackLink.addToSlackUrl;
};

export const cancelSlackAuth = (): { briefId: number; returnRoute: string } => {
  const flashedState = _getFlashedState();
  localStorage.removeItem(SlackAuthFlashKey);
  return flashedState;
};

export const _flashState = (state: SlackAuthFlashedState) => {
  localStorage.setItem(SlackAuthFlashKey, JSON.stringify(state));
};

export const _getFlashedState = (): SlackAuthFlashedState => {
  const json = localStorage.getItem(SlackAuthFlashKey);

  if (!json) {
    throw new Error(`Coudn't restore flashed state after Slack auth.`);
  }

  const state = JSON.parse(json) as SlackAuthFlashedState;

  if (isNaN(state.briefId) || !state.returnRoute) {
    throw new Error(`Invalid restored state after Slack auth.`);
  }
  return state;
};

interface SlackAuthFlashedState {
  briefId: number;
  returnRoute: string;
}

const ADD_SLACK_CHANNEL_MUTATION = gql`
  mutation addSlackChannel($briefId: ID!, $code: String!, $state: String!) {
    briefSlackChannelCreate(input: { briefId: $briefId, code: $code, state: $state }) {
      briefSlackChannel {
        id
        dateAdded
        name
      }
    }
  }
`;

export const addSlackChannel = async (
  code: string,
  state: string
): Promise<{ briefId: number; slackChannel: SlackChannel }> => {
  const client = getAuthenticatedGraphQLClient();

  const flashedState = _getFlashedState();

  const response = await client.mutate<{
    briefSlackChannelCreate: { briefSlackChannel: SlackChannel };
  }>({
    mutation: ADD_SLACK_CHANNEL_MUTATION,
    variables: {
      briefId: flashedState.briefId.toString(),
      code,
      state,
    },
  });

  if (
    !response.data ||
    !response.data.briefSlackChannelCreate ||
    !response.data.briefSlackChannelCreate.briefSlackChannel
  ) {
    throw new EmptyResponseError();
  }

  let slackChannel = response.data.briefSlackChannelCreate.briefSlackChannel;

  return { briefId: flashedState.briefId, slackChannel };
};

const DESTROY_BRIEF_SLACK_CHANNEL_MUTATION = gql`
  mutation destroyBriefSlackChannel($briefId: ID!, $slackChannelId: ID!) {
    briefSlackChannelDestroy(input: { briefId: $briefId, slackChannelId: $slackChannelId }) {
      deletedSlackChannelId
    }
  }
`;

export const destroySlackChannel = async (
  briefId: number,
  slackChannelId: number
): Promise<number> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{
    briefSlackChannelDestroy: { deletedSlackChannelId: number };
  }>({
    mutation: DESTROY_BRIEF_SLACK_CHANNEL_MUTATION,
    variables: {
      briefId: briefId.toString(),
      slackChannelId: slackChannelId.toString(),
    },
  });

  if (
    !response.data ||
    !response.data.briefSlackChannelDestroy ||
    !response.data.briefSlackChannelDestroy.deletedSlackChannelId
  ) {
    throw new EmptyResponseError();
  }

  return response.data.briefSlackChannelDestroy.deletedSlackChannelId;
};
