import { Session } from '../../../services/models/session';
import { AccountAction } from '../../actions/account-actions';
import { AccountActivationAction } from '../../actions/account-activation-actions';
import { BriefsAction } from '../../actions/briefs-actions';
import { EmailAction } from '../../actions/email-actions';
import { SessionAction } from '../../actions/session-actions';
import { SettingsAction } from '../../actions/settings-actions';
import { StripeAction } from '../../actions/stripe-actions';
import accountReducer from './account';

export default function sessionReducer(state: Session = {} as Session, action: any): Session {
  switch (action.type) {
    case SessionAction.StartSignUp:
    case SessionAction.StartSignIn:
      return { ...state, isFetching: true };

    case SessionAction.CompleteSignIn:
    case AccountActivationAction.CompleteActivatingAccount:
      return { ...action.session, isFetching: false };

    case SessionAction.CancelSignIn:
      return { ...state, isFetching: false };

    case SessionAction.SignOut:
      return {} as any;

    case SessionAction.StartPasswordResetRequest:
      return { ...state, isRequestingPasswordReset: true };
    case SessionAction.CompletePasswordResetRequest:
      return { ...state, isRequestingPasswordReset: false, isPasswordResetRequested: true };
    case SessionAction.CancelPasswordResetRequest:
      return { ...state, isRequestingPasswordReset: false };
    case SessionAction.DiscardPasswordResetRequestNotification:
      return { ...state, isPasswordResetRequested: false };
    case SessionAction.StartPasswordReset:
      return { ...state, isResettingPassword: true };
    case SessionAction.CompletePasswordReset:
      return { ...state, isResettingPassword: false, isPasswordResetConfirmed: true };
    case SessionAction.CancelPasswordReset:
      return { ...state, isResettingPassword: false };
    case SessionAction.DiscardPasswordResetNotification:
      return { ...state, isPasswordResetConfirmed: false };

    case SessionAction.StartCreatingRefreshToken:
      return { ...state, isCreatingRefreshToken: true };
    case SessionAction.CompleteCreatingRefreshToken:
      return {
        ...state,
        isCreatingRefreshToken: false,
        refreshToken: action.refreshToken,
      };
    case SessionAction.CancelCreatingRefreshToken:
      return { ...state, isCreatingRefreshToken: false };

    case AccountAction.StartFetching:
      return { ...state, isFetching: true };
    case AccountAction.CancelFetching:
      return { ...state, isFetching: false };
    case AccountAction.CompleteFetching:
      return {
        ...state,
        isFetching: false,
        account: accountReducer(state.account, action),
      };

    case EmailAction.StartUpdating:
    case EmailAction.CompleteUpdating:
    case EmailAction.CancelUpdating:
    case EmailAction.StartSendingVerification:
    case EmailAction.CompleteSendingVerification:
    case EmailAction.CancelSendingVerification:
    case EmailAction.StartVerifying:
    case EmailAction.CompleteVerifying:
    case EmailAction.CancelVerifying:
    case AccountAction.StartUpdatingName:
    case AccountAction.CompleteUpdatingName:
    case AccountAction.CancelUpdatingName:
    case AccountAction.StartCancelingSubscription:
    case AccountAction.CompleteCancelingSubscription:
    case AccountAction.CancelCancelingSubscription:
    case AccountAction.StartDeactivatingAccount:
    case AccountAction.CompleteDeactivatingAccount:
    case AccountAction.CancelDeactivatingAccount:
    case AccountAction.StartGeneratingZapierApiKey:
    case AccountAction.CompleteGeneratingZapierApiKey:
    case AccountAction.CancelGeneratingZapierApiKey:
    case SettingsAction.StartUpdating:
    case SettingsAction.CancelUpdating:
    case SettingsAction.CompleteUpdating:
    case SettingsAction.StartUpdatingTimeZone:
    case SettingsAction.CancelUpdatingTimeZone:
    case SettingsAction.CompleteUpdatingTimeZone:
    case StripeAction.StartCheckoutFlow:
    case StripeAction.CompleteCheckoutFlow:
    case StripeAction.CancelCheckoutFlow:
    case StripeAction.StartChangingSubscription:
    case StripeAction.CompleteChangingSubscription:
    case StripeAction.CancelChangingSubscription:
    case BriefsAction.CompleteCreating:
    case BriefsAction.CompleteDestroying:
      return {
        ...state,
        account: accountReducer(state.account, action),
      };

    default:
      return state;
  }
}
