// @ts-ignore
import { EventTypes } from 'redux-segment';
import * as account from '../../services/account';
import { Account } from '../../services/models/account';
import { ApiApp } from '../../services/models/api-app';
import { Subscription } from '../../services/models/subscription';
import { asyncAction, Dispatch } from '../types';

export enum AccountAction {
  StartFetching = 'AccountActionStartFetching',
  CompleteFetching = 'AccountActionCompleteFetching',
  CancelFetching = 'AccountActionCancelFetching',
  StartUpdatingName = 'AccountActionStartUpdatingName',
  CompleteUpdatingName = 'AccountActionCompleteUpdatingName',
  CancelUpdatingName = 'AccountActionCancelUpdatingName',
  StartCancelingSubscription = 'AccountActionStartCancelingSubscription',
  CompleteCancelingSubscription = 'AccountActionCompleteCancelingSubscription',
  CancelCancelingSubscription = 'AccountActionCompleteCancelCancelingSubscription',
  StartDeactivatingAccount = 'AccountActionStartDeactivatingAccount',
  CompleteDeactivatingAccount = 'AccountActionCompleteDeactivatingAccount',
  CancelDeactivatingAccount = 'AccountActionCancelDeactivatingAccount',
  StartGeneratingZapierApiKey = 'AccountActionStartGeneratingZapierApiKey',
  CompleteGeneratingZapierApiKey = 'AccountActionCompleteGeneratingZapierApiKey',
  CancelGeneratingZapierApiKey = 'AccountActionCancelStartGeneratingZapierApiKey',
}

export const requestCurrentAccount = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingCurrentAccount());
    const currentAccount = await account.getCurrentAccount();
    dispatch(completeFetchingCurrentAccount(currentAccount));
  }, cancelFetchingCurrentAccount);

export const startFetchingCurrentAccount = () => ({
  type: AccountAction.StartFetching,
});

export const completeFetchingCurrentAccount = (currentAccount: Account) => ({
  type: AccountAction.CompleteFetching,
  currentAccount,
});

export const cancelFetchingCurrentAccount = () => ({
  type: AccountAction.CancelFetching,
});

export const updateName = (firstName: string, lastName: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startUpdatingName());
    const currentAccount = await account.updateName(firstName, lastName);
    dispatch(completeUpdatingName(currentAccount));
  }, cancelUpdatingName);

export const startUpdatingName = () => ({
  type: AccountAction.StartUpdatingName,
});

export const completeUpdatingName = (currentAccount: Account) => ({
  type: AccountAction.CompleteUpdatingName,
  account: currentAccount,
  meta: {
    analytics: {
      eventType: EventTypes.identify,
      eventPayload: {
        userId: currentAccount.userid,
        traits: {
          firstName: currentAccount.firstName,
          lastName: currentAccount.lastName,
        },
      },
    },
  },
});

export const cancelUpdatingName = () => ({
  type: AccountAction.CancelUpdatingName,
});

export const cancelSubscription = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startCancelingSubscription());
    const subscription = await account.cancelSubscription();
    dispatch(completeCancelingSubscription(subscription));
  }, cancelCancelingSubscription);

export const startCancelingSubscription = () => ({
  type: AccountAction.StartCancelingSubscription,
});

export const completeCancelingSubscription = (subscription: Subscription) => ({
  type: AccountAction.CompleteCancelingSubscription,
  subscription,
});

export const cancelCancelingSubscription = () => ({
  type: AccountAction.CancelCancelingSubscription,
});

export const deactivateAccount = (reason: string, comment: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startDeactivationAccount());
    const success = await account.deactivateAccount(reason, comment);
    if (success) {
      dispatch(completeDeactivationAccount());
    } else {
      dispatch(cancelDeactivationAccount());
    }
  }, cancelDeactivationAccount);

export const startDeactivationAccount = () => ({
  type: AccountAction.StartDeactivatingAccount,
});

export const completeDeactivationAccount = () => ({
  type: AccountAction.CompleteDeactivatingAccount,
});

export const cancelDeactivationAccount = () => ({
  type: AccountAction.CancelDeactivatingAccount,
});

export const generateZapierApiKey = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startGeneratingZapierApiKey());
    const apiApp = await account.generateZapierApiKey();
    dispatch(completeGeneratingZapierApiKey(apiApp));
  }, cancelGeneratingZapierApiKey);

export const startGeneratingZapierApiKey = () => ({
  type: AccountAction.StartGeneratingZapierApiKey,
});

export const completeGeneratingZapierApiKey = (apiApp: ApiApp) => ({
  type: AccountAction.CompleteGeneratingZapierApiKey,
  apiApp,
});

export const cancelGeneratingZapierApiKey = () => ({
  type: AccountAction.CancelGeneratingZapierApiKey,
});
