import * as briefsManager from '../../services/briefs';
import { Brief } from '../../services/models/brief';
import { BriefKeyword } from '../../services/models/brief-keyword';
import { BriefKeywordType } from '../../services/models/brief-keyword-type';
import { BriefLanguage } from '../../services/models/brief-language';
import { BriefRecipient } from '../../services/models/brief-recipient';
import { DayOfWeek } from '../../services/models/day-of-week';
import { SlackChannel } from '../../services/models/slack-channel';
import * as slackManager from '../../services/slack';
import { asyncAction, Dispatch } from '../types';

export enum BriefSettingsAction {
  // Language Update
  StartUpdatingLanguage = 'BriefSettingsActions/StartUpdatingLanguage',
  CompleteUpdatingLanguage = 'BriefSettingsActions/CompleteUpdatingLanguage',
  CancelUpdatingLanguage = 'BriefSettingsActions/CancelUpdatingLanguage',

  // Keywords update actions
  StartUpdatingKeywords = 'BriefSettingsActions/StartUpdatingKeywords',
  CompleteCreatingKeyword = 'BriefSettingsActions/CompleteCreatingKeyword',
  CancelCreatingKeyword = 'BriefSettingsActions/CancelCreatingKeyword',
  CompleteDestroyingKeyword = 'BriefSettingsActions/CompleteDestroyingKeyword',
  CancelDestroyingKeyword = 'BriefSettingsActions/CancelDestroyingKeyword',

  // Schedule Actions
  StartUpdatingSchedule = 'BriefSettingsActions/StartUpdatingSchedule',
  CompleteUpdatingSchedule = 'BriefSettingsActions/CompleteUpdatingSchedule',
  CancelUpdatingSchedule = 'BriefSettingsActions/CancelUpdatingSchedule',

  // Suggested Stories
  StartUpdatingSuggestedStories = 'BriefSettingsActions/StartUpdatingSuggestedStories',
  CompleteUpdatingSuggestedStories = 'BriefSettingsActions/CompleteUpdatingSuggestedStories',
  CancelUpdatingSuggestedStories = 'BriefSettingsActions/CancelUpdatingSuggestedStories',

  // Recipient Actions
  StartCreatingRecipient = 'BriefSettingsActions/StartCreatingRecipient',
  CompleteCreatingRecipient = 'BriefSettingsActions/CompleteCreatingRecipient',
  CancelCreatingRecipient = 'BriefSettingsActions/CancelCreatingRecipient',
  StartDestroyingRecipient = 'BriefSettingsActions/StartDestroyingRecipient',
  CompleteDestroyingRecipient = 'BriefSettingsActions/CompleteDestroyingRecipient',
  CancelDestroyingRecipient = 'BriefSettingsActions/CancelDestroyingRecipient',

  // Slack Channel Actions
  StartAddingSlackChannel = 'BriefSettingsActions/StartAddingSlackChannel',
  CompleteAddingSlackChannel = 'BriefSettingsActions/CompleteAddingSlackChannel',
  CancelAddingSlackChannel = 'BriefSettingsActions/CancelAddingSlackChannel',
  StartRemovingSlackChannel = 'BriefSettingsActions/StartRemovingSlackChannel',
  CompleteRemovingSlackChannel = 'BriefSettingsActions/CompleteRemovingSlackChannel',
  CancelRemovingSlackChannel = 'BriefSettingsActions/CancelRemovingSlackChannel',
}

export const updateLanguage = (briefId: number, language: BriefLanguage) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startUpdatingLanguage(briefId));
      const report = await briefsManager.updateLanguage(briefId, language);
      dispatch(completeUpdatingLanguage(briefId, report));
    },
    () => cancelUpdatingLanguage(briefId)
  );

export const startUpdatingLanguage = (briefId: number) => ({
  type: BriefSettingsAction.StartUpdatingLanguage,
  briefId,
});

export const completeUpdatingLanguage = (briefId: number, brief: Brief) => ({
  type: BriefSettingsAction.CompleteUpdatingLanguage,
  briefId,
  brief,
});

export const cancelUpdatingLanguage = (briefId: number) => ({
  type: BriefSettingsAction.CancelUpdatingLanguage,
  briefId,
});

export const startUpdatingKeywords = (briefId: number) => ({
  type: BriefSettingsAction.StartUpdatingKeywords,
  briefId,
});

export const createBriefKeyword = (
  briefId: number,
  keyword: string,
  keywordType: BriefKeywordType
) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startUpdatingKeywords(briefId));

      const briefKeyword = await briefsManager.createBriefKeyword(briefId, keyword, keywordType);

      dispatch(completeCreatingKeyword(briefId, briefKeyword));
    },
    () => cancelCreatingKeyword(briefId)
  );

export const completeCreatingKeyword = (briefId: number, keyword: BriefKeyword) => ({
  type: BriefSettingsAction.CompleteCreatingKeyword,
  briefId,
  keyword,
});

export const cancelCreatingKeyword = (briefId: number) => ({
  type: BriefSettingsAction.CancelCreatingKeyword,
  briefId,
});

export const destroyBriefKeyword = (briefId: number, briefKeywordId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startUpdatingKeywords(briefId));

      const updatedKeywordId = await briefsManager.destroyBriefKeyword(briefId, briefKeywordId);

      updatedKeywordId
        ? dispatch(completeDestroyingKeyword(briefId, briefKeywordId))
        : dispatch(cancelDestroyingKeyword(briefId, briefKeywordId));
    },
    () => cancelDestroyingKeyword(briefId, briefKeywordId)
  );

export const completeDestroyingKeyword = (briefId: number, keywordId: number) => ({
  type: BriefSettingsAction.CompleteDestroyingKeyword,
  briefId,
  keywordId,
});

export const cancelDestroyingKeyword = (briefId: number, keywordId: number) => ({
  type: BriefSettingsAction.CancelDestroyingKeyword,
  briefId,
  keywordId,
});

export const updateSchedule = (briefId: number, hour: number, days: DayOfWeek[]) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startUpdatingSchedule(briefId));
      const updatedBrief = await briefsManager.updateBriefSchedule(briefId, days, hour);
      dispatch(completeUpdatingSchedule(briefId, updatedBrief));
    },
    () => cancelUpdatingSchedule(briefId)
  );

export const startUpdatingSchedule = (briefId: number) => ({
  type: BriefSettingsAction.StartUpdatingSchedule,
  briefId,
});

export const completeUpdatingSchedule = (briefId: number, brief: Brief) => ({
  type: BriefSettingsAction.CompleteUpdatingSchedule,
  briefId,
  brief,
});

export const cancelUpdatingSchedule = (briefId: number) => ({
  type: BriefSettingsAction.CancelUpdatingSchedule,
  briefId,
});

// TODO(Jose): Needs to be exposed within the Edit Panel UI
export const toggleSuggestedStories = (briefId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startUpdatingSuggestedStories(briefId));
      const updatedBrief = await briefsManager.toggleBriefSuggestedStories(briefId);
      dispatch(completeUpdatingSuggestedStories(briefId, updatedBrief));
    },
    () => cancelUpdatingSuggestedStories(briefId)
  );

export const startUpdatingSuggestedStories = (briefId: number) => ({
  type: BriefSettingsAction.StartUpdatingSuggestedStories,
  briefId,
});

export const completeUpdatingSuggestedStories = (briefId: number, brief: Brief) => ({
  type: BriefSettingsAction.CompleteUpdatingSuggestedStories,
  briefId,
  brief,
});

export const cancelUpdatingSuggestedStories = (briefId: number) => ({
  type: BriefSettingsAction.CancelUpdatingSuggestedStories,
  briefId,
});

export const createRecipient = (briefId: number, email: string) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startCreatingRecipient(briefId));
      const newRecipient = await briefsManager.createBriefRecipient(briefId, email);
      dispatch(completeCreatingRecipient(briefId, newRecipient));
    },
    () => cancelCreatingRecipient(briefId)
  );

export const startCreatingRecipient = (briefId: number) => ({
  type: BriefSettingsAction.StartCreatingRecipient,
  briefId,
});

export const completeCreatingRecipient = (briefId: number, recipient: BriefRecipient) => ({
  type: BriefSettingsAction.CompleteCreatingRecipient,
  briefId,
  recipient,
});

export const cancelCreatingRecipient = (briefId: number) => ({
  type: BriefSettingsAction.CancelCreatingRecipient,
  briefId,
});

export const destroyRecipient = (briefId: number, recipientId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startDestroyingRecipient(briefId));
      const destroyedRecipientId = await briefsManager.destroyBriefRecipient(briefId, recipientId);
      destroyedRecipientId
        ? dispatch(completeDestroyingRecipient(briefId, recipientId))
        : dispatch(cancelDestroyingRecipient(briefId));
    },
    () => cancelDestroyingRecipient(briefId)
  );

export const startDestroyingRecipient = (briefId: number) => ({
  type: BriefSettingsAction.StartDestroyingRecipient,
  briefId,
});

export const completeDestroyingRecipient = (briefId: number, recipientId: number) => ({
  type: BriefSettingsAction.CompleteDestroyingRecipient,
  briefId,
  recipientId,
});

export const cancelDestroyingRecipient = (briefId: number) => ({
  type: BriefSettingsAction.CancelDestroyingRecipient,
  briefId,
});

export const addSlackChannel = (code: string, state: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startAddingSlackChannel());
    const result = await slackManager.addSlackChannel(code, state);
    dispatch(completeAddingSlackChannel(result.briefId, result.slackChannel));
  }, cancelAddingSlackChannel);

export const startAddingSlackChannel = () => ({
  type: BriefSettingsAction.StartAddingSlackChannel,
});

export const cancelAddingSlackChannel = () => ({
  type: BriefSettingsAction.CancelAddingSlackChannel,
});

export const completeAddingSlackChannel = (briefId: number, slackChannel: SlackChannel) => ({
  type: BriefSettingsAction.CompleteAddingSlackChannel,
  briefId,
  slackChannel,
});

export const removeSlackChannel = (briefId: number, slackChannelId: number) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startRemovingSlackChannel(briefId));

    const removedSlackChannelId = await slackManager.destroySlackChannel(briefId, slackChannelId);

    removedSlackChannelId
      ? dispatch(completeRemovingSlackChannel(briefId, slackChannelId))
      : dispatch(cancelRemovingSlackChannel(briefId));
  });

export const startRemovingSlackChannel = (briefId: number) => ({
  type: BriefSettingsAction.StartRemovingSlackChannel,
  briefId,
});

export const cancelRemovingSlackChannel = (briefId: number) => ({
  type: BriefSettingsAction.CancelRemovingSlackChannel,
  briefId,
});

export const completeRemovingSlackChannel = (briefId: number, slackChannelId: number) => ({
  type: BriefSettingsAction.CompleteRemovingSlackChannel,
  briefId,
  slackChannelId,
});
