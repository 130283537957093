import React from 'react';
import { Redirect } from 'react-router-dom';
import { discardLocalSession } from '../../../services/authentication';
import { NotAuthenticatedError } from '../../../services/errors/not-authenticated-error';
import { onUserSignOut } from '../../../services/user-events';
import { ErrorIdentifier } from './error-identifier';

export const notAuthenticatedErrorIdentifier: ErrorIdentifier = (index: number, error: any) => {
  if (error.name !== NotAuthenticatedError.name) {
    return null;
  }

  discardLocalSession();
  onUserSignOut();

  return <Redirect key={index} to="/users/signin" />;
};
