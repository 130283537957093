// @ts-ignore
import { EventTypes } from 'redux-segment';
import * as accountService from '../../services/account';
import * as auth from '../../services/authentication';
import { getSession } from '../../services/authentication';
import { Session } from '../../services/models/session';
import { SignUpRequest } from '../../services/models/signup-request';
import * as plausible from '../../services/plausible';
import { minutesBetween } from '../../utils/date';
import { asyncAction, Dispatch } from '../types';

export enum SessionAction {
  StartSignIn = 'SessionActionStartSignIn',
  StartSignUp = 'SessionActionStartSignUp',
  CompleteSignIn = 'SessionActionCompleteSignIn',
  CancelSignIn = 'SessionActionCancelSignIn',
  SignOut = 'SessionActionSignOut',
  StartPasswordResetRequest = 'SessionActionStartPasswordResetRequest',
  CompletePasswordResetRequest = 'SessionActionCompletePasswordResetRequest',
  CancelPasswordResetRequest = 'SessionActionCancelPasswordResetRequest',
  DiscardPasswordResetRequestNotification = 'SessionActionDiscardPasswordResetRequestNotification',
  StartPasswordReset = 'SessionActionStartPasswordReset',
  CompletePasswordReset = 'SessionActionCompletePasswordReset',
  CancelPasswordReset = 'SessionActionCancelPasswordReset',
  DiscardPasswordResetNotification = 'SessionActionDiscardPasswordResetNotification',
  StartCreatingRefreshToken = 'SessionActionStartCreatingRefreshToken',
  CompleteCreatingRefreshToken = 'SessionActionCompleteCreatingRefreshToken',
  CancelCreatingRefreshToken = 'SessionActionCancelCreatingRefreshToken',
}

export const requestSignIn = (email: string, password: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startSignIn());
    const session = await auth.signIn(email, password);
    plausible.trackEvent('Signed In', { mode: 'Email' });
    dispatch(completeSignIn(session));
  }, cancelSignIn);

export const signInWithTwitter = (oauthToken: string, oauthVerifier: string, hasSession: boolean) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startSignIn());
    const session = await auth.signInWithTwitter(oauthToken, oauthVerifier, hasSession);
    dispatch(completeTwitterSignIn(session));
  }, cancelSignIn);

export const finalizeSignInWithTwitter = () =>
  asyncAction(async (dispatch: Dispatch) => {
    const session = getSession() || ({} as Session);

    if (session && session.account && session.token) {
      const memberSinceInMinutes = minutesBetween(
        new Date(),
        new Date(session.account.memberSince)
      );

      plausible.trackEvent(memberSinceInMinutes < 5 ? 'Signed Up' : 'Signed In', {
        mode: 'Twitter',
      });

      dispatch(completeSignIn(session, memberSinceInMinutes));
    }
  }, cancelSignIn);

export const verifyLogin = (token: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startSignIn());
    const session = await auth.loginVerification(token);
    dispatch(completeSignIn(session));
  }, cancelSignIn);

export const startSignIn = () => ({
  type: SessionAction.StartSignIn,
});

export const startSignUp = () => ({
  type: SessionAction.StartSignUp,
});

export const completeSignIn = (session: Session, memberSinceMinutes?: number) => ({
  type: SessionAction.CompleteSignIn,
  session,
  meta: {
    analytics: [
      {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: session.account.userid,
        },
      },
      {
        eventType: EventTypes.track,
        eventPayload: {
          event:
            typeof memberSinceMinutes !== 'undefined' && memberSinceMinutes < 5
              ? 'User Signed Up'
              : 'User Signed In',
        },
      },
    ],
  },
});

export const completeTwitterSignIn = (session: Session) => ({
  type: SessionAction.CompleteSignIn,
  session,
});

export const cancelSignIn = () => ({
  type: SessionAction.CancelSignIn,
});

export const signOut = () =>
  asyncAction(async (dispatch: Dispatch) => {
    await auth.signOut();
    dispatch(completeSignOut());
  });

export const completeSignOut = () => ({
  type: SessionAction.SignOut,
  meta: {
    analytics: [
      {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'User Signed Out',
        },
      },
      {
        eventType: EventTypes.reset,
      },
    ],
  },
});

export const requestSignUp = (signUpRequest: SignUpRequest) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startSignUp());
    const session = await auth.signUp(signUpRequest);
    plausible.trackEvent('Signed Up', { mode: 'Email' });
    dispatch(completeSignIn(session));
  }, cancelSignIn);

export const requestPasswordReset = (email: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startPasswordResetRequest());
    const success = await auth.requestPasswordReset(email);
    if (success) {
      dispatch(completePasswordResetRequest());
    } else {
      dispatch(cancelPasswordResetRequest());
    }
  }, cancelPasswordResetRequest);

export const startPasswordResetRequest = () => ({
  type: SessionAction.StartPasswordResetRequest,
});

export const completePasswordResetRequest = () => ({
  type: SessionAction.CompletePasswordResetRequest,
});

export const cancelPasswordResetRequest = () => ({
  type: SessionAction.CancelPasswordResetRequest,
});

export const discardPasswordResetRequestNotification = () => ({
  type: SessionAction.DiscardPasswordResetRequestNotification,
});

export const resetPassword = (code: string, password: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startPasswordReset());
    const success = await auth.resetPassword(code, password);
    if (success) {
      dispatch(completePasswordReset());
    } else {
      dispatch(cancelPasswordReset());
    }
  }, cancelPasswordReset);

export const startPasswordReset = () => ({
  type: SessionAction.StartPasswordReset,
});

export const completePasswordReset = () => ({
  type: SessionAction.CompletePasswordReset,
});

export const cancelPasswordReset = () => ({
  type: SessionAction.CancelPasswordReset,
});

export const discardPasswordResetNotification = () => ({
  type: SessionAction.DiscardPasswordResetNotification,
});

export const createRefreshToken = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startCreatingRefreshToken());
    const refreshToken = await accountService.createRefreshToken();
    dispatch(completeCreatingRefreshToken(refreshToken));
  }, cancelCreatingRefreshToken);

export const startCreatingRefreshToken = () => ({
  type: SessionAction.StartCreatingRefreshToken,
});

export const completeCreatingRefreshToken = (refreshToken: String) => ({
  type: SessionAction.CompleteCreatingRefreshToken,
  refreshToken,
});

export const cancelCreatingRefreshToken = () => ({
  type: SessionAction.CancelCreatingRefreshToken,
});
