export const timezones: { [key: string]: string } = {
  'Pacific/Honolulu': '(GMT-10:00) Hawaii',
  'America/Anchorage': '(GMT-8:00) Alaska',
  'America/Los_Angeles': '(GMT-7:00) Pacific Time (US & Canada)',
  'America/Phoenix': '(GMT-7:00) Arizona',
  'America/Denver': '(GMT-6:00) Mountain Time (US & Canada)',
  'America/Chicago': '(GMT-5:00) Central Time (US & Canada)',
  'America/Indiana/Indianapolis': '(GMT-4:00) Indiana (East)',
  'America/New_York': '(GMT-4:00) Eastern Time (US & Canada)',
  'America/Halifax': '(GMT-3:00) Atlantic Time (Canada)',
  'Pacific/Midway': '(GMT-11:00) Midway',
  'Pacific/Niue': '(GMT-11:00) Niue',
  'Pacific/Pago_Pago': '(GMT-11:00) Pago Pago',
  'Pacific/Rarotonga': '(GMT-10:00) Rarotonga',
  'Pacific/Tahiti': '(GMT-10:00) Tahiti',
  'Pacific/Marquesas': '(GMT-9:30) Marquesas',
  'America/Adak': '(GMT-9:00) Adak',
  'Pacific/Gambier': '(GMT-9:00) Gambier',
  'America/Juneau': '(GMT-8:00) Juneau',
  'America/Metlakatla': '(GMT-8:00) Metlakatla',
  'America/Nome': '(GMT-8:00) Nome',
  'America/Sitka': '(GMT-8:00) Sitka',
  'America/Yakutat': '(GMT-8:00) Yakutat',
  'Pacific/Pitcairn': '(GMT-8:00) Pitcairn',
  'America/Creston': '(GMT-7:00) Creston',
  'America/Dawson': '(GMT-7:00) Dawson',
  'America/Dawson_Creek': '(GMT-7:00) Dawson Creek',
  'America/Fort_Nelson': '(GMT-7:00) Fort Nelson',
  'America/Hermosillo': '(GMT-7:00) Hermosillo',
  'America/Tijuana': '(GMT-7:00) Tijuana',
  'America/Vancouver': '(GMT-7:00) Vancouver',
  'America/Whitehorse': '(GMT-7:00) Whitehorse',
  'America/Belize': '(GMT-6:00) Belize',
  'America/Boise': '(GMT-6:00) Boise',
  'America/Cambridge_Bay': '(GMT-6:00) Cambridge Bay',
  'America/Chihuahua': '(GMT-6:00) Chihuahua',
  'America/Costa_Rica': '(GMT-6:00) Costa Rica',
  'America/Edmonton': '(GMT-6:00) Edmonton',
  'America/El_Salvador': '(GMT-6:00) El Salvador',
  'America/Guatemala': '(GMT-6:00) Guatemala',
  'America/Inuvik': '(GMT-6:00) Inuvik',
  'America/Managua': '(GMT-6:00) Managua',
  'America/Mazatlan': '(GMT-6:00) Mazatlan',
  'America/Ojinaga': '(GMT-6:00) Ojinaga',
  'America/Regina': '(GMT-6:00) Regina',
  'America/Swift_Current': '(GMT-6:00) Swift Current',
  'America/Tegucigalpa': '(GMT-6:00) Tegucigalpa',
  'America/Yellowknife': '(GMT-6:00) Yellowknife',
  'Pacific/Galapagos': '(GMT-6:00) Galapagos',
  'America/Atikokan': '(GMT-5:00) Atikokan',
  'America/Bahia_Banderas': '(GMT-5:00) Bahia Banderas',
  'America/Bogota': '(GMT-5:00) Bogota',
  'America/Cancun': '(GMT-5:00) Cancun',
  'America/Cayman': '(GMT-5:00) Cayman',
  'America/Eirunepe': '(GMT-5:00) Eirunepe',
  'America/Guayaquil': '(GMT-5:00) Guayaquil',
  'America/Jamaica': '(GMT-5:00) Jamaica',
  'America/Lima': '(GMT-5:00) Lima',
  'America/Matamoros': '(GMT-5:00) Matamoros',
  'America/Menominee': '(GMT-5:00) Menominee',
  'America/Merida': '(GMT-5:00) Merida',
  'America/Mexico_City': '(GMT-5:00) Mexico City',
  'America/Monterrey': '(GMT-5:00) Monterrey',
  'America/Panama': '(GMT-5:00) Panama',
  'America/Rainy_River': '(GMT-5:00) Rainy River',
  'America/Rankin_Inlet': '(GMT-5:00) Rankin Inlet',
  'America/Resolute': '(GMT-5:00) Resolute',
  'America/Rio_Branco': '(GMT-5:00) Rio Branco',
  'America/Winnipeg': '(GMT-5:00) Winnipeg',
  'Pacific/Easter': '(GMT-5:00) Easter',
  'America/Anguilla': '(GMT-4:00) Anguilla',
  'America/Antigua': '(GMT-4:00) Antigua',
  'America/Aruba': '(GMT-4:00) Aruba',
  'America/Asuncion': '(GMT-4:00) Asuncion',
  'America/Barbados': '(GMT-4:00) Barbados',
  'America/Blanc-Sablon': '(GMT-4:00) Blanc-Sablon',
  'America/Boa_Vista': '(GMT-4:00) Boa Vista',
  'America/Campo_Grande': '(GMT-4:00) Campo Grande',
  'America/Caracas': '(GMT-4:00) Caracas',
  'America/Cuiaba': '(GMT-4:00) Cuiaba',
  'America/Curacao': '(GMT-4:00) Curacao',
  'America/Detroit': '(GMT-4:00) Detroit',
  'America/Dominica': '(GMT-4:00) Dominica',
  'America/Grand_Turk': '(GMT-4:00) Grand Turk',
  'America/Grenada': '(GMT-4:00) Grenada',
  'America/Guadeloupe': '(GMT-4:00) Guadeloupe',
  'America/Guyana': '(GMT-4:00) Guyana',
  'America/Havana': '(GMT-4:00) Havana',
  'America/Iqaluit': '(GMT-4:00) Iqaluit',
  'America/Kralendijk': '(GMT-4:00) Kralendijk',
  'America/La_Paz': '(GMT-4:00) La Paz',
  'America/Lower_Princes': '(GMT-4:00) Lower Princes',
  'America/Manaus': '(GMT-4:00) Manaus',
  'America/Marigot': '(GMT-4:00) Marigot',
  'America/Martinique': '(GMT-4:00) Martinique',
  'America/Montserrat': '(GMT-4:00) Montserrat',
  'America/Nassau': '(GMT-4:00) Nassau',
  'America/Nipigon': '(GMT-4:00) Nipigon',
  'America/Pangnirtung': '(GMT-4:00) Pangnirtung',
  'America/Port-au-Prince': '(GMT-4:00) Port-au-Prince',
  'America/Port_of_Spain': '(GMT-4:00) Port of Spain',
  'America/Porto_Velho': '(GMT-4:00) Porto Velho',
  'America/Puerto_Rico': '(GMT-4:00) Puerto Rico',
  'America/Santo_Domingo': '(GMT-4:00) Santo Domingo',
  'America/St_Barthelemy': '(GMT-4:00) St Barthelemy',
  'America/St_Kitts': '(GMT-4:00) St Kitts',
  'America/St_Lucia': '(GMT-4:00) St Lucia',
  'America/St_Thomas': '(GMT-4:00) St Thomas',
  'America/St_Vincent': '(GMT-4:00) St Vincent',
  'America/Thunder_Bay': '(GMT-4:00) Thunder Bay',
  'America/Toronto': '(GMT-4:00) Toronto',
  'America/Tortola': '(GMT-4:00) Tortola',
  'America/Araguaina': '(GMT-3:00) Araguaina',
  'America/Bahia': '(GMT-3:00) Bahia',
  'America/Belem': '(GMT-3:00) Belem',
  'America/Cayenne': '(GMT-3:00) Cayenne',
  'America/Fortaleza': '(GMT-3:00) Fortaleza',
  'America/Glace_Bay': '(GMT-3:00) Glace Bay',
  'America/Goose_Bay': '(GMT-3:00) Goose Bay',
  'America/Maceio': '(GMT-3:00) Maceio',
  'America/Moncton': '(GMT-3:00) Moncton',
  'America/Montevideo': '(GMT-3:00) Montevideo',
  'America/Paramaribo': '(GMT-3:00) Paramaribo',
  'America/Punta_Arenas': '(GMT-3:00) Punta Arenas',
  'America/Recife': '(GMT-3:00) Recife',
  'America/Santarem': '(GMT-3:00) Santarem',
  'America/Santiago': '(GMT-3:00) Santiago',
  'America/Sao_Paulo': '(GMT-3:00) Sao Paulo',
  'America/Thule': '(GMT-3:00) Thule',
  'Antarctica/Palmer': '(GMT-3:00) Palmer',
  'Antarctica/Rothera': '(GMT-3:00) Rothera',
  'Atlantic/Bermuda': '(GMT-3:00) Bermuda',
  'Atlantic/Stanley': '(GMT-3:00) Stanley',
  'America/St_Johns': '(GMT-2:30) St Johns',
  'America/Godthab': '(GMT-2:00) Godthab',
  'America/Miquelon': '(GMT-2:00) Miquelon',
  'America/Noronha': '(GMT-2:00) Noronha',
  'Atlantic/South_Georgia': '(GMT-2:00) South Georgia',
  'Atlantic/Cape_Verde': '(GMT-1:00) Cape Verde',
  'Africa/Abidjan': '(GMT) Abidjan',
  'Africa/Accra': '(GMT) Accra',
  'Africa/Bamako': '(GMT) Bamako',
  'Africa/Banjul': '(GMT) Banjul',
  'Africa/Bissau': '(GMT) Bissau',
  'Africa/Conakry': '(GMT) Conakry',
  'Africa/Dakar': '(GMT) Dakar',
  'Africa/Freetown': '(GMT) Freetown',
  'Africa/Lome': '(GMT) Lome',
  'Africa/Monrovia': '(GMT) Monrovia',
  'Africa/Nouakchott': '(GMT) Nouakchott',
  'Africa/Ouagadougou': '(GMT) Ouagadougou',
  'Africa/Sao_Tome': '(GMT) Sao Tome',
  'America/Danmarkshavn': '(GMT) Danmarkshavn',
  'America/Scoresbysund': '(GMT) Scoresbysund',
  'Atlantic/Azores': '(GMT) Azores',
  'Atlantic/Reykjavik': '(GMT) Reykjavik',
  'Atlantic/St_Helena': '(GMT) St Helena',
  'Africa/Algiers': '(GMT+1:00) Algiers',
  'Africa/Bangui': '(GMT+1:00) Bangui',
  'Africa/Brazzaville': '(GMT+1:00) Brazzaville',
  'Africa/Casablanca': '(GMT+1:00) Casablanca',
  'Africa/Douala': '(GMT+1:00) Douala',
  'Africa/El_Aaiun': '(GMT+1:00) El Aaiun',
  'Africa/Kinshasa': '(GMT+1:00) Kinshasa',
  'Africa/Lagos': '(GMT+1:00) Lagos',
  'Africa/Libreville': '(GMT+1:00) Libreville',
  'Africa/Luanda': '(GMT+1:00) Luanda',
  'Africa/Malabo': '(GMT+1:00) Malabo',
  'Africa/Ndjamena': '(GMT+1:00) Ndjamena',
  'Africa/Niamey': '(GMT+1:00) Niamey',
  'Africa/Porto-Novo': '(GMT+1:00) Porto-Novo',
  'Africa/Tunis': '(GMT+1:00) Tunis',
  'Africa/Windhoek': '(GMT+1:00) Windhoek',
  'Atlantic/Canary': '(GMT+1:00) Canary',
  'Atlantic/Faroe': '(GMT+1:00) Faroe',
  'Atlantic/Madeira': '(GMT+1:00) Madeira',
  'Europe/Dublin': '(GMT+1:00) Dublin',
  'Europe/Guernsey': '(GMT+1:00) Guernsey',
  'Europe/Isle_of_Man': '(GMT+1:00) Isle of Man',
  'Europe/Jersey': '(GMT+1:00) Jersey',
  'Europe/Lisbon': '(GMT+1:00) Lisbon',
  'Europe/London': '(GMT+1:00) London',
  'Africa/Blantyre': '(GMT+2:00) Blantyre',
  'Africa/Bujumbura': '(GMT+2:00) Bujumbura',
  'Africa/Cairo': '(GMT+2:00) Cairo',
  'Africa/Ceuta': '(GMT+2:00) Ceuta',
  'Africa/Gaborone': '(GMT+2:00) Gaborone',
  'Africa/Harare': '(GMT+2:00) Harare',
  'Africa/Johannesburg': '(GMT+2:00) Johannesburg',
  'Africa/Kigali': '(GMT+2:00) Kigali',
  'Africa/Lubumbashi': '(GMT+2:00) Lubumbashi',
  'Africa/Lusaka': '(GMT+2:00) Lusaka',
  'Africa/Maputo': '(GMT+2:00) Maputo',
  'Africa/Maseru': '(GMT+2:00) Maseru',
  'Africa/Mbabane': '(GMT+2:00) Mbabane',
  'Africa/Tripoli': '(GMT+2:00) Tripoli',
  'Antarctica/Troll': '(GMT+2:00) Troll',
  'Arctic/Longyearbyen': '(GMT+2:00) Longyearbyen',
  'Europe/Amsterdam': '(GMT+2:00) Amsterdam',
  'Europe/Andorra': '(GMT+2:00) Andorra',
  'Europe/Belgrade': '(GMT+2:00) Belgrade',
  'Europe/Berlin': '(GMT+2:00) Berlin',
  'Europe/Bratislava': '(GMT+2:00) Bratislava',
  'Europe/Brussels': '(GMT+2:00) Brussels',
  'Europe/Budapest': '(GMT+2:00) Budapest',
  'Europe/Busingen': '(GMT+2:00) Busingen',
  'Europe/Copenhagen': '(GMT+2:00) Copenhagen',
  'Europe/Gibraltar': '(GMT+2:00) Gibraltar',
  'Europe/Kaliningrad': '(GMT+2:00) Kaliningrad',
  'Europe/Ljubljana': '(GMT+2:00) Ljubljana',
  'Europe/Luxembourg': '(GMT+2:00) Luxembourg',
  'Europe/Madrid': '(GMT+2:00) Madrid',
  'Europe/Malta': '(GMT+2:00) Malta',
  'Europe/Monaco': '(GMT+2:00) Monaco',
  'Europe/Oslo': '(GMT+2:00) Oslo',
  'Europe/Paris': '(GMT+2:00) Paris',
  'Europe/Podgorica': '(GMT+2:00) Podgorica',
  'Europe/Prague': '(GMT+2:00) Prague',
  'Europe/Rome': '(GMT+2:00) Rome',
  'Europe/San_Marino': '(GMT+2:00) San Marino',
  'Europe/Sarajevo': '(GMT+2:00) Sarajevo',
  'Europe/Skopje': '(GMT+2:00) Skopje',
  'Europe/Stockholm': '(GMT+2:00) Stockholm',
  'Europe/Tirane': '(GMT+2:00) Tirane',
  'Europe/Vaduz': '(GMT+2:00) Vaduz',
  'Europe/Vatican': '(GMT+2:00) Vatican',
  'Europe/Vienna': '(GMT+2:00) Vienna',
  'Europe/Warsaw': '(GMT+2:00) Warsaw',
  'Europe/Zagreb': '(GMT+2:00) Zagreb',
  'Europe/Zurich': '(GMT+2:00) Zurich',
  'Africa/Addis_Ababa': '(GMT+3:00) Addis Ababa',
  'Africa/Asmara': '(GMT+3:00) Asmara',
  'Africa/Dar_es_Salaam': '(GMT+3:00) Dar es Salaam',
  'Africa/Djibouti': '(GMT+3:00) Djibouti',
  'Africa/Juba': '(GMT+3:00) Juba',
  'Africa/Kampala': '(GMT+3:00) Kampala',
  'Africa/Khartoum': '(GMT+3:00) Khartoum',
  'Africa/Mogadishu': '(GMT+3:00) Mogadishu',
  'Africa/Nairobi': '(GMT+3:00) Nairobi',
  'Antarctica/Syowa': '(GMT+3:00) Syowa',
  'Asia/Aden': '(GMT+3:00) Aden',
  'Asia/Amman': '(GMT+3:00) Amman',
  'Asia/Baghdad': '(GMT+3:00) Baghdad',
  'Asia/Bahrain': '(GMT+3:00) Bahrain',
  'Asia/Beirut': '(GMT+3:00) Beirut',
  'Asia/Damascus': '(GMT+3:00) Damascus',
  'Asia/Famagusta': '(GMT+3:00) Famagusta',
  'Asia/Gaza': '(GMT+3:00) Gaza',
  'Asia/Hebron': '(GMT+3:00) Hebron',
  'Asia/Istanbul': '(GMT+3:00) Istanbul',
  'Asia/Jerusalem': '(GMT+3:00) Jerusalem',
  'Asia/Kuwait': '(GMT+3:00) Kuwait',
  'Asia/Nicosia': '(GMT+3:00) Nicosia',
  'Asia/Qatar': '(GMT+3:00) Qatar',
  'Asia/Riyadh': '(GMT+3:00) Riyadh',
  'Europe/Athens': '(GMT+3:00) Athens',
  'Europe/Bucharest': '(GMT+3:00) Bucharest',
  'Europe/Chisinau': '(GMT+3:00) Chisinau',
  'Europe/Helsinki': '(GMT+3:00) Helsinki',
  'Europe/Istanbul': '(GMT+3:00) Istanbul',
  'Europe/Kiev': '(GMT+3:00) Kiev',
  'Europe/Kirov': '(GMT+3:00) Kirov',
  'Europe/Mariehamn': '(GMT+3:00) Mariehamn',
  'Europe/Minsk': '(GMT+3:00) Minsk',
  'Europe/Moscow': '(GMT+3:00) Moscow',
  'Europe/Nicosia': '(GMT+3:00) Nicosia',
  'Europe/Riga': '(GMT+3:00) Riga',
  'Europe/Simferopol': '(GMT+3:00) Simferopol',
  'Europe/Sofia': '(GMT+3:00) Sofia',
  'Europe/Tallinn': '(GMT+3:00) Tallinn',
  'Europe/Uzhgorod': '(GMT+3:00) Uzhgorod',
  'Europe/Vilnius': '(GMT+3:00) Vilnius',
  'Europe/Volgograd': '(GMT+3:00) Volgograd',
  'Europe/Zaporozhye': '(GMT+3:00) Zaporozhye',
  'Indian/Antananarivo': '(GMT+3:00) Antananarivo',
  'Indian/Comoro': '(GMT+3:00) Comoro',
  'Indian/Mayotte': '(GMT+3:00) Mayotte',
  'Asia/Baku': '(GMT+4:00) Baku',
  'Asia/Dubai': '(GMT+4:00) Dubai',
  'Asia/Muscat': '(GMT+4:00) Muscat',
  'Asia/Tbilisi': '(GMT+4:00) Tbilisi',
  'Asia/Yerevan': '(GMT+4:00) Yerevan',
  'Europe/Astrakhan': '(GMT+4:00) Astrakhan',
  'Europe/Samara': '(GMT+4:00) Samara',
  'Europe/Saratov': '(GMT+4:00) Saratov',
  'Europe/Ulyanovsk': '(GMT+4:00) Ulyanovsk',
  'Indian/Mahe': '(GMT+4:00) Mahe',
  'Indian/Mauritius': '(GMT+4:00) Mauritius',
  'Indian/Reunion': '(GMT+4:00) Reunion',
  'Asia/Kabul': '(GMT+4:30) Kabul',
  'Asia/Tehran': '(GMT+4:30) Tehran',
  'Antarctica/Mawson': '(GMT+5:00) Mawson',
  'Asia/Aqtau': '(GMT+5:00) Aqtau',
  'Asia/Aqtobe': '(GMT+5:00) Aqtobe',
  'Asia/Ashgabat': '(GMT+5:00) Ashgabat',
  'Asia/Atyrau': '(GMT+5:00) Atyrau',
  'Asia/Dushanbe': '(GMT+5:00) Dushanbe',
  'Asia/Karachi': '(GMT+5:00) Karachi',
  'Asia/Oral': '(GMT+5:00) Oral',
  'Asia/Samarkand': '(GMT+5:00) Samarkand',
  'Asia/Tashkent': '(GMT+5:00) Tashkent',
  'Asia/Yekaterinburg': '(GMT+5:00) Yekaterinburg',
  'Indian/Kerguelen': '(GMT+5:00) Kerguelen',
  'Indian/Maldives': '(GMT+5:00) Maldives',
  'Asia/Colombo': '(GMT+5:30) Colombo',
  'Asia/Kolkata': '(GMT+5:30) Kolkata',
  'Asia/Kathmandu': '(GMT+5:45) Kathmandu',
  'Antarctica/Vostok': '(GMT+6:00) Vostok',
  'Asia/Almaty': '(GMT+6:00) Almaty',
  'Asia/Bishkek': '(GMT+6:00) Bishkek',
  'Asia/Dhaka': '(GMT+6:00) Dhaka',
  'Asia/Omsk': '(GMT+6:00) Omsk',
  'Asia/Qyzylorda': '(GMT+6:00) Qyzylorda',
  'Asia/Thimphu': '(GMT+6:00) Thimphu',
  'Asia/Urumqi': '(GMT+6:00) Urumqi',
  'Indian/Chagos': '(GMT+6:00) Chagos',
  'Asia/Yangon': '(GMT+6:30) Yangon',
  'Indian/Cocos': '(GMT+6:30) Cocos',
  'Antarctica/Davis': '(GMT+7:00) Davis',
  'Asia/Bangkok': '(GMT+7:00) Bangkok',
  'Asia/Barnaul': '(GMT+7:00) Barnaul',
  'Asia/Ho_Chi_Minh': '(GMT+7:00) Ho Chi Minh',
  'Asia/Hovd': '(GMT+7:00) Hovd',
  'Asia/Jakarta': '(GMT+7:00) Jakarta',
  'Asia/Krasnoyarsk': '(GMT+7:00) Krasnoyarsk',
  'Asia/Novokuznetsk': '(GMT+7:00) Novokuznetsk',
  'Asia/Novosibirsk': '(GMT+7:00) Novosibirsk',
  'Asia/Phnom_Penh': '(GMT+7:00) Phnom Penh',
  'Asia/Pontianak': '(GMT+7:00) Pontianak',
  'Asia/Tomsk': '(GMT+7:00) Tomsk',
  'Asia/Vientiane': '(GMT+7:00) Vientiane',
  'Indian/Christmas': '(GMT+7:00) Christmas',
  'Asia/Brunei': '(GMT+8:00) Brunei',
  'Asia/Choibalsan': '(GMT+8:00) Choibalsan',
  'Asia/Hong_Kong': '(GMT+8:00) Hong Kong',
  'Asia/Irkutsk': '(GMT+8:00) Irkutsk',
  'Asia/Kuala_Lumpur': '(GMT+8:00) Kuala Lumpur',
  'Asia/Kuching': '(GMT+8:00) Kuching',
  'Asia/Macau': '(GMT+8:00) Macau',
  'Asia/Makassar': '(GMT+8:00) Makassar',
  'Asia/Manila': '(GMT+8:00) Manila',
  'Asia/Shanghai': '(GMT+8:00) Shanghai',
  'Asia/Singapore': '(GMT+8:00) Singapore',
  'Asia/Taipei': '(GMT+8:00) Taipei',
  'Asia/Ulaanbaatar': '(GMT+8:00) Ulaanbaatar',
  'Australia/Perth': '(GMT+8:00) Perth',
  'Asia/Pyongyang': '(GMT+8:30) Pyongyang',
  'Australia/Eucla': '(GMT+8:45) Eucla',
  'Asia/Chita': '(GMT+9:00) Chita',
  'Asia/Dili': '(GMT+9:00) Dili',
  'Asia/Jayapura': '(GMT+9:00) Jayapura',
  'Asia/Khandyga': '(GMT+9:00) Khandyga',
  'Asia/Seoul': '(GMT+9:00) Seoul',
  'Asia/Tokyo': '(GMT+9:00) Tokyo',
  'Asia/Yakutsk': '(GMT+9:00) Yakutsk',
  'Pacific/Palau': '(GMT+9:00) Palau',
  'Australia/Adelaide': '(GMT+9:30) Adelaide',
  'Australia/Broken_Hill': '(GMT+9:30) Broken Hill',
  'Australia/Darwin': '(GMT+9:30) Darwin',
  'Antarctica/DumontDUrville': '(GMT+10:00) DumontDUrville',
  'Asia/Ust-Nera': '(GMT+10:00) Ust-Nera',
  'Asia/Vladivostok': '(GMT+10:00) Vladivostok',
  'Australia/Brisbane': '(GMT+10:00) Brisbane',
  'Australia/Currie': '(GMT+10:00) Currie',
  'Australia/Hobart': '(GMT+10:00) Hobart',
  'Australia/Lindeman': '(GMT+10:00) Lindeman',
  'Australia/Melbourne': '(GMT+10:00) Melbourne',
  'Australia/Sydney': '(GMT+10:00) Sydney',
  'Pacific/Chuuk': '(GMT+10:00) Chuuk',
  'Pacific/Guam': '(GMT+10:00) Guam',
  'Pacific/Port_Moresby': '(GMT+10:00) Port Moresby',
  'Pacific/Saipan': '(GMT+10:00) Saipan',
  'Australia/Lord_Howe': '(GMT+10:30) Lord Howe',
  'Antarctica/Casey': '(GMT+11:00) Casey',
  'Antarctica/Macquarie': '(GMT+11:00) Macquarie',
  'Asia/Magadan': '(GMT+11:00) Magadan',
  'Asia/Sakhalin': '(GMT+11:00) Sakhalin',
  'Asia/Srednekolymsk': '(GMT+11:00) Srednekolymsk',
  'Pacific/Bougainville': '(GMT+11:00) Bougainville',
  'Pacific/Efate': '(GMT+11:00) Efate',
  'Pacific/Guadalcanal': '(GMT+11:00) Guadalcanal',
  'Pacific/Kosrae': '(GMT+11:00) Kosrae',
  'Pacific/Norfolk': '(GMT+11:00) Norfolk',
  'Pacific/Noumea': '(GMT+11:00) Noumea',
  'Pacific/Pohnpei': '(GMT+11:00) Pohnpei',
  'Antarctica/McMurdo': '(GMT+12:00) McMurdo',
  'Asia/Anadyr': '(GMT+12:00) Anadyr',
  'Asia/Kamchatka': '(GMT+12:00) Kamchatka',
  'Pacific/Auckland': '(GMT+12:00) Auckland',
  'Pacific/Fiji': '(GMT+12:00) Fiji',
  'Pacific/Funafuti': '(GMT+12:00) Funafuti',
  'Pacific/Kwajalein': '(GMT+12:00) Kwajalein',
  'Pacific/Majuro': '(GMT+12:00) Majuro',
  'Pacific/Nauru': '(GMT+12:00) Nauru',
  'Pacific/Tarawa': '(GMT+12:00) Tarawa',
  'Pacific/Wake': '(GMT+12:00) Wake',
  'Pacific/Wallis': '(GMT+12:00) Wallis',
  'Pacific/Chatham': '(GMT+12:45) Chatham',
  'Pacific/Apia': '(GMT+13:00) Apia',
  'Pacific/Enderbury': '(GMT+13:00) Enderbury',
  'Pacific/Fakaofo': '(GMT+13:00) Fakaofo',
  'Pacific/Tongatapu': '(GMT+13:00) Tongatapu',
  'Pacific/Kiritimati': '(GMT+14:00) Kiritimati',
};
