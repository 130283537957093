import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { DocumentTextIcon, DownloadIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import newslitLogo from '../../assets/images/newslit-logo.svg';
import { usePwaContext } from '../../contexts/pwa';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { Account } from '../../services/models/account';
import { Brief } from '../../services/models/brief';
import { ExternalUserType } from '../../services/models/external-user';
import { FeatureFlag } from '../../services/models/feature-flags';
import * as plausible from '../../services/plausible';
import * as segment from '../../services/segment';
import { classNames } from '../../utils/class-names';
import { AddNewBriefBtn } from '../add-new-brief-btn/addNewBriefBtn';
import { EmptyBriefCTA } from '../empty-brief-cta/emptyBriefCTA';
import { UpsellSidebarNotification } from '../upsell-sidebar-notification/upsellSidebarNotification';

const userNavigation = [
  { name: 'Settings', href: '/dashboard/settings/overview' },
  { name: 'Sign out', href: '/users/signout' },
];

interface MobileSidebarProps {
  account: Account;
  briefs: Brief[];
  currentBriefCount: number;
  maxBriefCount: number;
  sidebarOpen: boolean;
  onCloseSidebar: () => void;
  onAddNewBrief: () => void;
  onIosAppInstall: () => void;
  onTwitterSignInSuccess: () => void;
}

export const MobileSidebar: React.FC<MobileSidebarProps> = ({
  account,
  briefs,
  currentBriefCount,
  maxBriefCount,
  sidebarOpen,
  onCloseSidebar,
  onAddNewBrief,
  onIosAppInstall,
  onTwitterSignInSuccess,
}) => {
  const {
    browser,
    deferredPrompt,
    clearDeferredPrompt,
    isIos,
    isPwaCompatibleBrowser,
    showInstallButton,
  } = usePwaContext();

  const socialBriefFlag = useFeatureFlag(FeatureFlag.ShowSocialBrief);

  const twitterUser = account.externalUsers.find(eu => eu.type === ExternalUserType.TWITTER);

  const handleAddNewBriefClick = () => {
    onCloseSidebar();
    setTimeout(() => onAddNewBrief(), 200);
  };

  const handlePwaInstallClick = () => {
    plausible.trackEvent('Install App Started', {
      browser: browser !== undefined ? browser : 'unknown',
    });

    segment.trackEvent('Install App Started', {
      browser: browser !== undefined ? browser : 'unknown',
    });

    if (isPwaCompatibleBrowser) {
      onCloseSidebar();

      if (!!deferredPrompt) {
        deferredPrompt.prompt();
      }

      if (clearDeferredPrompt) {
        clearDeferredPrompt();
      }
    } else if (isIos) {
      onCloseSidebar();
      setTimeout(() => onIosAppInstall(), 200);
    }

    // TODO(Jose): MacOs installs
    // else if (isMac && browser === 'safari') {
    //   setMacInstallModalShown(true);
    // }
  };

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-inset-0 tw-flex tw-z-40 md:tw-hidden"
        onClose={() => onCloseSidebar()}
      >
        <Transition.Child
          as={Fragment}
          enter="tw-transition-opacity tw-ease-linear tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-transition-opacity tw-ease-linear tw-duration-300"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="tw-transition tw-ease-in-out tw-duration-300"
          enterFrom="tw--translate-x-full"
          enterTo="tw-translate-x-0"
          leave="tw-transition tw-ease-in-out tw-duration-300"
          leaveFrom="tw-translate-x-0"
          leaveTo="tw--translate-x-full"
        >
          <div className="tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-xs tw-w-full tw-pt-5 tw-bg-white">
            {/* Close Button */}
            <Transition.Child
              as={Fragment}
              enter="tw-ease-in-out tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-ease-in-out tw-duration-300"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <div className="tw-absolute tw-top-0 tw-right-0 tw--mr-12 tw-pt-2">
                <button
                  type="button"
                  className="tw-ml-1 tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-white"
                  onClick={() => onCloseSidebar()}
                >
                  <span className="tw-sr-only">Close sidebar</span>
                  <XIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-px-4">
              <img className="tw-h-7 tw-w-auto" src={newslitLogo} alt="Newslit" />
            </div>

            <div className="tw-pt-6 tw-flex-1 tw-h-0 tw-overflow-y-auto">
              {/* Navigation */}
              <nav className="tw-px-2">
                <div className="tw-space-y-1"></div>

                <div className="tw-mt-8">
                  {/* Secondary navigation */}
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <h3
                      className="tw-px-2 tw-text-sm tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider"
                      id="mobile-briefs-headline"
                    >
                      Briefs
                    </h3>
                    <span className="tw-text-sm tw-font-light tw-text-gray-700 tw-tracking-wider">
                      using{' '}
                      <span className="tw-font-bold tw-text-gray-900">{currentBriefCount}</span>/
                      {maxBriefCount}
                    </span>
                  </div>

                  {currentBriefCount === 0 &&
                    (!account.metadata?.isTwitterUser || !socialBriefFlag) && (
                      <div className="tw-mt-4">
                        <EmptyBriefCTA onClick={handleAddNewBriefClick} />
                      </div>
                    )}

                  {((socialBriefFlag && account.metadata?.isTwitterUser) ||
                    currentBriefCount > 0) && (
                    <div className="tw-px-2 tw-py-4">
                      <AddNewBriefBtn onClick={handleAddNewBriefClick} />
                    </div>
                  )}

                  <div
                    className="tw-mt-1 tw-space-y-1"
                    role="group"
                    aria-labelledby="mobile-briefs-headline"
                  >
                    {socialBriefFlag && account.metadata?.isTwitterUser && twitterUser!! && (
                      <NavLink
                        to="/dashboard/social"
                        activeClassName="tw-bg-gray-100 tw-text-gray-900"
                        className="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-leading-5 tw-font-medium tw-text-gray-600 tw-rounded-md hover:tw-text-gray-900 hover:tw-bg-gray-50 hover:tw-no-underline"
                      >
                        <DocumentTextIcon
                          className="tw-mr-3 tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                          aria-hidden="true"
                        />
                        @{twitterUser?.username}
                      </NavLink>
                    )}

                    {briefs.map(brief => (
                      <NavLink
                        key={brief.id}
                        to={`/dashboard/brief/${brief.id}`}
                        className="tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-leading-5 tw-font-medium tw-text-gray-600 tw-rounded-md hover:tw-text-gray-900 hover:tw-bg-gray-50 hover:tw-no-underline"
                        activeClassName="tw-bg-gray-100 tw-text-gray-900"
                      >
                        <DocumentTextIcon
                          className="tw-mr-3 tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                          aria-hidden="true"
                        />
                        <span className="tw-truncate">{brief.name}</span>
                      </NavLink>
                    ))}
                  </div>
                </div>
              </nav>
            </div>

            {showInstallButton && (
              <div className="tw-flex-shrink-0 tw-px-2 tw-pt-4 tw-pb-3">
                <button
                  type="button"
                  className="tw-order-0 tw-w-full tw-flex tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-primary tw-bg-blue-100 hover:tw-bg-blue-200 hover:tw-no-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                  onClick={handlePwaInstallClick}
                >
                  <DownloadIcon className="tw--ml-1 tw-mr-2 tw-h-5 tw-w-5" aria-hidden="true" />{' '}
                  Install App
                </button>
              </div>
            )}

            <UpsellSidebarNotification account={account} />

            <div className="tw-flex-shrink-0 tw-border-t tw-border-gray-200 tw-px-2 tw-pt-4 tw-pb-3">
              <Disclosure as="div" className="tw-space-y-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        'tw-bg-white tw-text-gray-600 hover:tw-text-gray-900',
                        'tw-group tw-w-full tw-flex tw-items-center tw-pl-2 tw-pr-1 tw-py-2 tw-text-left tw-text-sm tw-font-medium tw-rounded-md focus:tw-outline-none'
                      )}
                    >
                      <div className="flex-shrink-0">
                        <img
                          className="tw-inline-block tw-h-10 tw-w-10 tw-rounded-full"
                          src={account?.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="tw-flex-1 tw-ml-3 tw-truncate">
                        <div className="tw-text-base tw-font-medium tw-text-gray-700">
                          {account?.firstName} {account?.lastName}
                        </div>
                        <div className="tw-text-sm tw-font-medium tw-text-gray-500">
                          {account?.email?.emailAddress}
                        </div>
                      </div>
                      <svg
                        className={classNames(
                          open ? 'tw-text-gray-400 tw-rotate-90' : 'tw-text-gray-300',
                          'tw-ml-3 tw-flex-shrink-0 tw-h-5 tw-w-5 group-hover:tw-text-gray-400 tw-transition-colors tw-ease-in-out tw-duration-150'
                        )}
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                      </svg>
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                      <button
                        className="tw-group tw-flex tw-items-center tw-w-full tw-px-3 tw-py-2 tw-rounded-md tw-text-base tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-text-gray-900 hover:tw-bg-gray-50 hover:tw-no-underline"
                        onClick={() => {
                          const my = window as any;
                          if (my.hasOwnProperty('Intercom')) {
                            my['Intercom']('show');
                          }
                          onCloseSidebar();
                        }}
                      >
                        Get Help
                      </button>

                      {userNavigation.map(item => (
                        <Disclosure.Button
                          key={item.name}
                          as={Link}
                          to={item.href}
                          className="tw-block tw-px-3 tw-py-2 tw-rounded-md tw-text-base tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-text-gray-900 hover:tw-bg-gray-50 hover:tw-no-underline"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </Transition.Child>
        <div className="tw-flex-shrink-0 tw-w-14">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};
