import * as auth from '../../services/authentication';
import { AccountActivation } from '../../services/models/account-activation';
import { Session } from '../../services/models/session';
import { asyncAction, Dispatch } from '../types';

export enum AccountActivationAction {
  StartFetching = 'AccountActivationActionStartFetching',
  CompleteFetching = 'AccountActivationActionCompleteFetching',
  CancelFetching = 'AccountActivationActionCancelFetching',
  StartActivatingAccount = 'AccountActivationActionStartActivatingAccount',
  CompleteActivatingAccount = 'AccountActivationActionCompleteActivatingAccount',
  CancelActivatingAccount = 'AccountActivationActionCancelActivatingAccount',
}

export const getAccountActivation = (code: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingAccountActivation());
    const accountActivation = await auth.getAccountActivation(code);
    dispatch(completeFetchingAccountActivation(accountActivation));
  }, cancelFetchingAccountActivation);

export const startFetchingAccountActivation = () => ({
  type: AccountActivationAction.StartFetching,
});

export const completeFetchingAccountActivation = (accountActivation: AccountActivation) => ({
  type: AccountActivationAction.CompleteFetching,
  accountActivation,
});

export const cancelFetchingAccountActivation = () => ({
  type: AccountActivationAction.CancelFetching,
});

export const activateAccount = (code: string, password: string) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startActivatingAccount());
    const session = await auth.activateAccount(code, password);
    dispatch(completeActivatingAccount(session));
  }, cancelActivatingAccount);

export const startActivatingAccount = () => ({
  type: AccountActivationAction.StartActivatingAccount,
});

export const completeActivatingAccount = (session: Session) => ({
  type: AccountActivationAction.CompleteActivatingAccount,
  session,
});

export const cancelActivatingAccount = () => ({
  type: AccountActivationAction.CancelActivatingAccount,
});
