import { ExternalAuthActions } from '../../actions/external-auth-actions';
import { ExternalAuth } from '../../store-state';

export default function externalAuthReducer(state: ExternalAuth = {}, action: any): ExternalAuth {
  switch (action.type) {
    /**
     * Twitter Login
     */
    case ExternalAuthActions.StartFetchingTwitterLoginLink:
      return { ...state, isFetchingTwitterLoginLink: true };

    case ExternalAuthActions.CompleteFetchingTwitterLoginLink:
      return {
        ...state,
        isFetchingTwitterLoginLink: false,
        twitterLoginLink: action.twitterLoginLink,
      };

    case ExternalAuthActions.CancelFetchingTwitterLoginLink:
      return { ...state, isFetchingTwitterLoginLink: false };

    /**
     * Add to Slack
     */
    case ExternalAuthActions.StartFetchingAddToSlackLink:
      return { ...state, isFetchingAddToSlackLink: true };

    case ExternalAuthActions.CompleteFetchingAddToSlackLink:
      return {
        ...state,
        isFetchingAddToSlackLink: false,
        addToSlackLink: action.addToSlackLink,
      };

    case ExternalAuthActions.CancelFetchingAddToSlackLink:
      return { ...state, isFetchingAddToSlackLink: false };

    /**
     * Reset Slack Info
     */
    case ExternalAuthActions.ResetAddToSlackLink:
      return {
        ...state,
        isFetchingAddToSlackLink: false,
        addToSlackLink: undefined,
      };

    default:
      return state;
  }
}
