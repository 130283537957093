import { StoryTweet } from '../../../../../../../../services/models/story-tweet';
import { BriefStoryTweetsAction } from '../../../../../../../actions/brief-story-tweets-actions';

export default function briefStoryTweetsReducer(state: StoryTweet[] = [], action: any) {
  switch (action.type) {
    case BriefStoryTweetsAction.CompleteFetching:
      return [...action.tweets];

    default:
      return state;
  }
}
