import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/loading-spinner/LoadingSpinner';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { initCheckoutFlow } from '../../../redux/actions/stripe-actions';
import { AppDispatch } from '../../../redux/store';
import { Account } from '../../../services/models/account';
import { Subscription } from '../../../services/models/subscription';
import { CheckoutCallbackRoute } from '../../../utils/Routes';

type PageParams = {
  planId: string;
};

const PlanCheckoutPage: FunctionComponent = () => {
  const history = useHistory();

  const dispatch: AppDispatch = useAppDispatch();

  const { planId } = useParams<PageParams>();
  const callbackUrl = window.location.origin + CheckoutCallbackRoute;

  const account: Account = useAppSelector(state => state.session?.account);
  const email = account?.email?.emailAddress;
  const subscription: Subscription = account.subscription;

  const [currentPlanId] = useState(subscription.planId);

  useEffect(() => {
    dispatch(initCheckoutFlow(account.userid, email, planId, callbackUrl));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subscription.planId !== currentPlanId) {
      history.goBack();

      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }, [subscription, history, currentPlanId]);

  return <LoadingSpinner />;
};

export default PlanCheckoutPage;
