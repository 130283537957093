import React, { FunctionComponent } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import NotFoundPage from '../../pages/not-found/NotFoundPage';
import ApiIntegrationPage from '../../pages/settings/integrations/api/apiIntegrationPage';
import IntegrationsPage from '../../pages/settings/integrations/integrationsPage';
import RapidapiIntegrationPage from '../../pages/settings/integrations/rapidapi/RapidapiIntegrationPage';
import ZapierIntegrationPage from '../../pages/settings/integrations/zapier/zapierIntegrationPage';
import OverviewPage from '../../pages/settings/overview/overviewPage';
import PlanCheckoutPage from '../../pages/settings/plan/planCheckoutPage';
import PlanPage from '../../pages/settings/plan/planPage';
import ProtectedRoute from '../protected-route/ProtectedRoute';

const SettingsAppRoutes: FunctionComponent = () => {
  return (
    <Switch>
      <ProtectedRoute exact path={'/dashboard/settings'}>
        <Redirect to={'/dashboard/settings/overview'} />
      </ProtectedRoute>

      <ProtectedRoute exact path={'/dashboard/settings/overview'} component={OverviewPage} />
      <ProtectedRoute exact path={'/dashboard/settings/plan'} component={PlanPage} />
      <ProtectedRoute
        path={'/dashboard/settings/plan/checkout/:planId'}
        component={PlanCheckoutPage}
      />

      {/* Old Billing Settings route */}
      <Redirect from={'/dashboard/settings/billing'} to={'/dashboard/settings/plan'} />

      {/* Dashboard Settings Integrations Routes */}
      <ProtectedRoute
        exact
        path={'/dashboard/settings/integrations'}
        component={IntegrationsPage}
      />

      <ProtectedRoute
        exact
        path={'/dashboard/settings/integrations/zapier'}
        component={ZapierIntegrationPage}
      />

      <ProtectedRoute
        exact
        path={'/dashboard/settings/integrations/api'}
        component={ApiIntegrationPage}
      />

      <ProtectedRoute
        exact
        path={'/dashboard/settings/integrations/rapidapi'}
        component={RapidapiIntegrationPage}
      />

      <ProtectedRoute component={NotFoundPage} />
    </Switch>
  );
};

export default SettingsAppRoutes;
