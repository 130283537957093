import { Card } from './card';

export interface Subscription {
  active: boolean;
  canceled: boolean;
  cardOnFile: Card;
  currentPeriodEnd: string;
  description: string;
  interval: string;
  pastDue: boolean;
  planId: string;
  status: SubscriptionStatus;
  trial: boolean;
  trialDaysLeft: number;
  trialEndDate: Date;
  trialPeriodDays: number;
  trialStartDate: Date;
  isChanging?: boolean;
}

export enum SubscriptionStatus {
  Trial = 'trial',
  TrialExpired = 'trial_expired',
  Active = 'active',
  PastDue = 'past_due',
  Canceled = 'canceled',
}

export enum SubscriptionInterval {
  Monthly = 'month',
  Yearly = 'year',
}
