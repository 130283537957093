import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationAlert } from '../services/models/application-alert';
import { SubscriptionStatus } from '../services/models/subscription';
import {
  BillingRoute,
  OnboardingReportRoute,
  OnboardingRoute,
  PricingRoute,
  UpgradeRoute,
} from '../utils/Routes';
import { useAppSelector } from './useAppSelector';

export const useApplicationAlerts = () => {
  const location = useLocation();

  const account = useAppSelector(state => state.session?.account);
  const briefs = useAppSelector(state => state.briefs);

  const [alerts, setAlerts] = useState<ApplicationAlert[]>([]);

  useEffect(() => {
    const result: ApplicationAlert[] = [];

    const pathName = location.pathname;

    const mustHideUpsellNotifications =
      pathName === BillingRoute || pathName === PricingRoute || pathName === UpgradeRoute;

    const mustHideEmailConfirmationNotifications =
      pathName === OnboardingRoute || pathName === OnboardingReportRoute;

    if (!mustHideEmailConfirmationNotifications) {
      if (account && account.email && !account.email.verified) {
        result.push(ApplicationAlert.EMAIL_CONFIRMATION);
      }
    }

    if (!mustHideUpsellNotifications) {
      if (
        account?.subscription?.trial &&
        account?.subscription?.status !== SubscriptionStatus.TrialExpired &&
        ((briefs && briefs.items && briefs.items.length > 0) || account.metadata?.isTwitterUser)
      ) {
        result.push(ApplicationAlert.UPSELL);
      }
    }

    if (!!account?.subscription?.pastDue) {
      result.push(ApplicationAlert.PAST_DUE);
    }

    setAlerts(result);
  }, [account, briefs, location]);

  return alerts;
};
