import { Story } from '../../../../../../../services/models/story';
import { BriefStoriesAction } from '../../../../../../actions/brief-stories-actions';
import { BriefStoryTweetsAction } from '../../../../../../actions/brief-story-tweets-actions';
import briefStoryShareDataReducer from './share-date';
import briefStoryTweetsReducer from './tweets';

export default function briefStoryReducer(state: Story = {} as Story, action: any): Story {
  switch (action.type) {
    case BriefStoriesAction.StartFetchingShareData:
    case BriefStoriesAction.CompleteFetchingShareData:
    case BriefStoriesAction.CancelFetchingShareData:
      return {
        ...state,
        shareData: briefStoryShareDataReducer(state.shareData, action),
      };

    case BriefStoriesAction.CompleteMarkStoryAsViewed:
      return { ...state, isClicked: true };

    case BriefStoryTweetsAction.StartFetching:
      return {
        ...state,
        isFetchingTweets: true,
      };

    case BriefStoryTweetsAction.CompleteFetching:
    case BriefStoryTweetsAction.CancelFetching:
      return {
        ...state,
        isFetchingTweets: false,
        tweetsLoaded: true,
        tweets: briefStoryTweetsReducer(state.tweets, action),
      };

    default:
      return state;
  }
}
