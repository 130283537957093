import { loadStripe } from '@stripe/stripe-js';
import gql from 'graphql-tag';
import { configuration } from './configuration';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient } from './graphql-client';
import { Subscription } from './models/subscription';

export const redirectToPurchaseFlow = async (planId: string, callbackUrl?: string) => {
  const { id } = await fetchPaymentCheckoutSession(planId, callbackUrl);
  const stripe = await loadStripe(configuration.stripe.publishableKey);

  if (stripe === null) {
    throw new Error("Can't start Payment system. Please try again");
  }

  const { error } = await stripe.redirectToCheckout({
    sessionId: id,
  });

  if (error) {
    throw error;
  }
};

export const redirectToUpdatePaymentMethodFlow = async (callbackUrl?: string): Promise<any> => {
  const { id } = await fetchUpdateCheckoutSession(callbackUrl);
  const stripe = await loadStripe(configuration.stripe.publishableKey);

  if (stripe != null) {
    const { error } = await stripe.redirectToCheckout({
      sessionId: id,
    });

    if (!error) {
    }
  }
};

export const fetchUpdateCheckoutSession = async (callbackUrl?: string): Promise<{ id: string }> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.mutate<{ updatePaymentSessionCreate: { sessionId: string } }>({
    mutation: _getUpdatePaymentSession(),
    variables: {
      callbackUrl,
    },
  });

  if (!response.data || !response.data.updatePaymentSessionCreate.sessionId) {
    throw new EmptyResponseError();
  }

  return {
    id: response.data.updatePaymentSessionCreate.sessionId,
  };
};

export const fetchPaymentCheckoutSession = async (
  planId: string,
  callbackUrl?: string
): Promise<{ id: string }> => {
  const client = getAuthenticatedGraphQLClient();
  const query = _getPurchaseSession();

  const response = await client.mutate<{ checkoutSessionCreate: { sessionId: string } }>({
    mutation: query,
    variables: {
      planId: planId,
      callbackUrl,
    },
  });

  if (!response.data || !response.data.checkoutSessionCreate.sessionId) {
    throw new EmptyResponseError();
  }

  return {
    id: response.data.checkoutSessionCreate.sessionId,
  };
};

const _getUpdatePaymentSession = () => {
  return gql`
    mutation updatePaymentMethod {
      updatePaymentSessionCreate {
        sessionId
      }
    }
  `;
};

const _getPurchaseSession = () => {
  return gql`
    mutation createCheckoutSession($planId: String!, $callbackUrl: String) {
      checkoutSessionCreate(input: { planId: $planId, callbackUrl: $callbackUrl }) {
        sessionId
      }
    }
  `;
};

export const changeSubscription = async (planId: string): Promise<Subscription> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.mutate<{ subscriptionChange: Subscription }>({
    mutation: _getSubscriptionChangeMutation(),
    variables: {
      planId,
    },
  });

  if (!response.data || !response.data.subscriptionChange) {
    throw new EmptyResponseError();
  }

  return response.data.subscriptionChange;
};

const _getSubscriptionChangeMutation = () => {
  return gql`
    mutation changeSubscription($planId: String!) {
      subscriptionChange(input: { planId: $planId }) {
        planId
        interval
        status
        description
      }
    }
  `;
};
