import { AppType } from '../services/models/app-type';
import { useAppSelector } from './useAppSelector';

/**
 * It determines if an app is available as an integration for the current account.
 */
export const useAvailableIntegration = (appType: AppType): boolean => {
  return useAppSelector(state => {
    const availableIntegrations = state.session?.account?.availableIntegrations || [];
    return availableIntegrations.some(x => x === appType);
  });
};
