import { Intent } from '@blueprintjs/core';
import { AppToaster } from '../../../utils/toaster';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const loggingTroubleErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: Error,
  discard: DiscardErrorCallback
) => {
  if (error.message.indexOf('Looks like we had trouble logging you in') === -1) {
    return null;
  }

  discard(error);

  AppToaster.show({
    intent: Intent.WARNING,
    icon: 'warning-sign',
    message: 'Wrong email or password, please try again.',
  });

  return false;
};
