import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import unsubscribeImg from '../../../../assets/images/unsubscribe.png';
import {
  cancelSubscription,
  deactivateAccount,
  requestCurrentAccount,
} from '../../../../redux/actions/account-actions';
import { completeSignOut } from '../../../../redux/actions/session-actions';
import { StoreState } from '../../../../redux/store-state';
import { discardLocalSession } from '../../../../services/authentication';
import { Account } from '../../../../services/models/account';
import CancelSubscriptionDialog from '../../../cancel-subscription-dialog/CancelSubscriptionDialog';
import './DeactivateAccountPage.scss';

interface Props {
  account: Account;
  isFetching: boolean;
  hasActiveSubscription: boolean;
  signOut: () => void;
  cancelSubscription: () => void;
  requestCurrentAccount: () => void;
  deactivateAccount: (reason: string, comment: string) => void;
}

const DeactivateAccountPage: FunctionComponent<Props> = ({
  account,
  isFetching,
  hasActiveSubscription,
  signOut,
  deactivateAccount,
  cancelSubscription,
  requestCurrentAccount,
}) => {
  const history = useHistory();

  const [reason, setReason] = useState('');
  const [reasonTouched, setReasonTouched] = useState(false);
  const [comment, setComment] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [showCancelSubsDialog, setShowCancelSubsDialog] = useState(false);

  useEffect(() => {
    if (account && !account.subscription) {
      requestCurrentAccount();
    }

    if (account && !account.isCancelingSubscription) {
      setShowCancelSubsDialog(false);
    }
  }, [account, requestCurrentAccount, setShowCancelSubsDialog]);

  if (!account) {
    history.push('/');
    return <></>;
  }

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    deactivateAccount(reason, comment);
  };

  const onReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonTouched(true);
    setReason(event.currentTarget.value);
  };

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const onConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonTouched(true);
    setConfirmation(event.target.checked);
  };

  const getForm = () => {
    return (
      <Form noValidate onSubmit={(e: any) => onSubmit(e)}>
        <Form.Group>
          <Form.Label>Reason for deactivating:</Form.Label>
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="I have a duplicate account"
            value="I have a duplicate account"
            id="duplicate-account"
            onChange={(e: any) => onReasonChange(e)}
          />
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="I am getting too many emails"
            value="I am getting too many emails"
            id="too-many-emails"
            onChange={(e: any) => onReasonChange(e)}
          />
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="I don't find Newslit useful"
            value="I don't find Newslit useful"
            id="not-useful"
            onChange={(e: any) => onReasonChange(e)}
          />
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="I have a privacy concern"
            value="I have a privacy concern"
            id="privacy"
            onChange={(e: any) => onReasonChange(e)}
          />
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="I don't like reading news"
            value="I don't like reading news"
            id="dont-like-reading-news"
            onChange={(e: any) => onReasonChange(e)}
          />
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="The stories were not interesting to me"
            value="The stories were not interesting to me"
            id="stories-not-interesting"
            onChange={(e: any) => onReasonChange(e)}
          />
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="I can't figure out how to use Newslit"
            value="I can't figure out how to use Newslit"
            id="how-to-use"
            onChange={(e: any) => onReasonChange(e)}
          />
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="I get too much email in general"
            value="I get too much email in general"
            id="too-much-email"
            onChange={(e: any) => onReasonChange(e)}
          />
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            name="reason"
            type="radio"
            label="Other"
            value="Other"
            id="other"
            onChange={(e: any) => onReasonChange(e)}
          />
          {(() => {
            if (!reason && reasonTouched) {
              return (
                <Form.Text className="has-error text-muted">Please, select a reason.</Form.Text>
              );
            }
          })()}
        </Form.Group>
        <Form.Group>
          <Row>
            <Col md="8">
              <Form.Control
                disabled={hasActiveSubscription}
                as="textarea"
                placeholder="Any comment for us?"
                rows={3}
                value={comment}
                onChange={(e: any) => onCommentChange(e)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Check
            disabled={hasActiveSubscription}
            className="deactivate-account-check"
            type="checkbox"
            id="confirm"
            label="Yes, really deactivate my Newslit account"
            checked={confirmation}
            onChange={(e: any) => onConfirmationChange(e)}
          />
        </Form.Group>
        <div className="deactivate-account-actions">
          {(() => {
            if (hasActiveSubscription) {
              return (
                <Button variant="danger" onClick={() => setShowCancelSubsDialog(true)}>
                  Cancel Subscription
                </Button>
              );
            }
          })()}
          <Button
            type="submit"
            disabled={
              hasActiveSubscription ||
              account.isDeactivatingAccount ||
              !confirmation ||
              !reasonTouched ||
              !reason
            }
            variant="danger"
          >
            {account.isDeactivatingAccount ? 'Deactivating...' : 'Deactivate'}
          </Button>
          <Button variant="white" as={Link} to="/" disabled={account.isDeactivatingAccount}>
            Cancel
          </Button>
          {(() => {
            if (hasActiveSubscription) {
              return (
                <Form.Text className="has-error text-muted">
                  You have an active subscription. Please, cancel your subscription before
                  deactivating your account.
                </Form.Text>
              );
            }
          })()}
        </div>
        <CancelSubscriptionDialog
          account={account}
          open={showCancelSubsDialog}
          onAccept={() => cancelSubscription()}
          onCancel={() => setShowCancelSubsDialog(false)}
        />
      </Form>
    );
  };

  const getPreDeactivationStage = () => (
    <>
      <Row>
        <Col sm="12" lg="6">
          <p className="lead">Are you sure you want to deactivate your Newslit account?</p>
        </Col>
        <Col sm="12" lg="6">
          <img className="please-dont-go" src={unsubscribeImg} alt="Please don't go!" />
        </Col>
      </Row>

      <p>You should know:</p>
      <ul>
        <li>You can change or disable the emails and notifications you receive from Newslit</li>
      </ul>
      <p>
        <Link className="deactivate-account-settings-link" to="/dashboard/settings">
          Change Settings
        </Link>
      </p>
      <p>If you deactivate your Newslit account:</p>
      <ul>
        <li>You will no longer receive daily News Briefs</li>
        <li>You may miss important news stories</li>
      </ul>
      {getForm()}
    </>
  );

  const getPostDeactivationStage = () => (
    <>
      <Alert variant="success">Your account has been deactivated</Alert>
      <div className="deactivate-account-actions">
        <Button variant="secondary" onClick={() => signOut()}>
          Continue
        </Button>
      </div>
    </>
  );

  return (
    <div>
      <Helmet>
        <title>Deactivate your Newslit account</title>
      </Helmet>

      <Card>
        <Card.Body>
          <h1 className="h2">Deactivate Account</h1>
          {!account.isDeactivationCompleted
            ? getPreDeactivationStage()
            : getPostDeactivationStage()}
        </Card.Body>
      </Card>
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  const isFetching = !!state.session?.isFetching;
  const subscription = state.session?.account?.subscription;

  return {
    isFetching,
    account: state.session.account,
    hasActiveSubscription:
      !isFetching && subscription && !subscription.trial && !subscription.canceled,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    requestCurrentAccount: () => dispatch(requestCurrentAccount()),
    cancelSubscription: () => dispatch(cancelSubscription()),
    deactivateAccount: (reason: string, comment: string) =>
      dispatch(deactivateAccount(reason, comment)),
    signOut: () => {
      discardLocalSession();
      return dispatch(completeSignOut());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateAccountPage);
