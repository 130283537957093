import gql from 'graphql-tag';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient } from './graphql-client';
import { FeatureFlags } from './models/feature-flags';

export const getFeatureFlags = async (): Promise<FeatureFlags> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.query<{ viewer: { featureFlags: FeatureFlags } }>({
    query: _getFeatureFlagsQuery(),
  });

  if (!response.data?.viewer?.featureFlags) {
    throw new EmptyResponseError();
  }
  return response.data.viewer.featureFlags;
};

const _getFeatureFlagsQuery = () => {
  return gql`
    query getFeatureFlags {
      viewer {
        featureFlags {
          showAddToSlack
          showReportLanguage
          showSocialAlerts
          showSocialBrief
          reportStoryClickShowStoryReader
          newReportShowPreviewScreen
        }
      }
    }
  `;
};
