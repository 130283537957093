import { StoryCardPlaceholder } from '@newslit/uikit';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { requestBriefStoriesPreview } from '../../../../redux/actions/brief-stories-actions';
import { StoreState } from '../../../../redux/store-state';
import { Brief } from '../../../../services/models/brief';
import { FeatureFlag } from '../../../../services/models/feature-flags';
import BriefStoryCard from '../../../brief-story-card/BriefStoryCard';
import './RefineBriefStep.scss';

interface Props extends InternalProps {
  active: boolean;
  draftBrief: Brief | null;
  onComplete: () => void;
}

interface InternalProps {
  getStoriesPreview: (reportId: number) => void;
}

const MAX_PREVIEW_COUNT = 3;

const RefineBriefStep: FunctionComponent<Props> = ({
  onComplete,
  active,
  draftBrief,
  getStoriesPreview,
}) => {
  const changesHashRef = useRef<string>('');

  const newReportShowPreviewScreenFlag = useFeatureFlag(FeatureFlag.NewReportShowPreviewScreen);

  useEffect(() => {
    if (draftBrief && !draftBrief.isFetching) {
      const changesHash = [...draftBrief.keywords.map(x => x.keyword)].join(', ');

      if (changesHashRef.current !== changesHash) {
        getStoriesPreview(draftBrief.id);
        changesHashRef.current = changesHash;
      }
    }
  }, [draftBrief, getStoriesPreview]);

  if (!active || !draftBrief) {
    return <></>;
  }

  const getButtons = () => {
    if (!newReportShowPreviewScreenFlag) {
      return;
    }

    return (
      <div className="form-controls">
        <Button variant="purple" onClick={() => onComplete()}>
          Looks good!
        </Button>
      </div>
    );
  };

  const getContent = () => {
    if (draftBrief.isFetching || !draftBrief.storiesPreview) {
      return (
        <>
          <StoryCardPlaceholder isPreview />
          <StoryCardPlaceholder isPreview />
          <StoryCardPlaceholder isPreview />
        </>
      );
    }

    if (draftBrief.storiesPreview.length === 0) {
      return (
        <div className="empty-preview text-muted">There are no stories with these keywords.</div>
      );
    }

    const result = draftBrief.storiesPreview.map(x => (
      <BriefStoryCard key={x.id} story={x} briefId={draftBrief.id} isPreview />
    ));

    if (newReportShowPreviewScreenFlag) {
      return result.slice(0, MAX_PREVIEW_COUNT);
    }

    return result;
  };

  return (
    <div className="refine-screen-container">
      <div className="stories-preview">{getContent()}</div>
      {getButtons()}
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    getStoriesPreview: (reportId: number) => dispatch(requestBriefStoriesPreview(reportId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RefineBriefStep);
