import { Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import DefaultPageLayout from '../../layouts/defaultPageLayout';
import { requestCurrentAccount } from '../../redux/actions/account-actions';
import { getSession } from '../../services/authentication';
import { Account } from '../../services/models/account';

const StripeCheckoutCallbackPage: FunctionComponent = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.session.account);

  const closeWindow = useCallback(() => {
    if (window.opener && window.opener.popupCompleted) {
      window.opener.popupCompleted();
    } else {
      history.push('/');
    }
  }, [history]);

  const isPaymentCustomer = (account: Account) => {
    return !!account.subscription.planId;
  };

  useEffect(() => {
    if (getSession()) {
      dispatch(requestCurrentAccount());
    }
  }, [dispatch]);

  useEffect(() => {
    if (account && account.subscription && isPaymentCustomer(account)) {
      closeWindow();
    }
  }, [account, history, closeWindow]);

  return (
    <DefaultPageLayout title="Verify Subscription">
      <div className="tw-min-h-full tw-flex tw-flex-col tw-justify-center tw-py-12 tw-bg-gray-50 sm:tw-px-6 lg:tw-px-8">
        <div className="tw-mt-8 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
          <div className="tw-bg-white tw-py-8 tw-px-4 tw-shadow sm:tw-rounded-lg sm:tw-px-10">
            <h2 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
              <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
              Checking your account status
            </h2>
          </div>
        </div>
      </div>
    </DefaultPageLayout>
  );
};

export default StripeCheckoutCallbackPage;
