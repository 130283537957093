import React, { FunctionComponent, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import slackInfoSvg from '../../assets/images/slack-info.svg';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAvailableIntegration } from '../../hooks/useAvailableIntegration';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { requestBriefDetails } from '../../redux/actions/briefs-actions';
import { resetSlackAuthInformation } from '../../redux/actions/external-auth-actions';
import { AppType } from '../../services/models/app-type';
import { Brief } from '../../services/models/brief';
import { FeatureFlag } from '../../services/models/feature-flags';
import { SlackChannel } from '../../services/models/slack-channel';
import { launchPopupWindow } from '../../services/popups';
import { getAddToSlackLink } from '../../services/slack';
import DisconnectSlackChannelDialog from './components/disconnect-slack-channel-dialog/DisconnectSlackChannelDialog';
import './EditSlackIntegration.scss';

interface Props {
  brief: Brief;
  destroy: (id: number) => void;
}

const EditSlackIntegration: FunctionComponent<Props> = ({ brief, destroy }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const externalAuth = useAppSelector(state => state.externalAuth);

  const [selectedSlackChannel, setSelectedSlackChannel] = useState<SlackChannel | null>(null);

  const slackFeatureFlag = useFeatureFlag(FeatureFlag.ShowAddToSlack);
  const isSlackIntegrationAvailable = useAvailableIntegration(AppType.Slack);

  if (!slackFeatureFlag) {
    return <></>;
  }

  const isLoading = () => {
    return brief.isUpdatingSlackChannels || externalAuth.isFetchingAddToSlackLink;
  };

  const addSlackChannel = async () => {
    if (isLoading()) {
      return;
    }

    const addToSlackUrl = await getAddToSlackLink(brief.id, location.pathname);

    if (addToSlackUrl) {
      launchPopupWindow(
        addToSlackUrl,
        'location=yes,scrollbars=yes,status=yes',
        { height: 700, width: 800 },
        () => {
          dispatch(requestBriefDetails(brief.id));
          dispatch(resetSlackAuthInformation());
        }
      );
    }
  };

  const removeSelectedSlackChannel = () => {
    if (!isLoading() && selectedSlackChannel) {
      destroy(selectedSlackChannel.id);
      setSelectedSlackChannel(null);
    }
  };

  const getSlackChannels = () => {
    const list = brief?.slackChannels || [];

    if (list.length === 0) {
      return null;
    }

    return (
      <div className="tool-item-list">
        <div className="tool-item-list-header bold-h3">Slack channels connected</div>
        {list.map((x, i) => (
          <div className="tool-item" key={i}>
            <span>{x.name}</span>
            <Button variant="tool-button" onClick={() => destroy(x.id)}>
              Delete
            </Button>
          </div>
        ))}
      </div>
    );
  };

  const getSlackButton = () => {
    return isSlackIntegrationAvailable ? (
      <Button
        onClick={() => addSlackChannel()}
        variant="white"
        className="slack-button"
        disabled={isLoading()}
      >
        <div className="slack-logo"></div>
        <span className="slack-button-text">
          Add to <strong> Slack</strong>
        </span>
        {(() => {
          if (isLoading()) {
            return <Spinner size="sm" animation="border"></Spinner>;
          }
        })()}
      </Button>
    ) : (
      <Button variant="purple" as={Link} to="/dashboard/pricing">
        Upgrade Plan
      </Button>
    );
  };

  return (
    <div className="slack-tool-layout">
      <div className="bold-h2 black-text">Slack channels</div>

      <div className="light-h2 carbon-text">
        Add or Remove the channels your News Brief is connected to.
      </div>

      <div className="slack-tool-buttons">
        <img src={slackInfoSvg} alt="Slack Info" />
        <div className="add-to-slack-wrapper">{getSlackButton()}</div>
        <div className="intelligence-edit-report-form-tags-container js-keyword-tag-container">
          {getSlackChannels()}
        </div>
      </div>

      <DisconnectSlackChannelDialog
        slackChannel={selectedSlackChannel}
        onAccept={() => removeSelectedSlackChannel()}
        onCancel={() => setSelectedSlackChannel(null)}
      />
    </div>
  );
};

export default EditSlackIntegration;
