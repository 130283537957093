import { ClockIcon, LightBulbIcon, SearchIcon, SupportIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { changeSubscription, initCheckoutFlow } from '../../redux/actions/stripe-actions';
import { isInHootSuite } from '../../services/hootsuite';
import { Product } from '../../services/models/product';
import {
  Subscription,
  SubscriptionInterval,
  SubscriptionStatus,
} from '../../services/models/subscription';
import { launchPopupWindow } from '../../services/popups';
import { BillingRoute, CheckoutRoute } from '../../utils/Routes';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import ProductCard from '../product-card/ProductCard';

const newslitFeatures = [
  {
    id: 1,
    name: 'Save Time',
    description:
      'We do the research for you! You get a real-time dashboard and a daily news briefs via e-mail or Slack to ensure you never miss a thing.',
    icon: ClockIcon,
  },
  {
    id: 2,
    name: 'Cut the noise ',
    description:
      'We spot signals hidden to 90% of the world by tracking well over 100,000 news sources and integrating social signals from thousands of top business influencers.',
    icon: SearchIcon,
  },
  {
    id: 3,
    name: 'Stay ahead',
    description:
      'It’s easy to miss important stories. With Newslit, you can connect with over 2,000 applications via Zapier to create workflows and keep track of everything.',
    icon: LightBulbIcon,
  },
  {
    id: 4,
    name: 'Sit back and relax',
    description:
      'Newslit is working 24/7 so you can rest at ease. Did we mention? once is set up, is 100% automated.',
    icon: SupportIcon,
  },
];

export default function Paywall() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const account = useAppSelector(state => state.session.account);
  const currentReportsCount = account?.metadata?.activeReportsCount || 0;
  const subscription = useAppSelector(state => state.session.account.subscription);
  let isBilledMonthly = subscription?.interval !== SubscriptionInterval.Yearly;
  const productStore = useAppSelector(state => state.productStore);

  const [planRequiringConfirmation, setPlanRequiringConfirmation] = useState('');
  const [planRequiringLoading, setPlanRequiringLoading] = useState('');
  const [bestProduct, setBestProduct] = useState<Product>();

  useEffect(() => {
    // Compute best plan for user to upgrade
    const betterPlans = productStore
      .products!.filter(p => p.published)
      .filter(p => {
        return p.features.briefs >= currentReportsCount;
      });

    setBestProduct(betterPlans[0]);
  }, [productStore, currentReportsCount]);

  const handleProductPriceSelection = (planId: string, product: Product) => {
    setPlanRequiringLoading(product.productId);

    if (account?.subscription?.planId && !account?.subscription?.canceled) {
      dispatch(changeSubscription(planId));
      return;
    }

    const checkoutUrl = window.location.origin + CheckoutRoute + planId;

    if (isInHootSuite()) {
      launchPopupWindow(
        checkoutUrl,
        'location=yes,scrollbars=yes,status=yes',
        { height: 700, width: 800 },
        () => {
          history.goBack();

          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
      );
    } else {
      dispatch(initCheckoutFlow(account.userid, account.email.emailAddress, planId));
    }
  };

  const renderPlan = (product?: Product) => {
    if (!product) {
      return <LoadingSpinner />;
    }

    return (
      <ProductCard
        key={product.productId}
        account={account}
        active={false}
        mostPopular={false}
        product={product}
        isBilledMonthly={isBilledMonthly}
        showsConfirmation={planRequiringConfirmation === product.productId}
        showsLoading={planRequiringLoading === product.productId}
        handleConfirmationChange={(confirmation: boolean) => {
          if (confirmation) {
            setPlanRequiringConfirmation(product.productId);
          } else {
            setPlanRequiringConfirmation('');
          }
        }}
        onSelected={handleProductPriceSelection}
      />
    );
  };

  return (
    <div className="tw-relative tw-w-full tw-bg-white">
      <div className="tw-absolute tw-inset-0" aria-hidden="true">
        <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-w-1/2 tw-bg-secondary-dark" />
      </div>
      <div className="tw-relative tw-max-w-7xl tw-mx-auto lg:tw-px-8 lg:tw-grid lg:tw-grid-cols-2">
        <div className="tw-bg-white tw-py-16 tw-px-4 sm:tw-py-12 sm:tw-px-6 lg:tw-px-0 lg:tw-pr-8">
          <div className="tw-max-w-lg tw-mx-auto lg:tw-mx-0">
            {subscription.status === SubscriptionStatus.TrialExpired && <HeaderTrialEnded />}

            {subscription.status === SubscriptionStatus.Trial && (
              <HeaderTrialing subscription={subscription} />
            )}

            <div className="lg:tw-hidden tw-mt-8 tw-max-w-xs tw-mx-auto tw-w-full tw-space-y-8 lg:tw-mx-0">
              <div>
                <h2 className="tw-sr-only">Price</h2>
                {renderPlan(bestProduct)}
                <NavLink to={BillingRoute} className="tw-block tw-mt-4 tw-text-center tw-text-base tw-font-medium">
                  Explore all plans <span aria-hidden="true">&rarr;</span>
                </NavLink>
              </div>
            </div>

            <dl className="tw-mt-12 tw-space-y-10">
              {newslitFeatures.map(feature => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="tw-absolute tw-h-12 tw-w-12 tw-flex tw-items-center tw-justify-center tw-bg-secondary tw-rounded-md">
                      <feature.icon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                    </div>
                    <p className="tw-ml-16 tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="tw-mt-2 tw-ml-16 tw-text-base tw-text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="tw-hidden lg:tw-block tw-bg-secondary-dark tw-py-16 tw-px-4 sm:tw-py-24 sm:tw-px-6 lg:tw-bg-none lg:tw-px-0 lg:tw-pl-8 lg:tw-flex lg:tw-items-center lg:tw-justify-end">
          <div className="tw-max-w-lg tw-mx-auto tw-w-full tw-space-y-8 lg:tw-mx-0">
            <div className="tw-px-6">
              <h2 className="tw-sr-only">Price</h2>
              {renderPlan(bestProduct)}
            </div>
            <NavLink to={BillingRoute} className="tw-block tw-mt-4 tw-text-center tw-text-base tw-font-medium tw-text-indigo-200 hover:tw-text-white">
              Explore all plans <span aria-hidden="true">&rarr;</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

interface HeaderTrialingProps {
  subscription: Subscription;
}

const HeaderTrialing = ({ subscription }: HeaderTrialingProps) => {
  const trialDaysLeft = subscription.trialDaysLeft;

  return (
    <div>
      <h2 className="tw-text-center tw-text-2xl sm:tw-text-3xl tw-leading-8 tw-font-extrabold tw-tracking-tight tw-text-gray-900">
        Enjoying your Newslit trial?
      </h2>
      <p className="mt-3 tw-text-center text-xl text-gray-500">
        You have <span className="tw-font-bold">{trialDaysLeft}</span> day
        {trialDaysLeft > 1 ? 's' : ''} left in your trial
      </p>
    </div>
  );
};

const HeaderTrialEnded = () => {
  return (
    <h2 className="tw-text-center tw-text-2xl sm:tw-text-3xl tw-leading-8 tw-font-extrabold tw-tracking-tight tw-text-gray-900">
      Upgrade to keep using Newslit
    </h2>
  );
};
