import React, { FunctionComponent, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getTwitterLoginLink } from '../../../../redux/actions/external-auth-actions';
import { isAuthenticated } from '../../../../services/authentication';
import './TwitterPopupPage.scss';

const TwitterPopupPage: FunctionComponent = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const isHootsuite = searchParams.get('hsp');

  const dispatch = useAppDispatch();
  const externalAuth = useAppSelector(state => state.externalAuth);

  const [isAuth] = useState(isAuthenticated());

  useEffect(() => {
    if (isHootsuite) {
      localStorage.setItem('is-hootsuite', JSON.stringify(true));
    }

    if (externalAuth.twitterLoginLink) {
      document.location.href = externalAuth.twitterLoginLink;
    } else if (!externalAuth.isFetchingTwitterLoginLink) {
      dispatch(getTwitterLoginLink(isAuth));
    }
  }, [isHootsuite, externalAuth, isAuth, dispatch]);

  return (
    <div>
      <Card>
        <Card.Body>
          <h1 className="twitter-popup-content-header">
            <Spinner animation="grow"></Spinner> Loading Twitter...
          </h1>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TwitterPopupPage;
