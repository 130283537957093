import React, { FunctionComponent, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Redirect, Route, Switch } from 'react-router-dom';
import newslitLogo from '../../assets/images/newslit-wordmark-logo.png';
import ErrorReporter from '../error-reporter/ErrorReporter';
import './LargeSingleColumnLayout.scss';
import DeactivateAccountPage from './pages/deactivate-account-page/DeactivateAccountPage';
import UnsubscribeReportPage from './pages/unsubscribe-report-page/UnsubscribeReportPage';

const LargeSingleColumnLayout: FunctionComponent = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';

    return () => {
      document.body.style.backgroundColor = '';
    };
  });

  const getSubRoutes = () => {
    return (
      <Switch>
        <Route path={`/cancel`}>
          <DeactivateAccountPage />
        </Route>
        <Route path={`/report/unsubscribe`}>
          <UnsubscribeReportPage />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  };

  return (
    <div className="large-single-column-layout">
      <Container>
        <Row className="large-single-column-layout-header justify-content-center">
          <a href="https://newslit.co/">
            <img className="large-single-column-layout-logo" src={newslitLogo} alt="Newslit" />
          </a>
        </Row>

        <Row className="justify-content-center">
          <Col xs="12" md="8">
            <ErrorReporter />
            {getSubRoutes()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LargeSingleColumnLayout;
