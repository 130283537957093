import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgressBar } from '../../components/circular-progress-bar/CircularProgressBar';
import { Input } from '../../components/ui';
import { Origins, Roles } from '../../data/Content';
import { useAppSelector } from '../../hooks/useAppSelector';
import DefaultPageLayout from '../../layouts/defaultPageLayout';
import { submitUserOnboarding } from '../../services/segment';
import { classNames } from '../../utils/class-names';
import { BriefRoute } from '../../utils/Routes';
import './QuestionnairePage.scss';

const QuestionnairePage: FunctionComponent = () => {
  const history = useHistory();

  const account = useAppSelector(state => state.session?.account);

  const [role, setRole] = useState(Roles[0]);
  const [reason, setReason] = useState('');
  const [origin, setOrigin] = useState(Origins[0]);
  const [company, setCompany] = useState('');
  const [questionIndex, setQuestionIndex] = useState(0);

  const getButtonBar = (action: () => void, index: number) => {
    return (
      <>
        <div className="buttonBar">
          <button
            type="button"
            className="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
            onClick={action}
          >
            Next
          </button>
        </div>
        <div className="stepInformation light-h4 carbon-text">
          <div>Step {index} of 2</div>
          <CircularProgressBar removeLabel={true} total={2} current={index} />
        </div>
      </>
    );
  };

  const getHearFromUs = () => {
    //const showInputBox = origin === 'Other';

    return (
      <div className="question-line">
        <div className="tw-block tw-text-lg tw-font-bold">How did you hear about us?</div>

        <SelectMenu activeItem={origin} items={Origins} onItemSelect={item => setOrigin(item)} />

        {/* TODO: Support adding Others */}
        {/*{showInputBox && ()}*/}
      </div>
    );
  };

  const getRole = () => {
    //const showInputBox = role === 'Other';

    return (
      <div className="question-line">
        <div className="tw-block tw-text-lg tw-font-bold">How would you describe your role?</div>

        <SelectMenu activeItem={role} items={Roles} onItemSelect={item => setRole(item)} />

        {/* TODO: Support adding Others */}
        {/*{showInputBox && ()}*/}
      </div>
    );
  };

  const getCompany = () => {
    return (
      <div className="question-line">
        <div className="bold-h3 black-text">What is your company name or industry?</div>
        <div className="light-h3 gray-text-text tw-mb-2">
          We’ll help you monitor your company’s news to keep you updated?
        </div>
        <Input
          type="text"
          name="companyName"
          id="companyName"
          required
          autoComplete="off"
          placeholder="Enter your company name"
          onChange={e => {
            const companyName = e.target.value;
            setCompany(companyName);
          }}
          value={company}
        />
      </div>
    );
  };

  const getImportant = () => {
    return (
      <div className="question-line">
        <div className="bold-h3 black-text tw-mb-2">
          Why is important for you to monitor the news?
        </div>
        <Input
          type="text"
          name="reason"
          id="reason"
          required
          autoComplete="off"
          onChange={e => {
            const reason = e.target.value;
            setReason(reason);
          }}
          value={reason}
        />
      </div>
    );
  };

  const renderQuestionScreen1 = () => {
    return (
      <div className="step">
        <h2>Tell us a bit about yourself </h2>
        {getRole()}
        {getHearFromUs()}
        {getButtonBar(() => {
          setQuestionIndex(1);
        }, 1)}
      </div>
    );
  };

  const handleQuestionnaireEnd = () => {
    // TODO(Jose): Handle Other responses
    // role === 'Other' ? otherRole : role
    // origin === 'Other' ? otherOrigin : origin
    submitUserOnboarding(account, {
      role: role,
      interest: company,
      referal: origin,
      reason: reason,
    });

    history.replace(BriefRoute);
  };

  const renderQuestionScreen2 = () => {
    return (
      <div className="step">
        <h2>Tell us a bit about your company </h2>
        {getCompany()}
        {getImportant()}
        {getButtonBar(handleQuestionnaireEnd, 2)}
      </div>
    );
  };

  return (
    <DefaultPageLayout title="Onboarding">
      <div className="questionnaire-container">
        <h1 className="intense-blue-text questionnaire-container-header">
          <span>Welcome to Newslit!</span>
        </h1>

        <p className="light-h3 gray-text">
          First, to get you the News content you are looking for, we need some information and we
          are positive that you will love your daily News briefings.
        </p>

        <hr />

        {questionIndex === 0 && renderQuestionScreen1()}

        {questionIndex === 1 && renderQuestionScreen2()}
      </div>
    </DefaultPageLayout>
  );
};

export default QuestionnairePage;

interface SelectMenuProps {
  activeItem: string;
  items: string[];
  onItemSelect: (item: string) => void;
}

function SelectMenu(props: SelectMenuProps) {
  return (
    <Listbox value={props.activeItem} onChange={props.onItemSelect}>
      {({ open }) => (
        <>
          <div className="tw-mt-1 tw-relative">
            <Listbox.Button className="tw-bg-white tw-relative tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-pl-3 tw-pr-10 tw-py-2 tw-text-left tw-cursor-default focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-indigo-500 focus:tw-border-indigo-500 sm:tw-text-sm">
              <span className="tw-block tw-truncate">{props.activeItem}</span>
              <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none">
                <SelectorIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="tw-transition tw-ease-in tw-duration-100"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <Listbox.Options className="tw-absolute tw-z-10 tw-mt-1 tw-w-full tw-bg-white tw-shadow-lg tw-max-h-60 tw-rounded-md tw-py-1 tw-text-base tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm">
                {props.items.map((item, id) => (
                  <Listbox.Option
                    key={id}
                    className={({ active }) =>
                      classNames(
                        active ? 'tw-text-white tw-bg-indigo-600' : 'tw-text-gray-900',
                        'tw-cursor-default tw-select-none tw-relative tw-py-2 tw-pl-3 tw-pr-9'
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'tw-font-semibold' : 'tw-font-normal',
                            'tw-block tw-truncate'
                          )}
                        >
                          {item}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'tw-text-white' : 'tw-text-indigo-600',
                              'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4'
                            )}
                          >
                            <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
