import { BriefKeyword } from './brief-keyword';
import { BriefKeywordType } from './brief-keyword-type';
import { BriefLanguage } from './brief-language';
import { BriefRecipient } from './brief-recipient';
import { BriefStatus } from './brief-status';
import { DayOfWeek } from './day-of-week';
import { SlackChannel } from './slack-channel';
import { Story } from './story';

export interface Brief extends BriefGraphQLEntity {
  isFetching?: boolean;
  isPublishing?: boolean;
  isUpdatingName?: boolean;
  isUpdatingLanguage?: boolean;
  isUpdatingKeywords?: boolean;
  isUpdatingSchedule?: boolean;
  isUpdatingRecipients?: boolean;
  isUpdatingSuggestedStories?: boolean;
  isUpdatingSlackChannels?: boolean;
  isDestroying?: boolean;
  showDestroyPrompt?: boolean;
  hasMoreStories?: boolean;
  lastLoadedStoryCursor?: number;
}

interface BriefGraphQLEntity {
  id: number;
  name: string;
  dateAdded: string;
  daysOfWeek: DayOfWeek[];
  keywords: BriefKeyword[];
  briefHour: number;
  recipients: BriefRecipient[];
  stories: Story[];
  status: BriefStatus;
  language: BriefLanguage;
  showSuggestedStories: boolean;
  nextReportDate: string;
  slackChannels: SlackChannel[];
  storiesPreview: Story[];
}

export function getKeywordsByType(brief: Brief, keywordType: BriefKeywordType): BriefKeyword[] {
  return brief.keywords.filter(keyword => keyword.type === keywordType);
}
