import { Brief } from '../../../../services/models/brief';
import { BriefSettingsAction } from '../../../actions/brief-settings-actions';
import { BriefStoriesAction } from '../../../actions/brief-stories-actions';
import { BriefStoryTweetsAction } from '../../../actions/brief-story-tweets-actions';
import { BriefsAction } from '../../../actions/briefs-actions';
import briefReducer from './brief';

export default function briefItemsReducer(state: Brief[] = [], action: any) {
  switch (action.type) {
    case BriefsAction.CompleteFetchingAll:
      return completeFetchingBriefs(action);

    case BriefsAction.CompleteCreating:
      return completeCreatingBrief(state, action);

    case BriefsAction.CompleteDestroying:
      return completeDestroyingBrief(state, action);

    case BriefsAction.StartFetchingBriefDetails:
    case BriefsAction.CompleteFetchingBriefDetails:
    case BriefsAction.CancelFetchingBriefDetails:
    case BriefsAction.ShowBriefDestroyPrompt:
    case BriefsAction.HideBriefDestroyPrompt:
    case BriefsAction.StartDestroying:
    case BriefsAction.CancelDestroying:
    case BriefsAction.StartUpdatingName:
    case BriefsAction.CompleteUpdatingName:
    case BriefsAction.CancelUpdatingName:
    case BriefSettingsAction.StartUpdatingLanguage:
    case BriefSettingsAction.CompleteUpdatingLanguage:
    case BriefSettingsAction.CancelUpdatingLanguage:
    case BriefSettingsAction.StartUpdatingKeywords:
    case BriefSettingsAction.CompleteCreatingKeyword:
    case BriefSettingsAction.CancelCreatingKeyword:
    case BriefSettingsAction.CompleteDestroyingKeyword:
    case BriefSettingsAction.CancelDestroyingKeyword:
    case BriefSettingsAction.StartUpdatingSchedule:
    case BriefSettingsAction.CompleteUpdatingSchedule:
    case BriefSettingsAction.CancelUpdatingSchedule:
    case BriefSettingsAction.StartUpdatingSuggestedStories:
    case BriefSettingsAction.CompleteUpdatingSuggestedStories:
    case BriefSettingsAction.CancelUpdatingSuggestedStories:
    case BriefSettingsAction.StartCreatingRecipient:
    case BriefSettingsAction.CompleteCreatingRecipient:
    case BriefSettingsAction.CancelCreatingRecipient:
    case BriefSettingsAction.StartDestroyingRecipient:
    case BriefSettingsAction.CompleteDestroyingRecipient:
    case BriefSettingsAction.CancelDestroyingRecipient:
    case BriefSettingsAction.StartRemovingSlackChannel:
    case BriefSettingsAction.CompleteRemovingSlackChannel:
    case BriefSettingsAction.CancelRemovingSlackChannel:
    case BriefStoriesAction.StartFetching:
    case BriefStoriesAction.CompleteFetchingPage:
    case BriefStoriesAction.StartFetchingPreview:
    case BriefStoriesAction.CompleteFetchingPreview:
    case BriefStoriesAction.CancelFetchingPreview:
    case BriefStoriesAction.StartFetchingShareData:
    case BriefStoriesAction.CompleteFetchingShareData:
    case BriefStoriesAction.CancelFetchingShareData:
    case BriefStoriesAction.StartMarkStoryAsViewed:
    case BriefStoriesAction.CompleteMarkStoryAsViewed:
    case BriefStoriesAction.CancelMarkStoryAsViewed:
    case BriefStoryTweetsAction.StartFetching:
    case BriefStoryTweetsAction.CompleteFetching:
    case BriefStoryTweetsAction.CancelFetching:
      return updateBriefById(state, action);

    default:
      return state;
  }
}

const completeFetchingBriefs = function (action: any) {
  return [...action.briefs];
};

const completeCreatingBrief = function (state: Brief[], action: any) {
  return [...state, action.brief];
};

const completeDestroyingBrief = function (state: Brief[], action: any) {
  return state.filter(brief => brief.id !== action.briefId);
};

const updateBriefById = function (state: Brief[], action: any) {
  return state.map(brief => (brief.id === action.briefId ? briefReducer(brief, action) : brief));
};
