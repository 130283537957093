import { Brief } from '../../services/models/brief';
import { SidebarTool } from './SidebarTool';

export enum SidebarActions {
  OpenSidebar = 'SidebarActionsOpenSidebar',
  CancelOpenSidebar = 'SidebarActionsCancelOpenSidebar',
  CloseSidebar = 'SidebarActionsCloseSidebar',
  OpenMenu = 'SidebarActionsOpenMenu',
  CloseMenu = 'SidebarActionsCloseMenu',
  DismissNotifHub = 'SidebarActionsDismissNotifHub',
}

export const openSidebar = (brief: Brief, tool: SidebarTool) => ({
  type: SidebarActions.OpenSidebar,
  brief,
  tool,
});

export const openMenu = () => ({
  type: SidebarActions.OpenMenu,
});

export const cancelOpenSidebar = () => ({
  type: SidebarActions.CancelOpenSidebar,
});

export const closeSidebar = () => ({
  type: SidebarActions.CloseSidebar,
});

export const closeMenu = () => ({
  type: SidebarActions.CloseMenu,
});

export const dismissNotifHub = () => ({
  type: SidebarActions.DismissNotifHub,
});
