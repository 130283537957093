import React, { MouseEventHandler } from 'react';

interface EmptyBriefCTAProps {
  onClick?: MouseEventHandler;
}

export function EmptyBriefCTA(props: EmptyBriefCTAProps) {
  return (
    <button
      type="button"
      className="tw-relative tw-block tw-w-full tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-p-12 tw-text-center hover:tw-border-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
      onClick={props.onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400 tw-stroke-1"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
      <span className="tw-mt-2 tw-block tw-text-sm tw-font-medium tw-text-gray-900">
        Create a new brief
      </span>
    </button>
  );
}
