import React, { useEffect, useRef, useState } from 'react';
import { createRefreshToken } from '../../services/account';
import { configuration } from '../../services/configuration';
import NewslitApp from '../newslit-app/NewslitApp';
import Panel from '../ui/panel';

interface ReaderPanelProps {
  url: string | null;
  storyId: number | null;
  show: boolean;
  onClose: (value: boolean) => void;
}

export function ReaderPanel({ url, storyId, show, onClose }: ReaderPanelProps) {
  const readerApp = useRef(0);
  const [readerAppConf, setReaderAppConf] = useState<any>();

  useEffect(() => {
    setReaderAppConf({ storyId, storyUri: url });
  }, [storyId, url]);

  const sendMessageToReader = async (data: any) => {
    const newslitApp = readerApp.current;
    const refreshToken = await createRefreshToken();

    if (!newslitApp) {
      return;
    }

    const app = newslitApp as any;

    data.token = refreshToken;

    app.contentWindow.postMessage(data, '*');
  };

  return (
    <Panel isOpen={show} onClose={() => onClose(false)}>
      {readerAppConf && (
        <NewslitApp
          appRef={readerApp}
          load={() => sendMessageToReader(readerAppConf)}
          title={'Newslit Reader'}
          uri={configuration.reader.url}
        />
      )}
    </Panel>
  );
}
