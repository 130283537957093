import React, { FunctionComponent } from 'react';
import { Brief, getKeywordsByType } from '../../services/models/brief';
import { BriefKeywordType } from '../../services/models/brief-keyword-type';
import EditBriefKeyword from '../edit-brief-keyword/EditBriefKeyword';

export interface EditBriefExclusionsProps {
  brief: Brief;
  create: (keyword: string) => void;
  destroy: (keywordId: number) => void;
  maxItems: number;
}

const EditBriefExclusions: FunctionComponent<EditBriefExclusionsProps> = ({
  brief,
  create,
  destroy,
  maxItems,
}) => {
  const briefTopics = getKeywordsByType(brief, BriefKeywordType.Topic);
  const briefExclusions = getKeywordsByType(brief, BriefKeywordType.Exclusion);

  return (
    <EditBriefKeyword
      title="Exclusions"
      maxItems={maxItems}
      tagClassName="carbon-tag"
      placeholder="press ENTER after each keyword"
      keywords={briefExclusions}
      isUpdating={!!brief.isUpdatingKeywords}
      helpSummary={
        <span>
          Articles must <strong>not</strong> include any of these words
        </span>
      }
      helpContent={
        <>
          <p>Any article containing these words won't appear. ie: jobs, courses, webinars.</p>
          <p>
            Press <code>ENTER</code> after each word.
          </p>
        </>
      }
      optional={true}
      create={keyword => create(keyword)}
      destroy={keywordId => destroy(keywordId)}
      validate={keyword =>
        (briefTopics || []).some(x => x.keyword === keyword)
          ? `There's a topic with this keyword`
          : null
      }
    />
  );
};

export default EditBriefExclusions;
