import { CheckIcon } from '@heroicons/react/solid';
import React, { FunctionComponent, HTMLAttributes, useState } from 'react';
import { Account } from '../../services/models/account';
import { Product } from '../../services/models/product';
import { SubscriptionInterval } from '../../services/models/subscription';
import { launchIntercom } from '../../utils/Intercom';
import './ProductCard.scss';

enum BuyerIntent {
  None = 'None',
  Upgrade = 'Upgrade',
  Downgrade = 'Downgrade',
  ChangeInterval = 'Change Interval',
}

interface ProductCardProps {
  account: Account;
  active: boolean;
  mostPopular: boolean;
  product: Product;
  isBilledMonthly: boolean;
  onSelected: (planId: string, product: Product) => void;
  showsConfirmation: boolean;
  showsLoading: boolean;
  handleConfirmationChange: (confirmation: boolean) => void;
}

const ProductCard: FunctionComponent<ProductCardProps> = ({
  account,
  active,
  product,
  isBilledMonthly,
  mostPopular,
  onSelected,
  showsLoading,
  handleConfirmationChange,
  showsConfirmation,
}) => {
  const [buyerIntent, setBuyerIntent] = useState(BuyerIntent.None);

  const activeBriefsCount = account.metadata?.activeReportsCount || 0;
  const isTrial = account.subscription?.trial;
  const isCurrentPlanCanceled = account.subscription?.canceled;
  const requiresConfirmation =
    account.subscription?.planId !== '' || product.features.briefs < activeBriefsCount;
  const productFeatures = product.features;
  const periodicity = isBilledMonthly ? 'month' : 'year';
  const productPlan = product.plans.find(p => p.periodicity === periodicity);

  const handleBuyerIntent = (intent: BuyerIntent) => () => {
    if (requiresConfirmation) {
      setBuyerIntent(intent);
      handleConfirmationChange(true);
      return;
    }

    executeBuyerIntent();
  };

  const executeBuyerIntent = () => {
    onSelected(productPlan!.planId, product);
  };

  const getActionButton = () => {
    const canUpgrade =
      isTrial ||
      productFeatures.briefs.toString() === 'Unlimited' ||
      productFeatures.briefs > account.product.features.briefs;

    const isCurrentPlanMonthly = account.subscription.interval !== SubscriptionInterval.Yearly;
    const sameAsCurrentProduct = product.productId === account.product.productId;
    const haveDifferentInterval = isCurrentPlanMonthly !== isBilledMonthly;

    if (isCurrentPlanCanceled) {
      return <ActionButton title="Buy" isLoading={showsLoading} onClick={executeBuyerIntent} />;
    }

    if (canUpgrade) {
      return (
        <ActionButton
          title="Upgrade"
          isLoading={showsLoading}
          onClick={handleBuyerIntent(BuyerIntent.Upgrade)}
        />
      );
    }

    if (sameAsCurrentProduct) {
      if (haveDifferentInterval) {
        return (
          <ActionButton
            title={`Change to ${
              isCurrentPlanMonthly ? SubscriptionInterval.Yearly : SubscriptionInterval.Monthly
            }`}
            isLoading={showsLoading}
            onClick={handleBuyerIntent(BuyerIntent.ChangeInterval)}
          />
        );
      } else {
        return (
          <button
            className="tw-mt-8 tw-block tw-w-full tw-bg-white tw-border tw-border-gray-300 tw-rounded-md tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-700 tw-text-center disabled:tw-opacity-50"
            disabled
          >
            Current Plan
          </button>
        );
      }
    }

    // Prevent Yearly plan downgrades
    if (!isBilledMonthly) {
      return (
        <button
          className="tw-mt-8 tw-block tw-w-full tw-bg-white tw-border tw-border-gray-300 tw-rounded-md tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-700 tw-text-center disabled:tw-opacity-50"
          onClick={launchIntercom}
        >
          Contact Us
        </button>
      );
    }

    return (
      <ActionButton
        isLoading={showsLoading}
        title="Change Plan"
        onClick={handleBuyerIntent(BuyerIntent.Downgrade)}
      />
    );
  };

  if (showsConfirmation) {
    return (
      <ConfirmationScreen
        intent={buyerIntent}
        product={product}
        account={account}
        isBilledMonthly={isBilledMonthly}
        showsLoading={showsLoading}
        onChangePlan={executeBuyerIntent}
        onCancelChange={() => handleConfirmationChange(false)}
      />
    );
  }

  return (
    <div
      key={product.name}
      className="tw-relative tw-p-6 tw-bg-white tw-border tw-border-gray-200 tw-rounded-2xl tw-shadow-sm tw-flex tw-flex-col"
    >
      <div className="tw-flex-1">
        <h3 className="tw-text-xl tw-font-semibold tw-text-gray-900">{product.name}</h3>

        {isTrial && product.mostPopular ? (
          <p className="tw-absolute tw-top-0 tw-py-1.5 tw-px-4 tw-bg-primary tw-rounded-full tw-text-xs tw-font-semibold tw-uppercase tw-tracking-wide tw-text-white tw--translate-y-1/2">
            Most popular
          </p>
        ) : null}

        <p className="tw-mt-4 tw-flex tw-items-baseline tw-text-gray-900">
          <span className="tw-text-4xl tw-font-extrabold tw-tracking-tight">
            ${productPlan!.monthlyPrice}
          </span>
          <span className="tw-ml-1 tw-text-sm tw-font-medium tw-text-gray-500">/month</span>
        </p>

        <p className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-400">
          {!isBilledMonthly ? 'Billed annually' : ' '}&nbsp;
        </p>

        {/* <p className="tw-mt-6 tw-text-gray-500">{tier.description}</p> */}

        {/* Feature list */}
        <ul className="tw-mt-4 tw-space-y-6">
          {/*{product.features.map((feature) => (*/}
          {/*  <li key={feature} className="tw-flex">*/}
          {/*    <CheckIcon className="tw-flex-shrink-0 tw-w-6 h-6 tw-text-indigo-500" aria-hidden="true" />*/}
          {/*    <span className="tw-ml-3 tw-text-gray-500">{feature}</span>*/}
          {/*  </li>*/}
          {/*))}*/}

          <li className="tw-flex">
            <CheckIcon
              className="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-green-500"
              aria-hidden="true"
            />
            <span className="tw-ml-3 tw-text-sm tw-text-gray-500">
              {productFeatures.briefs} Briefs
            </span>
          </li>
          <li className="tw-flex tw-space-x-3">
            <CheckIcon
              className="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-green-500"
              aria-hidden="true"
            />
            <span className="tw-text-sm tw-text-gray-500">
              Up to {productFeatures.recipientsPerBrief} recipient
              {productFeatures.recipientsPerBrief > 1 ? 's' : ''}
            </span>
          </li>
          <li className="tw-flex tw-space-x-3">
            <CheckIcon
              className="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-green-500"
              aria-hidden="true"
            />
            <span className="tw-text-sm tw-text-gray-500">
              Up to {productFeatures.keywordsPerBrief} Keywords
            </span>
          </li>
        </ul>
      </div>
      {getActionButton()}
    </div>
  );
};

export default ProductCard;

interface ConfirmationScreenProps {
  intent: BuyerIntent;
  product: Product;
  account: Account;
  isBilledMonthly: boolean;
  onChangePlan: () => void;
  onCancelChange: () => void;
  showsLoading: boolean;
}

const ConfirmationScreen: React.FC<ConfirmationScreenProps> = ({
  intent,
  product,
  account,
  isBilledMonthly,
  onChangePlan,
  onCancelChange,
  showsLoading,
}) => {
  const activeBriefsCount = account.metadata?.activeReportsCount || 0;

  return (
    <div className="tw-relative tw-p-6 tw-bg-white tw-border tw-border-secondary tw-rounded-2xl tw-shadow-sm tw-flex tw-flex-col">
      <div className="tw-flex-1">
        <h3 className="tw-text-xl tw-font-extrabold tw-text-gray-900">{intent}</h3>
        <div className="tw-mt-4">
          {/* Prevent accounts from downgrading if not possible */}
          {activeBriefsCount <= product.features.briefs ? (
            <>
              <p className="tw-text-gray-900">
                You are about to change to our <span className="tw-font-bold">{product.name}</span>{' '}
                plan, billed {isBilledMonthly ? 'Monthly' : 'Annually'}.
              </p>
              <p className="tw-text-gray-900">
                We'll credit you for any unused portion of your current plan.
              </p>
            </>
          ) : (
            <>
              <p className="tw-text-gray-900">
                You have <span className="tw-font-bold">{activeBriefsCount}</span> active News
                Briefs. Please, bring the number of active briefs to{' '}
                <span className="tw-font-bold">{product.features.briefs}</span> before downgrading
                your account.
              </p>
            </>
          )}
        </div>
      </div>
      <div>
        {activeBriefsCount <= product.features.briefs && (
          <ActionButton title="Change Plan" isLoading={showsLoading} onClick={onChangePlan} />
        )}

        <button
          type="button"
          className="tw-mt-3 tw-py-2 tw-w-full tw-block tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50"
          onClick={onCancelChange}
          disabled={showsLoading}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

interface ActionButtonProps extends HTMLAttributes<HTMLButtonElement> {
  title: string;
  isLoading?: boolean;
}

function ActionButton(props: ActionButtonProps) {
  const { title, isLoading, ...rest } = props;
  return (
    <button
      {...rest}
      disabled={isLoading}
      className="tw-mt-8 tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-bg-secondary tw-rounded-md tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-text-center hover:tw-bg-secondary-dark disabled:tw-opacity-50"
    >
      {isLoading && (
        <svg
          className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="tw-opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="tw-opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {title}
    </button>
  );
}
