import { useFormik } from 'formik';
import React, { useRef } from 'react';
import * as yup from 'yup';
import { Input, Modal } from '../ui';

interface UpdateEmailDialogProps {
  isOpen: boolean;
  isUpdatingName?: boolean;
  email: string;
  onClose: () => void;
  onSave: (email: string) => void;
}

export default function UpdateEmailDialog(props: UpdateEmailDialogProps) {
  const cancelButtonRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      email: props.email,
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required('Email is required'),
    }),
    onSubmit: values => {
      if (props.email !== values.email) {
        props.onSave(values.email);
      }

      props.onClose();
    },
  });

  const handleOnCancel = () => {
    formik.resetForm();
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={handleOnCancel} initialFocus={cancelButtonRef}>
      <div className="sm:flex sm:items-start">
        <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
          Update your email
        </h3>
        <div className="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
          <p>Change the email address you want associated with your account.</p>
        </div>

        <form onSubmit={formik.handleSubmit} className="tw-space-y-6 tw-mt-3">
          <div>
            <label htmlFor="email" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
              Email
            </label>
            <div className="tw-mt-1">
              <Input
                type="text"
                name="email"
                id="email"
                required
                autoComplete="given-name"
                error={
                  formik.touched.email && formik.errors.email ? formik.errors.email : undefined
                }
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div className="tw-space-y-1">
            <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
              <button
                type="submit"
                className="tw-mt-3 tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm disabled:tw-opacity-50"
                disabled={
                  !formik.isValid ||
                  (Object.keys(formik.touched).length === 0 &&
                    formik.touched.constructor === Object)
                }
              >
                Save
              </button>
              <button
                type="button"
                className="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm"
                onClick={handleOnCancel}
                ref={cancelButtonRef}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
