import { Intent } from '@blueprintjs/core';
import { AppToaster } from '../../../utils/toaster';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const cancelSubsBeforeDeactivationErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: any,
  discard: DiscardErrorCallback
) => {
  const message = 'Account needs to cancel active subscription before deactivating';

  if (error.message.indexOf(message) === -1) {
    return null;
  }

  discard(error);

  AppToaster.show({
    intent: Intent.WARNING,
    icon: 'warning-sign',
    message:
      'Please cancel your active subscription before deactivating. Go to <a href="/dashboard/settings">settings page</a> and cancel your subscription first.',
  });

  return false;
};
