import { BriefSettingsAction } from '../../actions/brief-settings-actions';
import { SlackIntegration } from '../../store-state';

export default function slackIntegrationReducer(
  state: SlackIntegration = {},
  action: any
): SlackIntegration {
  switch (action.type) {
    case BriefSettingsAction.StartAddingSlackChannel:
      return { ...state, isAddingSlackToBrief: true };

    case BriefSettingsAction.CompleteAddingSlackChannel:
      return {
        ...state,
        isAddingSlackToBrief: false,
        briefAdded: true,
        briefId: action.briefId,
        // TODO(Jose): Investigate if this can be removed
        // returnRoute: action.returnRoute,
      };

    case BriefSettingsAction.CancelAddingSlackChannel:
      return { ...state, isAddingSlackToBrief: false };

    default:
      return state;
  }
}
