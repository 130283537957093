import gql from 'graphql-tag';
import { EmptyResponseError } from './errors/empty-response-error';
import { getGraphQLClient } from './graphql-client';
import { EmailUnsubscribeLink } from './models/email-unsubscribe-link';

export const getEmailUnsubscribeLink = async (
  verificationCode: string,
  emailId: string
): Promise<EmailUnsubscribeLink> => {
  const client = getGraphQLClient();
  const response = await client.query<{ emailUnsubscribeLink: EmailUnsubscribeLink }>({
    query: _getEmailUnsubscribeLinkQuery(),
    variables: {
      verificationCode,
      emailId,
    },
  });

  if (!response.data || !response.data.emailUnsubscribeLink) {
    throw new EmptyResponseError();
  }

  return response.data.emailUnsubscribeLink;
};

const _getEmailUnsubscribeLinkQuery = () => {
  return gql`
    query emailUnsubscribeLink($verificationCode: String!, $emailId: String!) {
      emailUnsubscribeLink(c: $verificationCode, e: $emailId) {
        e
        c
        emailAddress
        receiveDigest
        digestHour
      }
    }
  `;
};

export const unsubscribeFromSocialDigest = async (
  verificationCode: string,
  emailId: string
): Promise<boolean> => {
  const client = getGraphQLClient();
  const response = await client.mutate<{ emailUnsubscribe: { ok: boolean } }>({
    mutation: _getUnsubscribeFromSocialDigestMutation(),
    variables: {
      verificationCode,
      emailId,
    },
  });

  if (!response.data || !response.data.emailUnsubscribe.ok) {
    throw new EmptyResponseError();
  }

  return response.data.emailUnsubscribe.ok;
};

const _getUnsubscribeFromSocialDigestMutation = () => {
  return gql`
    mutation emailUnsubscribe($verificationCode: String!, $emailId: String!) {
      emailUnsubscribe(input: { e: $emailId, c: $verificationCode }) {
        ok
      }
    }
  `;
};
