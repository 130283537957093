export enum FeatureFlag {
  ShowAddToSlack = 'showAddToSlack',
  ShowReportLanguage = 'showReportLanguage',
  ShowSocialAlerts = 'showSocialAlerts',
  ShowSocialBrief = 'showSocialBrief',
  ReportStoryClickShowStoryReader = 'reportStoryClickShowStoryReader',
  NewReportShowPreviewScreen = 'newReportShowPreviewScreen',
}

export interface FeatureFlags {
  isFetching?: boolean;
  flags?: { [featureFlag in FeatureFlag]: boolean };
}
