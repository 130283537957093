import React, { FunctionComponent, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { verifyLogin } from '../../../../redux/actions/session-actions';
import { StoreState } from '../../../../redux/store-state';
import { Session } from '../../../../services/models/session';
import './VerifyLoginPage.scss';

interface Props {
  session: Session;
  verifyLogin: (code: string) => void;
}

const VerifyLoginPage: FunctionComponent<Props> = ({ session, verifyLogin }) => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stateToken = queryParams.get('state');

    if (!stateToken) {
      history.push('/');
      return;
    }

    setToken(stateToken);
  }, [location.search, history, setToken]);

  useEffect(() => {
    if (session && session.account && session.token) {
      history.push('/');
    }

    if (session.isFetching === false) {
      setLoading(false);
    }
  }, [session, history]);

  useEffect(() => {
    if (token) {
      verifyLogin(token);
    }
  }, [token, verifyLogin]);

  if (!loading) {
    return (
      <div>
        <Card>
          <Card.Body>
            <h1 className="verify-login-content-header">Oops, something went wrong</h1>
            <Form noValidate>
              <Button className="mt-3 btn-block" as={Link} to={'/'}>
                Back
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <h1 className="verify-login-content-header text-center">
            <Spinner animation="grow"></Spinner> Verifying login...
          </h1>
        </Card.Body>
      </Card>
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    verifyLogin: (token: string) => dispatch(verifyLogin(token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyLoginPage);
