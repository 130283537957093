export interface IntercomProps {
  appId: string;
  deactivated?: boolean;
}

export const launchIntercom = () => {
  const wnd = window as any;
  if (wnd['Intercom']) {
    wnd['Intercom']('showNewMessage');
  }
};
