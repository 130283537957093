import { StoryTweetCard } from '@newslit/uikit';
import React, { FunctionComponent, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAppSelector } from '../../hooks/useAppSelector';
import { StoreState } from '../../redux/store-state';
import { hsp, isInHootSuite } from '../../services/hootsuite';
import { AppSetting, BooleanSettingValue } from '../../services/models/app-settings';
import { Story } from '../../services/models/story';
import { StoryTweet } from '../../services/models/story-tweet';
import StoryCardLink from '../story-card-link/StoryCardLink';
import StoryCardShareOptions from '../story-card-share-options/StoryCardShareOptions';
import './BriefStoryCard.scss';

const selectOpenInNewTab = (state: StoreState) => {
  const settings = state.session?.account?.settings || [];
  const newTabSetting = settings.find(x => x.name === AppSetting.NewTab);
  return !!newTabSetting ? newTabSetting.value === BooleanSettingValue.Yes : true;
};

interface BriefStoryCardProps {
  briefId: number;
  story: Story;
  isPreview?: boolean;
  requestStoryTweets?: (story: Story) => void;
  titleClick?: (storyId: number, storyUri: string) => void;
}

const BriefStoryCard: FunctionComponent<BriefStoryCardProps> = ({
  briefId,
  story,
  titleClick,
  isPreview,
  requestStoryTweets,
}) => {
  const openInNewTab = useAppSelector(selectOpenInNewTab);
  const inHootsuite = isInHootSuite();

  const goToTwitter = (tweet: StoryTweet) => {
    const url = `http://twitter.com/${tweet.username}`;

    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  };

  useEffect(() => {
    if (requestStoryTweets && !story.isFetchingTweets && !story.tweetsLoaded) {
      requestStoryTweets(story);
    }
  }, [requestStoryTweets, story]);

  const getStoryTools = () => {
    if (isPreview) return null;

    return (
      <div className="StoryCard__Info-bar">
        <StoryCardShareOptions briefId={briefId} story={story} isPreview={isPreview} />

        <div className="score-info">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${story.id}`}>Interest Score - {story.scoreLabel}</Tooltip>
            }
          >
            <div>
              <div className="score-label">
                <span className="blue-text">{story.score}</span>
                <span className="gray-text">/100</span>
              </div>
              <div className="score">
                <div
                  className="score-bar"
                  style={{ width: `${story.score}%`, backgroundColor: `#${story.scoreHexColor}` }}
                />
              </div>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    );
  };

  const getStoryDate = () => {
    return new Intl.DateTimeFormat('en', {
      month: 'short',
      day: 'numeric',
    }).format(new Date(story.publicationDate));
  };

  const renderTweets = () => {
    if (story.tweets && story.tweets.length > 0) {
      const currentTweet = story.tweets[0];
      return (
        <StoryTweetCard
          tweet={currentTweet}
          openClickCommand={() => {
            if (!inHootsuite) {
              goToTwitter(currentTweet);
            } else {
              console.log('[Newslit] Showing user');
              hsp.showUser(currentTweet.username);
            }
          }}
        />
      );
    }
  };

  return (
    <div className="BriefStoryCard">
      <div className="BriefStoryCard__Body">
        <article className="StoryCard">
          {story.imageUrl && (
            <figure className="StoryCard__Image">
              <StoryCardLink
                isClicked={story.isClicked}
                briefId={briefId}
                storyId={story.id}
                href={story.url}
              >
                <div
                  className="image-container rounded"
                  style={{ backgroundImage: `url("${story.imageUrl}")` }}
                />
              </StoryCardLink>
            </figure>
          )}

          <div className="StoryCard__Publisher gray-text text-uppercase">{story.source}</div>

          <h2 className="StoryCard__Title mt-1">
            <StoryCardLink
              isClicked={story.isClicked}
              briefId={briefId}
              storyId={story.id}
              href={story.url}
            >
              {story.title}
            </StoryCardLink>
          </h2>

          <div className="StoryCard__Details">
            {story.author && <span className="date-pub">{story.author}&nbsp;–&nbsp;</span>}
            <time dateTime={story.publicationDate.toLocaleString()} className="date-pub">
              {getStoryDate()}
            </time>
          </div>

          <div className="StoryCard__Excerpt">{story.excerpt}</div>

          {getStoryTools()}
        </article>

        {renderTweets()}
      </div>
    </div>
  );
};

export default BriefStoryCard;
