import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SignInPage from './signin/SignInPage';
import SignUpPage from './signup/SignUpPage';

const UsersPage: FunctionComponent = () => {
  return (
    <>
      <Switch>
        <Route path="/users/signin" component={SignInPage} />
        <Route path="/users/signup" component={SignUpPage} />
        <Route render={() => <Redirect to={'/users/signin'} />} />
      </Switch>
    </>
  );
};

export default UsersPage;
