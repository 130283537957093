import { Dialog } from '@headlessui/react';
import React from 'react';
import { Modal } from '../ui';

interface IOSInstallModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function IOSInstallModal(props: IOSInstallModalProps) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <div className="sm:tw-flex sm:tw-items-start">
        <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
          <Dialog.Title as="h3" className="tw-text-lg tw-leading-6 tw-font-medium tw-text-primary">
            Install Newslit
          </Dialog.Title>
          <div className="tw-mt-2">
            <p className="css-obkyno">
              Press the{' '}
              <strong>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-4 tw-w-4 tw-flex tw-relative tw-inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>{' '}
                Share
              </strong>{' '}
              button in Safari to add Newslit to your Home Screen and use it as a native app.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
