import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { Button, Form, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { requestBriefDetails } from '../../redux/actions/briefs-actions';
import { resetSlackAuthInformation } from '../../redux/actions/external-auth-actions';
import { Brief } from '../../services/models/brief';
import { BriefStatus } from '../../services/models/brief-status';
import { launchPopupWindow } from '../../services/popups';
import { getAddToSlackLink } from '../../services/slack';
import './ConnectReportToSlackChannelDialog.scss';

interface Props {
  open: boolean;
  briefs: Brief[];
  addToSlackLink: string | null;
  onClose: () => void;
}

const ConnectReportToSlackChannelDialog: FunctionComponent<Props> = ({ open, briefs, onClose }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [selectedBrief, setSelectedBrief] = useState<Brief | null>(null);

  const close = () => {
    onClose();
  };

  const selectBrief = async (brief: Brief, event: SyntheticEvent) => {
    event.preventDefault();
    setSelectedBrief(brief);
    const addToSlackUrl = await getAddToSlackLink(brief.id, location.pathname);

    if (addToSlackUrl) {
      launchPopupWindow(
        addToSlackUrl,
        'location=yes,scrollbars=yes,status=yes',
        { height: 700, width: 800 },
        () => {
          setSelectedBrief(null);
          dispatch(requestBriefDetails(brief.id));
          dispatch(resetSlackAuthInformation());
        }
      );
    }
  };

  const isLoading = () => selectedBrief !== null;

  const getBriefsList = () => {
    if (briefs.length === 0) {
      return (
        <div>
          You don't have any report yet. Let's{' '}
          <Link to="/dashboard/brief">create your first News Brief</Link>.
        </div>
      );
    }

    return briefs
      .filter(brief => {
        return brief.status !== BriefStatus.Draft;
      })
      .map(brief => (
        <ListGroup.Item
          key={brief.id}
          disabled={isLoading()}
          action
          onClick={(e: any) => selectBrief(brief, e)}
        >
          <div className="connect-report-item">
            <span className="connect-report-item-name">{brief.name}</span>
            {(() => {
              if (selectedBrief && selectedBrief.id === brief.id) {
                return <Spinner size="sm" animation="border"></Spinner>;
              } else {
                return <div className="connect-report-item-slack-icon"></div>;
              }
            })()}
          </div>
        </ListGroup.Item>
      ));
  };

  return (
    <Modal centered show={open} onHide={() => close()}>
      <Form noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Select a Brief to connect with Slack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">{getBriefsList()}</ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" disabled={isLoading()} onClick={() => close()}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ConnectReportToSlackChannelDialog;
