import React, { FunctionComponent, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { verifyEmail } from '../../../../redux/actions/email-actions';
import { StoreState } from '../../../../redux/store-state';
import { Session } from '../../../../services/models/session';
import './EmailVerificationPage.scss';

interface Props {
  session: Session;
  verifyEmail: (code: string) => void;
}

type PageParams = {
  code: string;
};

const EmailVerificationPage: FunctionComponent<Props> = ({ session, verifyEmail }) => {
  const history = useHistory();
  let { code } = useParams<PageParams>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (code) {
      verifyEmail(code);
    } else {
      history.push('/');
    }
  }, [code, history, verifyEmail]);

  useEffect(() => {
    if (session?.account?.email?.isVerifying === false) {
      setLoading(false);
    }

    if (session?.account?.email?.verified) {
      history.push('/');
    }
  }, [session, history]);

  return (
    <div>
      <Helmet>
        <title>Email verification</title>
      </Helmet>

      <Card>
        <Card.Body>
          <h1 className="verify-email-content-header text-center">
            {(() => {
              if (loading) {
                return (
                  <>
                    <Spinner animation="grow"></Spinner> Verifying email...
                  </>
                );
              }
              return 'Oops, something went wrong';
            })()}
          </h1>
          <Form noValidate>
            {(() => {
              if (!loading) {
                return (
                  <Button className="mt-3 btn-block" as={Link} to={'/'}>
                    Back
                  </Button>
                );
              }
            })()}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    verifyEmail: (code: string) => dispatch(verifyEmail(code)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPage);
