import { Story } from '../../../../../../services/models/story';
import { BriefStoriesAction } from '../../../../../actions/brief-stories-actions';
import { BriefStoryTweetsAction } from '../../../../../actions/brief-story-tweets-actions';
import briefStoryReducer from './story';
import briefStoryTweetsReducer from './story/tweets';

export default function briefStoriesReducer(state: Story[] = [], action: any) {
  switch (action.type) {
    case BriefStoriesAction.CompleteFetchingPage:
      return completeFetchingBriefStoriesPage(state, action);

    case BriefStoriesAction.StartFetchingShareData:
    case BriefStoriesAction.CompleteFetchingShareData:
    case BriefStoriesAction.CancelFetchingShareData:
    case BriefStoriesAction.CompleteMarkStoryAsViewed:
    case BriefStoryTweetsAction.StartFetching:
    case BriefStoryTweetsAction.CompleteFetching:
    case BriefStoryTweetsAction.CancelFetching:
      return reduceBriefStory(state, action);

    default:
      return state;
  }
}

const completeFetchingBriefStoriesPage = function (state: Story[], action: any) {
  const newStories: Story[] = action.stories;
  const stories: Story[] = [];
  const isFirstPage: boolean = action.isFirstPage;

  if (isFirstPage) {
    newStories.forEach(newStory => {
      const story = state.find(oldStory => oldStory.id === newStory.id);

      if (story && story.tweets) {
        newStory.tweets = briefStoryTweetsReducer(story.tweets, action);
      }
    });

    return [...newStories];
  }

  state.forEach(existentStory => {
    const overwrittenStory = newStories.find(x => x.id === existentStory.id);

    if (overwrittenStory) {
      overwrittenStory.tweets = briefStoryTweetsReducer(existentStory.tweets, action);
      stories.push(overwrittenStory);
    } else {
      stories.push(existentStory);
    }
  });

  newStories.forEach(newStory => {
    const existentStory = state.find(oldStory => oldStory.id === newStory.id);

    if (!existentStory) {
      stories.push(newStory);
    }
  });

  return [...stories];
};

const reduceBriefStory = function (state: Story[], action: any) {
  return state.map(story =>
    story.id === action.storyId ? briefStoryReducer(story, action) : story
  );
};
