import React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const emailNotConnectedErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: any,
  discard: DiscardErrorCallback
) => {
  if (error.message.indexOf('Email not connected') === -1) {
    return null;
  }

  return (
    <Alert key={index} variant="warning" dismissible onClose={() => discard(error)}>
      Looks like your email isn't connected to an account yet.{' '}
      <Alert.Link as={Link} to="/users/signup">
        Start a free trial today
      </Alert.Link>
      .
    </Alert>
  );
};
