import { PlusIcon } from '@heroicons/react/solid';
import React, { MouseEventHandler } from 'react';

interface AddNewBriefBtnProps {
  onClick?: MouseEventHandler;
}

export function AddNewBriefBtn(props: AddNewBriefBtnProps) {
  return (
    <button
      type="button"
      className="tw-order-0 tw-w-full tw-flex tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark hover:tw-text-white hover:tw-no-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
      onClick={props.onClick}
    >
      <PlusIcon className="tw--ml-1 tw-mr-2 tw-h-5 tw-w-5" aria-hidden="true" /> Add New Brief
    </button>
  );
}
