import { Setting } from '../../../../../services/models/setting';
import { SettingsAction } from '../../../../actions/settings-actions';

export default function settingsReducer(state: Setting[] = [], action: any) {
  switch (action.type) {
    case SettingsAction.CompleteUpdating:
    case SettingsAction.CompleteUpdatingTimeZone:
      return completeUpdatngSetting(state, action);
    default:
      return state;
  }
}

const completeUpdatngSetting = (state: Setting[], action: any) => {
  const modifiedSettings: Setting[] = action.settings;
  return [
    ...state.filter(s => !modifiedSettings.some(m => m.name === s.name)),
    ...modifiedSettings,
  ];
};
