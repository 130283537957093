import React, { FunctionComponent } from 'react';
import { Brief, getKeywordsByType } from '../../services/models/brief';
import { BriefKeywordType } from '../../services/models/brief-keyword-type';
import EditBriefKeyword from '../edit-brief-keyword/EditBriefKeyword';

export interface EditBriefFiltersProps {
  brief: Brief;
  create: (keyword: string) => void;
  destroy: (keywordId: number) => void;
  maxItems: number;
}

const EditBriefFilters: FunctionComponent<EditBriefFiltersProps> = ({
  brief,
  create,
  destroy,
  maxItems,
}) => {
  const briefFilters = getKeywordsByType(brief, BriefKeywordType.Filter);

  return (
    <EditBriefKeyword
      title="Filters"
      maxItems={maxItems}
      tagClassName="purple-tag"
      placeholder="press ENTER after each keyword"
      keywords={briefFilters}
      isUpdating={!!brief.isUpdatingKeywords}
      helpSummary={
        <span>
          Articles <strong>must</strong> include at least one of these words
        </span>
      }
      helpContent={
        <>
          <p>
            Only articles that have one of these keywords <strong>AND</strong> one of your topics
            will appear. ie: trends, updates, tools.
          </p>
          <p>
            Press <code>ENTER</code> after each word.
          </p>
        </>
      }
      optional={true}
      create={keyword => create(keyword)}
      destroy={keywordId => destroy(keywordId)}
    />
  );
};

export default EditBriefFilters;
