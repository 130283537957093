import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import SettingsPageLayout from '../../../../layouts/settings/settingsPageLayout';
import { ApiApp } from '../../../../services/models/api-app';
import { AppType } from '../../../../services/models/app-type';

const ApiIntegrationPage: FunctionComponent = () => {
  const apiApp = useAppSelector(state => {
    let apiApps: ApiApp[] = state.session?.account?.apiApps || [];
    return apiApps.find(x => x.type === AppType.Api);
  });

  if (!apiApp) return <Redirect to="/dashboard/settings/integrations" />;

  return (
    <SettingsPageLayout title="API | Newslit">
      <div className="tw-mt-10 tw-divide-y tw-divide-gray-200">
        <div className="tw-space-y-1">
          <h3 className="tw-text-lg tw-leading-6 tw-font-bold tw-text-gray-900">API Integration</h3>
          <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500 tw-m-0">
            Integrate your briefs into your application or website through a single API
          </p>
        </div>

        <div className="tw-mt-6">
          <dl className="tw-divide-y tw-divide-gray-200">
            <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
              <dt className="tw-text-sm tw-font-medium tw-text-gray-500">API Key</dt>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <span className="tw-flex-grow">
                  <code>{apiApp.apiKey}</code>
                </span>
              </dd>
            </div>
          </dl>
          <p className="tw-text-gray-600 tw-font-normal tw-mt-4">
            For more information on the API please see our{' '}
            <a
              rel="noopener noreferrer"
              href="https://hackmd.io/@newslit/r19VgU7GB"
              target="_blank"
              className="tw-font-bold"
            >
              API documentation
            </a>
          </p>
        </div>
      </div>
    </SettingsPageLayout>
  );
};

export default ApiIntegrationPage;
