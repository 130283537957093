import React, { FunctionComponent } from 'react';

export const VectorIcon: FunctionComponent<{
  file: string;
  alt?: string;
  size?: number;
}> = ({ file, alt, size = 24 }) => {
  return (
    <img
      className="imgIcon tw-inline"
      width={`${size}px`}
      src={`/images/dashboard/${file}.svg`}
      alt={alt}
    />
  );
};
