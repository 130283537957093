import * as briefStoriesManager from '../../services/brief-stories';
import { Story } from '../../services/models/story';
import { StoryShareData } from '../../services/models/story-share-data';
import * as plausible from '../../services/plausible';
import { asyncAction, Dispatch } from '../types';

export enum BriefStoriesAction {
  // Stories
  StartFetching = 'BriefStoriesAction/StartFetching',
  CompleteFetchingPage = 'BriefStoriesAction/CompleteFetchingPage',
  CancelFetching = 'BriefStoriesAction/CancelFetching',

  // Story Preview
  StartFetchingPreview = 'BriefStoriesAction/StartFetchingPreview',
  CompleteFetchingPreview = 'BriefStoriesAction/CompleteFetchingPreview',
  CancelFetchingPreview = 'BriefStoriesAction/CancelFetchingPreview',

  StartFetchingShareData = 'BriefStoriesAction/StartFetchingShareData',
  CompleteFetchingShareData = 'BriefStoriesAction/CompleteFetchingShareData',
  CancelFetchingShareData = 'BriefStoriesAction/CancelFetchingShareData',

  StartMarkStoryAsViewed = 'BriefStoriesAction/StartMarkStoryAsViewed',
  CompleteMarkStoryAsViewed = 'BriefStoriesAction/CompleteMarkStoryAsViewed',
  CancelMarkStoryAsViewed = 'BriefStoriesAction/CancelMarkStoryAsViewed',
}

export const requestBriefStoriesPage = (briefId: number, after: number, pageSize: number = 20) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startFetchingBriefStories(briefId));
      const page = await briefStoriesManager.getBriefStoriesPaged(briefId, after, pageSize);
      dispatch(
        completeFetchingBriefStoriesPage(
          briefId,
          page.hasMoreStories,
          after === 0,
          page.lastCursor,
          page.stories
        )
      );
    },
    () => cancelFetchingBriefStories(briefId)
  );

export const startFetchingBriefStories = (briefId: number) => ({
  type: BriefStoriesAction.StartFetching,
  briefId: briefId,
});

export const completeFetchingBriefStoriesPage = (
  briefId: number,
  hasMoreStories: boolean,
  isFirstPage: boolean,
  lastCursor: number,
  stories: Story[]
) => ({
  type: BriefStoriesAction.CompleteFetchingPage,
  hasMoreStories,
  isFirstPage,
  lastCursor,
  briefId: briefId,
  stories,
});

export const cancelFetchingBriefStories = (briefId: number) => ({
  type: BriefStoriesAction.CancelFetching,
  briefId: briefId,
});

export const requestBriefStoriesPreview = (briefId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startFetchingPreview(briefId));
      const storiesPreview = await briefStoriesManager.getBriefStoriesPreview(briefId);
      dispatch(completeFetchingPreview(briefId, storiesPreview));
    },
    () => cancelFetchingPreview(briefId)
  );

export const startFetchingPreview = (briefId: number) => ({
  type: BriefStoriesAction.StartFetchingPreview,
  briefId,
});

export const completeFetchingPreview = (briefId: number, storiesPreview: Story[]) => ({
  type: BriefStoriesAction.CompleteFetchingPreview,
  briefId,
  storiesPreview,
});

export const cancelFetchingPreview = (briefId: number) => ({
  type: BriefStoriesAction.CancelFetchingPreview,
  briefId,
});

export const getBriefStoryShareData = (briefId: number, storyId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startFetchingShareData(briefId, storyId));
      const shareData = await briefStoriesManager.getStoryShareData(briefId, storyId);
      dispatch(completeFetchingBriefStoryShareData(briefId, storyId, shareData));
    },
    () => cancelFetchingShareData(briefId, storyId)
  );

export const startFetchingShareData = (briefId: number, storyId: number) => ({
  type: BriefStoriesAction.StartFetchingShareData,
  briefId: briefId,
  storyId: storyId,
});

export const completeFetchingBriefStoryShareData = (
  briefId: number,
  storyId: number,
  shareData: StoryShareData
) => ({
  type: BriefStoriesAction.CompleteFetchingShareData,
  briefId: briefId,
  storyId: storyId,
  shareData: shareData,
});

export const cancelFetchingShareData = (briefId: number, storyId: number) => ({
  type: BriefStoriesAction.CancelFetchingShareData,
  briefId: briefId,
  storyId: storyId,
});

export const markStoryAsViewed = (storyId: number, briefId?: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startMarkStoryAsViewed(storyId, briefId));

      // TODO(Jose): use briefId for mark story as viewed flow
      plausible.trackEvent('Story Clicked', { source: briefId ? 'brief' : 'social' });

      const viewedStoryId = await briefStoriesManager.markStoryAsViewed(storyId);

      if (viewedStoryId) {
        dispatch(completeMarkStoryAsViewed(storyId, briefId));
      } else {
        cancelMarkStoryAsViewed(storyId, briefId);
      }
    },
    () => cancelMarkStoryAsViewed(storyId, briefId)
  );

export const startMarkStoryAsViewed = (storyId: number, briefId?: number) => ({
  type: BriefStoriesAction.StartMarkStoryAsViewed,
  storyId,
  briefId,
});

export const completeMarkStoryAsViewed = (storyId: number, briefId?: number) => ({
  type: BriefStoriesAction.CompleteMarkStoryAsViewed,
  storyId,
  briefId,
});

export const cancelMarkStoryAsViewed = (storyId: number, briefId?: number) => ({
  type: BriefStoriesAction.CancelMarkStoryAsViewed,
  storyId,
  briefId,
});
