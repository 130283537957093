export const BillingRoute = '/dashboard/settings/plan';
export const UpgradeRoute = '/dashboard/upgrade';
export const HomeRoute = '/dashboard/home';
export const OnboardingRoute = '/dashboard/onboarding';
export const OnboardingReportRoute = '/dashboard/onboarding/new-report';
export const PricingRoute = '/dashboard/settings/pricing';
export const SocialRoute = '/dashboard/social';
export const BriefRoute = '/dashboard/brief';
export const CheckoutRoute = '/dashboard/settings/plan/checkout/';
export const CheckoutCallbackRoute = '/treasury/callback';
