export interface HSP {
  init(data: object): void;

  updatePlacementSubtitle(subtitle: string): void;

  showUser(name: string): void;

  composeMessage(message: string, params?: { shortenLinks: boolean }): void;

  composeMessageV2(message: string, params?: { scheduleTimestamp: number | boolean }): void;

  bind(
    eventName: 'sendtoapp' | 'refresh' | 'sendassignmentupdates' | 'savemessagetoapp',
    callback: Function
  ): void;

  saveData(data: object): void;

  getData(callback: (data: any) => void): void;

  getMemberInfo(callback: (memberInfo: { userId: string }) => void): void;
}

export const hsp: HSP = (window as any).hsp;

export const isInHootSuite = () => {
  try {
    return (
      (window as any).top.location.hostname.startsWith('hootsuite.') &&
      (window as any).hasOwnProperty('hsp')
    );
  } catch {
    return true;
  }
};

export const getHootsuiteUID = (): Promise<string | undefined> => {
  return new Promise(resolve => {
    if (!isInHootSuite()) {
      resolve(undefined);
    } else {
      hsp.getMemberInfo(info => {
        resolve(info.userId);
      });
    }
  });
};
