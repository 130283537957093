import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment, MutableRefObject, ReactNode } from 'react';

interface PanelProps {
  isOpen: boolean;
  initialFocus?: MutableRefObject<HTMLElement | null>;
  onClose: (value: boolean) => void;
  children?: ReactNode;
}

export default function Panel(props: PanelProps) {
  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="tw-fixed tw-inset-0 tw-overflow-hidden" onClose={props.onClose}>
        <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-in-out tw-duration-500"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in-out tw-duration-500"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-absolute tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
          </Transition.Child>

          <div className="tw-fixed tw-inset-y-0 tw-right-0 tw-max-w-full tw-flex">
            <Transition.Child
              as={Fragment}
              enter="tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
              enterFrom="tw-translate-x-full"
              enterTo="tw-translate-x-0"
              leave="tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
              leaveFrom="tw-translate-x-0"
              leaveTo="tw-translate-x-full"
            >
              <div className="tw-relative tw-w-screen tw-max-w-2xl">
                <Transition.Child
                  as={Fragment}
                  enter="tw-ease-in-out tw-duration-500"
                  enterFrom="tw-opacity-0"
                  enterTo="tw-opacity-100"
                  leave="tw-ease-in-out tw-duration-500"
                  leaveFrom="tw-opacity-100"
                  leaveTo="tw-opacity-0"
                >
                  <div className="tw-absolute tw-top-0 tw-right-0 tw-pt-4 tw-pr-2 tw-flex sm:tw-pr-4">
                    <button
                      type="button"
                      className="tw-rounded-md tw-text-gray-400 tw-bg-white hover:tw-text-grey-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white"
                      onClick={() => props.onClose(true)}
                    >
                      <span className="tw-sr-only">Close panel</span>
                      <XIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl">
                  {props.children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
