import { SharedLink } from '../../../../../services/models/shared-link';
import { BriefStoriesAction } from '../../../../actions/brief-stories-actions';
import { SharedLinkAction } from '../../../../actions/shared-link-action';
import storyReducer from './story';

export default function sharedLinkReducer(
  state: SharedLink = {} as SharedLink,
  action: any
): SharedLink {
  switch (action.type) {
    case SharedLinkAction.StartFetching:
      return { ...state, isFetching: true };

    case SharedLinkAction.CompleteFetching:
      return {
        ...state,
        ...action.sharedLink,
        isFetching: false,
        isLoaded: !!action.sharedLink.story,
      };

    case SharedLinkAction.CancelFetching:
      return { ...state, isFetching: false };

    case BriefStoriesAction.CompleteMarkStoryAsViewed:
      return {
        ...state,
        story: state.id === action.storyId ? storyReducer(state.story, action) : state.story,
      };

    default:
      return state;
  }
}
