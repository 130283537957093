import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Redirect, Route, Switch } from 'react-router-dom';
import newslitLogo from '../../assets/images/newslit-wordmark-logo.png';
import { isAuthenticated } from '../../services/authentication';
import ErrorReporter from '../error-reporter/ErrorReporter';
import ProtectedRoute from '../protected-route/ProtectedRoute';
import ActivateAccountPage from './pages/activate-account-page/ActivateAccountPage';
import ConfirmPasswordResetPage from './pages/confirm-password-reset-page/ConfirmPasswordResetPage';
import EmailUnsubscribePage from './pages/email-unsubscribe/EmailUnsubscribePage';
import EmailVerificationPage from './pages/email-verification/EmailVerificationPage';
import PasswordResetPage from './pages/password-reset-page/PasswordResetPage';
import SignOutPage from './pages/signout-page/SignOutPage';
import SlackCallbackPage from './pages/slack-callback-page/SlackCallbackPage';
import TwitterCallbackPage from './pages/twitter-callback-page/TwitterCallbackPage';
import TwitterPopupPage from './pages/twitter-popup-page/TwitterPopupPage';
import VerifyLoginPage from './pages/verify-login-page/VerifyLoginPage';
import './SingleColumnLayout.scss';

export default class SingleColumnLayout extends Component {
  componentDidMount() {
    document.body.style.backgroundColor = '#f1f1f1';
    const authenticated = isAuthenticated();
    if (authenticated) {
      this.setState({ redirectToDashboard: true });
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = '';
  }

  getSubRoutes() {
    return (
      <Switch>
        <ProtectedRoute path={`/auth/callback/slack`}>
          <SlackCallbackPage />
        </ProtectedRoute>
        <Route path={`/auth/callback/twitter`}>
          <TwitterCallbackPage />
        </Route>
        <Route path={`/email/unsubscribe`} component={EmailUnsubscribePage} />
        <Route exact path={`/login`}>
          <VerifyLoginPage />
        </Route>
        <Route exact path={`/popup/twitter`}>
          <TwitterPopupPage />
        </Route>
        <Route path={`/users/activate`}>
          <ActivateAccountPage />
        </Route>
        <Route path={`/users/signout`}>
          <SignOutPage />
        </Route>
        <Route path={`/users/password_reset`}>
          <PasswordResetPage />
        </Route>
        <Route path={`/users/reset_password`}>
          <ConfirmPasswordResetPage />
        </Route>
        <Route path={`/verify-email/:code`}>
          <EmailVerificationPage />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }

  render() {
    return (
      <div className="users-layout">
        <Container>
          <Row className="users-layout-header justify-content-center">
            <a href="https://newslit.co/">
              <img className="users-layout-logo" src={newslitLogo} alt="Newslit" />
            </a>
          </Row>

          <Row className="justify-content-center">
            <Col xs="12" md="7" lg="5">
              <ErrorReporter />
              {this.getSubRoutes()}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
