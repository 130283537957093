import React, { Component, SyntheticEvent } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { requestPasswordReset } from '../../../../redux/actions/session-actions';
import { StoreState } from '../../../../redux/store-state';
import { Session } from '../../../../services/models/session';
import './PasswordResetPage.scss';

interface PasswordResetPageProps {
  session: Session;
  requestPasswordReset: (email: string) => void;
}

interface PasswordResetPageState {
  email: string;
  emailTouched?: boolean;
  wrongEmail?: boolean;
  wrongCredentials?: boolean;
  emailNotFound?: boolean;
}

class PasswordResetPage extends Component<PasswordResetPageProps, PasswordResetPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      emailTouched: false,
    };
  }

  async handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    this.props.requestPasswordReset(this.state.email);
  }

  handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const wrongEmail = !regex.test(value);
    this.setState({
      email: value,
      emailTouched: true,
      wrongEmail,
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>

        {this.redirect()}

        <Card>
          <Card.Body>
            <h1 className="password-reset-content-header text-center">Forgot Your Password?</h1>
            <div>{this.showError()}</div>
            <div>{this.showForm()}</div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  showError() {
    if (this.state.emailNotFound) {
      return (
        <Alert variant="danger">
          Email not found. Please contact us at <a href="mailto:help@newslit.co">help@newslit.co</a>{' '}
          if you continue having issues.
        </Alert>
      );
    }
  }

  showForm() {
    return (
      <Form noValidate onSubmit={e => this.handleSubmit(e)}>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            isInvalid={this.state.wrongEmail}
            value={this.state.email}
            onChange={(e: any) => this.handleEmail(e)}
            placeholder="Email"
            required
          />
          <Form.Text muted>
            Enter the email address of your Newslit account. You will receive an email to reset your
            password.
          </Form.Text>
        </Form.Group>

        <Button
          disabled={
            this.props.session.isRequestingPasswordReset ||
            !this.state.emailTouched ||
            this.state.wrongEmail
          }
          className="mt-3 btn-block"
          variant="purple"
          type="submit"
        >
          {this.props.session.isRequestingPasswordReset ? 'Sending...' : 'Send reset instructions'}
        </Button>
      </Form>
    );
  }

  redirect() {
    if (this.props.session.isPasswordResetRequested) {
      return <Redirect to="/users/signin"></Redirect>;
    }
  }
}

function mapStateToProps(state: StoreState) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    requestPasswordReset: (email: string) => dispatch(requestPasswordReset(email)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
