import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import newslitLogo from '../../../assets/images/newslit-logo.svg';
import rocketIllustration from '../../../assets/images/rocket-illustration.png';
import ErrorReporter from '../../../components/error-reporter/ErrorReporter';
import TwitterLoginBtn from '../../../components/twitter-login-btn/TwitterLoginBtn';
import Input from '../../../components/ui/Input';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import DefaultPageLayout from '../../../layouts/defaultPageLayout';
import { finalizeSignInWithTwitter, requestSignUp } from '../../../redux/actions/session-actions';
import { isLocalStorageAvailable } from '../../../services/authentication';

const SignUpPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const session = useAppSelector(state => state.session);
  const { search: searchLocation } = useLocation();

  // Query Params
  const searchParams = new URLSearchParams(searchLocation);
  const urlEmail = searchParams.get('email');

  // TODO: Control via SiteFlag
  const showTwitterLogin = false;

  const [legalAccepted, setLegalAccepted] = useState(true);

  useEffect(() => {
    // if account is already logged in, redirect them to the dashboard page
    if (session?.account && session?.token) {
      history.push('/dashboard');
    }
  }, [session.account, session.token, history]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: urlEmail || '',
      password: '',
      legal: true,
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required('First Name is required'),
      lastName: yup.string().required('Last Name is required'),
      email: yup.string().email().required('Email is required'),
      password: yup.string().min(6).max(32).required('Password is required'),
      legal: yup.boolean().isTrue().required(),
    }),
    onSubmit: values => {
      dispatch(
        requestSignUp({
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.email,
          password: values.password,
        })
      );
    },
  });

  return (
    <DefaultPageLayout title="Sign up for Newslit" addClearbitRisk={true}>
      <div className="tw-min-h-screen tw-bg-white tw-flex">
        <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-py-12 tw-px-4 sm:tw-px-6 lg:tw-flex-none lg:tw-px-20 xl:tw-px-24">
          <div className="tw-mx-auto tw-w-full tw-max-w-sm lg:tw-w-96">
            <div>
              <img className="tw-h-12 tw-w-auto" src={newslitLogo} alt="Newslit" />
              <h2 className="tw-mt-6 tw-text-3xl tw-font-extrabold tw-text-gray-900">
                Get started for free
              </h2>
              <p className="tw-mt-2 tw-text-sm tw-text-gray-600">
                Already have an account?{' '}
                <a
                  href="/users/signin"
                  className="tw-font-medium tw-text-secondary-dark hover:tw-text-secondary"
                >
                  Sign in here.
                </a>
              </p>
            </div>

            <div className="tw-mt-8">
              {showTwitterLogin && (
                <>
                  <div>
                    <div className="tw-mt-1">
                      <TwitterLoginBtn
                        label="Sign up with Twitter"
                        disabled={true}
                        checkIfEnable={() => isLocalStorageAvailable()}
                        onSuccess={() => dispatch(finalizeSignInWithTwitter())}
                      />
                    </div>
                  </div>

                  <div className="tw-mt-6 tw-relative">
                    <div
                      className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                      aria-hidden="true"
                    >
                      <div className="tw-w-full tw-border-t tw-border-gray-300" />
                    </div>
                    <div className="tw-relative tw-flex tw-justify-center tw-text-sm">
                      <span className="tw-px-2 tw-bg-white tw-text-gray-500">
                        Or continue with Email
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="tw-mt-6">
                <form
                  onSubmit={formik.handleSubmit}
                  className="tw-grid tw-grid-cols-1 tw-gap-y-6 tw-gap-x-4 tw-grid-cols-6"
                >
                  <div className="tw-col-span-3">
                    <label
                      htmlFor="firstName"
                      className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >
                      First name
                    </label>
                    <div className="tw-mt-1">
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        required
                        autoComplete="given-name"
                        error={
                          formik.touched.firstName && formik.errors.firstName
                            ? formik.errors.firstName
                            : undefined
                        }
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="tw-col-span-3">
                    <label
                      htmlFor="lastName"
                      className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >
                      Last name
                    </label>
                    <div className="tw-mt-1">
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        required
                        autoComplete="family-name"
                        error={
                          formik.touched.lastName && formik.errors.lastName
                            ? formik.errors.lastName
                            : undefined
                        }
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="tw-col-span-6">
                    <label
                      htmlFor="email"
                      className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="tw-mt-1">
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        error={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : undefined
                        }
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="tw-col-span-6">
                    <label
                      htmlFor="password"
                      className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >
                      Password
                    </label>
                    <div className="tw-mt-1">
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        error={
                          formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : undefined
                        }
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="tw-col-span-6">
                    <div className="tw-flex">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        checked={legalAccepted}
                        onChange={(e: any) => {
                          const value = e.target.checked;
                          setLegalAccepted(value);
                        }}
                        className="tw-h-4 tw-w-4 tw-text-indigo-600 focus:tw-ring-indigo-500 tw-border-gray-300 tw-rounded"
                      />
                      <label
                        htmlFor="remember-me"
                        className="tw-ml-2 tw-block tw-text-sm tw-text-gray-900 tw-font-normal tw-m-0"
                      >
                        I agree to the{' '}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          className="tw-font-bold tw-text-secondary-dark hover:tw-text-secondary"
                          href="https://newslit.co/terms"
                        >
                          terms of service
                        </a>{' '}
                        and{' '}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          className="tw-font-bold tw-text-secondary-dark hover:tw-text-secondary"
                          href="https://newslit.co/privacy"
                        >
                          privacy policy
                        </a>
                      </label>
                    </div>
                  </div>

                  <div className="tw-col-span-6">
                    <button
                      type="submit"
                      disabled={session.isFetching || !formik.isValid || !legalAccepted}
                      className="tw-w-full tw-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary disabled:tw-opacity-50"
                    >
                      {session.isFetching && (
                        <svg
                          className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="tw-opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="tw-opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      Start Free Trial
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-hidden lg:tw-block tw-relative tw-w-0 tw-flex-1">
          <img
            className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
            src={rocketIllustration}
            alt="Newslit Illustration"
          />
        </div>
      </div>

      <ErrorReporter />
    </DefaultPageLayout>
  );
};

export default SignUpPage;
