import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Brief } from '../../services/models/brief';
import { DayOfWeek } from '../../services/models/day-of-week';
import './EditBriefSchedule.scss';

export interface EditBriefScheduleProps {
  brief: Brief;
  update: (hour: number, selectedDays: DayOfWeek[]) => void;
}

const weekdays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
];

const allDaysOfWeek = [DayOfWeek.Sunday, ...weekdays, DayOfWeek.Saturday];

enum BriefFrequency {
  Daily = 'daily',
  Weekday = 'weekday',
  Custom = 'custom',
}

const allFrequencies = [BriefFrequency.Daily, BriefFrequency.Weekday, BriefFrequency.Custom];

const EditBriefSchedule: FunctionComponent<EditBriefScheduleProps> = ({ brief, update }) => {
  const [briefFrequency, setBriefFrequency] = useState(BriefFrequency.Daily);
  const [daysOfWeek, setDaysOfWeek] = useState<DayOfWeek[]>(weekdays);
  const [briefHour, setBriefHour] = useState(9);

  useEffect(() => {
    const getFrequencyFromSelectedDays = () => {
      if (brief.daysOfWeek.length === 7) {
        return BriefFrequency.Daily;
      } else if (
        brief.daysOfWeek.length === 5 &&
        weekdays.every(x => brief.daysOfWeek.some(day => x === day))
      ) {
        return BriefFrequency.Weekday;
      }
      return BriefFrequency.Custom;
    };
    setDaysOfWeek(brief.daysOfWeek || []);
    setBriefFrequency(getFrequencyFromSelectedDays());
  }, [brief.daysOfWeek]);

  useEffect(() => {
    setBriefHour(brief.briefHour);
  }, [brief.briefHour]);

  const handleBriefFrequencyChange = (val: BriefFrequency) => {
    let newDaysOfTheWeek = daysOfWeek;
    switch (val) {
      case BriefFrequency.Weekday:
        newDaysOfTheWeek = [...weekdays];
        break;
      case BriefFrequency.Daily:
        newDaysOfTheWeek = [...allDaysOfWeek];
        break;
    }
    update(getBriefHour(), newDaysOfTheWeek);
  };

  const handleBriefHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    update(newValue, daysOfWeek);
  };

  const getBriefHour = () => {
    return briefHour || 9;
  };

  const handleToggleDay = (dayOfWeeks: DayOfWeek[]) => {
    if (daysOfWeek.length > 1) {
      update(getBriefHour(), dayOfWeeks);
    }
  };

  const renderDaySelector = () => {
    const fetchLabel = (source: DayOfWeek) => {
      switch (source) {
        case DayOfWeek.Monday:
          return 'M';
        case DayOfWeek.Tuesday:
          return 'T';
        case DayOfWeek.Wednesday:
          return 'W';
        case DayOfWeek.Thursday:
          return 'Th';
        case DayOfWeek.Friday:
          return 'F';
        case DayOfWeek.Saturday:
        case DayOfWeek.Sunday:
          return 'S';
      }
    };

    return (
      <div className="tool-section">
        <div className="bold-h2 carbon-text">Custom</div>
        <ToggleButtonGroup
          name="customFrequency"
          type="checkbox"
          value={daysOfWeek}
          onChange={handleToggleDay}
        >
          {allDaysOfWeek.map(x => (
            <ToggleButton variant="outline-purple" value={x} className="mod-scheduling">
              {fetchLabel(x)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    );
  };

  const getTimeSection = () => {
    return (
      <div className="tool-section">
        <div className="bold-h2 carbon-text">What time you want for your News Brief?</div>
        <Form.Control
          size="sm"
          as="select"
          value={briefHour}
          onChange={(e: any) => handleBriefHourChange(e)}
          className="edit-brief-schedule-hours-selector"
        >
          <option value="24">12 am</option>
          <option value="1">1 am</option>
          <option value="2">2 am</option>
          <option value="3">3 am</option>
          <option value="4">4 am</option>
          <option value="5">5 am</option>
          <option value="6">6 am</option>
          <option value="7">7 am</option>
          <option value="8">8 am</option>
          <option value="9">9 am</option>
          <option value="10">10 am</option>
          <option value="11">11 am</option>
          <option value="12">12 pm</option>
          <option value="13">1 pm</option>
          <option value="14">2 pm</option>
          <option value="15">3 pm</option>
          <option value="16">4 pm</option>
          <option value="17">5 pm</option>
          <option value="18">6 pm</option>
          <option value="19">7 pm</option>
          <option value="20">8 pm</option>
          <option value="21">9 pm</option>
          <option value="22">10 pm</option>
          <option value="23">11 pm</option>
        </Form.Control>
      </div>
    );
  };

  const getFrequency = () => {
    const fetchLabel = (source: BriefFrequency) => {
      switch (source) {
        case BriefFrequency.Daily:
          return 'Daily';
        case BriefFrequency.Weekday:
          return 'Weekdays';
        case BriefFrequency.Custom:
          return 'Custom';
      }
    };
    return (
      <div className="tool-section">
        <div className="bold-h2 carbon-text">How often?</div>
        <ToggleButtonGroup
          name="reportFrequency"
          type="radio"
          value={briefFrequency}
          onChange={handleBriefFrequencyChange}
        >
          {allFrequencies.map(x => (
            <ToggleButton variant="outline-purple" value={x}>
              {fetchLabel(x)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    );
  };

  return (
    <div className="schedule-tool-layout">
      {getTimeSection()}
      {getFrequency()}
      {renderDaySelector()}
    </div>
  );
};

export default EditBriefSchedule;
