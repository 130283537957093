import React, { Component, ReactNode, RefObject } from 'react';
import { classNames } from '../../utils/class-names';
import './pullToRefresh.scss';

export interface PullToRefreshProps {
  options?: { pullDownHeight: number };
  children: ReactNode;
  onRefresh: () => Promise<any>;
  textError?: string;
  textStart?: string;
  textReady?: string;
  textRefresh?: string;
}

export interface PullToRefreshState {
  isMounted: boolean;
  label: string;
  iconClass: string;
  style: object;
  height: number;
  status: number;
  canPull: boolean;
}

// @see https://github.com/lakb248/vue-pull-refresh/blob/master/src/vue-pull-refresh.vue
// @see https://github.com/cuongdevjs/pull-to-refresh-react/blob/master/src/index.js
// @see https://github.com/sconstantinides/react-pullable/blob/master/src/index.js
export default class PullToRefresh extends Component<PullToRefreshProps, PullToRefreshState> {
  pullDownContainer: RefObject<HTMLDivElement>;
  pullDownHeader: RefObject<HTMLDivElement>;
  pullDownChildren: RefObject<HTMLDivElement>;
  iconPullDown: RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      isMounted: false,
      label: '',
      iconClass: '',
      style: {},
      height: 0,
      status: this.statusStart,
      canPull: false,
    };

    this.pullDownContainer = React.createRef<HTMLDivElement>();
    this.pullDownHeader = React.createRef<HTMLDivElement>();
    this.pullDownChildren = React.createRef<HTMLDivElement>();
    this.iconPullDown = React.createRef<HTMLDivElement>();
  }

  statusError = -1;
  statusStart = 0;
  statusReady = 1;
  statusRefresh = 2;
  listLabel = ['Error', 'Start', 'Ready', 'Refresh'];
  animation = 'all 0.2s ease';
  resistance = 2.5;

  checkListLabel() {
    this.listLabel[0] = this.props.textError || this.listLabel[0];
    this.listLabel[1] = this.props.textStart || this.listLabel[1];
    this.listLabel[2] = this.props.textReady || this.listLabel[2];
    this.listLabel[3] = this.props.textRefresh || this.listLabel[3];
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    this.checkListLabel();

    const sleep = (timeout: number) => new Promise(resolve => setTimeout(resolve, timeout));

    let _el = document;
    let pullDownContainer = this.pullDownContainer.current!;
    let pullDownHeader = this.pullDownHeader.current!;
    let pullDownChildren = this.pullDownChildren.current!;
    let icon = this.iconPullDown.current!;

    if (pullDownContainer == null || pullDownHeader == null) {
      return;
    }

    // set default pullDownHeight
    let pullDownHeight =
      this.props.options && this.props.options.pullDownHeight
        ? this.props.options.pullDownHeight
        : 60;

    /**
     * reset the status of pull down
     * @param {Boolean} withAnimation whether add animation when pull up
     */
    let resetPullDown = (withAnimation: boolean) => {
      if (withAnimation) {
        pullDownHeader.style.transition = this.animation;
      }

      if (this.state.isMounted) {
        this.setState({
          status: this.statusStart,
          height: 0,
        });
      }

      // pullDownContainer.style.height = '100%';
    };

    // store of touch position, include start position and distance
    let touchPosition = {
      start: 0,
      distance: 0,
    };

    // @see https://www.chromestatus.com/feature/5745543795965952
    // Test via a getter in the options object to see if the passive property is accessed
    let supportPassive = false;
    let options = Object.defineProperty({}, 'passive', {
      get: () => {
        supportPassive = true;
        return true;
      },
    });
    // @ts-ignore
    _el.addEventListener('test', null, options);

    // bind touchstart event to store start position of touch
    pullDownContainer.addEventListener(
      'touchstart',
      async (e: TouchEvent) => {
        if (this.state.status === this.statusRefresh) {
          return;
        }

        let isTop = pullDownChildren.getBoundingClientRect().top === 80;

        if (this.state.isMounted) {
          this.setState({
            canPull: isTop,
          });
        }

        touchPosition.start = e.touches[0].pageY;
        // console.log('touchStart: ', touchPosition.start);
        // console.log('canPull: ', this.state.canPull);
      },
      supportPassive ? { passive: true } : false
    );

    /**
     * bind touchmove event, do the following:
     * first, update the height of pull down
     * finally, update the status of pull down based on the distance
     */
    pullDownContainer.addEventListener(
      'touchmove',
      async (e: TouchEvent) => {
        if (!this.state.canPull) {
          return;
        }

        if (this.state.status === this.statusRefresh) {
          return;
        }

        let distance = e.touches[0].pageY - touchPosition.start;

        // limit the height of pull down to 180
        distance = distance > 280 ? 280 : distance;
        // console.log('touchMove: distance ', distance);

        if (distance < 0) {
          if (this.state.isMounted) {
            this.setState({
              canPull: false,
            });
          }
          return;
        }

        // prevent native scroll
        // if (distance > 0) {
        //   pullDownContainer.style.height = '100vh';
        // }

        if (e.cancelable) {
          e.preventDefault();
        }

        // update touchPosition and the height of pull down with a bit of resistance
        let yDistanceMoved = distance / this.resistance;
        touchPosition.distance = distance;
        this.setState({
          height: yDistanceMoved,
        });

        /**
         * if yDistanceMoved is bigger than the height of pull down
         * set the status of pull down to STATUS_READY
         */
        if (yDistanceMoved > pullDownHeight) {
          // console.log('touchMove: distance: ', distance);
          // console.log('touchMove: yDistanceMoved: ', yDistanceMoved);

          if (this.state.isMounted) {
            this.setState({
              status: this.statusReady,
            });
          }

          icon.style.transform = 'rotate(180deg)';
        } else {
          /**
           * else set the status of pull down to STATUS_START
           * and rotate the icon based on distance
           */
          if (this.state.isMounted) {
            this.setState({
              status: this.statusStart,
            });
          }

          icon.style.transform = 'rotate(' + (yDistanceMoved / pullDownHeight) * 180 + 'deg)';
        }
      },
      supportPassive ? { passive: true } : false
    );

    // bind touchend event
    pullDownContainer.addEventListener('touchend', async (e: any) => {
      if (this.state.status !== this.statusRefresh && this.state.canPull) {
        if (this.state.isMounted) {
          this.setState({
            canPull: false,
          });
        }

        pullDownHeader.style.transition = this.animation;

        // if status is ready (ie: distance is bigger than pullDownHeight)
        if (this.state.status === this.statusReady) {
          pullDownContainer.scrollTop = 0;

          if (this.state.isMounted) {
            this.setState({
              height: pullDownHeight,
              status: this.statusRefresh,
            });
          }

          // trigger refresh callback
          if (this.props.onRefresh && typeof this.props.onRefresh === 'function') {
            this.props
              .onRefresh()
              .then(async res => {
                if (!res) {
                  await sleep(250);
                }
                resetPullDown(true);
              })
              .catch((err: any) => {
                // console.log('catch: ', err);
                resetPullDown(true);

                if (this.state.isMounted) {
                  this.setState({
                    status: this.statusError,
                  });
                }
              });
          } else {
            await sleep(2500);
            resetPullDown(false);
          }
        } else {
          resetPullDown(false);
        }

        // reset touchPosition
        touchPosition.distance = 0;
        touchPosition.start = 0;

        // console.log('touchEnd: ', this.state.height);
      } else return false;
    });

    // remove transition when transitionend
    pullDownHeader.addEventListener('transitionend', () => {
      pullDownHeader.style.transition = '';
    });

    pullDownHeader.addEventListener('webkitTransitionEnd', () => {
      pullDownHeader.style.transition = '';
    });
  }

  componentDidUpdate(prevProps: any, prevState: { status: number }, snapshot: any) {
    if (this.state.status !== prevState.status) {
      this.setState({
        label: this.listLabel[this.state.status + 1],
        iconClass:
          this.state.status === this.statusError
            ? 'pull-down-error'
            : this.state.status === this.statusRefresh
            ? 'pull-down-refresh'
            : '',
      });
    }
  }

  render() {
    return (
      <div className="pull-down-container tw-overflow-visible" ref={this.pullDownContainer}>
        <div
          className="pull-down-header"
          style={{ height: this.state.height + 'px' }}
          ref={this.pullDownHeader}
        >
          <div className="pull-down-content">
            <i
              className={classNames('pull-down-content--icon', this.state.iconClass)}
              ref={this.iconPullDown}
            />
          </div>
        </div>
        <div className="pull-down-children" ref={this.pullDownChildren}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
