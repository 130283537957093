import { Intent } from '@blueprintjs/core';
import { AppToaster } from '../../../utils/toaster';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const invalidLoginVerificationErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: any,
  discard: DiscardErrorCallback
) => {
  if (error.message.indexOf('(/accountLoginVerification)') === -1) {
    return null;
  }

  AppToaster.show({
    intent: Intent.DANGER,
    icon: 'warning-sign',
    message: 'This request is no longer valid.',
  });

  discard(error);

  return false;
};
