import React, { FunctionComponent } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useAppSelector';

const patchNextUrl = (path: string) => {
  const finalRoute = path !== '/cancel' ? `?next=${path}` : '';
  return `/users/signin${finalRoute}`;
};

const ProtectedRoute: FunctionComponent<any> = ({ component, ...rest }: any) => {
  const location = useLocation();

  const session = useAppSelector(state => state.session);

  const isAuthenticated = () => {
    return !!session && !!session.account && !!session.token;
  };

  const routeComponent = (props: any) =>
    isAuthenticated() ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={patchNextUrl(location.pathname)} />
    );

  return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRoute;
