import React from 'react';
import './PeriodicitySwitch.scss';

interface PeriodicitySwitchProps {
  isToggled: boolean;
  onChange: (value: boolean) => void;
}

export default function PeriodicitySwitch(props: PeriodicitySwitchProps) {
  return (
    <div className="tw-flex tw-text-center tw-mt-4">
      <div className="periodicity-switch">
        <div className="periodicity-switch-monthly">MONTHLY</div>
        <div className="periodicity-switch-input">
          <label className="switch">
            <input
              type="checkbox"
              checked={!props.isToggled}
              onChange={() => props.onChange(!!props.isToggled)}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="periodicity-switch-annually">
          ANNUALLY
          <div className="periodicity-switch-annually-footnote">save 2 months</div>
        </div>
      </div>
    </div>
  );
}
