import React, { FunctionComponent, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { StoreState } from '../../../../redux/store-state';
import { BriefKeywordInput } from '../../../../services/models/brief-creation-request';
import CreateBriefKeyword from '../create-brief-keyword/CreateBriefKeyword';
import './DefineBriefStep.scss';

interface Props {
  maxTopics: number;
  active: boolean;
  showCancelAction: boolean;
  onComplete: (keywords: BriefKeywordInput[]) => void;
}

const DefineBriefStep: FunctionComponent<Props> = ({
  active,
  showCancelAction,
  maxTopics,
  onComplete,
}) => {
  const [keywords, setKeywords] = useState<BriefKeywordInput[]>([]);
  const [emptyTopics, setEmptyTopics] = useState(true);

  const getKeywordInputControl = () => {
    return (
      <CreateBriefKeyword
        maxItems={maxTopics}
        keywords={keywords}
        update={keywords => {
          setKeywords(keywords);
          setEmptyTopics(keywords.length === 0);
        }}
      />
    );
  };

  if (!active) {
    return <></>;
  }

  return (
    <div className="define-screen-container">
      <div className="contents">
        <p className="carbon-text">
          <strong>Tip.</strong> It works best to focus the keywords on a single topic, use many
          different words to describe it. You can always create more briefs dedicated to specific
          topics.
        </p>
      </div>
      <div className="topics">{getKeywordInputControl()}</div>
      <div className="form-controls">
        {showCancelAction && (
          <Button
            variant="light"
            className="mr-3"
            onClick={() => {
              window.history.back();
            }}
          >
            Cancel
          </Button>
        )}
        <Button disabled={emptyTopics} variant="purple" onClick={() => onComplete(keywords)}>
          Next
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    maxTopics: state.session?.account?.product?.features.keywordsPerBrief || 0,
  };
}

export default connect(mapStateToProps)(DefineBriefStep);
