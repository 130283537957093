import { Account } from '../../../../services/models/account';
import { AppType } from '../../../../services/models/app-type';
import { AccountAction } from '../../../actions/account-actions';
import { BriefsAction } from '../../../actions/briefs-actions';
import { EmailAction } from '../../../actions/email-actions';
import { SettingsAction } from '../../../actions/settings-actions';
import { StripeAction } from '../../../actions/stripe-actions';
import emailReducer from './email';
import metadataReducer from './metadata';
import settingsReducer from './settings';
import subscriptionReducer from './subscription';

export default function accountReducer(state: Account = {} as Account, action: any): Account {
  switch (action.type) {
    case StripeAction.StartChangingSubscription:
    case StripeAction.CompleteChangingSubscription:
    case StripeAction.CancelChangingSubscription:
      return { ...state, subscription: subscriptionReducer(state.subscription, action) };
    case StripeAction.StartCheckoutFlow:
      return { ...state, isStartingCheckoutFlow: true };
    case StripeAction.CompleteCheckoutFlow:
    case StripeAction.CancelCheckoutFlow:
      return { ...state, isStartingCheckoutFlow: false };

    case AccountAction.CompleteFetching:
      return { ...action.currentAccount };

    case EmailAction.StartUpdating:
    case EmailAction.CompleteUpdating:
    case EmailAction.CancelUpdating:
    case EmailAction.StartSendingVerification:
    case EmailAction.CompleteSendingVerification:
    case EmailAction.CancelSendingVerification:
    case EmailAction.StartVerifying:
    case EmailAction.CompleteVerifying:
    case EmailAction.CancelVerifying:
      return { ...state, email: emailReducer(state.email, action) };

    case AccountAction.StartUpdatingName:
      return { ...state, isUpdatingName: true };
    case AccountAction.CompleteUpdatingName:
      return { ...state, ...action.account, isUpdatingName: false };
    case AccountAction.CancelUpdatingName:
      return { ...state, isUpdatingName: false };
    case AccountAction.StartCancelingSubscription:
      return { ...state, isCancelingSubscription: true };
    case AccountAction.CompleteCancelingSubscription:
      return { ...state, isCancelingSubscription: false, subscription: action.subscription };
    case AccountAction.CancelCancelingSubscription:
      return { ...state, isCancelingSubscription: false };
    case AccountAction.StartDeactivatingAccount:
      return { ...state, isDeactivatingAccount: true };
    case AccountAction.CompleteDeactivatingAccount:
      return { ...state, isDeactivatingAccount: false, isDeactivationCompleted: true };
    case AccountAction.CancelDeactivatingAccount:
      return { ...state, isDeactivatingAccount: false };
    case AccountAction.StartGeneratingZapierApiKey:
      return { ...state, isGeneratingZapierApiKey: true };
    case AccountAction.CompleteGeneratingZapierApiKey:
      return {
        ...state,
        isGeneratingZapierApiKey: false,
        apiApps: [...(state.apiApps || []).filter(x => x.type === AppType.Zapier), action.apiApp],
      };

    case AccountAction.CancelGeneratingZapierApiKey:
      return { ...state, isGeneratingZapierApiKey: false };

    case SettingsAction.StartUpdating:
      return {
        ...state,
        isUpdatingSettings: true,
        settings: settingsReducer(state.settings, action),
      };
    case SettingsAction.CompleteUpdating:
    case SettingsAction.CancelUpdating:
      return {
        ...state,
        isUpdatingSettings: false,
        settings: settingsReducer(state.settings, action),
      };
    case SettingsAction.StartUpdatingTimeZone:
      return {
        ...state,
        isUpdatingTimeZoneSettings: true,
        settings: settingsReducer(state.settings, action),
      };
    case SettingsAction.CompleteUpdatingTimeZone:
    case SettingsAction.CancelUpdatingTimeZone:
      return {
        ...state,
        isUpdatingTimeZoneSettings: false,
        settings: settingsReducer(state.settings, action),
      };

    case BriefsAction.CompleteCreating:
    case BriefsAction.CompleteDestroying:
      return { ...state, metadata: metadataReducer(state.metadata, action) };

    default:
      return state;
  }
}
