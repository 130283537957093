import { XIcon } from '@heroicons/react/outline';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationIcon,
  SpeakerphoneIcon,
} from '@heroicons/react/solid';
import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePaywallContext } from '../../contexts/paywall';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { sendVerificationEmail } from '../../redux/actions/email-actions';
import * as honeybadger from '../../services/honeybadger';
import { Account } from '../../services/models/account';
import { ApplicationAlert } from '../../services/models/application-alert';
import { SubscriptionStatus } from '../../services/models/subscription';
import { classNames } from '../../utils/class-names';
import { BillingRoute } from '../../utils/Routes';

interface Props {
  account: Account;
  alerts: ApplicationAlert[];
  isVisible: boolean;
  onDismiss: () => void;
}

const NotificationHub: FunctionComponent<Props> = ({ account, alerts, isVisible, onDismiss }) => {
  const [index, setIndex] = useState(0);
  const activeAlert = alerts[index];

  const getButtons = () => {
    if (alerts.length === 1) {
      return null;
    }

    const showPrev = index >= 1;
    const showNext = index < alerts.length - 1;

    // TODO(Jose): properly enable this, at the moment is hidden "md:tw-block"
    return (
      <nav
        className="tw-z-0 tw-inline-flex tw-rounded-md tw-shadow-sm tw--space-x-px tw-hidden"
        aria-label="Pagination"
      >
        <button
          onClick={() => setIndex(index - 1)}
          className="tw-relative tw-inline-flex tw-items-center tw-px-2 tw-py-2 tw-rounded-l-md tw-border tw-border-gray-300 tw-bg-white tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-50"
          disabled={!showPrev}
        >
          <span className="tw-sr-only">Previous</span>
          <ChevronLeftIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
        </button>
        <button
          onClick={() => setIndex(index + 1)}
          className="tw-relative tw-inline-flex tw-items-center tw-px-2 tw-py-2 tw-rounded-r-md tw-border tw-border-gray-300 tw-bg-white tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-50"
          disabled={!showNext}
        >
          <span className="tw-sr-only">Next</span>
          <ChevronRightIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
        </button>
      </nav>
    );
  };

  const renderAlert = (alert: ApplicationAlert) => {
    if (alert === ApplicationAlert.UPSELL) {
      return <UpsellNotification account={account} onDismiss={onDismiss} />;
    }

    if (alert === ApplicationAlert.PAST_DUE) {
      return <PastDueNotification />;
    }

    if (alert === ApplicationAlert.EMAIL_CONFIRMATION) {
      return <EmailVerificationNotification account={account} />;
    }

    return null;
  };

  if (alerts.length === 0) {
    return null;
  }

  return (
    <div className="tw-fixed tw-bottom-0 tw-inset-x-0 tw-pb-2 sm:tw-pb-5 md:tw-ml-64">
      <div className="tw-max-w-7xl tw-mx-auto tw-px-2 sm:tw-px-6 lg:tw-px-8">
        <div
          className={classNames(
            activeAlert === ApplicationAlert.EMAIL_CONFIRMATION
              ? 'tw-bg-yellow-200'
              : activeAlert === ApplicationAlert.PAST_DUE
              ? 'tw-bg-red-700'
              : 'tw-bg-secondary',
            'tw-p-2 tw-rounded-lg tw-shadow-lg sm:tw-p-3'
          )}
        >
          <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
            {renderAlert(activeAlert)}

            <div className="tw-flex-shrink-0 tw-mt-0 tw-w-auto">{getButtons()}</div>

            <div className="tw-flex-shrink-0 sm:tw-ml-2">
              <button
                type="button"
                className={classNames(
                  activeAlert === ApplicationAlert.EMAIL_CONFIRMATION
                    ? 'text-yellow-500 hover:tw-bg-yellow-200'
                    : activeAlert === ApplicationAlert.PAST_DUE
                    ? 'tw-text-white hover:tw-bg-red-800'
                    : 'tw-text-white hover:tw-bg-secondary-dark',
                  'tw--mr-1 tw-flex tw-p-2 tw-rounded-md  focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white'
                )}
                onClick={() => onDismiss()}
              >
                <span className="tw-sr-only">Dismiss</span>
                <XIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationHub;

interface UpsellNotificationProps {
  account: Account;
  onDismiss: () => void;
}

function UpsellNotification(props: UpsellNotificationProps) {
  const { setPaywallModalShown } = usePaywallContext();

  const account = props.account;
  const trialExpired = account.subscription.status === SubscriptionStatus.TrialExpired;
  const trialDaysLeft = account.subscription.trialDaysLeft;

  if (
    account.subscription.status !== SubscriptionStatus.Trial &&
    account.subscription.status !== SubscriptionStatus.TrialExpired
  ) {
    // Ignore for users not in trial
    return null;
  }

  const handleClick = () => {
    props.onDismiss();
    setPaywallModalShown(true);
  };

  return (
    <div className="tw-w-0 tw-flex-1 tw-flex tw-items-center">
      <span className="tw-flex tw-p-2 tw-rounded-lg tw-bg-secondary-dark">
        <SpeakerphoneIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
      </span>
      <p className="tw-ml-3 tw-font-medium tw-text-white tw-truncate">
        <span className="tw-inline">
          {trialExpired && 'Your trial has ended'}
          {!trialExpired && trialDaysLeft > 0
            ? `${account?.subscription?.trialDaysLeft} days left in your trial`
            : 'Enjoying your trial?'}
        </span>
        <span className="tw-block tw-ml-2 tw-inline-block">
          <button
            onClick={() => handleClick()}
            className="tw-text-white tw-font-bold tw-underline hover:tw-text-white"
          >
            {' '}
            Upgrade now <span aria-hidden="true">&rarr;</span>
          </button>
        </span>
      </p>
    </div>
  );
}

interface EmailVerificationNotificationProps {
  account: Account;
}

function EmailVerificationNotification(props: EmailVerificationNotificationProps) {
  const dispatch = useAppDispatch();

  const account = props.account;

  if (!account || !account.email) {
    honeybadger.notify(
      new Error('Empty account email object'),
      'NotificationHub/EmailVerificationNotification'
    );
    return null;
  }

  return (
    <div className="tw-w-0 tw-flex-1 tw-flex tw-items-center">
      <span className="tw-flex tw-p-2 tw-rounded-lg tw-bg-yellow-300">
        <SpeakerphoneIcon className="tw-h-6 tw-w-6 tw-text-yellow-900" aria-hidden="true" />
      </span>
      <p className="tw-ml-3 tw-font-medium tw-text-yellow-900 tw-truncate">
        <span className="tw-inline md:tw-hidden">Please confirm your email address</span>
        <span className="tw-hidden md:tw-inline">
          Please confirm your email address: {account.email.emailAddress}.{' '}
        </span>
        <span className="tw-block tw-ml-2 tw-inline-block">
          <button
            className="tw-text-yellow-900 tw-font-bold tw-underline hover:tw-text-yellow-900"
            onClick={() => dispatch(sendVerificationEmail())}
          >
            Resend email <span aria-hidden="true">&rarr;</span>
          </button>
        </span>
      </p>
    </div>
  );
}

function PastDueNotification() {
  return (
    <div className="tw-w-0 tw-flex-1 tw-flex tw-items-center">
      <span className="tw-flex tw-p-2 tw-rounded-lg tw-bg-red-800">
        <ExclamationIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
      </span>
      <p className="tw-ml-3 tw-font-medium tw-text-white tw-truncate">
        <span className="tw-hidden md:tw-inline">
          Your payment method recently failed to process.
        </span>
        <span className="tw-block tw-ml-2 tw-inline-block">
          <Link
            to={BillingRoute}
            className="tw-text-white tw-font-bold tw-underline hover:tw-text-white"
          >
            Update your payment information <span aria-hidden="true">&rarr;</span>
          </Link>
        </span>
      </p>
    </div>
  );
}
