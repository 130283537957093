// @ts-ignore
import { EventTypes } from 'redux-segment';
import { refreshUserAccount } from '../../services/account';
import * as briefsManager from '../../services/briefs';
import { Brief } from '../../services/models/brief';
import { BriefCreationRequest } from '../../services/models/brief-creation-request';
import { asyncAction, Dispatch } from '../types';

export enum BriefsAction {
  // Query Actions
  StartFetchingAll = 'BriefsAction/StartFetchingAll',
  CompleteFetchingAll = 'BriefsAction/CompleteFetchingAll',
  CancelFetchingAll = 'BriefsAction/CancelFetchingAll',
  StartFetchingBriefDetails = 'BriefsAction/StartFetchingBriefDetails',
  CompleteFetchingBriefDetails = 'BriefsAction/CompleteFetchingBriefDetails',
  CancelFetchingBriefDetails = 'BriefsAction/CancelFetchingBriefDetails',

  // Mutation Actions
  StartCreating = 'BriefsAction/StartCreating',
  CompleteCreating = 'BriefsAction/CompleteCreating',
  CancelCreating = 'BriefsAction/CancelCreating',

  ShowBriefDestroyPrompt = 'BriefsAction/ShowBriefDestroyPrompt',
  HideBriefDestroyPrompt = 'BriefsAction/HideBriefDestroyPrompt',

  StartDestroying = 'BriefsAction/StartDestroying',
  CompleteDestroying = 'BriefsAction/CompleteDestroying',
  CancelDestroying = 'BriefsAction/CancelDestroying',

  StartUpdatingName = 'BriefsAction/StartUpdatingName',
  CompleteUpdatingName = 'BriefsAction/CompleteUpdatingName',
  CancelUpdatingName = 'BriefsAction/CancelUpdatingName',

  StartPublishingDraft = 'BriefsAction/StartPublishingDraft',
  CompletePublishingDraft = 'BriefsAction/CompletePublishingDraft',
  CancelPublishingDraft = 'BriefsAction/CancelPublishingDraft',
}

export const requestBriefsOverview = () =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startFetchingBriefs());
    const briefs = await briefsManager.getBriefsOverview();
    dispatch(completeFetchingBriefs(briefs));
  }, cancelFetchingBriefs);

export const startFetchingBriefs = () => ({
  type: BriefsAction.StartFetchingAll,
});

export const completeFetchingBriefs = (briefs: Brief[]) => ({
  type: BriefsAction.CompleteFetchingAll,
  briefs,
});

export const cancelFetchingBriefs = () => ({
  type: BriefsAction.CancelFetchingAll,
});

export const requestBriefDetails = (briefId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startFetchingBriefDetails(briefId));
      const brief = await briefsManager.getBriefDetails(briefId);
      dispatch(completeFetchingBriefDetails(briefId, brief));
    },
    () => cancelFetchingBriefDetails(briefId)
  );

export const startFetchingBriefDetails = (briefId: number) => ({
  type: BriefsAction.StartFetchingBriefDetails,
  briefId,
});

export const completeFetchingBriefDetails = (briefId: number, brief: Brief) => ({
  type: BriefsAction.CompleteFetchingBriefDetails,
  briefId,
  brief,
});

export const cancelFetchingBriefDetails = (briefId: number) => ({
  type: BriefsAction.CancelFetchingBriefDetails,
  briefId,
});

export const createBrief = (creationRequest: BriefCreationRequest) =>
  asyncAction(async (dispatch: Dispatch) => {
    dispatch(startCreatingBrief());
    const newBrief = await briefsManager.createBrief(creationRequest);
    await refreshUserAccount();
    dispatch(completeCreatingBrief(newBrief));
  }, cancelCreatingBrief);

export const startCreatingBrief = () => ({
  type: BriefsAction.StartCreating,
});

export const completeCreatingBrief = (newBrief: Brief) => ({
  type: BriefsAction.CompleteCreating,
  brief: newBrief,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Brief Created',
        properties: {
          name: newBrief.name,
          status: newBrief.status,
        },
      },
    },
  },
});

export const cancelCreatingBrief = () => ({
  type: BriefsAction.CancelCreating,
});

export const showBriefDestroyPrompt = (briefId: number) => ({
  type: BriefsAction.ShowBriefDestroyPrompt,
  briefId,
});

export const hideBriefDestroyPrompt = (briefId: number) => ({
  type: BriefsAction.HideBriefDestroyPrompt,
  briefId,
});

export const destroyBrief = (briefId: number) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startDestroyingBrief(briefId));

      const deletedBriefId = await briefsManager.destroyBrief(briefId);

      if (deletedBriefId) {
        dispatch(completeDestroyingBrief(briefId));
      } else {
        dispatch(cancelDestroyingBrief(briefId));
      }
    },
    () => cancelDestroyingBrief(briefId)
  );

export const startDestroyingBrief = (briefId: number) => ({
  type: BriefsAction.StartDestroying,
  briefId,
});

export const completeDestroyingBrief = (briefId: number) => ({
  type: BriefsAction.CompleteDestroying,
  briefId,
});

export const cancelDestroyingBrief = (briefId: number) => ({
  type: BriefsAction.CancelDestroying,
  briefId,
});

export const updateBriefName = (briefId: number, name: string) =>
  asyncAction(
    async (dispatch: Dispatch) => {
      dispatch(startUpdatingBriefName(briefId));
      const updatedbrief = await briefsManager.updateBriefName(briefId, name);
      dispatch(completeUpdatingBriefName(briefId, updatedbrief));
    },
    () => cancelUpdatingBriefName(briefId)
  );

export const startUpdatingBriefName = (briefId: number) => ({
  type: BriefsAction.StartUpdatingName,
  briefId: briefId,
});

export const completeUpdatingBriefName = (briefId: number, brief: Brief) => ({
  type: BriefsAction.CompleteUpdatingName,
  briefId,
  brief,
});

export const cancelUpdatingBriefName = (briefId: number) => ({
  type: BriefsAction.CancelUpdatingName,
  briefId,
});
