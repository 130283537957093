import React, { FunctionComponent, useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { getProducts } from '../../redux/actions/products-actions';
import SettingsAppRoutes from '../Routes/settingsAppRoutes';

const SettingsApp: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const productStore = useAppSelector(state => state.productStore);

  useEffect(() => {
    if (productStore.isFetching === undefined) {
      dispatch(getProducts());
    }
  }, [productStore, dispatch]);

  return (
    <>
      <SettingsAppRoutes />
    </>
  );
};

export default SettingsApp;
