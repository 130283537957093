import { Brief } from '../../../../../services/models/brief';
import { BriefRecipient } from '../../../../../services/models/brief-recipient';
import { SlackChannel } from '../../../../../services/models/slack-channel';
import { BriefSettingsAction } from '../../../../actions/brief-settings-actions';
import { BriefStoriesAction } from '../../../../actions/brief-stories-actions';
import { BriefStoryTweetsAction } from '../../../../actions/brief-story-tweets-actions';
import { BriefsAction } from '../../../../actions/briefs-actions';
import briefKeywordsReducer from './keywords';
import briefStoriesReducer from './stories';

export default function briefReducer(state: Brief = {} as any, action: any): Brief {
  switch (action.type) {
    case BriefsAction.StartFetchingBriefDetails:
      return startFetchingBriefDetails(state, action);
    case BriefsAction.CompleteFetchingBriefDetails:
      return completeFetchingBriefDetails(state, action);
    case BriefsAction.CancelFetchingBriefDetails:
      return cancelFetchingBriefDetails(state, action);

    case BriefStoriesAction.StartFetching:
      return startFetchingBriefStories(state, action);
    case BriefStoriesAction.CompleteFetchingPage:
      return completeFetchingBriefStoriesPage(state, action);

    /**
     * Brief Destroy
     */
    case BriefsAction.ShowBriefDestroyPrompt:
      return { ...state, showDestroyPrompt: true };
    case BriefsAction.HideBriefDestroyPrompt:
      return { ...state, showDestroyPrompt: false };
    case BriefsAction.StartDestroying:
      return { ...state, isDestroying: true };
    case BriefsAction.CancelDestroying:
      return { ...state, isDestroying: false };

    /**
     * Brief Name Update
     */
    case BriefsAction.StartUpdatingName:
      return { ...state, isUpdatingName: true };
    case BriefsAction.CancelUpdatingName:
      return { ...state, isUpdatingName: false };
    case BriefsAction.CompleteUpdatingName:
      return {
        ...state,
        isUpdatingName: false,
        name: action.brief.name,
      };

    /**
     * Brief Settings Update
     */
    case BriefSettingsAction.StartUpdatingLanguage:
      return { ...state, isUpdatingLanguage: true };
    case BriefSettingsAction.CancelUpdatingLanguage:
      return { ...state, isUpdatingLanguage: false };
    case BriefSettingsAction.CompleteUpdatingLanguage:
      return { ...state, isUpdatingLanguage: false, language: action.brief.language };

    /**
     * Brief Keywords Update
     */
    case BriefSettingsAction.StartUpdatingKeywords:
      return { ...state, isUpdatingKeywords: true };

    case BriefSettingsAction.CompleteCreatingKeyword:
    case BriefSettingsAction.CompleteDestroyingKeyword:
      return {
        ...state,
        isUpdatingKeywords: false,
        keywords: briefKeywordsReducer(state.keywords, action),
      };

    case BriefSettingsAction.CancelCreatingKeyword:
    case BriefSettingsAction.CancelDestroyingKeyword:
      return { ...state, isUpdatingKeywords: false };

    /**
     * Brief Schedule Update
     */
    case BriefSettingsAction.StartUpdatingSchedule:
      return { ...state, isUpdatingSchedule: true };
    case BriefSettingsAction.CancelUpdatingSchedule:
      return { ...state, isUpdatingSchedule: false };
    case BriefSettingsAction.CompleteUpdatingSchedule:
      return {
        ...state,
        isUpdatingSchedule: false,
        briefHour: action.brief.briefHour,
        daysOfWeek: action.brief.daysOfWeek,
      };

    /**
     * Brief Suggested Stories
     */
    case BriefSettingsAction.StartUpdatingSuggestedStories:
      return { ...state, isUpdatingSuggestedStories: true };
    case BriefSettingsAction.CancelUpdatingSuggestedStories:
      return { ...state, isUpdatingSuggestedStories: false };
    case BriefSettingsAction.CompleteUpdatingSuggestedStories:
      return {
        ...state,
        isUpdatingSuggestedStories: false,
        showSuggestedStories: action.brief.showSuggestedStories,
      };

    /**
     * Brief Recipients
     */
    case BriefSettingsAction.StartCreatingRecipient:
    case BriefSettingsAction.StartDestroyingRecipient:
      return { ...state, isUpdatingRecipients: true };
    case BriefSettingsAction.CancelCreatingRecipient:
    case BriefSettingsAction.CancelDestroyingRecipient:
      return { ...state, isUpdatingRecipients: false };
    case BriefSettingsAction.CompleteCreatingRecipient:
    case BriefSettingsAction.CompleteDestroyingRecipient:
      return {
        ...state,
        isUpdatingRecipients: false,
        recipients: recipientsReducer(state.recipients, action),
      };

    /**
     * Brief Slack Actions
     */
    case BriefSettingsAction.StartRemovingSlackChannel:
      return { ...state, isUpdatingSlackChannels: true };
    case BriefSettingsAction.CancelRemovingSlackChannel:
      return { ...state, isUpdatingSlackChannels: false };
    case BriefSettingsAction.CompleteRemovingSlackChannel:
      return {
        ...state,
        isUpdatingSlackChannels: false,
        slackChannels: slackChannelsReducer(state.slackChannels, action),
      };

    /**
     * Brief Stories Preview
     */
    case BriefStoriesAction.StartFetchingPreview:
      return { ...state, isFetching: true };
    case BriefStoriesAction.CompleteFetchingPreview:
      return {
        ...state,
        isFetching: false,
        storiesPreview: [...action.storiesPreview],
      };
    case BriefStoriesAction.CancelFetchingPreview:
      return { ...state, isFetching: false };

    /**
     * Brief Stories Reducers
     */
    case BriefStoriesAction.StartFetchingShareData:
    case BriefStoriesAction.CompleteFetchingShareData:
    case BriefStoriesAction.CancelFetchingShareData:
    case BriefStoriesAction.StartMarkStoryAsViewed:
    case BriefStoriesAction.CompleteMarkStoryAsViewed:
    case BriefStoriesAction.CancelMarkStoryAsViewed:
    case BriefStoryTweetsAction.StartFetching:
    case BriefStoryTweetsAction.CompleteFetching:
    case BriefStoryTweetsAction.CancelFetching:
      return {
        ...state,
        stories: briefStoriesReducer(state.stories, action),
      };

    default:
      return state;
  }
}

const startFetchingBriefDetails = function (state: Brief, action: any): Brief {
  return { ...state, isFetching: true };
};

const completeFetchingBriefDetails = function (state: Brief, action: any): Brief {
  return { ...state, ...action.brief, isFetching: false };
};

const cancelFetchingBriefDetails = function (state: Brief, action: any): Brief {
  return { ...state, isFetching: false };
};

const startFetchingBriefStories = function (state: Brief, action: any): Brief {
  return { ...state, ...action.brief, isFetching: true };
};

const completeFetchingBriefStoriesPage = function (state: Brief, action: any): Brief {
  return {
    ...state,
    stories: briefStoriesReducer(state.stories, action),
    hasMoreStories: action.hasMoreStories,
    lastLoadedStoryCursor: action.lastCursor,
    isFetching: false,
  };
};

function recipientsReducer(state: BriefRecipient[] = [], action: any) {
  switch (action.type) {
    case BriefSettingsAction.CompleteCreatingRecipient:
      return [...state, action.recipient];

    case BriefSettingsAction.CompleteDestroyingRecipient:
      return state.filter(recipient => recipient.id !== action.recipientId);

    default:
      return state;
  }
}

function slackChannelsReducer(state: SlackChannel[] = [], action: any): SlackChannel[] {
  switch (action.type) {
    case BriefSettingsAction.CompleteRemovingSlackChannel:
      return state.filter(slackChannel => slackChannel.id !== action.slackChannelId);

    default:
      return state;
  }
}
