import { getSession } from '../services/authentication';
import { Session } from '../services/models/session';
import configureStore from './configureStore';

export const store = configureStore({
  errors: [],
  dashboardConfiguration: { isNotifHubOpen: true },
  briefs: {},
  sharedLinks: {},
  featureFlags: {},
  externalAuth: {},
  slackIntegration: {},
  accountActivation: {},
  toastNotification: '',
  briefUnsubscribeLink: {},
  emailUnsubscribeLink: {},
  productStore: {},
  session: getSession() || ({} as Session),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
