import React from 'react';
import { Redirect } from 'react-router-dom';
import { discardLocalSession } from '../../../services/authentication';
import { onUserSignOut } from '../../../services/user-events';
import { ErrorIdentifier } from './error-identifier';

export const accessDeniedErrorIdentifier: ErrorIdentifier = (index: number, error: any) => {
  const message = 'Access denied';
  if (error.message.indexOf(message) === -1) {
    return null;
  }

  discardLocalSession();
  onUserSignOut();

  return <Redirect key={index} to="/users/signin" />;
};
