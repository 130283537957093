import { Intent } from '@blueprintjs/core';
import { AppToaster } from '../../../utils/toaster';
import { DiscardErrorCallback, ErrorIdentifier } from './error-identifier';

export const invalidTwitterCredentialsErrorIdentifier: ErrorIdentifier = (
  index: number,
  error: Error,
  discard: DiscardErrorCallback
) => {
  if (error.message.indexOf('Invalid twitter credentials') === -1) {
    return null;
  }

  discard(error);

  AppToaster.show({
    intent: Intent.WARNING,
    icon: 'warning-sign',
    message: 'Invalid Twitter credentials. Please check your email and password then try again.',
  });

  return false;
};
