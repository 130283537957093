import { EditableText, Intent } from '@blueprintjs/core';
import { Menu, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  DotsHorizontalIcon,
  DotsVerticalIcon,
  PencilIcon,
  PuzzleIcon,
  TrashIcon,
  UsersIcon,
} from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { showBriefDestroyPrompt, updateBriefName } from '../../redux/actions/briefs-actions';
import { SidebarTool, sidebarToolToPathName } from '../../redux/actions/SidebarTool';
import { AppDispatch } from '../../redux/store';
import { Brief } from '../../services/models/brief';
import { classNames } from '../../utils/class-names';
import DashboardNav from '../dashboard-nav/DashboardNav';

export interface BriefNavBarProps {
  brief: Brief;
}

const briefNavigation = [
  { name: 'Edit', tool: SidebarTool.EDIT_BRIEF, icon: PencilIcon },
  { name: 'Schedule', tool: SidebarTool.EDIT_SCHEDULE, icon: CalendarIcon },
  { name: 'Recipients', tool: SidebarTool.EDIT_RECIPIENTS, icon: UsersIcon },
  { name: 'Integrations', tool: SidebarTool.EDIT_INTEGRATIONS, icon: PuzzleIcon },
];

const BriefNavBar: React.FC<BriefNavBarProps> = ({ brief }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch: AppDispatch = useAppDispatch();

  const openSidebarTool = (tool: SidebarTool) => {
    const pathName = location.pathname;
    const toolPath = sidebarToolToPathName(tool);
    const paths = pathName.split('/').filter(x => x.length > 0);

    if (toolPath.length > 0) {
      paths.push(toolPath);
    }

    const newPathName = '/' + paths.join('/');

    history.push(newPathName);
  };

  const handleOnDeleteBrief = (brief: Brief) => {
    dispatch(showBriefDestroyPrompt(brief.id));
  };

  // TODO(Jose): better manage empty name
  const handleOnChangeBriefName = (value: string, briefName: string) => {
    if (value.length > 0 && briefName !== value) {
      dispatch(updateBriefName(brief.id, value));
    } else {
      // empty state reverts to original value
    }
  };

  return (
    <DashboardNav>
      <div className="tw-flex-1 tw-flex">
        <h1 className="tw-text-xl tw-font-bold tw-m-0 tw-text-gray-900">
          <EditableText
            key={brief.id}
            intent={Intent.NONE}
            defaultValue={brief.name}
            onConfirm={(value: string) => handleOnChangeBriefName(value, brief.name)}
            disabled={brief.isUpdatingName}
          />
        </h1>
      </div>
      <div className="tw-flex tw-ml-4">
        <div className="tw-flex tw-flex-nowrap">
          {/* More Options */}
          <Menu as="div" className="tw-hidden sm:tw-block">
            <Menu.Button className="tw-bg-white tw-rounded-full tw-p-1 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary">
              <span className="tw-sr-only">More Options</span>
              <DotsHorizontalIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="tw-transition tw-ease-out tw-duration-200"
              enterFrom="tw-opacity-0 tw-scale-95"
              enterTo="tw-opacity-100 tw-scale-100"
              leave="tw-transition tw-ease-in tw-duration-75"
              leaveFrom="tw-opacity-100 tw-scale-100"
              leaveTo="tw-opacity-0 tw-scale-95"
            >
              <Menu.Items className="tw-z-10 tw-origin-top-right tw-absolute tw-right-48 tw-mt-2 tw-w-40 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-100 focus:tw-outline-none">
                <div className="tw-py-1">
                  <Menu.Item>
                    <button
                      className="tw-text-gray-700 tw-group tw-flex tw-w-full tw-items-center tw-px-4 tw-py-2 tw-text-sm"
                      onClick={() => handleOnDeleteBrief(brief)}
                    >
                      <TrashIcon
                        className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                        aria-hidden="true"
                      />
                      Delete Brief
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          {briefNavigation
            .slice(0)
            .reverse()
            .map(item => (
              <span key={item.name} className="tw-hidden sm:tw-block tw-ml-3">
                <button
                  type="button"
                  className="tw-bg-white tw-rounded-full tw-p-1 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                  onClick={() => openSidebarTool(item.tool)}
                >
                  <item.icon className="tw-h-6 tw-w-6" aria-hidden="true" />
                  <span className="tw-sr-only">{item.name}</span>
                </button>
              </span>
            ))}

          {/* Dropdown */}
          <Menu as="div" className="tw-relative tw-inline-block tw-text-left sm:tw-hidden">
            <Menu.Button className="tw-bg-white tw-rounded-full tw-p-1 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary">
              <span className="tw-sr-only">Open options</span>
              <DotsVerticalIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="tw-transition tw-ease-out tw-duration-200"
              enterFrom="tw-opacity-0 tw-scale-95"
              enterTo="tw-opacity-100 tw-scale-100"
              leave="tw-transition tw-ease-in tw-duration-75"
              leaveFrom="tw-opacity-100 tw-scale-100"
              leaveTo="tw-opacity-0 tw-scale-95"
            >
              <Menu.Items className="tw-z-10 tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-40 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-100 focus:tw-outline-none">
                <div className="tw-py-1">
                  {briefNavigation.map(item => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                            'tw-group tw-flex tw-w-full tw-items-center tw-px-4 tw-py-2 tw-text-sm'
                          )}
                          onClick={() => openSidebarTool(item.tool)}
                        >
                          <item.icon
                            className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                            aria-hidden="true"
                          />
                          {item.name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
                <div className="tw-py-1">
                  <Menu.Item>
                    <button
                      className="tw-text-gray-700 tw-group tw-flex tw-w-full tw-items-center tw-px-4 tw-py-2 tw-text-sm"
                      onClick={() => handleOnDeleteBrief(brief)}
                    >
                      <TrashIcon
                        className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                        aria-hidden="true"
                      />
                      Delete Brief
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </DashboardNav>
  );
};

export default BriefNavBar;
