import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { updateBriefName } from '../../../../redux/actions/briefs-actions';
import { Brief } from '../../../../services/models/brief';
import './SetBriefNameStep.scss';

interface Props {
  active: boolean;
  draftBrief: Brief | null;
}

const SetBriefNameStep: FunctionComponent<Props> = ({ active, draftBrief }) => {
  const dispatch = useAppDispatch();

  const briefs: Brief[] = useAppSelector(state => state.briefs.items || []);

  const [name, setName] = useState(draftBrief?.name);
  const [emptyName, setEmptyName] = useState(false);
  const [duplicatedName, setDuplicatedName] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);

  useEffect(() => {
    if (draftBrief && !name) {
      setName(draftBrief?.name);
    }
    setDuplicatedName(briefs.some(x => x.name === name && x.id !== draftBrief?.id));
    setEmptyName(name?.trim().length === 0);
  }, [name, setDuplicatedName, setEmptyName, duplicatedName, briefs, draftBrief]);

  const save = (event: SyntheticEvent) => {
    event.preventDefault();

    if (draftBrief && name) {
      dispatch(updateBriefName(draftBrief.id, name));
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameTouched(true);
    setName(event.target.value);
  };

  if (!active || !draftBrief) {
    return <></>;
  }

  return (
    <div className="name-screen-container">
      <Form onSubmit={e => save(e)}>
        <Form.Group>
          <Form.Label className="mt-3">
            <h2 className="bold-h1">Name Your News Brief</h2>
          </Form.Label>
          <Form.Control
            type="text"
            value={name}
            className="mt-3"
            isInvalid={nameTouched && (emptyName || duplicatedName)}
            onChange={(e: any) => handleNameChange(e)}
            placeholder="Enter your news brief title"
          />
          {nameTouched && emptyName && (
            <Form.Text className="text-muted">The name is mandatory</Form.Text>
          )}
          {nameTouched && duplicatedName && (
            <Form.Text className="text-muted">There's already a report with this name</Form.Text>
          )}
          <Button
            className="mt-4"
            type="submit"
            size="lg"
            disabled={draftBrief.isPublishing || emptyName || duplicatedName}
            variant="purple"
          >
            Save
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default SetBriefNameStep;
