import { isInHootSuite } from './hootsuite';
import { Account } from './models/account';

const actionMap: any = {
  StripeStartCheckoutFlow: 'Start Checkout Flow',
  ReportsActionStartCreating: 'Create Report',
};

export const trackPageArrival = () => {
  (window as any).analytics.page();
};

export const trackEvent = (event: string, properties?: any) => {
  let eventKey = event;

  if (actionMap[event]) {
    eventKey = actionMap[event];
  }

  (window as any).analytics.track(eventKey, properties);
};

export const identifyUser = (
  account: Account,
  activeReportsCount: number,
  totalReportsCount: number,
  isTwitterUser: boolean
) => {
  if (!account) {
    throw new Error(`Couldn't identify user.`);
  }

  const traits = {
    createdAt: account.memberSince,
    email: account.email?.emailAddress,
    firstName: account.firstName,
    lastName: account.lastName,
    plan: account.subscription?.planId,
    tier: account.product?.productId,
    product: account.product?.name,
    trial: account.subscription?.trial,
    trial_period_days: account.subscription?.trialPeriodDays,
    trial_starts_at: account.subscription?.trialStartDate,
    trial_ends_at: account.subscription?.trialEndDate,
    plan_active: account.subscription?.active,
    active_reports: activeReportsCount,
    total_reports: totalReportsCount,
    active_briefs: activeReportsCount,
    total_briefs: totalReportsCount,
    twitter_user: isTwitterUser,
  };

  const integrations = buildIntegrationsObject(account);

  (window as any).analytics.identify(account.userid, traits, integrations);
};

const buildIntegrationsObject = (account: Account) => {
  const integrations = {} as any;

  integrations.Intercom = isInHootSuite()
    ? false
    : {
        user_hash: account.intercomUserHash,
        hideDefaultLauncher: true,
      };

  // account.subscription?.active && !account.subscription?.trial ? false : true;
  integrations.FullStory = false;

  return integrations;
};

export const submitUserOnboarding = (
  account: Account,
  onboarding: {
    referal: string;
    reason: string;
    interest: string;
    role: string;
  }
) => {
  if (!account) {
    throw new Error(`Couldn't identify user.`);
  }
  const payload = {
    onboarding_role: onboarding.role,
    onboarding_referal: onboarding.referal,
    onboarding_reason: onboarding.reason,
    onboarding_interest: onboarding.interest,
  };
  (window as any).analytics.identify(account.userid, payload);
};

export const updateAccountTraits = (account: Account) => {
  if (!account) {
    throw new Error(`Couldn't identify user.`);
  }

  const traits = {
    firstName: account.firstName,
    lastName: account.lastName,
    email: account.email.emailAddress,
    active_reports: account.metadata?.activeReportsCount,
    total_reports: account.metadata?.totalReportsCount,
  };

  (window as any).analytics.identify(account.userid, traits);
};

export const reset = () => {
  (window as any).analytics.reset();
};
