import { BriefStoriesAction } from '../../actions/brief-stories-actions';
import { SessionAction } from '../../actions/session-actions';
import { SharedLinkAction } from '../../actions/shared-link-action';
import { SharedLinksAction } from '../../actions/shared-links-action';
import { SharedLinksStoreNode } from '../../store-state';
import sharedLinkItemsReducer from './items';

export default function sharedLinksReducer(state: SharedLinksStoreNode = {}, action: any) {
  switch (action.type) {
    case SharedLinksAction.StartFetching:
      return startFetching(state, action);

    case SharedLinksAction.CancelFetching:
      return cancelFetching(state, action);

    case SharedLinksAction.CompleteFetchingPage:
      return completeFetchingSharedlinksPage(state, action);

    case SharedLinkAction.StartFetching:
    case SharedLinkAction.CompleteFetching:
    case SharedLinkAction.CancelFetching:
    case BriefStoriesAction.StartMarkStoryAsViewed:
    case BriefStoriesAction.CompleteMarkStoryAsViewed:
    case BriefStoriesAction.CancelMarkStoryAsViewed:
      return updateItems(state, action);

    /**
     * Clear Briefs Store node after signout
     */
    case SessionAction.SignOut:
      return {};

    default:
      return state;
  }
}

const startFetching = (state: SharedLinksStoreNode, action: any) => {
  return {
    ...state,
    items: sharedLinkItemsReducer(state.items, action),
    isFetching: true,
    isFetchingFirstPage: action.isFirstPage,
  };
};

const cancelFetching = (state: SharedLinksStoreNode, action: any) => {
  return {
    ...state,
    items: sharedLinkItemsReducer(state.items, action),
    isFetching: false,
    isFetchingFirstPage: false,
  };
};

const completeFetchingSharedlinksPage = (state: SharedLinksStoreNode, action: any) => {
  return {
    ...state,
    items: sharedLinkItemsReducer(state.items, action),
    hasMore: action.hasMoreSharedLinks,
    lastLoadedCursor: action.lastCursor,
    isFetching: false,
    isFetchingFirstPage: false,
  };
};

const updateItems = (state: SharedLinksStoreNode, action: any) => {
  return { ...state, items: sharedLinkItemsReducer(state.items, action) };
};
