import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import DashboardNav from '../../components/dashboard-nav/DashboardNav';
import DashboardPageLayout from '../dashboard/dashboardPageLayout';

const settingsTabs = [
  { name: 'Overview', href: '/dashboard/settings/overview', current: true },
  { name: 'Plan & Billing', href: '/dashboard/settings/plan', current: false },
  { name: 'Integrations', href: '/dashboard/settings/integrations', current: false },
];

interface SettingsPageLayoutProps {
  title: string;
  children: ReactNode;
}

export default function SettingsPageLayout(props: SettingsPageLayoutProps) {
  return (
    <DashboardPageLayout title={props.title}>
      <div className="tw-flex tw-flex-1 tw-flex-col">
        <DashboardNav className="md:tw-hidden">
          <div className="tw-flex-1 tw-flex">
            <h1 className="tw-text-lg tw-font-bold tw-m-0 tw-text-gray-900">Settings</h1>
          </div>
        </DashboardNav>

        <main className="tw-flex tw-flex-1 tw-flex-col tw-w-full tw-overflow-y-auto focus:tw-outline-none">
          <div className="tw-relative md:tw-max-w-2xl tw-pb-8 md:tw-px-8 md:tw-pt-10 md:tw-pb-16">
            {/* Settings Nav Tabs */}
            <div className="tw-border-b tw-border-gray-200 tw-w-full">
              <div className="md:tw-flex md:tw-items-baseline">
                <h3 className="tw-hidden md:tw-flex tw-text-lg tw-leading-6 tw-font-bold tw-text-gray-900">
                  Settings
                </h3>
                <div className="tw-mt-4 md:tw-mt-0 md:tw-ml-10">
                  <nav className="tw--mb-px tw-flex tw-space-x-8">
                    {settingsTabs.map(tab => (
                      <NavLink
                        key={tab.name}
                        to={tab.href}
                        activeClassName="tw-border-secondary tw-text-secondary hover:tw-text-secondary-dark"
                        className="tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm hover:tw-no-underline"
                      >
                        {tab.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            {/* Settings Page Main Content*/}
            <div className="tw-px-4 sm:tw-px-6 md:tw-px-0">{props.children}</div>
          </div>
        </main>
      </div>
    </DashboardPageLayout>
  );
}
