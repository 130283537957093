import gql from 'graphql-tag';
import { ServiceResult } from '../redux/types';
import { EmptyResponseError } from './errors/empty-response-error';
import { getAuthenticatedGraphQLClient, getGraphQLClient } from './graphql-client';
import { Account } from './models/account';
import { ApiApp } from './models/api-app';
import { Email } from './models/email';
import { Setting } from './models/setting';
import { Subscription } from './models/subscription';
import { Viewer } from './models/viewer';
import { updateAccountTraits } from './segment';
import { onUserIdentified } from './user-events';

export const refreshUserAccount = async () => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ viewer: Viewer }>({
    query: _getViewerQuery(),
  });

  const account = response.data.viewer.account;
  account.metadata = {
    activeReportsCount: response.data.viewer.activeReportsCount,
    isTwitterUser: response.data.viewer.isTwitterUser,
    totalReportsCount: response.data.viewer.totalReportsCount,
  };

  updateAccountTraits(account);
};

export const getCurrentAccount = async (): Promise<Account> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.query<{ viewer: Viewer }>({
    query: _getViewerQuery(),
  });

  onUserIdentified(response.data.viewer);

  const account = response.data.viewer.account;
  account.metadata = {
    activeReportsCount: response.data.viewer.activeReportsCount,
    isTwitterUser: response.data.viewer.isTwitterUser,
    totalReportsCount: response.data.viewer.totalReportsCount,
  };

  return account;
};

const _getViewerQuery = () => {
  return gql`
    query getViewer {
      viewer {
        activeReportsCount
        isTwitterUser
        totalReportsCount
        account {
          userid
          firstName
          lastName
          hasSocialLogin
          hasPassword
          memberSince
          imageUrl
          intercomUserHash
          email {
            emailAddress
            verified
          }
          product {
            productId
            name
            features {
              briefs
              recipientsPerBrief
              keywordsPerBrief
              slackEnabled
              zapierEnabled
            }
            mostPopular
            published
          }
          subscription {
            active
            canceled
            canceledAt
            cardOnFile {
              brand
              lastFourDigits
            }
            currentPeriodEnd
            description
            interval
            pastDue
            planId
            status
            trial
            trialDaysLeft
            trialEndDate
            trialPeriodDays
            trialStartDate
          }
          settings {
            name
            value
          }
          apiApps {
            apiKey
            type
          }
          availableIntegrations
          externalUsers {
            bannerImageUrl
            description
            externalUserid
            imageUrl
            name
            type
            url
            username
          }
        }
      }
    }
  `;
};

export const updateEmail = async (emailAddress: string): Promise<Email> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ accountEmailUpdate: { email: Email } }>({
    mutation: _getUpdateEmailMutation(),
    variables: {
      email: emailAddress,
    },
  });

  if (!response.data) {
    throw new Error('Empty response');
  }

  if (response.data?.accountEmailUpdate.email.emailAddress === emailAddress) {
    return response.data?.accountEmailUpdate.email;
  }

  return {
    ...response.data?.accountEmailUpdate.email,
    emailAddress: emailAddress,
    verified: false,
  };
};

const _getUpdateEmailMutation = () => {
  return gql`
    mutation updateEmail($email: String) {
      accountEmailUpdate(input: { email: $email }) {
        email {
          id
          emailAddress
          verified
        }
      }
    }
  `;
};

export const updateName = async (firstName: string, lastName: string): Promise<Account> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ accountUpdate: Account }>({
    mutation: _getUpdateNameMutation(),
    variables: {
      firstName,
      lastName,
    },
  });

  if (!response.data) {
    throw new Error('Empty response');
  }

  return response.data?.accountUpdate;
};

const _getUpdateNameMutation = () => {
  return gql`
    mutation updateName($firstName: String, $lastName: String) {
      accountUpdate(input: { firstName: $firstName, lastName: $lastName }) {
        firstName
        lastName
      }
    }
  `;
};

export const updateSetting = async (name: string, value: string): Promise<Setting[]> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ accountSettingsUpdate: Setting[] }>({
    mutation: _getUpdateSettingMutation(),
    variables: {
      name,
      value,
    },
  });

  if (!response.data) {
    throw new Error('Empty response');
  }

  return response.data?.accountSettingsUpdate;
};

const _getUpdateSettingMutation = () => {
  return gql`
    mutation updateSetting($name: String, $value: String) {
      accountSettingsUpdate(input: { settings: [{ name: $name, value: $value }] }) {
        dateUpdated
        dateAdded
        name
        value
      }
    }
  `;
};

export const cancelSubscription = async (): Promise<Subscription> => {
  const client = getAuthenticatedGraphQLClient();
  const response = await client.mutate<{ subscriptionCancel: Subscription }>({
    mutation: _getCancelSubscriptionMutation(),
  });

  if (!response.data || !response.data.subscriptionCancel) {
    throw new EmptyResponseError();
  }

  return response.data.subscriptionCancel;
};

const _getCancelSubscriptionMutation = () => {
  return gql`
    mutation cancelSubscription {
      subscriptionCancel {
        active
        canceled
        canceledAt
        cardOnFile {
          brand
          lastFourDigits
        }
        currentPeriodEnd
        description
        interval
        pastDue
        planId
        status
        trial
        trialDaysLeft
        trialEndDate
        trialPeriodDays
        trialStartDate
      }
    }
  `;
};

export const deactivateAccount = async (reason: string, comment: string): Promise<boolean> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ accountDeactivation: { ok: boolean } }>({
    mutation: _getDeactivateAccountMutation(),
    variables: {
      reason,
      comment,
    },
  });

  if (!response.data || !response.data.accountDeactivation) {
    throw new EmptyResponseError();
  }

  return response.data.accountDeactivation.ok;
};

const _getDeactivateAccountMutation = () => {
  return gql`
    mutation deactivateAccount($reason: String, $comment: String) {
      accountDeactivation(input: { reason: $reason, comment: $comment }) {
        ok
      }
    }
  `;
};

export const generateZapierApiKey = async (): Promise<ApiApp> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ zapierApiKeyCreate: ApiApp }>({
    mutation: _getGenerateZapierApiKeyMutation(),
  });

  if (!response.data || !response.data.zapierApiKeyCreate) {
    throw new EmptyResponseError();
  }

  return response.data.zapierApiKeyCreate;
};

const _getGenerateZapierApiKeyMutation = () => {
  return gql`
    mutation generateZapierApiKey {
      zapierApiKeyCreate {
        apiKey
        type
      }
    }
  `;
};

export const sendVerificationEmail = async (): Promise<boolean> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ accountResendVerificationEmail: { ok: boolean } }>({
    mutation: _getSendVerificationEmailMutation(),
  });

  if (!response.data || !response.data.accountResendVerificationEmail) {
    throw new EmptyResponseError();
  }

  return response.data.accountResendVerificationEmail.ok;
};

const _getSendVerificationEmailMutation = () => {
  return gql`
    mutation sendVerificationEmail {
      accountResendVerificationEmail {
        ok
      }
    }
  `;
};

export const verifyEmail = async (code: string): Promise<boolean> => {
  const client = getGraphQLClient();
  const response = await client.mutate<{ accountEmailVerification: { ok: boolean } }>({
    mutation: _getVerifyEmailMutation(),
    variables: {
      code,
    },
  });

  if (!response.data || !response.data.accountEmailVerification) {
    throw new EmptyResponseError();
  }

  return response.data.accountEmailVerification.ok;
};

const _getVerifyEmailMutation = () => {
  return gql`
    mutation verifyEmail($code: String!) {
      accountEmailVerification(input: { code: $code }) {
        ok
      }
    }
  `;
};

export const updatePassword = async (
  newPassword: string,
  currentPassword: string
): Promise<ServiceResult> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ accountPasswordUpdate: { ok: boolean } }>({
    mutation: _getUpdatePasswordMutation(),
    variables: {
      currentPassword,
      newPassword,
    },
  });

  if (!response.data) {
    throw new Error('Empty response');
  }

  return {
    ok: response.data.accountPasswordUpdate.ok,
  };
};

const _getUpdatePasswordMutation = () => {
  return gql`
    mutation updatePassword($currentPassword: String, $newPassword: String!) {
      accountPasswordUpdate(
        input: { currentPassword: $currentPassword, newPassword: $newPassword }
      ) {
        ok
      }
    }
  `;
};

export const createRefreshToken = async (): Promise<string> => {
  const client = getAuthenticatedGraphQLClient();

  const response = await client.mutate<{ accountRefreshTokenCreate: { refreshToken: string } }>({
    mutation: _getCreateRefreshTokenMutation(),
  });

  if (!response.data || !response.data.accountRefreshTokenCreate) {
    throw new EmptyResponseError();
  }

  return response.data.accountRefreshTokenCreate.refreshToken;
};

const _getCreateRefreshTokenMutation = () => {
  return gql`
    mutation accountRefreshTokenCreate {
      accountRefreshTokenCreate {
        refreshToken
      }
    }
  `;
};
