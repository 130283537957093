import { Spinner } from '@blueprintjs/core';
import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import zapierLogo from '../../../../assets/images/integrations/zapier.svg';
import withAvailableIntegration from '../../../../hoc/withAvailableIntegration';
import withSubscriptionAwareness from '../../../../hoc/withSubscriptionAwareness';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAvailableIntegration } from '../../../../hooks/useAvailableIntegration';
import SettingsPageLayout from '../../../../layouts/settings/settingsPageLayout';
import { generateZapierApiKey } from '../../../../redux/actions/account-actions';
import { AppDispatch } from '../../../../redux/store';
import { configuration } from '../../../../services/configuration';
import { Account } from '../../../../services/models/account';
import { ApiApp } from '../../../../services/models/api-app';
import { AppType } from '../../../../services/models/app-type';

const ZapierIntegrationPage: FunctionComponent = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();

  const account: Account = useAppSelector(state => state.session.account);
  const zapierApp = useAppSelector(state => {
    let apiApps: ApiApp[] = state.session?.account?.apiApps || [];
    return apiApps.find(x => x.type === AppType.Zapier);
  });

  const isIntegrationAvailable = useAvailableIntegration(AppType.Zapier);

  useEffect(() => {
    const widget = document.createElement('script');
    widget.type = 'text/javascript';
    widget.async = true;
    widget.src = configuration.zapier.embeddedWidgetUrl;
    document.body.appendChild(widget);
  }, []);

  return (
    <SettingsPageLayout title="Zapier Integration | Newslit">
      <div className="tw-mt-10 tw-divide-y tw-divide-gray-200">
        <div className="tw-flex tw-tw-justify-between tw-items-center tw-py-3">
          <div className="tw-space-y-1">
            <h3 className="tw-text-lg tw-leading-6 tw-font-bold tw-text-gray-900">
              Zapier Integration
            </h3>
            <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500 tw-m-0">
              To integrate your Newslit News Brief with other services using Zapier, you will need
              to use the following key.
            </p>
          </div>
          <div className="tw-w-16 tw-flex-shrink-0">
            <img className="tw-h-10 tw-p-1 tw-object-contain" src={zapierLogo} alt="Zapier Logo" />
          </div>
        </div>

        <div className="tw-mt-6">
          <dl className="">
            <div className="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
              <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Zapier Key</dt>
              <dd className="tw-mt-1 tw-flex tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
                <span className="tw-flex-grow">
                  {isIntegrationAvailable && zapierApp && <code>{zapierApp.apiKey}</code>}
                </span>
                <span className="tw-ml-4 tw-flex-shrink-0">
                  {!isIntegrationAvailable && (
                    <button
                      type="button"
                      className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                      onClick={() => {
                        history.push('/dashboard/settings/pricing');
                      }}
                    >
                      Upgrade your plan
                    </button>
                  )}
                  {isIntegrationAvailable && !zapierApp && (
                    <button
                      type="button"
                      className="tw-bg-white tw-rounded-md tw-font-medium tw-text-secondary-dark hover:tw-text-secondary focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary"
                      onClick={() => dispatch(generateZapierApiKey())}
                      disabled={account.isGeneratingZapierApiKey}
                    >
                      {account.isGeneratingZapierApiKey ? 'Generating Key' : 'Generate Key'}
                    </button>
                  )}
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="tw-mt-10 tw-divide-y tw-divide-gray-200">
        <div className="tw-space-y-1">
          <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">Popular Zaps</h3>
          <p className="tw-max-w-2xl tw-text-sm tw-text-gray-500">
            Connect your Newslit Briefs to 2,000+ Apps using Zapier!
          </p>
        </div>
        <div className="tw-mt-6 tw-pt-3">
          <div id="js-zapier-integration-embed">
            <div className="loading-zapier">
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    </SettingsPageLayout>
  );
};

export default withSubscriptionAwareness(
  withAvailableIntegration(AppType.Zapier, ZapierIntegrationPage)
);
