import { Honeybadger } from '@honeybadger-io/react';
import { GraphQLError } from 'graphql';
import { configuration } from './configuration';

export const bootstrap = () => {
  Honeybadger.configure({
    apiKey: configuration.honeybadger.apiKey,
    environment: configuration.honeybadger.environmentName,
    //disabled: configuration.environment === 'local-dev',
    revision: configuration.version,
  });
};

export const identifyUser = (userId: number) => {
  Honeybadger.setContext({
    user_id: userId,
  });
};

export const notify = (error: any, component: string) => {
  if (!error.hasOwnProperty('graphQLErrors')) {
    Honeybadger.notify(error, { component: component });
    return;
  }

  error.graphQLErrors.forEach((graphQLError: GraphQLError) => {
    const errorOptions = {
      message: graphQLError ? graphQLError.message : 'Error message not found.',
      action: graphQLError && graphQLError.path ? String(graphQLError.path[0]) : undefined,
      component: component,
    };

    Honeybadger.notify(graphQLError, 'GraphQLError', errorOptions);
  });
};

export const resetContext = () => {
  Honeybadger.resetContext();
};
