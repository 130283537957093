import { Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import React, { FunctionComponent } from 'react';

const LoadingSpinner: FunctionComponent = () => {
  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-screen">
      <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
    </div>
  );
};

export default LoadingSpinner;
