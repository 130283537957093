import jstz from 'jstz';
import React, { FunctionComponent, useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { updateTimezoneSetting } from '../../redux/actions/settings-actions';
import { AppSetting } from '../../services/models/app-settings';

const TimezoneInferer: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const settings = useAppSelector(state => state.session?.account?.settings || null);

  const isUpdatingTimezone = useAppSelector(
    state => state.session?.account?.isUpdatingTimeZoneSettings || false
  );

  const areSettingsLoaded = !!settings && settings.length > 0;

  const timezoneSetting = settings
    ? settings.find(x => x.name === AppSetting.Timezone) || null
    : null;

  useEffect(() => {
    if (areSettingsLoaded && !isUpdatingTimezone && (!timezoneSetting || !timezoneSetting.value)) {
      const timezone = jstz.determine();
      const inferedTimeZone = timezone.name();
      dispatch(updateTimezoneSetting(inferedTimeZone));
    }
  }, [timezoneSetting, areSettingsLoaded, isUpdatingTimezone, dispatch]);

  return <></>;
};

export default TimezoneInferer;
