import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as UAParser from 'ua-parser-js';
import useEventListener from '../hooks/useEventListener';
import * as plausible from '../services/plausible';
import * as segment from '../services/segment';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

type DefaultValue = {
  browser: string | undefined;
  clearDeferredPrompt?: () => void;
  deferredPrompt: BeforeInstallPromptEvent | null;
  isInstalled: boolean;
  isIos: boolean;
  isPwa: boolean;
  isPwaCompatibleBrowser: boolean;
  showInstallButton: boolean;
  showPwaInstallButton: boolean;
};

const defaultValue: DefaultValue = {
  browser: undefined,
  deferredPrompt: null,
  isInstalled: false,
  isIos: false,
  isPwa: false,
  isPwaCompatibleBrowser: false,
  showInstallButton: false,
  showPwaInstallButton: false,
};

const uaParser = new UAParser.UAParser();

const PwaContext = createContext(defaultValue);

interface PwaContextProviderProps {
  children: ReactNode;
}
export const PwaContextProvider = ({ children }: PwaContextProviderProps) => {
  const [browser, setBrowser] = useState<string | undefined>(undefined);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [showPwaInstallButton, setShowPwaInstallButton] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isPwa, setIsPwa] = useState(false);
  const [isPwaCompatibleBrowser, setIsPwaCompatibleBrowser] = useState(false);

  function iOS() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  function getPWADisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return 'twa';
    } else if ((window.navigator as any).standalone || isStandalone) {
      return 'standalone';
    }
    return 'browser';
  }

  useEffect(() => {
    console.log('[Newslit/PWA] - PWA compatible browser ', isPwaCompatibleBrowser);
    console.log('[Newslit/PWA] - show PWA install prompt ', showPwaInstallButton);

    setIsPwaCompatibleBrowser(!!deferredPrompt && showPwaInstallButton);
  }, [showPwaInstallButton, deferredPrompt, isPwaCompatibleBrowser]);

  useEffect(() => {
    // Access window.navigator.userAgent only once, this prevents browser from re-rendering
    uaParser.setUA(window.navigator.userAgent);
    setBrowser(uaParser.getBrowser().name);
    setIsIos(iOS);
    setIsPwa(getPWADisplayMode() === 'standalone');
  }, []);

  useEventListener('beforeinstallprompt', (e: Event) => {
    e.preventDefault();
    setShowPwaInstallButton(true);
    setDeferredPrompt(e as BeforeInstallPromptEvent);
    console.log(`[Newslit] 'beforeinstallprompt' event was fired.`);
  });

  useEventListener('appinstalled', (e: Event) => {
    plausible.trackEvent('Install App Completed');
    segment.trackEvent('Install App Completed');
    setIsInstalled(true);
    setDeferredPrompt(null);
    console.log(`[Newslit] 'appinstalled' event was fired.`);
  });

  const clearDeferredPrompt = useCallback(() => {
    setDeferredPrompt(null);
  }, []);

  const showInstallButton = (() => {
    if (isPwa) return false;

    if (!isPwaCompatibleBrowser && !(browser?.includes('Safari') || isIos)) return false;

    return true;
  })();

  const pwaProviderValue = {
    browser,
    clearDeferredPrompt,
    deferredPrompt,
    isInstalled,
    isIos,
    isPwa,
    isPwaCompatibleBrowser,
    showInstallButton,
    showPwaInstallButton,
  };

  return (
    <PwaContext.Provider value={pwaProviderValue}>
      <>{children}</>
    </PwaContext.Provider>
  );
};

export const usePwaContext = () => useContext(PwaContext);
