import { XCircleIcon, XIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { serviceDispatch } from '../../redux/types';
import { updatePassword } from '../../services/account';
import { Account } from '../../services/models/account';
import { Input, Modal } from '../ui';

interface UpdatePasswordDialogProps {
  isOpen: boolean;
  account: Account;
  onClose: () => void;
}

export default function UpdatePasswordDialog(props: UpdatePasswordDialogProps) {
  const cancelButtonRef = useRef(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema: yup.object().shape({
      currentPassword: props.account.hasPassword
        ? yup.string().min(6).max(32).required('Your current password is required')
        : yup.string(),
      newPassword: yup.string().min(6).max(32).required('Your new nassword is required'),
      newPasswordConfirmation: yup
        .string()
        .min(6)
        .max(32)
        .when('newPassword', {
          is: (val: any) => !!(val && val.length > 0),
          then: yup.string().oneOf([yup.ref('newPassword')], 'Both password need to be the same'),
        })
        .required('Password verification required'),
    }),
    onSubmit: values => {
      setIsUpdating(true);
      changePassword(values.newPassword, values.currentPassword);
    },
  });

  const handleOnCancel = () => {
    setIsUpdating(false);
    setErrorMessage('');
    formik.resetForm();
    props.onClose();
  };

  const getCleanError = (source?: string): string => {
    return source?.replace(
      'GraphQL error: Exception while fetching data (/accountPasswordUpdate) : ',
      ''
    ) as string;
  };

  const changePassword = async (newPassword: string, currentPassword: string) => {
    const response = await serviceDispatch(() => updatePassword(newPassword, currentPassword));

    setIsUpdating(false);

    if (response.ok) {
      setTimeout(() => props.onClose(), 250);
    } else {
      setErrorMessage(getCleanError(response.message));
    }
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} initialFocus={cancelButtonRef}>
      <div className="tw-flex tw-flex-col">
        <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
          {props.account.hasPassword ? 'Change' : 'Set'} your password
        </h3>
        <div className="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
          <p>{props.account.hasPassword ? 'Change the' : 'Set a new'} password for your account.</p>
        </div>

        {errorMessage !== '' && (
          <div className="tw-rounded-md tw-bg-red-50 tw-p-4 tw-my-2">
            <div className="tw-flex">
              <div className="tw-flex-shrink-0">
                <XCircleIcon className="tw-h-5 tw-w-5 tw-text-red-400" aria-hidden="true" />
              </div>
              <div className="tw-ml-3">
                <p className="tw-text-sm tw-font-medium tw-text-red-800">{errorMessage}</p>
              </div>
              <div className="tw-ml-auto tw-pl-3">
                <div className="tw--mx-1.5 tw--my-1.5">
                  <button
                    type="button"
                    className="tw-inline-flex tw-bg-red-50 tw-rounded-md tw-p-1.5 tw-text-red-500 hover:tw-bg-red-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-red-50 focus:tw-ring-red-600"
                    onClick={() => setErrorMessage('')}
                  >
                    <span className="tw-sr-only">Dismiss</span>
                    <XIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <form
          onSubmit={formik.handleSubmit}
          className="tw-grid tw-grid-cols-1 tw-gap-y-6 tw-gap-x-4 tw-grid-cols-6 tw-mt-3"
        >
          {props.account.hasPassword && (
            <div className="tw-col-span-6">
              <label
                htmlFor="currentPassword"
                className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
              >
                Your current Password
              </label>
              <div className="tw-mt-1">
                <Input
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  autoComplete="current-password"
                  required
                  error={
                    formik.touched.currentPassword && formik.errors.currentPassword
                      ? formik.errors.currentPassword
                      : undefined
                  }
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          )}
          <div className="tw-col-span-6">
            <label
              htmlFor="newPassword"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
            >
              Your new Password
            </label>
            <div className="tw-mt-1">
              <Input
                id="newPassword"
                name="newPassword"
                type="password"
                autoComplete="current-password"
                required
                error={
                  formik.touched.newPassword && formik.errors.newPassword
                    ? formik.errors.newPassword
                    : undefined
                }
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div className="tw-col-span-6">
            <label
              htmlFor="newPasswordConfirmation"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
            >
              Verify your new password
            </label>
            <div className="tw-mt-1">
              <Input
                id="newPasswordConfirmation"
                name="newPasswordConfirmation"
                type="password"
                autoComplete="current-password"
                required
                error={
                  formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation
                    ? formik.errors.newPasswordConfirmation
                    : undefined
                }
                value={formik.values.newPasswordConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div className="tw-col-span-6">
            <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
              <button
                type="submit"
                className="tw-mt-3 tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-secondary hover:tw-bg-secondary-dark focus:tw-outline-none focus:ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm disabled:tw-opacity-50"
                disabled={isUpdating || !formik.isValid}
              >
                {isUpdating && (
                  <svg
                    className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="tw-opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="tw-opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Save
              </button>
              <button
                type="button"
                className="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-secondary sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm"
                onClick={handleOnCancel}
                ref={cancelButtonRef}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
