export const Roles = [
  'Public Relations',
  'Marketing',
  'Communications',
  'Analytics',
  'Founder or Executive',
  'Engineering',
  'Other',
];

export const Origins = ['Web Search', 'LinkedIn', 'Twitter', 'Word of Mouth', 'Other'];
